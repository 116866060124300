import * as types from './types';
import axios from 'axios';

import user from '@/utils/user';
import globalUtils from '@/utils/global';

import {_} from 'vue-underscore';

/*eslint no-console: ["error", { allow: ["log"] }] */
export default {
  //calendar
  // eslint-disable-next-line
  calendarPreValidate ({commit}, params) {
    // eslint-disable-next-line max-statements
    return new Promise((resolve) => {
      let orginalCompanySlug = user.getUser('company', 'slug');
      let orginalWorkerSlug = user.getUser('worker', 'slug');
      let workerSlug = params.workerSlug;
      let companySlug = params.companySlug || orginalCompanySlug;
      let path;
      if (!user.isProperlyLogged()) {
        if (companySlug) {
          globalUtils.getCompanyFromSlug(companySlug).then(company => {
            if (_.isEmpty(company)) {
              path = '/404';
            } else {//valid company slug
              if (workerSlug) {
                //when worker provided then redirect company page
                path = `/cal/${companySlug}`;
              }
            }
            return path;
          }).then(path => {
            resolve({path});//path to redirect
          });
        } else {
          path = '/login?r=/cal';
          resolve(path);
        }
      } else {
        if (user.hasManagerAccount()) {//manager login
          if (orginalCompanySlug && orginalCompanySlug !== companySlug) {
            path = '/cal';
          }
          //TODO: verify worker slug belongs to company also
        } else {//worker login
          if (orginalWorkerSlug && orginalWorkerSlug !== workerSlug) {
            path = `/cal/${orginalCompanySlug}/${orginalWorkerSlug}`;
          }
        }
        resolve(path);
      }
    });
  },
  login ({commit}, creds) {
    axios({
      method: 'post',
      url: process.env.VUE_APP_CAS_API_URL + 'users/login',
      data: {
        username: creds.username,
        password: creds.password
      },
      headers: {
        'X-Application-Key': process.env.VUE_APP_KEY
      }
    })
    .then(function (response) {
      var r = response.data;
      if (r.status === 'success') {
        let token = response.headers['x-auth-token'];
        // set token
        localStorage.setItem('x-simple-tym-token', token);
      } else {
        localStorage.setItem('login_error', r.message);
      }
    })
    .catch(function (error) {
      localStorage.setItem('login_error', error.name + ': ' + error.message);
     });
    return new Promise((resolve) => {
      setTimeout(() => {
        if (localStorage.getItem('x-simple-tym-token')) {
          commit(types.LOGIN_SUCCESS);
        } else {
          commit(types.LOGIN_FAIL, localStorage.getItem('login_error'));
          localStorage.removeItem('login_error');
        }
        resolve();
      }, 2000);
    });
  },
  logout ({commit}) {
    let skipIntro = localStorage.getItem('introState');
    localStorage.clear();
    localStorage.setItem('introState', skipIntro);
    sessionStorage.clear();
    commit(types.LOGOUT);
  }
};
