import * as moment from 'moment-timezone';

export const calendar = {
  data() {
    return {
      currentType: 1,
      types: [
        {
          id: 1,
          title: 'Week',
          disabled: false
        },
        {
          id: 2,
          title: 'Month',
          disabled: true
        }
      ],
      dates: []
    };
  },

  computed: {
    currentViewDates () {
      let _vm = this;
      let output = '';
      if (_vm.dates.length) {
        let statTimestamp = _vm.dates[0];
        let endTimestamp = _vm.dates[_vm.dates.length-1];
        let start = moment(statTimestamp*1000).utcOffset(0);
        let end = moment(endTimestamp*1000).utcOffset(0);
        if (start.year() !== end.year()) {
          output = `${start.format('MMMM Do YYYY')}
                      - ${end.format('MMMM Do YYYY')}`;
        } else {
          output = `${start.format('MMMM Do')}
                      - ${end.format('MMMM Do')} ${end.year()}`;
        }
      }
      return output;
    },
    isSameYear() {
      let _vm = this;
      let statTimestamp = _vm.dates[0];
      let endTimestamp = _vm.dates[_vm.dates.length-1];
      let start = moment(statTimestamp*1000).utcOffset(0);
      let end = moment(endTimestamp*1000).utcOffset(0);
      return start.year() === end.year();
    }
  },

  methods: {
    isDayView () {
      return (this.currentType === 0);
    },
    isWeekView () {
      return (this.currentType === 1);
    },
    isMonthView () {
      return (this.currentType === 2);
    },
    getCalendarDay() {
      return (this.companyInfo)
                ? this.companyInfo.mobileStartWeekDay
                : 1;
    },
    // set to specific date date range
    toDate(date) {
      let _vm = this;
      let daysCount = 7;
      let begin;
      if (_vm.isWeekView()) {
        begin = date.startOf('week')
                    .weekday(_vm.getCalendarDay())
                    .unix();
      }
      return _vm.updateView(begin, daysCount);

    },

    goToday () {
      return this.$router.push({name: 'calendar'});
    },
    //set today dates range
    today () {
      return this.toDate(moment().utcOffset(0));
    },
    prev () {
      let _vm = this;
      let begin;
      let daysCount = 7;
      //next week/month date
      let timestamp = _vm.dates[0] - daysCount*86400;
      if (_vm.isWeekView()) {
        begin = moment(timestamp*1000).utcOffset(0)
                                      .startOf('week')
                                      .weekday(_vm.getCalendarDay());
        let route = {name: 'calendar', params: _vm.$route.params};
        route.params.year = begin.format('YYYY');
        route.params.month = begin.format('MM');
        route.params.day = begin.format('DD');
        _vm.$router.push(route);
      }
      return _vm.updateView(begin ? begin.unix() : begin, daysCount);
    },
    next () {
      let _vm = this;
      let begin;
      //next week/month date
      let timestamp = _vm.dates[_vm.dates.length-1] + 86400;
      let daysCount = 7;
      if (_vm.isWeekView()) {
        begin = moment(timestamp*1000).utcOffset(0)
                                      .startOf('week')
                                      .weekday(_vm.getCalendarDay());
        let route = {name: 'calendar', params: _vm.$route.params};
        route.params.year = begin.format('YYYY');
        route.params.month = begin.format('MM');
        route.params.day = begin.format('DD');
        _vm.$router.push(route);
      }
      return _vm.updateView(begin ? begin.unix() : begin, daysCount);
    },
    updateView (timestamp, count) {
      return new Promise((resolve) => {
        let dates = [];
        let _vm = this;
        dates.push(timestamp);
        for (let i=1; i < count; i++) {
          timestamp += 86400;
          dates.push(timestamp);
        }
        _vm.dates = dates;
        resolve(dates);
      });
    }
  }
};
