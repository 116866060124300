<template>
    <div>
        <div class="left-content">
            <h1>Easy way to manage your workers time easily!</h1>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'home-left-content',
    data () {
      return {
      };
    },
    methods: {
    }
  };
</script>
<style scoped>
    .left-content {
        width: 550px;
        margin-top: 45px;
    }
    .left-content h1{
        font-size: 45px;
        font-family: 'Lato', sans-serif;
        text-shadow: 2px 2px #bbb;
        text-align: center;
    }
    .left-content {
        position: absolute;
        top: 120px;
        left: 10px
    }
</style>
