<template>
    <div style="background: #FFF;">
        <div class="body">
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ page.title }}</v-toolbar-title>
              <v-divider
                class="mx-2"
                inset
                vertical
              ></v-divider>
              <v-text-field
                :label="labels.search"
                v-model="search"
                append-icon="search"
                style="max-width: 350px"
                color="teal"
              ></v-text-field>
              <div class="searchHelpText">
                Search starts from {{ minSearchCharacterCount }} characters
              </div>
              <v-spacer></v-spacer>
               <v-switch
                :label="adminViewStatus"
                v-model="adminView"
                @change="changeView()"
                color="teal"
                width="20px"
                :hide-details="true"
                v-if="isAdmin()"
              ></v-switch>
              <v-dialog
                v-model="dialog"
                max-width="650px"
                :persistent="true"
                scrollable
              >
                <v-btn
                  slot="activator"
                  :style="greenButtonStyle"
                  dark
                  class="mb-2"
                  @click="reset"
                >
                <v-icon small>add</v-icon>&nbsp;
                    {{ labels.create }}
                </v-btn>

                <custom-card :card-title="formTitle"
                             :card-help-text="dialogHelpText"
                             @save="save" @close="close">
                    <v-container
                      grid-list-md
                      class="noVertcalPadding"
                    >
                        <v-layout wrap>
                        <v-flex xs12 sm6 md4 class="pa-0">
                          <!--FormManager
                            ref="mform"
                            class="mform"
                            inputColor="teal"
                            showSubtitle="true"
                            :companyId="companyId"
                            :form="editedItem"
                          /-->
                          <FormManager
                            ref="mform"
                            class="mform"
                            inputColor="teal"
                            :companyId="companyId"
                          />
                        </v-flex>
                        </v-layout>
                    </v-container>
                  </custom-card>
              </v-dialog>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="managers"
              :pagination.sync="pagination"
              :total-items="totalManagers"
              :loading="loading"
              :search="search"
              select-all
              :no-data-text='noRecordsText'
              :rows-per-page-items="selectPagination"
              v-model="selected"
              hide-actions
              style="border: 3px solid #04796e; background-color: #04796e;"
            >
            <v-progress-linear
              slot="progress"
              background-color="teal lighten-4"
              color="teal lighten-3"
              height="4"
              indeterminate
            ></v-progress-linear>
              <template slot="headers" slot-scope="props">
                <tr
                  :key="props.index"
                  style="border-bottom: 5px solid #04796e;">
                  <th class="teal">
                    <v-checkbox
                      :input-value="props.all"
                      :indeterminate="props.indeterminate"
                      primary
                      hide-details
                      @click.native="toggleAll"
                      :key="props.index"
                    ></v-checkbox>
                  </th>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="getHeadersConfig(header)"
                    @click="changeSort(header.sortable, header.value)"
                  >
                    <v-icon small v-if="header.sortable">arrow_upward</v-icon>
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template slot="items" slot-scope="props">
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details
                    color="teal"
                  ></v-checkbox>
                </td>
                <!--td :class="[
                  'text-xs-center'
                ]">
                {{ props.index+pagination.number }}
                </td-->
                <td
                  :class="[
                  'text-xs-center'
                  ]"
                  v-html="mapText(props.item.firstName
                    + ' ' + props.item.lastName)">
                </td>
                <td
                  :class="[
                  'text-xs-center'
                  ]"
                  v-html="mapText(props.item.email)"></td>
                <td
                  :class="[
                    'text-xs-center',
                    !adminView ? 'hide' : ''
                  ]">{{ props.item["Company.name"] }}</td>
                <td :class="[
                    'text-xs-center',
                    !adminView ? 'hide' : ''
                  ]">{{ props.item["Company.slug"] }}</td>
                <td
                  :class="[
                    'text-xs-center'
                  ]">
                  {{ readableOffset(props.item.timezone) }}
                  {{ props.item.timezone_text }}
                </td>
                <td
                  :class="[
                    'justify-center px-0'
                  ]"
                  :style="adminView ? 'width: 120px;': ''"
                >
                    <!--v-btn
                      v-show="allowManage(props.item)"
                      color="warning"
                      @click="editItem(props.item)"
                      fab
                      small
                      dark
                    >
                    <v-icon>{{ icons.edit }}</v-icon>
                    </v-btn-->
                    <v-btn
                      v-show="allowManage(props.item)"
                      color="error"
                      @click="deleteItem(props.item)"
                      title="Revoke Access"
                      fab
                      small
                      dark
                    >
                    <v-icon>{{ icons.delete }}</v-icon>
                    </v-btn>
                </td>
              </template>
              <template slot="footer">
                <tr
                  class="teal"
                  style="border-top: 4px solid rgb(4, 121, 110)"
                >
                  <td colspan="100%">
                      <v-layout row wrap>
                          <v-flex xs1 class="perPage white--text">
                            Per page :
                          </v-flex>
                          <v-flex xs1>
                              <v-select
                                  :items="selectPagination"
                                  v-model="pagination.rowsPerPage"
                                  :single-line="true"
                                  solo
                                  @change="resetPagination"
                              ></v-select>
                          </v-flex>
                          <v-flex xs8></v-flex>
                          <v-flex xs1>
                            <v-btn
                              color="error"
                              :disabled="disableBulkDelete"
                              v-show="!disableBulkDelete"
                              @click="bulkDelete"
                            >
                            {{ deleteManagerText }}
                            </v-btn>
                          </v-flex>
                      </v-layout>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                color="teal"
                circle
                @input="nextPage"
              ></v-pagination>
            </div>
            <v-dialog
              v-model="floading"
              hide-overlay
              persistent
              width="300"
            >
              <v-card
                color="teal"
                dark
              >
              <v-card-text>
                Please wait few moments...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
    </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */

//import all components
import HomeHeader from '@/components/layouts/HomeHeader';
import FormManager from '@/components/managers/FormManager';
import CustomCard from '@/components/common/custom-card';
import axios from 'axios';
import {
  VDialog,
  VDivider,
  VPagination,
  VCheckbox,
  VProgressLinear,
  VSelect,
  VTextField,
  VSwitch
} from 'vuetify';

//import all mixins
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {company} from '@/mixins/company';
import {crud} from '@/mixins/crud';
import {theme} from '@/mixins/theme';

//require node modules
var _ = require('underscore');

export default {
  name: 'Managers',
  mixins: [
    user,
    timezone,
    company,
    crud,
    theme
  ],
  components: {
    HomeHeader,
    VDialog,
    VDivider,
    VPagination,
    VCheckbox,
    VSelect,
    VProgressLinear,
    VTextField,
    FormManager,
    VSwitch,
    CustomCard
  },
  data () {
    return {
      adminView: false,
      floading: false,
      dialog: false,
      dialogSettings: {
        manager: {
        }
      },
      editedIndex: -1,
      editedItem: {},
      totalManagers: 0,
      loading: false,
      disableBulkDelete: true,
      disableDeleteNotification: false,
      selected: [],
      deleted: [],
      deleteManagerText: '',
      selectPagination: [20, 30, 40, 50],
      noRecordsText: '',
      search: '',
      page: {
        title: 'Managers'
      },
      pagination: {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: 10
      },
      headers: [
        /*{
            text: '#',
            sortable: false,
            value: 'index'
        },*/
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email'
        },
        {
          text: 'Company Name',
          sortable: true,
          value: 'name',
          hide: true
        },
        {
          text: 'Company Slug',
          sortable: true,
          value: 'slug',
          hide: true
        },
        {
          text: 'Timezone',
          sortable: false,
          value: 'timezone'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      managers: [],
      labels: {
        create: 'Add Manager',
        search: 'Search a Manager',
        createHelpText: '(Give full manager access to a user.)',
        delete: 'Delete Manager',
        deleteMany: 'Delete Managers',
        deleteAll: 'Delete All Managers',
        edit: 'Edit Manager',
        editHelpText: 'Edit manager informations.'
      }
    };
  },
  created () {
    let _vm = this;
    //emit start front loading
    _vm.$root.$on('onFrontLoading', () => {
      _vm.floading = true;
    });
    //emit stop front loading
    _vm.$root.$on('offFrontLoading', () => {
      _vm.floading = false;
    });
    //emit close
    _vm.$root.$on('close', () => {
      _vm.close();
    });
    //emit reload
    _vm.$root.$on('reload', () => {
      _vm.reload();
    });
  },
  mounted () {
    let _vm = this;
    _vm.pagination.rowsPerPage = _vm.$root.settings.defPageSize;
    if (_vm.pagination.rowsPerPage) {
      _vm.load();
    }
    _vm.$root.$watch('settings.defPageSize', function(value) {
      if (value) {
        _vm.pagination.rowsPerPage = value;
        _vm.load();
      }
    });
    //cancel sorting
    if (_vm.pagination.hasOwnProperty('sortBy')) {
      _vm.pagination = _.omit(_vm.pagination, ['sortBy']);
    }
  },
  watch: {
    pagination: {
      handler () {
        let _vm = this;
        _vm.disableBulkDelete = true;
        _vm.selected = [];
      },
      deep: true
    },
    selected: {
      handler () {
        let _vm = this;
        let length = _vm.selected.length;
        _vm.disableBulkDelete = !length;
        //small adjust for plural && all
        if (length === 1) {
          _vm.deleteManagerText = _vm.labels.delete;
        } else if (length === _vm.pagination.rowsPerPage
                    || length === _vm.totalManagers
                        && length < _vm.pagination.rowsPerPage) {
          _vm.deleteManagerText = _vm.labels.deleteAll;
        } else {
          _vm.deleteManagerText = _vm.labels.deleteMany;
        }
      },
      deep: true
    },
    search: {
      handler () {
        let _vm = this;
        if (_vm.search.length >= this.minSearchCharacterCount
            || _vm.search.length === 0) {
          _vm.fetchDataFromApi()
          .then(data => {
            _vm.managers = data;
          });
        }
      }
    }
  },
  computed: {
    minSearchCharacterCount () {
      return this.$root.settings.defMinSearch;
    },
    listingUrl () {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      let baseUrl = process.env.VUE_APP_API_URL;
      let adminUrl = baseUrl
               + 'managers/list/0';
      let userUrl = baseUrl
               + 'managers/list/'
               + companyId;
      return (_vm.adminView) ? adminUrl : userUrl;
    },
    searchUrl () {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      let baseUrl = process.env.VUE_APP_API_URL;
      let adminSearchUrl = baseUrl
                + 'managers/search/0';
      let userSearchUrl = baseUrl
                + 'managers/search/'
                + companyId;
      return (_vm.adminView) ? adminSearchUrl : userSearchUrl;
    },
    adminViewStatus () {
      let _vm = this;
      let status = (_vm.adminView) ? 'On' : 'Off';
      return 'System Admin (' + status + ')';
    },
    pages () {
      if (this.pagination.rowsPerPage == null) {
        return 0;
      }
      return Math.ceil(this.totalManagers / this.pagination.rowsPerPage);
    },
    formTitle () {
      return this.editedIndex === -1
              ? this.labels.create
              : this.labels.edit;
    },
    dialogHelpText () {
      return this.editedIndex === -1
              ? this.labels.createHelpText
              : this.labels.editHelpText;
    },
    companyId () {
      return this.getUser('manager', 'companyId');
    }
  },
  methods: {
    mapText(text) {
      let _vm = this;
      if (!_vm.loading) {
        let transformSearchText = '<b><u>$&</u></b>';
        let regEx = new RegExp(_vm.search, 'ig');
        let output = (_vm.search)
                        ? text.replace(regEx, transformSearchText)
                        : text;
        return output;
      } else {
        return text;
      }
    },
    getHeadersConfig(header) {
      let _vm = this;
      let baseConfig = [
        'column teal white--text text-xs-center',
        header.sortable ? 'sortable' : '',
        (header.value === _vm.pagination.sortBy
          && header.sortable) ? 'active' : '',
        header.hide && !_vm.adminView ? 'hide' : ''
      ];
      let sortableArrow = (_vm.pagination.descending) ? 'desc' : 'asc';
      if (header.sortable) {
        baseConfig.push(sortableArrow);
      }
      return baseConfig;
    },
    allowManage(item) {
      return ((item.id !== this.identity.manager.id
              && item.companyId === this.identity.manager.companyId)
              || this.isAdmin()
                  && item.companyId !== this.identity.manager.companyId);
    },
    changeView() {
      let _vm = this;
      _vm.reload();
    },
    nextPage (page) {
      let _vm = this;
      let rowsPerPage = _vm.pagination.rowsPerPage;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.pagination.number = ((page-1)*rowsPerPage)+1;
        _vm.managers = data;
      });
    },
    async fetchDataFromApi () {
      let _vm = this;
      _vm.loading = true;
      const {page, rowsPerPage} = _vm.pagination;
      let items = await _vm.loadManagers(page, rowsPerPage);
      return new Promise((resolve) => {
        if (_vm.pagination.sortBy) {
          items = _vm.sorting(items);
        }
        if (rowsPerPage > 0) {
          items = _vm.paginate(items);
        }
        setTimeout(() => {
          _vm.loading = false;
          _vm.clear();
          if (page === 1) {
            _vm.pagination.number = 1;
          }
          resolve(items);
        }, 1000);
      });
    },
    // eslint-disable-next-line
    async loadManagers (page, pageSize) {
        let _vm =  this;
        _vm.loading = true;
        _vm.noRecordsText = '';
        let url = (_vm.search.length > 0) ? _vm.searchUrl : _vm.listingUrl;
        url += `/${pageSize}/${page}`;
        url += (_vm.search.length > 0) ? `?q=${_vm.search}` : '';
        let managerPromise = axios.get(url, {
          headers: {
            'X-Auth-Token': _vm.getUserToken()
          }
        });
        const [manager] = await Promise.all([managerPromise]);
        let _data = _vm.managers;
        if (manager.data.status === 'success') {
          _data = manager.data.data;
          _vm.totalManagers = manager.data.totalCount;
          if (_vm.totalManagers === 0) {
            _vm.noRecordsText = 'No matching records found.';
          }
        } else {
          _vm.$notify(manager.data.message, 'error');
          _vm.noRecordsText = 'No matching records found.';
        }
      return _data;
    },
    sorting (items) {
      const {sortBy, descending} = this.pagination;
      return items.sort((a, b) => {
        const sortA = a[sortBy];
        const sortB = b[sortBy];
        if (descending) {
          if (sortA < sortB) {
            return 1;
          }
          if (sortA > sortB) {
            return -1;
          }
          return 0;
        } else {
          if (sortA < sortB) {
            return -1;
          }
          if (sortA > sortB) {
            return 1;
          }
          return 0;
        }
      });
    },
    paginate (items) {
      const {rowsPerPage} = this.pagination;
      return items.slice(0, rowsPerPage);
    },
    viewItem (item) {
      console.log(item);
    },
    editItem (item) {
      let _vm = this;
      _vm.editedIndex = _vm.managers.indexOf(item);
      item = _.omit(item, [
        'password'
      ]);
      item.timezone = _vm.readableOffset(item.timezone)
                        + ' '
                        + item.timezone_text;
      _vm.editedItem = Object.assign({}, item);
      _vm.dialog = true;
    },
    deleteManagerFromApi(id) {
      let _vm = this;
      let baseUrl = process.env.VUE_APP_API_URL;
        axios({
          url: baseUrl + 'managers/delete/' + id,
          method: 'PATCH',
          headers: {
            'Authorization': 'Bearer ' + _vm.getUserToken()
          }
        })
        .then(function(response) {
          let r = response.data;
          if (r.status === 'success') {
            if (!_vm.disableDeleteNotification) {
              _vm.$notify(r.message, 'warning');
              //track all managers deleted successfully
              _vm.deleted.push(id);
            }
            //append more if needed...
          } else {
            if (!_vm.disableDeleteNotification) {
              _vm.$notify(r.message, 'error');
            }
            //append more if needed...
          }
        });
    },
    deleteItem (item) {
      let _vm = this;
      const index = this.managers.indexOf(item);
      let ConfirmBoxTitle = 'Delete Manager Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want revoke '
                              + item.firstName
                              + ' '
                              + item.lastName
                              + ' access ?';
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _vm.deleteManagerFromApi(item.id);
        _vm.managers.splice(index, 1);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    close () {
      this.dialog = false;
      //reset validations
      if (this.$refs.mform) {
        this.$refs.mform.$validator.reset();
        this.$refs.mform.reset();
      }
    },
    reload () {
      let _vm = this;
      _vm.pagination = {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: _vm.$root.settings.defPageSize
      };
      _vm.load();
    },
    clear () {
      this.managers = [];
    },
    load () {
      let _vm = this;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.managers = data;
      });
    },
    resetPagination (value) {
      let _vm = this;
      _vm.pagination.page = 1;
      _vm.pagination.rowsPerPage = value;
      _vm.load();
    },
    reset () {
      let _vm = this;
      _vm.editedItem = {};
      _vm.editedIndex = -1;
    },
    save () {
      let _vm = this;
      _vm.$refs.mform.submit();
    },
    toggleAll () {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.managers.slice();
      }
    },
    changeSort (sortable, column) {
      if (sortable) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending;
        } else {
          this.pagination.sortBy = column;
          this.pagination.descending = false;
        }
        this.load();
      }
    },
    bulkDelete () {
      let _vm = this;
      let items = _vm.selected;
      let ConfirmBoxTitle = 'Delete Managers Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want to '
                              + _vm.deleteManagerText.toLowerCase().trim();
      _vm.disableDeleteNotification = true;
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _.each(items, (item) => {
          let index = _vm.managers.indexOf(item);
          _vm.managers.splice(index, 1);
          _vm.deleteManagerFromApi(item.casUserId);
        });
        setTimeout(() => {
          if (_vm.deleted.length === _vm.selected.length) {
            _vm.$notify('Managers have been deleted successfully!', 'success');
          } else {
            let _failedEntries = _vm.selected.length - _vm.deleted.length;
            _vm.$notify( _failedEntries + ' Managers not deleted!', 'error');
          }
        }, 500);
        _vm.selected = [];// clear selection
      }, function(){});
    }
  }
};
</script>
<style scoped>
.noVertcalPadding {
  padding-bottom: 0;
  padding-top: 0;
}
.dialogTitle {
  font-weight: bold;
  color: white;
  cursor: default;
}
.dialogHelpText {
  font-style: italic;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.63);
  position: absolute;
  margin-top: 20px;
  cursor: default;
}
.perPage {
  float: right;
  vertical-align: bottom;
  text-align: right;
  margin-right: 15px;
  position: relative;
  padding-top: 13px;
}
/* tables styles */
thead tr th {
  text-align: left;
}
.hide {
  display: none;
}
.adminViewMargin {
  margin-bottom: 20px;
}
table.v-table tbody td:not(:nth-child(1)) {
  padding-left:0;
}
.searchHelpText {
  position: absolute;
  left: 130px;
  margin-top: 20px;
  color:red;
  font-size: 12px;
}
</style>
