import axios from 'axios';
import encryption from '@/utils/encryption';
import _ from 'underscore';

export default {
  // Check if the user is authenticated
  isAuthenticated () {
    return (localStorage.getItem('x-simple-tym-token'));
  },
  // Check if the user is logged in and the user object are in localStorage
  isProperlyLogged () {
    return (localStorage.getItem('x-simple-tym-user')
              && this.isAuthenticated());
  },
  // Remove the token from localStorage
  clearToken () {
    localStorage.removeItem('x-simple-tym-token');
  },
  // Remove the temporay token from localStorage
  clearTempToken () {
    localStorage.removeItem('x-simple-tym-token-temp');
  },
  // Remove all data from localStorage
  clearUserData () {
    // clear all data
    let skipIntro = localStorage.getItem('introState');
    localStorage.clear();
    localStorage.setItem('introState', skipIntro);
  },
  // Verify the token on cas api.
  validateToken () {
    return new Promise((resolve) => {
      axios({
        method: 'get',
        url: process.env.VUE_APP_CAS_API_URL + 'users/verify_token',
        headers: {
          'Authorization': 'Bearer ' + this.getUserToken()
        }
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        /*eslint no-console: ["error", { allow: ["log"] }] */
        console.log(e);
      });
    });
  },
  // Encrypt the user object
  encryptUser (data) {
    return encryption.encrypt(data);
  },
  // Extract the local user from localStorage and decrypt it
  getLocalUser () {
    let userEncrypted = localStorage.getItem('x-simple-tym-user');
    if (userEncrypted !== null) {
      try {
        return encryption.decrypt(userEncrypted);
      } catch (err) {
        //eslint-disable-next-line
        console.log(`Error on get user from localStorage: ${err}`);
        this.clearUserData();
      }
    }
    return false;
  },
  // from local user, extract any properties needed
  // parent could be 'user' | 'manager'
  getUser (parent = 'user', property = '') {
    let localUser = this.getLocalUser();
    let output = localUser[parent];
    if (property.length
        && localUser.hasOwnProperty(parent)
        && localUser[parent].hasOwnProperty(property)) {
      output = localUser[parent][property];
    }
    return output;
  },
  // Move toket to temporary token than will be used on complete profile
  moveTokenTemp () {
    let localToken = localStorage.getItem('x-simple-tym-token');
    localStorage.setItem('x-simple-tym-token-temp', localToken);
  },
  // Move temporary token to token than was used on complete profile
  moveTokenFromTemp () {
    let localTempToken = localStorage.getItem('x-simple-tym-token-temp');
    localStorage.setItem('x-simple-tym-token', localTempToken);
  },
  // Remove the encrypted user object from localStorage
  dropLocalUser () {
    localStorage.removeItem('x-simple-tym-user');
  },
  // Get user token from localStorage
  getUserToken () {
    return localStorage.getItem('x-simple-tym-token');
  },
  userRegisterIsCompleted() {
    return this.getLocalUser().user && this.getLocalUser().user.completed;
  },
  hasManagerAccount() {
    return !_.isEmpty(this.getLocalUser().manager);
  },
  hasWorkerAccount() {
    return !_.isEmpty(this.getLocalUser().worker);
  },
  isManager() {
    return this.isProperlyLogged() && this.hasManagerAccount();
  },
  isWorker() {
    return this.isProperlyLogged() && this.hasWorkerAccount();
  }
};
