import axios from 'axios';
import _ from 'underscore';
/*eslint no-console: ["error", { allow: ["log"] }] */
export default {
    /**
     *
     * @param {number} managerId
     * @returns Promise
     */
    getCompanyInfo(managerId, object) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}companies/load/${managerId}`
        }).then(response => {
          let r = response.data;
          let data = r.data;
          if (r.status === 'success' && data) {
            //when object set, append company into it
            if (!_.isEmpty(object)) {
              data = Object.assign({
                company: data
              }, object);
            }
            resolve(data);
          } else {
            reject(r);
          }
        }).catch(err => {
          reject(err);
        });
      });
    }
};
