<template>
  <div class="text-xs-center">
    <v-menu ref="menu" :close-on-content-click="false" offset-y>
      <v-btn
        slot="activator"
        :style="customStyle.button">Departments
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>
      <v-list>
        <v-list-tile @click="selectAll">
          <v-list-tile-title>
            <v-icon
                v-show="unchecked()">
              check_circle_outline
            </v-icon>
            <v-icon :color="customStyle.item"
                v-show="checked()">
              check_circle
            </v-icon>
            All / None
          </v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="select(department)"
            v-for="(department, index) in editedDepartments"
            :key="index">

          <v-list-tile-title>
            <v-icon v-show="!department.selected">
              check_circle_outline
            </v-icon>
            <v-icon
            :color="customStyle.item"
            v-show="department.selected">
              check_circle
            </v-icon>
            {{department.name}}
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
      <div>
        <v-btn class="mx-auto"
               style="display: block"
               dark :color="customStyle.item"
               small @click="apply">
          Apply
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {VMenu} from 'vuetify';

export default {
  name: 'departmentSelector',
  components: {
    VMenu
  },
  props: ['departments', 'customStyle'],
  data () {
    return {
      savedDepartments: [],
      editedDepartments: []
    };
  },
  mounted() {
    this.$refs.menu.$on('input', open => {
      if (open) {
        this.editedDepartments = this.savedDepartments.map((v) => {
          return this.$_.clone(v);
        });
      }
    });
  },
  methods: {
    checked() {
      let _vm = this;
      return (_vm.editedSelectedDepartments.length === _vm.departments.length);
    },
    unchecked() {
      let _vm = this;
      return (_vm.editedSelectedDepartments.length !== _vm.departments.length);
    },
    apply() {
      this.savedDepartments = this.editedDepartments.map(v => this.$_.clone(v));
      this.changed();
      this.$refs.menu.isActive = false;
    },
    changed() {
      this.$emit('selected', this.selectedDepartments);
    },
    select(department) {
      this.$set(department, 'selected', !department.selected);
    },
    selectAll() {
      let shouldSelec = this.unchecked();
      for (let department of this.editedDepartments) {
        this.$set(department, 'selected', shouldSelec);
      }
    },
  },
  computed: {
    selectedDepartments() {
      if (this.savedDepartments) {
        return this.savedDepartments.filter(r => r.selected);
      }
    },
    editedSelectedDepartments() {
      if (this.editedDepartments) {
        return this.editedDepartments.filter(r => r.selected);
      }
    }
  },
  watch: {
    departments(value) {
      if (this.savedDepartments.length === 0) {
        let queryDep = null;
        if (this.$route.query.department) {
          queryDep = this.$route.query.department;
        }
        let newValue = value.map(v => {
          let newV = this.$_.clone(v);
          newV.selected = queryDep ? newV.name === queryDep : true;
          return newV;
        });
        this.savedDepartments = newValue;
      }
    },
    savedDepartments(value) {
      this.editedDepartments = value.map(v => this.$_.clone(v));
    }
  }
};

</script>

<style scoped>
.v-menu__content {
  background: white;
}
</style>
