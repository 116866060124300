export const picture = {
  data() {
    return {
    };
  },
  methods: {
    getAvatar(avatar) {
      if (avatar) {
        let url = new URL(avatar, process.env.VUE_APP_CAS_API_URL);
        return url.href;
      }
      return '/images/blank-avatar.png';
    }
  }
};
