<template>
    <v-form>
        <v-flex xs12>
            <v-layout row xs12 wrap>
                <v-flex xs12 sm6 md12>
                    <v-autocomplete
                        v-validate="'required'"
                        v-model="form.worker"
                        :color="inputColor"
                        :items="workers"
                        :filter="searchFilter"
                        data-vv-name="defaultWorker"
                        :error-messages="errors.collect('defaultWorker')"
                        label="Choose a Worker"
                        prepend-icon="person"
                        return-object
                    >
                    <template slot="selection" slot-scope="data">
                        <v-avatar>
                        <img
                            :src="getAvatar(data.item.avatar)"
                            alt="Avatar"
                            class="w_picture"
                            >
                        </v-avatar>
                        <span
                        :class="[
                            'subheading',
                            'font-weight-medium',
                            'text-capitalize',
                            'px-2'
                        ]">
                        {{ data.item.firstName + ' ' + data.item.lastName }}
                        </span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-avatar>
                        <img
                            :src="getAvatar(data.item.avatar)"
                            alt="Avatar"
                            class="w_picture">
                        </v-avatar>
                        <span
                        :class="[
                            'subheading',
                            'font-weight-medium',
                            'text-capitalize',
                            'px-2'
                        ]">
                        {{ data.item.firstName + ' ' + data.item.lastName }}
                        </span>
                    </template>
                    </v-autocomplete>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12>
            <v-layout row xs12 wrap>
                <div
                    class="red--text"
                    v-show="form.worker !== null"
                >
                    Be very sure that you want to give full access to this user.
                </div>
            </v-layout>
        </v-flex>
    </v-form>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';
//import all components
import {
  VForm,
  VInput,
  VTextField,
  VCheckbox,
  VDivider,
  VSelect,
  VAutocomplete
} from 'vuetify';

//import all mixins
import {timezone} from '@/mixins/timezone';
import {picture} from '@/mixins/picture';
import {_} from 'vue-underscore';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'formManager',
  components: {
    VForm,
    VInput,
    VTextField,
    VCheckbox,
    VDivider,
    VSelect,
    VAutocomplete
  },
  props: [
    'inputColor',
    'companyId'
  ],
  mixins: [
    timezone,
    picture
  ],
  mounted() {
    let _vm = this;
    // auto-load workers
    _vm.loadWorkers();
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
  },
  data () {
    return {
      workers: [],
      form: {
        worker: null
      },
      dictionary: {
        attributes: {
          defaultWorker: 'Default Worker'
        },
        custom: {
          defaultWorker: {
            required: () => 'Default Worker field is required.'
          }
        }
      }
    };
  },
  watch: {},
  computed: {
    baseApiUrl () {
      return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    reset: function() {
      this.form = {};
    },
    loadWorkers() {
      let _vm = this;
      let companyId = _vm.companyId;
      if (companyId) {
        return new Promise((resolve, reject) => {
          let url = process.env.VUE_APP_API_URL
            + `workers/list/company-${companyId}/1000/1/true`;
          axios.get(url).then(response => {
            if (response.data.status === 'success') {
              _vm.workers = response.data.data;
              resolve(_vm.workers);
            }
          }).catch(err => {
            reject(err);
            _vm.$notify(err.data, err);
          });
        });
      }
    },
    searchFilter (item, queryText) {
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1;
    },
    submit () {
      let _vm = this;
      let token = localStorage.getItem('x-simple-tym-token');
      _vm.$validator.validate().then(result => {
        if (result) { // well validated
          let form = _.pick(_vm.form.worker,
            'casUserId',
            'timezone',
            'timezone_text',
            'companyId',
            'firstName',
            'lastName'
          );
          //process to form saving
          axios({
            method: 'post',
            url: _vm.baseApiUrl + 'workers/allowManageAccess',
            data: form,
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then(function (response) {
            var r = response.data;
            if (r.status === 'success') {
              // auto-load workers
              _vm.loadWorkers();
              //close modal
              _vm.$root.$emit('close');
              //show success message
              _vm.$notify(r.message, 'success');
              //refresh manager list
              _vm.$root.$emit('reload');
            } else {
              _vm.$notify(r.message, 'error');
            }
          }).catch(function (error) {
            _vm.$notify(error.message, 'error');
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.w_picture {
    width: 25px;
    height: 25px;
}
form {
  width: 560px;
}
.dividerText {
    position: absolute;
    margin-top: -10px;
    background: white;
    left: 40%;
    padding-left: 10px;
    padding-right: 10px;
    color: #777;
    /* font-weight: bold; */
    font-style: italic;
}
</style>
