export const crud = {
    data() {
        return {
            labels: {
                new: 'Create',
                edit: 'Update',
                delete: 'Delete',
                cancel: 'Cancel',
                save: 'Save',
                search: 'Search',
                deleteAll: 'Delete All',
                no: 'No',
                yes: 'Yes'
            },
            icons: {
                edit: 'edit',
                delete: 'delete',
                view: 'view_list',
                cancel: 'close',
                confirm: 'done_outline'
            }
        };
    }
};
