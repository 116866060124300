<template>
  <div>
    <v-container style="background: #FFF;">
      <v-layout row wrap>
        <v-flex xs10 offset-xs1>
            <h1>{{page.name}}</h1>
            <v-form>
              <v-container>
                <v-layout row wrap>
                    <v-select
                      v-model="startWeekDay"
                      v-validate="'required'"
                      data-vv-name="startWeekDay"
                      :errors-messages="errors.collect('startWeekDay')"
                      label="Week Start On Mobile View"
                      :items="weekDays"
                      prepend-icon="today"
                      item-text="name"
                      item-value="index">
                    </v-select>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs1></v-flex>
                  <v-flex xs2>
                    <v-btn
                        @click="submit"
                        color="teal"
                        dark
                    >Update</v-btn>
                  </v-flex>
                </v-layout>

              </v-container>
            </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios';
import {user} from '@/mixins/user';
import {company} from '@/mixins/company';

import {
  VForm,
  VSelect,
} from 'vuetify';

export default {
  name: 'MobileSettings',
  components: {
    VForm,
    VSelect
  },
  mixins: [user, company],
  data () {
    return {
      page: {
        name: 'Mobile'
      },
      weekDays: [
        {
          index: 1,
          name: 'Monday'
        },
        {
          index: 2,
          name: 'Tuesday'
        },
        {
          index: 3,
          name: 'Wednesday'
        },
        {
          index: 4,
          name: 'Thursday'
        },
        {
          index: 5,
          name: 'Friday'
        },
        {
          index: 6,
          name: 'Saturday'
        },
        {
          index: 0,
          name: 'Sunday'
        }
      ],
      startWeekDay: 1,
    };
  },
  mounted() {
    let _vm = this;
    if (_vm.identity) {
      _vm.getCompanyInfo(_vm.identity.manager.id).then(data => {
        _vm.loadMobileSettings(data);
      });
    }
  },
  methods: {
    loadMobileSettings(company) {
      let _vm = this;
      _vm.startWeekDay = company.mobileStartWeekDay || 1;
      _vm.companyId = company.id;
    },
    submit(event) {
      event.preventDefault();
      let _vm = this;
      _vm.$validator.validate().then(result => {
        if (result) {
          let body = {mobileStartWeekDay: _vm.startWeekDay};
          axios({
            method: 'patch',
            url: process.env.VUE_APP_API_URL
                  + 'companies/update/mobile/'
                  + _vm.companyId,
            data: body
          }).then(response => {
            let r = response.data;
            if (r.status === 'success') {
              _vm.getCompanyInfo(_vm.identity.manager.id)
                .then(data => {
                  _vm.$set(_vm.identity, 'company', data);
                  let encrypted = _vm.encryptUser(_vm.identity);
                  localStorage.setItem('x-simple-tym-user', encrypted);
                });
              _vm.$notify(r.message, 'success');
            } else {
              _vm.$notify(r.errors.join('</br>'), 'error');
            }
          }).catch(err => {
            _vm.$notify(err, 'error');
          });
        }
      });
    }
  }
};

</script>

<style scoped>

</style>
