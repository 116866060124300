import axios from 'axios';
import user from '@/utils/user';

export default {
  markAll () {
    let baseUrl = process.env.VUE_APP_API_URL;
    let companyId = user.getUser('manager', 'companyId');
    return axios({
      url: `${baseUrl}requests/mark-read/${companyId}`,
      method: 'PATCH'
    });
  }
};
