<template>
    <div>
    <HomeHeader
        :identity="this.identity"
        style="position: fixed;width: 100%;"></HomeHeader>
    </div>
</template>

<script>
import HomeHeader from '@/components/layouts/HomeHeader';

//import all mixins
import {user} from '@/mixins/user';

export default {
  name: 'events',
  components: {
    HomeHeader
  },
  mixins: [
    user
  ],
  data: () => ({

  }),
  mounted() {
  },
  methods: {
  }
};
</script>

<style>

</style>
