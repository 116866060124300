/*eslint no-console: ["error", { allow: ["log"] }] */
import Vue from 'vue';
import Router from 'vue-router';
import {store} from '@/store/store';

//components import
import HomeComponent from '@/components/home/Index';
import DashboardComponent from '@/components/dashboard/Index';
import UserLoginComponent from '@/components/users/Login';
import WorkerLoginComponent from '@/components/users/WorkerLogin';
import UserCompleteProfileComponent from '@/components/users/CompleteProfile';
import PeopleIndexComponent from '@/components/people/Index';
import UserRegisterComponent from '@/components/users/Register';
import UserRegisterCompleteComponent from '@/components/users/RegisterComplete';
import RequestIndexComponent from '@/components/requests/Index';
import SettingIndexComponent from '@/components/settings/Index';
import CalendarIndexComponent from '@/components/schedules/Index';
import PayrollIndexComponent from '@/components/payroll/Index';
import EventIndexComponent from '@/components/events/Index';
import Error404Component from '@/components/error/404';

//utils import
import user from '@/utils/user';
import notification from '@/utils/notification';
import globalUtils from '@/utils/global';

Vue.use(Router);

// Authentication rules:
//  * if need to be guest set meta.authenticate = '?'
//  * if need to be logged in set meta.authenticate = '@'
let routeObj = {
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeComponent,
      meta: {authenticate: '?'}
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardComponent,
      meta: {authenticate: '@'}
    },
    {
      path: '/login',
      name: 'login',
      component: UserLoginComponent,
      meta: {authenticate: '?'}
    },
    {
      path: '/worker-login',
      name: 'worker-login',
      component: WorkerLoginComponent,
      meta: {authenticate: '?'}
    },
    {
      path: '/register',
      name: 'register',
      component: UserRegisterComponent,
      meta: {authenticate: '?'}
    },
    {
      path: '/register-complete',
      name: 'registerComplete',
      component: UserRegisterCompleteComponent,
      meta: {authenticate: '@'}
    },
    {
      path: '/complete-profile',
      name: 'completeProfile',
      component: UserCompleteProfileComponent,
      meta: {authenticate: '?'}
    },
    {
      path: '/people',
      name: 'people',
      component: PeopleIndexComponent,
      meta: {authenticate: '@'}
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingIndexComponent,
      meta: {authenticate: '@'}
    },
    {
      path: '/requests',
      name: 'requests',
      component: RequestIndexComponent,
      meta: {authenticate: '@'},
      // eslint-disable-next-line
      beforeEnter: (to, from, next) => {
        notification.markAll().then(() => {
          next();
        });
      }
    },
    {
      path: '/events',
      name: 'events',
      component: EventIndexComponent,
      meta: {authenticate: '@'}
    },
    {
      path: '/payroll',
      name: 'payroll',
      component: PayrollIndexComponent,
      meta: {authenticate: '@'}
    },
    {
      path: '/cal/:company?/:worker?/:year?/:month?/:day?',
      name: 'calendar',
      component: CalendarIndexComponent,
      meta: {
        authenticate: '*'
      },
      // eslint-disable-next-line
      beforeEnter: (to, from, next) => {
        store.dispatch('calendarPreValidate', {
          companySlug: to.params.company,
          workerSlug: to.params.worker
        }).then(path => next(path));
      }
    },
    {
      path: '/404',
      component: Error404Component
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/:company',
      beforeEnter: (to, from, next) => {
        if (!user.isAuthenticated() ||
            user.isAuthenticated()
              && user.hasWorkerAccount()) {
          let companySlug = to.params.company;
          if (companySlug) {
            globalUtils.getCompanyFromSlug(companySlug).then(company => {
              if (company) {
                next(`/cal/${companySlug}`);
              } else {
                next('/404');
              }
            });
          }
        } else {
          next('/');
        }
      }
    }
  ]
};

const router = new Router(routeObj);

//eslint-disable-next-line
router.beforeEach((to, from, next) => {
  // Checking the authentication

  //the user should be guest
  if (to.matched.some(record => record.meta.authenticate === '?')) {
    if (user.isAuthenticated()) {
      next({path: '/dashboard'});
    } else {
      next();
    }

  //the user should be loggin in
  } else if (to.matched.some(record => record.meta.authenticate === '@')) {
    if (!user.isAuthenticated()) {
      next({path: `/login?r=${to.path}`});
    } else if (user.isWorker()) {
      let answer = false;
      /*if (sessionStorage.getItem('calendarPageWasInit')) {*/
        //answer = window.confirm('Before continue, you need logout. '
          //+ 'Are you sure you want close your current session ?');
      /*}*/
      if (!answer) {
        next({path: '/cal'});
      } else {
        user.clearUserData();
        next({path: '/'});
      }
    } else if ((!user.userRegisterIsCompleted()
                  || !user.hasManagerAccount())
        && to.name !== 'registerComplete') {
      next({path: '/register-complete'});
    } else {
      next();
    }
  // for all users
  } else {
    next();
  }
  sessionStorage.removeItem('calendarPageWasInit');
});
export default router;
