import CryptoJS from 'crypto-js';

const VUE_APP_USER_ENCRYPTION = '@#$AzUiOpMjzYuI';

export default {
  encrypt (data) {
    let _data = JSON.stringify(data);
    return CryptoJS.AES.encrypt(_data, VUE_APP_USER_ENCRYPTION);
  },
  decrypt (data) {
    return JSON.parse(CryptoJS.AES.decrypt(data, VUE_APP_USER_ENCRYPTION)
      .toString(CryptoJS.enc.Utf8));
  }
};
