<template>
  <v-container style="min-width: 800px">
    <v-layout row>
      <v-flex xs10 offset-xs1>
        <h1>{{page.name}}</h1>
      </v-flex>
    </v-layout>
        <v-layout row>
        <v-flex xs2 offset-xs10>
            <v-btn
              :style="greenButtonStyle"
              dark
              @click="addRole()">
            <v-icon small>add</v-icon>&nbsp;
            {{ labels.create }}
            </v-btn>
        </v-flex>
        <v-dialog
          v-model="dialog"
          :persistent="true"
          width="40%"
          scrollable>
            <custom-card :card-title="formTitle"
                         :card-help-text="dialogHelpText"
                         @save="save" @close="close">
                <v-container>
                    <FormRole
                        ref="rform"
                        class="rform"
                        :form="editedItem"
                        :oldName="oldName"
                        :companyId="companyId"
                        :minStaffOpts="minStaffOpts"
                        :selected-colors="selectedColors"
                    />
                </v-container>
              </custom-card>
          </v-dialog>
        </v-layout>
    <v-layout row>
      <v-flex d-block xs12>
        <v-data-table
            :headers="headers"
            :items="roles"
            :total-items="totalRoles"
            :loading="loading"
            select-all
            :no-data-text='noRecordsText'
            :rows-per-page-items="selectPagination"
            v-model="selected"
            hide-actions
            class="elevation-1"
            style="border: 5px solid #04796e;" >
                <v-progress-linear
                slot="progress"
                background-color="teal lighten-4"
                color="teal lighten-3"
                indeterminate ></v-progress-linear>
                <template slot="headers" slot-scope="props">
                <tr :key="props.index"
                    style="border-bottom: 5px solid #04796e;">
                    <th class="teal" style="width: 15px">
                    <v-checkbox
                        :input-value="props.all"
                        :indeterminate="props.indeterminate"
                        primary
                        hide-details
                        @click.native="toggleAll"
                        :key="props.index"
                        ></v-checkbox>
                    </th>
                    <th :class="getHeadersConfig(header)"
                        v-for="header in props.headers"
                        :key="header.text"
                        :style="header.style || ''"
                        @click="changeSort(header.sortable, header.value)" >
                    <v-icon small v-if="header.sortable">
                    arrow_upward
                    </v-icon>
                    {{ header.text }}
                </th>
                </tr>
                </template>
                <template slot="items" slot-scope="props">
                <td>
                    <v-checkbox
                        v-model="props.selected" primary
                        hide-details
                        color="teal">
                    </v-checkbox>
                </td>
                <!--td class="text-xs-left">
                    {{ props.index+pagination.number }}
                </td-->
                <td >
                    {{ props.item.name }}
                </td>
                <td >
                  <router-link
                    :to="{name: 'people', query: {role: props.item.name}}"
                    >
                    {{ getWorkerCount(props.item.id) }}
                  </router-link>
                </td>
                <td class="text-xs-center">
                    <span :class="['theme-color']"
                    :style="'background-color: '+props.item.paperColor" >
                    </span>
                </td>
                <td >
                    {{ props.item.minStaff ? props.item.minStaff : 'n/a' }}
                </td>
                <td class="text-xs-right">
                <div>
                  <v-btn
                      color="warning"
                      @click="editItem(props.item)"
                      fab small dark>
                  <v-icon>{{ icons.edit }}</v-icon>
                  </v-btn>
                  <v-btn
                    color="error"
                    @click="deleteRole(props.item)"
                    v-if="canDeleteRole(props.item.id)"
                    fab small dark >
                  <v-icon>{{ icons.delete }}</v-icon>
                  </v-btn>
                  <v-btn
                      color="error"
                      v-else
                      :title="canNotDeleteMsg"
                      fab small dark>
                  <v-icon>lock</v-icon>
                  </v-btn>
                </div>
                </td>
                </template>
                <template slot="footer">
                <tr class="teal"
                    style="border-top: 4px solid rgb(4, 121, 110)" >
                    <td colspan="100%">
                    <v-layout row>
                        <v-flex xs2 class="perPage white--text">
                        Per page :
                        </v-flex>
                        <v-flex xs2>
                        <v-select :items="selectPagination"
                            v-model="pagination.rowsPerPage"
                            :single-line="true"
                            solo
                            @change="resetPagination" ></v-select>
                        </v-flex>
                        <v-flex xs3 offset-xs5>
                        <v-btn
                            color="error"
                            :disabled="disableBulkDelete"
                            v-show="!disableBulkDelete"
                            @click="bulkDelete" >
                            {{ deleteRoleText }}
                        </v-btn>
                        </v-flex>
                    </v-layout>
                    </td>
                </tr>
            </template>
        </v-data-table>
      </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          color="teal"
          total-visible="5"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';
import {crud} from '@/mixins/crud';
import {theme} from '@/mixins/theme';
import FormRole from '@/components/roles/FormRole';
import CustomCard from '@/components/common/custom-card';
import {_} from 'vue-underscore';
import {
  VPagination,
  VCheckbox,
  VProgressLinear,
  VSelect
} from 'vuetify';

export default {
  name: 'Roles',
  mounted() {
    let _vm = this;
    _vm.reset();
    _vm.pagination.rowsPerPage = _vm.$root.settings.defPageSize;
    if (_vm.pagination.rowsPerPage && _vm.companyId) {
      _vm.load();
    }
    _vm.$root.$watch('settings.defPageSize', function(value) {
      if (value) {
        _vm.pagination.rowsPerPage = value;
        if (_vm.companyId) {
          _vm.load();
        }
      }
    });
    // Init the minimal staff options
    for (let i = 0; i <= _vm.maxStaffSize; i++) {
      _vm.minStaffOpts.push(i);
    }
    //auto-load role counts
    _vm.loadRolesCount();
  },
  components: {
    VPagination,
    VCheckbox,
    VProgressLinear,
    VSelect,
    FormRole,
    CustomCard
  },
  mixins: [
    crud,
    theme
  ],
  props: ['companyId'],
  data: function() {
    return {
      labels: {
        create: 'Add a Role',
        deleteMany: 'Delete Roles',
        deleteAll: 'Delete All Roles',
      },
      page: {
        name: 'Manage Roles'
      },
      headers: [
       /* {
            text: '#',
            sortable: false,
            value: 'index',
            class: ['text-xs-left']
        },*/
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          class: ['text-xs-left']
        },
        {
          text: 'Number Of Workers',
          value: 'count',
          sortable: false
        },
        {
          text: 'Color',
          value: 'paperColor',
          sortable: false
        },
        {
          text: 'Minimun Staff Required',
          value: 'minStaff',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          class: ['text-xs-center']
        }
      ],
      roles: [],
      roleWorkersCount: [],
      //eslint-disable-next-line
      canNotDeleteMsg: 'You can not delete this role because it has workers assigned. Please remove workers before try deletion!!!',
      totalRoles: 0,
      loading: false,
      noRecordsText: 'No roles was found.',
      selectPagination: [20, 30, 40, 50],
      selected: [],
      pagination: {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: null
      },
      maxStaffSize: 10,
      minStaffOpts: [],
      deleteRoleText: '',
      disableBulkDelete: true,
      dialog: false,
      editedItem: {},
      oldName: ''
    };
  },
  computed: {
    formTitle() {
      if (this.editedItem.id) {
        return 'Edit Role';
      } else {
        return 'Create new Role';
      }
    },
    dialogHelpText() {
      if (this.editedItem.id) {
        return 'Edit the Role to your company easily';
      } else {
        return 'Add new Role to your company easily';
      }
    },
    pages() {
      if (this.totalRoles === 0 || this.pagination.rowsPerPage === null) {
        return 0;
      }
      return Math.ceil(this.totalRoles / this.pagination.rowsPerPage);
    },
    selectedColors() {
      return this.roles.map(r => r.paperColor);
    }
  },
  methods: {
    getWorkerCount(roleId) {
      let _vm = this;
      let _role = _.find(_vm.roleWorkersCount, role => {
        return (role.roleId === roleId);
      });
      return (typeof _role !== 'undefined'
               && _role.hasOwnProperty('count'))
              ? _role.count
              : '-';
    },
    canDeleteRole(id) {
      let _vm = this;
      let _role = _.find(_vm.roleWorkersCount, role => {
        return (role.roleId === id);
      });
      return (typeof _role === 'undefined');
    },
    loadRolesCount() {
      let _vm = this;
      let url = process.env.VUE_APP_API_URL
        + `workers/roles/count/${_vm.companyId}`;
      axios.get(url).then(response => {
        if (response.data.status === 'success') {
          this.roleWorkersCount = response.data.data;
        }
      }).catch(err => {
        this.$notify(err.data, err);
      });
    },
    addRole () {
      let _vm = this;
      _vm.reset();
      _vm.dialog = true;
    },
    nextPage(page) {
      let _vm = this;
      let rowsPerPage = _vm.pagination.rowsPerPage;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.pagination.number = ((page-1)*rowsPerPage)+1;
        _vm.roles = data;
      });
    },
    bulkDelete() {
      let _vm = this;
      let boxTitle = 'Delete Roles Confirmation';
      let boxMessage = 'Are you sure you want to '
          + _vm.deleteRoleText.toLowerCase().trim();
      _vm.disableDeleteNotification = true;
      _vm.$confirm(boxTitle, boxMessage, () => {
        let url = `${process.env.VUE_APP_API_URL}companies/role/bulk-delete`;
        axios.post(url, {ids: _vm.selected.map(i => i.id)})
          .then(res => {
            if (res.data.status === 'success') {
              _vm.$notify(res.data.message, 'success');
              _vm.selected = [];
              _vm.load();
            } else {
              _vm.$notify(res.data.message, 'error');
            }
          }).catch(err => {
              _vm.$notify(err.data.message, 'error');
          });
      }, () => {});
    },
    load() {
      let _vm = this;
      _vm.loading = true;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.roles = data;
        _vm.loading = false;
        if (_vm.$refs.dform) {
          _vm.$refs.dform.reset();
        }
      });
    },
    reload () {
      let _vm = this;
      _vm.pagination = {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: _vm.$root.settings.defPageSize
      };
      _vm.load();
    },
    fetchDataFromApi() {
      let _vm = this;
      _vm.loading = true;
      const {page, rowsPerPage} = _vm.pagination;
      return new Promise(resolve => {
        _vm.loadRoles(_vm.companyId, page, rowsPerPage).then(roles => {
          if (_vm.pagination.sortBy) {
            roles = _vm.sorting(roles);
          }
          if (rowsPerPage > 0) {
              roles = _vm.paginate(roles);
          }
          _vm.loading = false;
          _vm.clear();
          resolve(roles);
        });
      });
    },
    loadRoles(companyId, page, rowsPerPage) {
      let url = `${process.env.VUE_APP_API_URL}companies/${companyId}/role/`;
      url += `${rowsPerPage}/${page}`;
      return new Promise(resolve => {
        axios.get(url).then(res => {
          if (res.data.status === 'success') {
            resolve(res.data.data);
            this.totalRoles = res.data.totalCount;
          } else {
            resolve([]);
            this.$notify(res.data.message, 'error');
          }
        }).catch(err => {
          resolve([]);
          this.$notify(err.message, 'error');
        });
      });
    },
    getHeadersConfig(header) {
      let _vm = this;
      let baseConfig = ['column teal white--text',
        header.sortable ? 'sortable' : '',
        (header.value === _vm.pagination.sortBy
          && header.sortable) ? 'active' : '',
      ];
      if (header.class) {
        baseConfig = [...baseConfig, ...header.class];
      }
      let sortableArrow = (_vm.pagination.descending) ? 'desc' : 'asc';
      if (header.sortable) {
        baseConfig.push(sortableArrow);
      }
      return baseConfig;
    },
    clear() {
    },
    sorting (items) {
      const {sortBy, descending} = this.pagination;
      return items.sort((a, b) => {
        const sortA = a[sortBy];
        const sortB = b[sortBy];
        if (descending) {
          if (sortA < sortB) {
            return 1;
          }
          if (sortA > sortB) {
            return -1;
          }
          return 0;
        } else {
          if (sortA < sortB) {
            return -1;
          }
          if (sortA > sortB) {
            return 1;
          }
          return 0;
        }
      });
    },
    paginate(items) {
      const {rowsPerPage} = this.pagination;
      return items.slice(0, rowsPerPage);
    },
    resetPagination (value) {
      let _vm = this;
      _vm.pagination.page = 1;
      _vm.pagination.rowsPerPage = value;
      _vm.load();
    },
    editItem(item) {
      let _vm = this;
      _vm.editedItem = item;
      _vm.dialog = true;
      _vm.oldName = item.name;
    },
    deleteRole(role) {
      let url = `${process.env.VUE_APP_API_URL}companies/role/${role.id}`;
      let _vm = this;
      let boxTitle = 'Delete Role Confirmation';
      let boxMessage = `Are you sure you want to delete ${role.name}?`;
      _vm.$confirm(boxTitle, boxMessage, () => {
        axios.delete(url).then(res => {
          if (res.data.status === 'success') {
            _vm.$notify(res.data.message, 'success');
            _vm.load();
          } else {
            _vm.$notify(res.data.message, 'error');
          }
        }).catch(err => {
          _vm.$notify(err.data.message, 'error');
        });
        _vm.selected = [];
      }, () => {});
    },
    reset() {
      let _vm = this;
      _vm.$refs.rform.$validator.reset();
      _vm.editedItem = {minStaff: this.minStaffOpts[0]};
      _vm.oldName = '';
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.roles.slice();
      }
    },
    createRole(role) {
      let _vm = this;
      let url = `${process.env.VUE_APP_API_URL}companies/${_vm.companyId}/role`;
      axios.post(url, role).then(res => {
        if (res.data.status === 'success') {
          _vm.$notify(res.data.message, 'success');
          _vm.close();
          _vm.load();
        } else {
          _vm.$notify(res.data.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err.data.message, 'error');
      });
    },
    editRole(role) {
      let _vm = this;
      let url = `${process.env.VUE_APP_API_URL}companies/role/${role.id}`;
      axios.put(url, role).then(res => {
        if (res.data.status === 'success') {
          _vm.$notify(res.data.message, 'success');
          _vm.close();
          _vm.load();
        } else {
          _vm.$notify(res.data.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err.data.message, 'error');
      });
    },
    save() {
      let _vm = this;
      _vm.$refs.rform.$validator.validate().then(result => {
        if (result) {
          if (_vm.editedItem.id) {
            // edit role
            _vm.editRole(_vm.editedItem);
          } else {
            // add new role
            _vm.createRole(_vm.editedItem);
          }
        }
      });
    },
    close() {
      this.dialog = false;
      //this.reset();
    }
  },
  watch: {
    dialog (value) {
      if (value) {
        this.$refs.rform.reset();
      }
    },
    companyId (value) {
      if (value) {
        this.load();
      }
    },
    selected: {
        handler () {
          let _vm = this;
          let length = _vm.selected.length;
          _vm.disableBulkDelete = !length;
          //small adjust for plural && all
          if (length === 1) {
            _vm.deleteRoleText = _vm.labels.delete;
          } else if (length === _vm.pagination.rowsPerPage
                      || length === _vm.totalRoles
                          && length < _vm.pagination.rowsPerPage) {
            _vm.deleteRoleText = _vm.labels.deleteAll;
          } else {
            _vm.deleteRoleText = _vm.labels.deleteMany;
          }
      },
      deep: true
    }
  }
};

</script>

<style>
.dialogTitle {
  font-weight: bold;
  color: white;
  cursor: default;
}
.dialogHelpText {
  font-style: italic;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.63);
  position: absolute;
  margin-top: 20px;
  cursor: default;
}
.theme-color {
  padding: 15px;
}
</style>
<style scoped>
td a {
  text-decoration: none;
  font-weight: bold;
}
</style>
