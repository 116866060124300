//methods
export default {
    decimalForceFormat (field) {
      let value = 0;
      let _vm = this;
      let _fieldValue = _vm[field];
      if (typeof _fieldValue !== 'undefined') {
        //eslint-disable-next-line
        value = parseFloat(_fieldValue.replace(/[^\d\.]/g, ''));
      }
      if (isNaN(value)) {
        value = 0;
      }
      //
      _fieldValue = value.toFixed(2)
                         .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
      _vm.overtimeRate = _fieldValue;
    }
};
