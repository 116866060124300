<template>

  <div class="ds-month">

    <div class="ds-week-header">

      <div class="ds-week-header-day"
        v-for="(weekday, i) in updatedWeekDays"
        :key="weekday"
        :class="weekdayClasses(i)">

        {{ weekday }}

      </div>

    </div>

    <ds-day-row
      v-for="i in rows"
      v-bind="{$scopedSlots}"
      v-on="$listeners"
      :key="i"
      :days="daysAtRow( i, 7 )"
      :calendar="calendar"
      :placeholder="placeholder"
      :placeholder-for-create="placeholderForCreate"
    ></ds-day-row>

  </div>

</template>

<script>
import {Calendar, CalendarEvent} from 'dayspan';


export default {

  name: 'dsWeeksView',

  props: {
    calendar: {
      required: true,
      type: Calendar
    },

    placeholder: {
      type: CalendarEvent
    },

    placeholderForCreate: {
      type: Boolean,
      default: false
    },

    weekdays: {
      type: Array,
      default() {
        this.$dsDefaults().weekdays;
      }
    }
  },

  computed: {
    rows() {
      return Math.floor( this.calendar.days.length / 7 );
    },
    updatedWeekDays() {
      return this.$root.weekdays || this.weekdays;
    }
  },

  methods: {
    daysAtRow(row, rowSize) {
      var start = (row - 1) * rowSize;

      return this.calendar.days.slice( start, start + rowSize );
    },

    weekdayClasses(weekday) {
      return {
        'ds-week-header-today': this.$dayspan.today.dayOfWeek === weekday,
        'ds-week-ends': weekday === 0 && weekday === 6
      };
    }
  }
};
</script>

<style scoped>

.ds-month {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  background-color: white;
}

.ds-month .ds-week-header {
    width: 100%;
    display: flex;
    user-select: none;
    position: fixed;
    z-index: 5;
    background-color: white;
}

.ds-month .ds-week-header-day {
    width: 100%;
    border-right: #e0e0e0 1px solid;
    color: #757575;
    padding: 4px;
    padding-bottom: 0px;
}

.ds-month .ds-week-header-today {
      color: #4285f4;
      font-weight: 500;
}
</style>

<style>

.worker-calendar .ds-month .ds-week-header {
    top: 63px;
}
 .admin-calendar .ds-month .ds-week-header {
    top: 113px;
}

</style>
