<template>
<table class="stacked-table">
  <tr v-for="row in tableData" v-bind:key="row.key">
    <td class="label subheading">{{row.label}}</td>
    <td class="value body-1">{{row.value}}</td>
  </tr>
</table>
</template>

<script>

export default {
  name: 'stacked-table',
  props: ['tableFields', 'dataObject'],
  data () {
    return {
    };
  },
  components: {
  },
  computed: {
    tableData: function() {
      let tableData = [];
      for (let el of this.tableFields) {
        tableData.push({
          label: el.label,
          value: this.dataObject[el.key] || ''
        });
      }
      return tableData;
    }
  }
};

</script>

<style>

.stacked-table {
  width: 100%;

}

.stacked-table .label {
  text-align: right;
  padding-right: 5px;
  width: 50%;
}

.stacked-table .value {
  text-align: left;
  padding-left: 5px;
}

.stacked-table tr {
  border-bottom: 1px solid black;
}

</style>
