var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"events-list","grid-list-md":"","text-xs-center":""}},_vm._l((_vm.events),function(_events,date){return _c('v-flex',{key:'events-date'+date,attrs:{"xs12":""}},_vm._l((_events),function(dayEvent,i){return _c('v-layout',{key:'event-'+i,class:[
          'events-date-row',
          _vm.isToday(date) ? 'event-current-day' : ''
        ],style:({
          'background-color': _vm.bgColor(dayEvent.bgColor, date)
        }),attrs:{"row":"","wrap":"","xs12":""}},[_c('v-flex',{class:[
              'event-row-text',
              //(dayEvent.reviewed) ? 'vertical-text-60' : 'vertical-text-40',
              'vertical-text-40',
              (dayEvent.requested)
                ? 'requested-row-event'
                : '',
              _vm.eventHasDescription(dayEvent) ? 'has-note' : ''
            ],attrs:{"xs8":dayEvent.add || dayEvent.reviewed,"xs10":dayEvent.locked},on:{"click":function($event){return _vm.debug(dayEvent);}}},[(_vm.allowCancelRequest(dayEvent))?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.cancelRequest(dayEvent)}}},[_c('v-icon',{class:[
                  (dayEvent.requested) ? 'white--text' : 'red--text'
                ],staticStyle:{"font-size":"17px"},attrs:{"small":""}},[_vm._v("cancel")])],1):_vm._e(),(_vm.eventHasDescription(dayEvent))?_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-icon',{staticClass:"pin-icon",attrs:{"slot":"activator","small":"","dark":""},slot:"activator"},[_vm._v("fas fa-thumbtack")]),_c('span',[_vm._v(_vm._s(dayEvent.data.data.description))])],1):_vm._e(),_c('span',[_vm._v("\n            "+_vm._s(_vm.getMobileCalendarDateFormat(date))+"\n              - "+_vm._s(_vm.mapTitle(dayEvent.data))+"\n            ")]),(dayEvent.requested)?_c('div',{staticClass:"bottom-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.mapApprovalText(dayEvent))}})]):_vm._e()],1),(!dayEvent.locked)?_c('v-flex',{class:[
              (dayEvent.add || dayEvent.edit || dayEvent.requested)
                ? 'vertical-text-40' : ''
            ],attrs:{"xs4":dayEvent.reviewed}},[(dayEvent.add && _vm.canShowAddButton(date))?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.openAddTime(date)}}},[_vm._v("\n            + Add Time\n            ")]):_vm._e(),(_vm.allowEditButton(dayEvent) && _vm.allowReview(dayEvent))?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.openEditTime(dayEvent)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")]),_vm._v("Edit\n            ")],1):_vm._e(),(_vm.allowReview(dayEvent) && dayEvent.reviewed)?_c('v-btn',{staticStyle:{"margin-right":"4px"},attrs:{"dark":"","depressed":"","small":"","color":"success"},on:{"click":function($event){return _vm.openEditTime(dayEvent)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")]),_vm._v(" Review\n            ")],1):_vm._e()],1):_c('v-flex',{staticStyle:{"margin-top":"8px"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-lock")])],1)],1)}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }