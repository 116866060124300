<template>
  <div class="pl-2 pt-1 mt-2">
        <v-avatar class="avatar-picture"
                  :size="25"
                  @click="changeAvatar">
          <img v-bind:src="imageSrc" alt="Avatar" height="25" width="25">
        </v-avatar>
        <v-btn class="body-2 ml-3" color="blue lighten-4"
               small @click="changeAvatar">
          Change Avatar
        </v-btn>
      <my-upload
              ref="myUpload"
              field="avatar"
              @crop-success="cropSuccess"
              v-model="showUploadForm"
              :width="200"
              :height="200"
              lang-type="en"
              v-notice="noticeMsg"
              img-format="png">
      </my-upload>
  </div>
</template>

<script>

import {VAvatar, VInput} from 'vuetify';
import myUpload from 'vue-image-crop-upload/upload-2.vue';

export default {
  name: 'worker-avatar-form',
  props: ['workerAvatar'],
  components: {
    VAvatar,
    VInput,
    myUpload
  },
  data() {
    return {
      defaultAvatar: '/images/blank-avatar.png',
      showUploadForm: false,
      imgDataUrl: null,
      noticeMsg: '<span class="red--text text--lighten-2">' +
        'Minimum file Size : 200x200</span>'
    };
  },
  computed: {
    imageSrc () {
      return this.imgDataUrl || this.workerAvatar || this.defaultAvatar;
    }
  },
  methods: {
    changeAvatar () {
      this.showUploadForm = true;
    },
    // eslint-disable-next-line no-unused-vars
    cropSuccess (imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
      this.$emit('avatar-uploaded', imgDataUrl);
    },

    reset (){
      this.imgDataUrl = null;
      this.$refs.myUpload.setStep(1);
    }
  },
  directives: {
    notice: {
      inserted: function (el, bindings) {
        let notice = document.createElement('div');
        notice.innerHTML = bindings.value;
        let parentDiv = el.firstChild;
        let lastDiv = parentDiv.lastChild;
        parentDiv.insertBefore(notice, lastDiv);
      }
    }
  }
};

</script>

<style>

</style>
