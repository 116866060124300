//utility methods for calendar stuff
import moment from 'dayspan/node_modules/moment';

export default {
  /**
   *
   * @param {*} weekDayStart [0-6]
   * @param {*} date [timestamp, isoString...]
   */
  weekStart(isoWeekday, date) {
    let _date = moment(date).utc();
    var daysToSubtract = moment(_date).utc().isoWeekday() >= isoWeekday ?
        moment(_date).utc().isoWeekday() - isoWeekday :
        7 + moment(_date).utc().isoWeekday() - isoWeekday;

    // subtract days from start of period
    return moment(_date).utc().utcOffset(0).subtract('d', daysToSubtract);
  }
};
