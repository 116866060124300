import axios from 'axios';

export const company = {
  data: function () {
    return {
      companyInfo: null,
      companies: []
    };
  },
  methods: {
    setPreviewUrl(slug) {
      this.previewUrl = '';
      if (slug.length !== 0) {// slug set
        this.previewUrl = process.env.VUE_APP_PREVIEW_URL + slug;
      } else {
        this.previewUrl = '';
      }
    },
    getCompanyInfo(managerId) {
      let _vm = this;
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}companies/load/${managerId}`
        }).then(response => {
          let r = response.data;
          if (r.status === 'success' && r.data) {
            resolve(r.data);
            _vm.companyInfo = r.data;
          } else {
            reject(r);
          }
        }).catch(err => {
          _vm.$notify(`Can't load the company info: ${err}`, 'error');
          reject(err);
        });
      });
    },
    getCompanies(hasWorkers) {
      let _vm = this;
      let url = process.env.VUE_APP_API_URL + 'companies/list/all';
      if (hasWorkers) {
        url += '?hasWorkers=1';
      }
      axios(url).then(response => {
        if (response.data.status === 'success') {
          _vm.companies = response.data.data;
        }
      }).catch(() => {
      });
    }
  }
};
