<template>
    <div>
        <HomeHeader></HomeHeader>
        <HomeLeftContent></HomeLeftContent>
    </div>
</template>
<script>
import {user} from '../../mixins/user';
import HomeHeader from '@/components/layouts/HomeHeader';
import HomeLeftContent from '@/components/layouts/HomeLeftContent';
export default {
  name: 'login',
  metaInfo: {
    title: 'SimpleTym - Manage your workers easily!',
    titleTemplate: null
  },
  mixins: [user],
  components: {
    HomeHeader,
    HomeLeftContent
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {
  }
};
</script>
<style scoped>
</style>
