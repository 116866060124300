<template>
    <div style="background: #FFF;">
      <HomeHeader :identity="this.identity"></HomeHeader>
        <div class="body">
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="requests"
              :pagination.sync="pagination"
              :total-items="totalRequests"
              :loading="loading"
              select-all
              :no-data-text='noRecordsText'
              :no-results-text='noRecordsText'
              :rows-per-page-items="selectPagination"
              v-model="selected"
              hide-actions
              class="table-requests"
            >
            <v-progress-linear
              slot="progress"
              background-color="teal lighten-4"
              color="teal lighten-3"
              height="4"
              indeterminate
            ></v-progress-linear>
              <template slot="headers" slot-scope="props">
                <tr
                  :key="props.index"
                  style="border-bottom: 5px solid #04796e;">
                  <th class="teal">
                    <v-checkbox
                      :input-value="props.all"
                      :indeterminate="props.indeterminate"
                      primary
                      hide-details
                      @click.native="toggleAll"
                      :key="props.index"
                    ></v-checkbox>
                  </th>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="getHeadersConfig(header)"
                  >
                    <v-icon small v-if="header.sortable">arrow_upward</v-icon>
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template slot="items" slot-scope="props">
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details
                    color="teal"
                  ></v-checkbox>
                </td>
                <td :class="[
                  'text-xs-center'
                ]">
                  <div v-html="mapTitle(props.item)"></div>
                  <span
                    v-if="canShowDescription(props.item)">
                    <v-icon
                      small
                      style="font-size:13px;transform: rotate(-30deg)">
                      fas fa-thumbtack
                    </v-icon>
                    &laquo; <i>{{ props.item.description }}</i> &raquo;
                  </span>
                  <span
                    v-if="props.item.type===2">
                    <v-icon
                      small
                      style="font-size:13px;">
                      warning
                    </v-icon>
                    <b style="color: red;">Cancellation Request</b>
                  </span>
                </td>
                <td
                  :class="[
                    'text-xs-center'
                  ]">
                    <v-chip
                        small
                        :style="{
                        'background-color': props.item['Role.paperColor']
                        }"
                        text-color="white">
                        <v-avatar>
                            <v-icon>label</v-icon>
                        </v-avatar>
                        {{ props.item['Role.name'] }}
                    </v-chip>
                </td>
                <td
                  :class="[
                    'justify-center px-0'
                  ]"
                >
                  <v-btn
                    color="success"
                    @click="approveRequest(props.item)"
                    :title="labels.approve"
                    dark
                  >
                  <v-icon>fas fa-check-circle</v-icon>&nbsp;
                  <b>{{ labels.approve }}</b>
                  </v-btn>
                  <v-btn
                    color="error"
                    @click="declineRequest(props.item)"
                    :title="labels.decline"
                    dark
                  >
                  <v-icon>fas fa-ban</v-icon>&nbsp;
                  {{ labels.decline }}
                  </v-btn>
                </td>
              </template>
              <template slot="footer">
                <tr
                  class="teal"
                  style="border-top: 4px solid rgb(4, 121, 110)"
                >
                  <td colspan="100%">
                      <v-layout row wrap>
                          <v-flex xs1 class="perPage white--text">
                            Per page :
                          </v-flex>
                          <v-flex xs1>
                              <v-select
                                :items="selectPagination"
                                v-model="pagination.rowsPerPage"
                                :single-line="true"
                                solo
                                @change="resetPagination"
                              ></v-select>
                          </v-flex>
                          <v-flex xs5 style="margin-right: 35px;">
                          </v-flex>
                          <v-flex xs4 style="text-align: right;">
                            <v-btn
                              color="success"
                              :disabled="disableBulkApprove"
                              v-show="!disableBulkApprove"
                              @click="bulkApprove"
                            >
                            <v-icon>fas fa-check-circle</v-icon>&nbsp;
                            {{ approveRequestText }}
                            </v-btn>
                            <v-btn
                              color="error"
                              :disabled="disableBulkDecline"
                              v-show="!disableBulkDecline"
                              @click="bulkDecline"
                            >
                            <v-icon>fas fa-ban</v-icon>&nbsp;
                            {{ declineRequestText }}
                            </v-btn>
                          </v-flex>
                      </v-layout>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                color="teal"
                circle
                @input="nextPage"
              ></v-pagination>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */

//import all components
import HomeHeader from '@/components/layouts/HomeHeader';
import axios from 'axios';
import {
  VDialog,
  VPagination,
  VCheckbox,
  VProgressLinear,
  VChip
} from 'vuetify';

//import all mixins
import {user} from '@/mixins/user';
import {crud} from '@/mixins/crud';
import {timezone} from '@/mixins/timezone';

import * as moment from 'moment-timezone';

//require node modules
var _ = require('underscore');

export default {
  name: 'Requests',
  mixins: [
    user,
    crud,
    timezone
  ],
  components: {
    HomeHeader,
    VDialog,
    VPagination,
    VCheckbox,
    VProgressLinear,
    VChip
  },
  data () {
    return {
      loading: true,
      dialog: false,
      totalRequests: 0,
      disableBulkApprove: true,
      disableBulkDecline: true,
      disableNotification: false,
      approveRequestText: '',
      declineRequestText: '',
      requests: [],
      selected: [],
      deleted: [],
      selectPagination: [
        20,
        30,
        40,
        50
      ],
      noRecordsText: '',
      page: {
        title: 'Requests'
      },
      pagination: {
        page: 1,
        rowsPerPage: 20
      },
      headers: [
        {
          text: 'Title',
          value: 'title',
          sortable: false
        },
        {
          text: 'Role',
          sortable: false,
          value: 'role'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      labels: {
        approve: 'Approve',
        approveMany: 'Approve Many',
        approveAll: 'Approve All',
        decline: 'Decline',
        declineMany: 'Decline Many',
        declineAll: 'Decline All'
      }
    };
  },
  mounted () {
    let _vm = this;
    _vm.pagination.rowsPerPage = _vm.$root.settings.defPageSize;
    if (_vm.pagination.rowsPerPage) {
      _vm.load();
    }
    _vm.$root.$watch('settings.defPageSize', function(value) {
      if (value) {
        _vm.pagination.rowsPerPage = value;
        _vm.load();
      }
    });
    //emit reload
    _vm.$root.$on('request-reload', () => {
      _vm.reload();
    });
    _vm.noRecordsText = 'Loading requests...';
  },
  watch: {
    pagination: {
      handler () {
        let _vm = this;
        _vm.disableBulkApprove = true;
        _vm.disableBulkDecline = true;
        _vm.selected = [];
      },
      deep: true
    },
    deleted() {
      if (!this.totalRequests) {
        this.noRecordsText = 'No records found.';
      }
    },
    selected: {
      handler () {
        let _vm = this;
        let length = _vm.selected.length;
        _vm.disableBulkApprove = !length;
        _vm.disableBulkDecline = !length;
        //small adjust for plural && all
        if (length === 1) {
          _vm.approveRequestText = _vm.labels.approve;
          _vm.declineRequestText = _vm.labels.decline;
        } else if (length === _vm.pagination.rowsPerPage
                    || length === _vm.totalRequests
                        && length < _vm.pagination.rowsPerPage) {
          _vm.approveRequestText = _vm.labels.approveAll;
          _vm.declineRequestText = _vm.labels.declineAll;
        } else {
          _vm.approveRequestText = _vm.labels.approveMany;
          _vm.declineRequestText = _vm.labels.declineMany;
        }
      },
      deep: true
    }
  },
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null) {
        return 0;
      }
      return Math.ceil(this.totalRequests / this.pagination.rowsPerPage);
    },
    companyId () {
      return this.getUser('manager', 'companyId');
    }
  },
  methods: {
    canShowDescription(item) {
      return item.description && item.type !== 2;
    },
    mapTitle(item) {
      let _vm = this;
      let dateFormat = _vm.getReadableDateFormat();
      let tz = _vm.identity.manager.timezone;
      //eslint-disable-next-line
      let startTimestamp = item.date + _vm.timeToDecimalHour(item.timeStart) + tz*3600;
      //eslint-disable-next-line
      let endTimestamp = item.date + _vm.timeToDecimalHour(item.timeEnd) + tz*3600;
      let startTime = _vm.timeFormat(moment(startTimestamp*1000).utcOffset(0));
      let endTime = _vm.timeFormat(moment(endTimestamp*1000).utcOffset(0));
      return '<b>' + item.firstName + ' ' + item.lastName + '</b>'
              + ' have requested manual time at '
              + moment(startTimestamp*1000).format(dateFormat) + ' from '
              + startTime + ' to ' + endTime;
    },
    bulkApprove() {
      let _vm = this;
      let items = _vm.selected;
      let ConfirmBoxTitle = 'Approve Requests Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want to '
                              + _vm.approveRequestText.toLowerCase().trim()
                              + ' requests';
      _vm.disableNotification = true;
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _.each(items, (item) => {
          _vm.approveRequestApi(item);
        });
        setTimeout(() => {
          if (!_vm.selected.length) {
            _vm.$notify('Requests have been approved successfully!', 'success');
          } else {
            let _failedEntries = _vm.selected.length - _vm.deleted.length;
            _vm.$notify( _failedEntries + ' Requests not approved!', 'error');
          }
        }, 500);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    bulkDecline() {
      let _vm = this;
      let items = _vm.selected;
      let ConfirmBoxTitle = 'Decline Requests Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want to '
                              + _vm.declineRequestText.toLowerCase().trim()
                              + ' requests';
      _vm.disableNotification = true;
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _.each(items, (item) => {
          _vm.declineRequestApi(item);
        });
        setTimeout(() => {
          if (!_vm.selected.length) {
            _vm.$notify('Requests have been declined successfully!', 'success');
          } else {
            let _failedEntries = _vm.selected.length - _vm.deleted.length;
            _vm.$notify( _failedEntries + ' Requests not declined!', 'error');
          }
        }, 500);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    approveRequest(item) {
      let _vm = this;
      let ConfirmBoxTitle = 'Approve Request Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want approve '
                              + item.firstName
                              + ' '
                              + item.lastName
                              + ' request ?';
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _vm.approveRequestApi(item);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    declineRequest(item) {
      let _vm = this;
      let ConfirmBoxTitle = 'Decline Request Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want decline '
                              + item.firstName
                              + ' '
                              + item.lastName
                              + ' request ?';
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _vm.declineRequestApi(item);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    getHeadersConfig(header) {
      let _vm = this;
      let baseConfig = [
        'column teal white--text text-xs-center',
        header.sortable ? 'sortable' : '',
        (header.value === _vm.pagination.sortBy
          && header.sortable) ? 'active' : '',
        header.hide && !_vm.adminView ? 'hide' : ''
      ];
      let sortableArrow = (_vm.pagination.descending) ? 'desc' : 'asc';
      if (header.sortable) {
        baseConfig.push(sortableArrow);
      }
      return baseConfig;
    },
    nextPage (page) {
      let _vm = this;
      let rowsPerPage = _vm.pagination.rowsPerPage;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.pagination.number = ((page-1)*rowsPerPage)+1;
        _vm.requests = data;
      });
    },
    async fetchDataFromApi () {
      let _vm = this;
      _vm.loading = true;
      const {page, rowsPerPage} = _vm.pagination;
      let items = await _vm.loadRequests(page, rowsPerPage);
      return new Promise((resolve) => {
        /*if (_vm.pagination.sortBy) {
          items = _vm.sorting(items);
        }*/
        if (rowsPerPage > 0) {
          items = _vm.paginate(items);
        }
        setTimeout(() => {
          _vm.loading = false;
          _vm.clear();
          if (page === 1) {
            _vm.pagination.number = 1;
          }
          resolve(items);
        }, 1000);
      });
    },
    async loadRequests (page, pageSize) {
        let _vm =  this;
        _vm.loading = true;
        _vm.noRecordsText = '';
        let url = `${_vm.$root.apiUrl}requests/all/${_vm.companyId}/0/`;
        let requestPromise = axios(url
                + pageSize
                + '/' + page);
        const [request] = await Promise.all([requestPromise]);
        let _data = _vm.requests;
        if (request.data.status === 'success') {
          _data = request.data.data;
          _vm.totalRequests = request.data.totalCount;
          if (_vm.totalRequests === 0) {
            _vm.noRecordsText = 'No records found.';
          }
        } else {
          _vm.$notify(request.data.message, 'error');
          _vm.noRecordsText = 'No records found.';
        }
      return _data;
    },
    paginate (items) {
      const {rowsPerPage} = this.pagination;
      return items.slice(0, rowsPerPage);
    },
    approveRequestApi(item) {
      this.submitRequestApi(item, {
        url: `${this.$root.apiUrl}requests/approve/${item.id}`,
        method: 'PATCH',
        data: {
          managerId: this.identity.manager.id
        }
      }, 'success');
    },
    declineRequestApi(item) {
      this.submitRequestApi(item, {
        url: `${this.$root.apiUrl}requests/decline/${item.id}`,
        method: 'DELETE'
      }, 'error');
    },
    submitRequestApi(item, apiSetting, notificationType) {
      let _vm = this;
      axios(apiSetting).then(response => {
        let r = response.data;
        if (r.status === 'success') {
          let index = _vm.requests.indexOf(item);
          _vm.totalRequests -= 1;
          _vm.requests.splice(index, 1);
          //track all requests deleted successfully
          _vm.deleted.push(item.id);
          if (!_vm.disableNotification) {
            _vm.$notify(r.message, notificationType);
          }
          //append more if needed...
        } else {
          if (!_vm.disableNotification) {
            _vm.$notify(r.message, 'error');
          }
          //append more if needed...
        }
      }).catch(error => {
        _vm.$notify(error.message, 'error');
      });
    },
    reload () {
      let _vm = this;
      _vm.pagination = {
        page: 1,
        rowsPerPage: _vm.$root.settings.defPageSize
      };
      _vm.load();
    },
    clear () {
      this.requests = [];
    },
    load () {
      let _vm = this;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.requests = data;
      });
    },
    resetPagination (value) {
      let _vm = this;
      _vm.pagination.page = 1;
      _vm.pagination.rowsPerPage = value;
      _vm.load();
    },
    toggleAll () {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.requests.slice();
      }
    }
  }
};
</script>
<style scoped>
/* tables styles */
thead tr th {
  text-align: left;
}
.hide {
  display: none;
}
table.v-table tbody td:not(:nth-child(1)) {
  padding-left:0;
}
.table-requests {
  border: 5px solid #04796e;
  margin: 0px 30px;
  background-color: #04796e;
}
/*pagination*/
.perPage {
  float: right;
  vertical-align: bottom;
  text-align: right;
  margin-right: 15px;
  position: relative;
  padding-top: 13px;
}
</style>
