<template>
    <div>
        <div id="header" :class="[
          color
        ]">
            <div class="left logo">
              <a @click="home()">
                <v-icon>far fa-clock</v-icon> &nbsp;<span>SimpleTym</span>
              </a>
            </div>
            <div class="left"
            d-flex
            v-if="this.isAuthenticated()">
              <v-tooltip
                bottom
                slot="activator"
                v-for="nav in navs"
                :key="nav.name"
                v-if="nav.show"
              >
                <v-btn
                  :href="'/'+nav.uri"
                  color="info"
                  :class="[
                    'menu-link',
                    isActiveTab(nav.uri) ? 'active' : ''
                  ]"
                  slot="activator"
                v-intro="introInfo[nav.name] ?
                  introInfo[nav.name].message : ''"
                v-intro-step="introInfo[nav.name] ?
                  introInfo[nav.name].step : ''"
                v-intro-if="introInfo[nav.name]"
                >
                  <v-icon right dark>{{ nav.icon }}</v-icon>
                  <span :style="{color: systemTextColor}" class="nav-text">
                    {{ nav.name }}
                  </span>
                </v-btn>

                <span>{{ nav.info }}</span>
              </v-tooltip>
              <TuduBoardsList
                class="tudu-create-modal"
                v-if="isAuthenticated()"
                :identity="identity"
              ></TuduBoardsList>
            </div>
            <v-tooltip
              bottom
              slot="activator"
              v-if="this.isAuthenticated() && allRequestsCount>0"
            >
              <v-btn
                flat
                :href="'/requests'"
                color="info"
                :class="[
                  'menu-link',
                  'requests'
                ]"
                slot="activator"
              >
                <v-icon
                  right
                  dark
                  :class="[
                    newRequestsCount > 0 ? 'blink' : ''
                  ]">
                  notification_important
                </v-icon>
                <span :style="{color: systemTextColor}" class="nav-text">
                  REQUESTS ({{ allRequestsCount }})
                </span>
              </v-btn>
              <span v-if="newRequestsCount > 0">
                You have new requests.
              </span>
              <span v-else>
                Time Requests.
              </span>
            </v-tooltip>
            <div class="right">
                <v-menu offset-y v-show="true" v-if="this.isAuthenticated()">
                  <v-btn
                    slot="activator"
                    dark
                    flat
                    style="min-width: 10px;margin:0;padding:2px;"
                  >
                    <v-icon dark>notifications</v-icon>
                  </v-btn>
                  <v-list>
                    <v-list-tile>
                      <v-list-tile-title>
                        No new notifications.
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="this.isAuthenticated()">
                  <v-btn
                    slot="activator"
                    dark
                    flat
                    style="margin-left: 0"
                  >
                    <v-avatar class="mr-2" size="32px">
                      <img
                        :src="userAvatarSrc"
                        alt="Avatar" >
                    </v-avatar>
                    <b>{{this.getfullName()}}</b>
                    <v-icon right dark>arrow_drop_down</v-icon>
                  </v-btn>
                  <v-list>
                    <!--v-list-tile @click="profile">
                      <v-list-tile-title>Profile</v-list-tile-title>
                    </v-list-tile-->
                    <v-list-tile
                      @click="logout()"
                    >
                      <v-list-tile-title>Logout</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
                <div v-if="!this.isAuthenticated()">
                      <v-btn
                        color="blue"
                        class="white--text"
                        @click="$router.push('/login')"
                      >
                          <v-icon>fas fa-sign-in-alt</v-icon> &nbsp;&nbsp;Login
                      </v-btn>
                    <v-btn
                      color="warning"
                      class="white--text"
                      @click="$router.push('/register')"
                    >
                      <v-icon>fas fa-user-plus</v-icon> &nbsp;&nbsp;Register
                    </v-btn>
                </div>
            </div>
        </div>
        <v-dialog v-model="profileDialog" max-width="650px">
        <v-card>
          <v-card-title class="teal">
            <span class="headline dialogTitle">Profile</span>
          </v-card-title>
          <v-card-text class="text-xs-center">
            <!--<user-profile-avatar-->
                 <!--@changed="setAvatar"-->
                 <!--:user-token="getUserToken()"-->
                 <!--:user-avatar="userAvatarSrc"></user-profile-avatar>-->
            <stacked-table
              :table-fields="profileFields"
              :data-object="currentUser">
            </stacked-table>
          </v-card-text>
          <v-card-actions class="teal">
            <v-btn
              color="blue lighten-4"
              flat
              @click="profileDialog=false">
                Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';
import {user} from '../../mixins/user';
import notification from '@/utils/notification';
import {setting} from '@/mixins/setting';
import {intro} from '../../mixins/intro';
import {
  VDialog,
  VAvatar,
  VMenu
} from 'vuetify';
import {_} from 'vue-underscore';
import StackedTable from './StackedTable';
import UserProfileAvatar from './UserProfileAvatar';
import TuduBoardsList from '@/components/modules/tudu/BoardsList';
//define header bar methods
let methods = {
  cancelAutoUpdate() {
    clearInterval(this.requestNotificationTimer);
  },
  checkRequestsNotification() {
    let _vm = this;
    let companyId = _vm.getUser('company', 'id');
    //check not approved requests
    let url = `${_vm.$root.apiUrl}requests/check-new/${companyId}`;
    axios.get(url).then(response => {
      let r = response.data;
      if (r.status === 'success' &&
          r.hasOwnProperty('count')) {
        _vm.allRequestsCount = r.count.notApproved;
        if (_vm.$route.name === 'requests'
            && r.count.notReaded > 0) {//when requests page
          notification.markAll().then(() => {
            _vm.$root.$emit('request-reload');
            _vm.newRequestsCount = 0;
          });
        } else {
          _vm.newRequestsCount = r.count.notReaded;
        }
      }
    }).catch(err => {
      _vm.notify(err.message, 'error');
    });
  },
  home() {
    if (this.isAuthenticated()) {
      this.$router.push('/dashboard');
    } else {
      this.$router.push('/');
    }
  },
  go (link) {
    this.$router.push(link);
  },
  isActiveTab (tab) {
    return this.$router.currentRoute.name === tab;
  },
  logout () {
    this.$store.dispatch('logout');
    this.$router.push('/login');
    this.$root.settings.manDefThemeColor = 'teal';
  },
  login () {
    this.$router.push('/login');
  },
  register () {
    this.$router.push('/register');
  },
  profile () {
    this.profileDialog = true;
  }
  // append more if needed
};
_.extend(methods, user.methods);

  export default {
    name: 'home-header',
    props: ['identity'],
    data () {
      return {
        newRequestsCount: 0,
        allRequestsCount: 0,
        requestNotificationTimer: '',
        profileDialog: false,
        color: '',
        navs: [
          {
            name: 'People',
            icon: 'person_outline',
            info: 'Manage all People.',
            uri: 'people',
            show: true
          },
          {
            name: 'Calendar',
            icon: 'event',
            info: 'Manage people schedules (presets, manual).',
            uri: 'cal',
            show: true
          },
          {
            name: 'Payroll',
            icon: 'attach_money',
            info: 'Weekly Payments.',
            uri: 'payroll',
            show: true
          },
          {
            name: 'Settings',
            icon: 'settings',
            info: 'Admin Settings.',
            uri: 'settings',
            show: true
          }
        ],
        profileFields: [
          {
            key: 'firstName',
            label: 'Firstname'
          },
          {
            key: 'lastName',
            label: 'Lastname'
          },
          {
            key: 'email',
            label: 'Email'
          },
          {
            key: 'createdAt',
            label: 'Date of Creation'
          }
        ]
      };
    },
    mixins: [setting, intro],
    components: {
      VDialog,
      VMenu,
      VAvatar,
      StackedTable,
      UserProfileAvatar,
      TuduBoardsList,
    },
    created: function() {
      let _vm = this;
      if (_vm.isAuthenticated()) {
        let checkInterval = process.env.VUE_APP_REQUEST_CHECK_INTERVAL || 5000;
        _vm.checkRequestsNotification();
        _vm.requestNotificationTimer = setInterval(
                                          _vm.checkRequestsNotification
                                        ,checkInterval);
      }
    },
    beforeDestroy() {
      this.cancelAutoUpdate();
    },
    mounted () {
      let _vm = this;
      _vm.color = _vm.systemColor;
    },
    computed: {
      currentUser: function() {
        return this.identity ? this.identity.user : {};
      },
      userToken: function() {
        if (this.identity) {
          return this.getUserToken();
        }
        return '';
      },
      userAvatarSrc: function() {
        if (this.currentUser.avatar) {
          let url = new URL(this.currentUser.avatar,
            process.env.VUE_APP_CAS_API_URL);
          return url.href;
        } else {
            return '/images/blank-avatar.png';
        }
      }
    },
    methods: methods
  };
</script>

<style scoped>
#header {
    box-shadow: 1px 1px 1px #BBB;
    padding: 4px;
    height: 55px;
}
.menu-link {
    padding-left: 0px;
}
a {
    text-decoration: none;
}
a {
    color: #08c;
}
.right a:hover{
    text-decoration: underline;
}
#header .left {
    margin-left: 40px;
}
#header .logo {
    margin-top: 5px;
    margin-left: 5px;
}
#header .left span {
    font-weight: bold;
    font-size: 25px;
    color: #fff;
}
#header .left .nav-text {
  font-size: 14px;
  padding-left: 5px;
}
.dialogTitle {
  font-weight: bold;
  color: white;
  cursor: default;
}
#header .active {
  background-color: #FFA726 !important;
}
.requests {
  background-color: rgb(234, 7, 7);
}

.blink {
  opacity: 0;
  animation: blinking 1s linear infinite;
}

@keyframes blinking {
  from,
  49.9% {
    opacity: 0;
  }
  50%,
  to {
    opacity: 1;
  }
}
</style>
