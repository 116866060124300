var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","xs12":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md12":""}},[_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"color":_vm.inputColor,"items":_vm.workers,"filter":_vm.searchFilter,"data-vv-name":"defaultWorker","error-messages":_vm.errors.collect('defaultWorker'),"label":"Choose a Worker","prepend-icon":"person","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-avatar',[_c('img',{staticClass:"w_picture",attrs:{"src":_vm.getAvatar(data.item.avatar),"alt":"Avatar"}})]),_c('span',{class:[
                        'subheading',
                        'font-weight-medium',
                        'text-capitalize',
                        'px-2'
                    ]},[_vm._v("\n                    "+_vm._s(data.item.firstName + ' ' + data.item.lastName)+"\n                    ")])]}},{key:"item",fn:function(data){return [_c('v-avatar',[_c('img',{staticClass:"w_picture",attrs:{"src":_vm.getAvatar(data.item.avatar),"alt":"Avatar"}})]),_c('span',{class:[
                        'subheading',
                        'font-weight-medium',
                        'text-capitalize',
                        'px-2'
                    ]},[_vm._v("\n                    "+_vm._s(data.item.firstName + ' ' + data.item.lastName)+"\n                    ")])]}}]),model:{value:(_vm.form.worker),callback:function ($$v) {_vm.$set(_vm.form, "worker", $$v)},expression:"form.worker"}})],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","xs12":"","wrap":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.worker !== null),expression:"form.worker !== null"}],staticClass:"red--text"},[_vm._v("\n                Be very sure that you want to give full access to this user.\n            ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }