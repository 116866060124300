import axios from 'axios';

export default {
    //load company data from slug
    getCompanyFromSlug (slug) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}companies/${slug}/show`
        }).then(response => {
          resolve(response.data.data);
        }).catch(err => {
          reject(err);
        });
      });
    },
    //load worker from slug
    getWorkerFromSlug (slug) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}workers/${slug}/show`
        }).then(response => {
          resolve(response.data.data);
        }).catch(err => {
          reject(err);
        });
      });
    }
};
