<template>
    <div style="background: #FFF;">
        <HomeHeader :identity="this.identity"></HomeHeader>
        <div class="body">
            <v-container fluid grid-list-md text-xs-center>
                <v-layout row wrap>
                    <v-flex
                      xs2 offset-xs1
                      class="rightBorder leftMenu matchContainerSize"
                      style="padding: 0">
                      <div
                        :class="[
                          'left-tab',
                          (vNav.active) ? 'active' : ''
                        ]"
                        v-for="vNav in vNavs"
                        v-show="vNav.show"
                        :key="vNav.name">
                        <v-btn
                          flat large
                          :block="true"
                          :class="[]"
                          @click="goToNav(vNav.name)">
                          <v-icon left dark>{{ vNav.icon }} </v-icon>
                          {{ vNav.name }}
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs9>
                      <div
                        class="tab-content"
                        v-show="showTabContent('General')">
                        <general-settings
                          :form="generalForm"
                          :settingLabels="system.labels"
                          :timezoneList="timezoneList"></general-settings>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Account')">
                        <user-account :identity="this.identity"></user-account>
                      </div>
                      <div
                          class="tab-content"
                          v-show="showTabContent('Company')" >
                        <company-settings></company-settings>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Roles')">
                        <roles-page
                          :companyId="companyId">
                        </roles-page>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Departments')">
                        <departments-page
                          :companyId="companyId">
                        </departments-page>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Managers')">
                        <managers-page></managers-page>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Events')">
                        <h1>Events</h1>
                        <div class="tab-content-body">
                          Coming Soon...
                        </div>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Schedule')">
                        <h1>Schedule</h1>
                        <div class="tab-content-body">
                          Coming Soon...
                        </div>
                      </div>
                      <div
                        class="tab-content"
                        v-show="showTabContent('Mobile')">
                        <div class="tab-content-body">
                          <mobile-settings></mobile-settings>
                        </div>
                      </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import HomeHeader from '@/components/layouts/HomeHeader';
import CompanySettings from '@/components/settings/CompanySettings';
import MobileSettings from '@/components/settings/MobileSettings';
import GeneralSettings from '@/components/settings/GeneralSettings';
import UserAccount from '@/components/settings/UserAccount';
import ManagersPage from '@/components/managers/Index';
import RolesPage from '@/components/roles/Index';
import DepartmentsPage from '@/components/departments/Index';
//import AppearanceSettings from '@/components/settings/AppearanceSettings';

//import all mixins
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {company} from '@/mixins/company';

//import core modules
import {_} from 'vue-underscore';
import axios from 'axios';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'Settings',
  metaInfo: {
    title: 'SimpleTym - Settings',
    titleTemplate: null
  },
  mixins: [
    user,
    timezone,
    company
  ],
  components: {
    HomeHeader,
    GeneralSettings,
    CompanySettings,
    MobileSettings,
    //AppearanceSettings,
    UserAccount,
    ManagersPage,
    RolesPage,
    DepartmentsPage
  },
  data () {
    return {
      system: {
        labels: {
          defaultPaginationSize: 'Pagination',
          minSearchingStart: 'Minimum Search Start',
          //defaultWorkerTimezone: 'Worker TimeZone',
          defaultManagerTimezone: 'Set Default Manager Timezone',
          //defaultGuestManagerTimezone: 'Set Guest Default Manager Timezone',
          defaultThemeColor: 'Set Default Theme color'
        }
      },
      timezoneList: [],
      themes: [
        'teal'
      ],
      systemColor: 'teal',
      form: {
        managerTimezone: '',
        themeColor: ''
      },
      generalForm: {
        minSearchingStart: 2,
        //timezone: '',
        settingsPageSize: 10
      },
      vNavs: [
        {
          name: 'General',
          active: false,
          icon: 'fa fa-cogs',
          info: 'Update System General Settings',
          show: true
        },
        {
          name: 'Account',
          active: false,
          icon: 'person',
          info: 'Update Account Informations',
          show: true
        },
        {
          name: 'Company',
          active: false,
          icon: 'business',
          info: 'Update Company Settings',
          show: true
        },
        {
          name: 'Roles',
          active: false,
          icon: 'supervised_user_circle',
          info: 'Roles page',
          show: true
        },
        {
          name: 'Departments',
          active: false,
          icon: 'domain',
          info: 'Departments page',
          show: true
        },
        {
          name: 'Managers',
          active: false,
          icon: 'people',
          info: 'Managers page',
          show: true
        },
        {
          name: 'Events',
          active: false,
          icon: 'event',
          info: 'Manage all events to use in schedules page.',
          show: true
        },
        {
          name: 'Schedule',
          active: false,
          icon: 'av_timer',
          info: 'Custom Schedules Section',
          show: true
        },
        {
          name: 'Mobile',
          active: false,
          icon: 'phonelink_setup',
          info: 'Custom Mobile View Section',
          show: true
        },
        /*{
          name: 'Appearance',
          active: false,
          icon: 'blur_on',
          info: 'Change appearance of Simple Tym',
          show: true
        },
        {
          name: 'Logging',
          active: false,
          icon: '',
          info: '',
          show: true
        }*/
      ]
    };
  },
  mounted () {
    let _vm = this;
    let _query = _vm.$route.query;
    let _view = (_query.hasOwnProperty('view')) ? _query.view
                                                : '';
    // auto-load timezone data
    _vm.loadReadableTz();
    // load setting data from api
    _vm.loadSetting();
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
    //
    _vm.systemColor = _vm.$root.settings.manDefThemeColor;
    //load menu with roles
    _vm.loadMenuWithPermissions(_view);
  },
  computed: {
    companyId () {
      return this.getUser('manager', 'companyId');
    }
  },
  methods: {
    loadMenuWithPermissions (view) {
      let _vm = this;
      let generalTab = _.find(_vm.vNavs, function(item) {
        return item.name === 'General';
      });
      let viewTab = {};
      //show general tab for admin users only
      generalTab.show = _vm.isAdmin();
      if (view.length > 0) {//means that ?view=<view> set
        viewTab = _.find(_vm.vNavs, function(item) {
          return item.name.toLowerCase() === view.toLowerCase();
        });
      }
      //set active tab
      if (!_.isEmpty(viewTab)) {//view found from query
        _vm.changeTab(viewTab);
      } else {
        let firstActiveTab = _.find(_vm.vNavs, function(item) {
          return item.show === true;
        });
        _vm.changeTab(firstActiveTab);
      }
    },
    goToNav (name) {
      this.$router.push('settings?view='+name.toLowerCase());
    },
    changeTab (nav) {
      let _vm = this;
      _.each(_vm.vNavs, function(_nav) {
        _nav.active = false;
      });
      nav.active = true;
    },
    navColor(active) {
      return (active) ? 'white' : 'teal';
    },
    loadSetting() {
      let _vm = this;
      let baseUrl = process.env.VUE_APP_API_URL;
      let url = baseUrl + 'settings/load'
                        + '/';
      axios({
        url: url,
        headers: {
          'Authorization': 'Bearer ' + _vm.getUserToken()
        }
      })
      .then(function(response) {
        let r = response.data;
        if (r.status === 'success') {
          let _setting = r.data.globalSetting;
          /*
          let _dWorkerTz = _setting.defaultWorkerTimezone;
          let _dGuestManagerTz = _setting.defaultGuestManagerTimezone;
          let timezone = _vm.readableOffset(_dWorkerTz)
                          + ' '
                          + _setting.defaultWorkerTimezoneText;
          /*let guestManagerTimezone = _vm.readableOffset(_dGuestManagerTz)
                          + ' '
                          + _setting.defaultGuestManagerTimezoneText;*/
          //
          _vm.generalForm.minSearchingStart =  _setting.minSearchCharacterCount;
         // _vm.generalForm.timezone = timezone;
          //_vm.form.guestManagerTimezone = guestManagerTimezone;
          _vm.generalForm.settingsPageSize = _setting.defaultPageSize;
          _vm.form.managerTimezone = _setting.managerTimezone;
          _vm.form.themeColor = _setting.themeColor;
        }
      });
    },
    showTabContent (tabId) {
      let _vm = this;
      let tab = _.find(_vm.vNavs, function (nav) {
        return (nav.name === tabId);
      });
      return (tab && tab.active);
    }
  }
};
</script>

<style scoped>
.rightBorder {
  border-right: 4px solid #009688;
}
.leftMenu {
}
.matchContainerSize {
  min-height: 500px;
}
.left-tab {
  border-radius: 5px 0 0 5px;
}
.left-tab.active, .left-tab:hover {
  background: #009688;
  -moz-box-shadow:    inset 0 0 10px #5555;
  -webkit-box-shadow: inset 0 0 10px #5555;
  box-shadow:         inset 0 0 10px #5555;
}
.left-tab.active button, .left-tab:hover button{
  color: white;
}
.left-tab button {
  color: #009688;
}
.shadow {
  -moz-box-shadow:    inset 0 0 10px #444;
  -webkit-box-shadow: inset 0 0 10px #444;
  box-shadow:         inset 0 0 10px #444;
}
.tab-content-body {
  text-align: left;
}
</style>
