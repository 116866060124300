<template>
    <div >
    <v-flex xs12>
        <v-layout row xs12 wrap>
            <v-flex xs4></v-flex>
            <v-flex xs6>
        <v-card
            class="elevation-6 box2"
        >
            <v-card-text>
        <v-layout row xs12 wrap>
            <v-flex xs4></v-flex>
            <v-flex xs6>
            <v-img
                src="images/blank-avatar.png"
                style="width: 120px"
            >
            </v-img>
            </v-flex>
            <v-flex xs2></v-flex>
        </v-layout>
            <v-form>
                <v-flex xs12>
                    <v-layout row xs12 wrap>
                        <v-text-field
                            v-validate="'required'"
                            v-model="form.companyName"
                            label="Company Name"
                            prepend-icon="business"
                            data-vv-name="companyname"
                            color="teal"
                            :error-messages="errors.collect('companyname')"
                            ></v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs12>
                    <v-layout row xs12 wrap>
                        <v-text-field
                            v-validate="'required|slug-unique'"
                            v-model="form.companySlug"
                            label="Slug Url"
                            prepend-icon="link"
                            data-vv-name="companyslug"
                            color="teal"
                            :error-messages="errors.collect('companyslug')"
                        ></v-text-field>
                    </v-layout>
                    <div class="slug">{{ previewUrl }}</div>
                </v-flex>
                <v-flex xs12>
                    <v-layout row xs12 wrap>
                    <v-select
                        v-model="form.timezone"
                        v-validate="'required'"
                        data-vv-name="timezone"
                        color="teal"
                        :error-messages="errors.collect('timezone')"
                        :items="timezoneList"
                        :clearable="true"
                        label="Choose your timezone"
                        prepend-icon="language"
                        solo
                        ></v-select>
                    </v-layout>
                </v-flex>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn
                @click="$router.push('/')"
                color="teal"
                fab dark
            >
            Back
            </v-btn>
            <v-spacer></v-spacer>
                <v-btn
                    @click="submitProfile"
                    color="teal"
                    fab dark
                    title="Complete your profile now."
                >
                Done
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-flex>
        <v-flex xs2></v-flex>
        </v-layout>
    </v-flex>
    </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import * as types from '@/store/types';
// import all mixins needed
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {manager} from '@/mixins/manager';
import {
  VForm,
  VInput,
  VTextField,
  VImg,
  VProgressLinear,
  VSelect
} from 'vuetify';

// import others ressources
import {_} from 'vue-underscore';

// merge methods
let methods = {
    submitProfile () {
        let _vm = this;
        let form = _vm.form;
        form.complete = true;
        _.extend(form, _vm.identity.user);
        _vm.form = form;
        //validate data
        _vm.$validator.validate().then(result => {
            if (result) { // well validated
                this.showLoading = true;
                let form = _vm.form;
                let timezoneValues = this.getTimezoneValues(form.timezone);
                _.extend(form, {
                    tz: timezoneValues[0],
                    // eslint-disable-next-line camelcase
                    tz_text: timezoneValues[1]
                });
                //signup user
                _vm.signup(form).then((result) => {
                    // check if user have login access
                    if (result.status === types.SIGNUP_SUCCESS) {
                        //console.log(result);
                        //move token back
                        _vm.moveTokenFromTemp();
                        //clear temp token
                        _vm.clearTempToken();
                        //omit uneeded values
                        let data = _.omit(result, 'status', 'message');
                        //encrypt user data
                        let eud = _vm.encryptUser(data);
                        localStorage.setItem('x-simple-tym-user', eud);
                        //redirect to dashboard
                        _vm.$root.$emit('refresh-identity');
                        _vm.$router.push('/dashboard');
                    } else {
                        _vm.$notify(result.message, 'error');
                    }
                }).catch(function (error) {
                    _vm.$notify(error.message, 'error');
                });
            }
        });
    },
    setDefaultTimezone() {
      let vm = this;
      if (!vm.form.timezone && vm.$root.settings.defGuestTz) {
        let textOffset = vm.readableOffset(vm.$root.settings.defGuestTz);
        let defTimezone = `${textOffset} ${vm.$root.settings.defGuestTzText}`;
        vm.$set(vm.form, 'timezone', defTimezone);
      }
    }
};

_.extend(methods, manager.methods);

export default {
  name: 'completeProfile',
  metaInfo: {
    title: 'SimpleTym - Complete Profile',
    titleTemplate: null
  },
  components: {
    VForm,
    VInput,
    VTextField,
    VImg,
    VProgressLinear,
    VSelect
  },
  mixins: [
    user,
    timezone
  ],
  data () {
    return {
        form: {
            companyName: '',
            companySlug: '',
            timezone: ''
        },
        previewUrl: '',
        timezoneList: [],
            dictionary: {
            attributes: {
                timezone: 'Timezone',
                companyname: 'Company Name',
                companyslug: 'Company Slug'
            },
            custom: {
                timezone: {
                required: () => 'Timezone should be selected.'
                },
                companyname: {
                required: () => 'Company Name field is required.'
                },
                companyslug: {
                required: () => 'Company Slug field is required.'
                }
            }
            }
    };
  },
  watch: {
    companySlug() {
        let _vm = this;
        let slug = _vm.form.companySlug;
        if (slug.length !== 0) {// slug set
            _vm.previewUrl = process.env.VUE_APP_PREVIEW_URL
                                + slug;
        } else {
            _vm.previewUrl = '';
        }
    }
  },
  computed: {
    companySlug() {
      return this.form.companySlug;
    }
  },
  mounted () {
    let _vm = this;
    // auto-load timezone data
    _vm.loadReadableTz();
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
    //like user module require authenticate before set identity
    //user instance will be returned here instead
    _vm.identity = _vm.getLocalUser();
    /*_vm.$root.$watch('settings.defGuestTz', function(value) {
      if (value) {
        _vm.setDefaultTimezone();
      }
    });
    _vm.setDefaultTimezone();*/
  },
  methods: methods
};
</script>

<style scoped>
  .slug {
    color:red
  }
  .box2 {
    width: 500px;
    border: 5px solid #009688;
    border-radius: 10px;
    position: relative;
    top: 120px;
  }
</style>
