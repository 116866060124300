<template>
    <div>
        <HomeHeader :identity="this.identity"></HomeHeader>
    </div>
</template>
<script>
import {user} from '../../mixins/user';
import {intro} from '../../mixins/intro';
import HomeHeader from '@/components/layouts/HomeHeader';
export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'SimpleTym - Dashboard',
    titleTemplate: null
  },
  mixins: [user, intro],
  mounted() {
    this.initIntro();
  },
  components: {
    HomeHeader
  },
  data () {
    return {
    };
  },
};
</script>
