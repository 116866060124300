<template>
    <v-form id="department-form">
        <v-layout row>
            <v-flex xs12>
            <v-text-field label="Department Name"
                data-vv-name="name"
                :error-messages="errors.collect('name')"
                v-model="form.name"
                v-validate="fieldValidateRules"
                required >
            </v-text-field>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
            <color-picker  ref="colorPicker"
                           :selected-colors="selectedColors"
                           v-model="form.paperColor"
                           label="Department Color:">
            </color-picker>
            </v-flex>
        </v-layout>
    </v-form>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {crud} from '@/mixins/crud';
import colorPicker from '@/components/common/colorpicker';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  }, name: 'formDepartment',
  components: {
    colorPicker
  },
  mixins: [
    crud
  ],
  props: [
    'form',
    'companyId',
    'oldName',
    'selectedColors'
  ],
  data() {
    return {
      dictionary: {
        attributes: {
          name: 'Department Name'
        },
        custom: {
          name: {
            required: () => 'The Department Name field is required',
          }
        }
      }
    };
  },
  mounted() {
    let _vm = this;
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
  },
  computed: {
    fieldValidateRules() {
      let rules = 'required|min:2|department-unique:';
      rules += this.companyId + ',';
      if (this.oldName) {
        rules += this.oldName;
      }
      return rules;
    }
  },
  methods: {
    reset() {
      this.$refs.colorPicker.reset();
    }
  },
  watch: {
    //eslint-disable-next-line
    'form.paperColor': function(value) {}
  }
};
</script>
