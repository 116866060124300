<template>

  <div>
    <v-container>
      <v-layout>
        <v-flex>
          <v-avatar class="avatar-picture" :size="size || 200" @click="change">
            <img v-bind:src="imageSrc">
          </v-avatar>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-btn
            class="body-2"
            color="blue lighten-4"
            small
            @click="change">
            change
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <my-upload
      field="avatar"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="showUploadForm"
      :width="200"
      :height="200"
      :url="uploadUrl"
      :params="params"
      :headers="headers"
      lang-type="en"
      v-notice="noticeMsg"
      img-format="png"></my-upload>
  </div>

</template>

<script>

import {VAvatar} from 'vuetify';
import myUpload from 'vue-image-crop-upload/upload-2.vue';

export default {
  name: 'user-profile-avatar',
  props: ['userAvatar', 'userToken', 'size'],
  data () {
    return {
      defaultAvatar: '/images/blank-avatar.png',
      params: {},
      headers: {
        'Authorization': 'Bearer ' + this.userToken
      },
      showUploadForm: false,
      uploadUrl: process.env.VUE_APP_CAS_API_URL + 'users/avatar/upload',
      imgDataUrl: null,
      noticeMsg: '<span class="red--text text--lighten-2">' +
        'Minimum file Size : 200x200</span>'
    };
  },
  computed: {
    imageSrc: function() {
      return this.imgDataUrl || this.userAvatar || this.defaultAvatar;
    }
  },
  components: {
    VAvatar,
    myUpload
  },
  methods: {
    change () {
      this.showUploadForm = true;
    },
    // eslint-disable-next-line no-unused-vars
    cropSuccess (imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
    },
    // eslint-disable-next-line no-unused-vars
    cropUploadSuccess (jsonData, field) {
      this.$emit('changed', jsonData.avatar);
    },
    // eslint-disable-next-line no-unused-vars
    cropUploadFail (status, field) {
      this.imgDataUrl = null;
    }
  },
  directives: {
    notice: {
      inserted: function (el, bindings) {
        let notice = document.createElement('div');
        notice.innerHTML = bindings.value;
        let parentDiv = el.firstChild;
        let lastDiv = parentDiv.lastChild;
        parentDiv.insertBefore(notice, lastDiv);
      }
    }
  }
};

</script>

<style>
.avatar-picture {
  cursor: pointer;
}
</style>
