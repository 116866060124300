import * as types from './types';

export default {
  [types.LOGIN_FAIL] (state, playload) {
    state.isLoggedIn = false;
    state.loginMessage = playload;
  },
  [types.LOGIN_SUCCESS] (state) {
    state.isLoggedIn = true;
  },
  [types.LOGOUT] (state) {
    state.isLoggedIn = false;
  },
  [types.SIGNUP_FAIL] (state, playload) {
    state.signupMessage = playload;
  }
};
