<template>
    <div>
        <!-- Mobile Menu -->
        <MHeader
            :identity="identity"
        ></MHeader>
        <v-toolbar
          id="calendar-event-toolbar">
            <!--v-menu offset-y min-width="150">
                <v-btn dark
                slot="activator" class="ds-skinny-button">
                View
                </v-btn>
                <v-card>
                <v-list>
                    <v-list-tile v-for="type in types"
                        :key="type.id"
                        @click="currentType = type.id"
                        :disabled="type.disabled">
                        <v-list-tile-content>
                        <v-list-tile-title>
                            {{ type.title }}
                        </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
                </v-card>
            </v-menu-->
            <span
              :class="[
                'date-range-text',
                !isSameYear ? 'small-text' : ''
              ]">
              {{ currentViewDates }}
            </span>
            <v-spacer></v-spacer>
            <!--v-btn
                dark
                @click="today()">TODAY</v-btn-->
            <v-btn @click="prev()"
                fab dark small depressed>
                <v-icon>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn @click="next()"
                fab dark small depressed>
                <v-icon>keyboard_arrow_right</v-icon>
            </v-btn>
            <!--department-selector
                v-if="hasManagerAccount()"
                :departments="this.departments"
                :customStyle="dayspanDropdownStyle"
                @selected="this.departmentSelected">
            </department-selector-->
        </v-toolbar>
        <CalendarDayEventList
          :dates="dates"
          :events="events"
          :company-info="companyInfo"
          :user="user"
          v-show="!!totalMatchedEvents">
        </CalendarDayEventList>
        <v-alert
          :value="isNoneActivity"
          type="info"
          style="margin: 10px"
        >
          No Activities found during this week.
        </v-alert>
    </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import DepartmentSelector from '@/components/common/department-selector';
import MHeader from '@/components/layouts/mobile/MHeader';
// eslint-disable-next-line
import CalendarDayEventList from '@/components/schedules/mobile/CalendarDayEventList';
import {user} from '@/mixins/user';
import {calendar} from '@/mixins/calendar';
import {theme} from '@/mixins/theme';
import {timezone} from '@/mixins/timezone';
import {company} from '@/mixins/company';
import moment from 'dayspan/node_modules/moment';
//import {_} from 'vue-underscore';
import {
  VAlert
} from 'vuetify';

export default {
  name: 'MobileView',

  props: {
    events: {
      type: Object,
      default() {
        return {};
      }
    },
    totalMatchedEvents: {
      type: Number
    },
    loading: {
      type: Boolean
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  components: {
    MHeader,
    DepartmentSelector,
    CalendarDayEventList,
    VAlert
  },

  computed: {
    isNoneActivity() {
      //loaded then no events found.
      return !this.loading
                && this.totalMatchedEvents === 0;
    }
  },

  mixins: [
    user,
    calendar,
    theme,
    timezone,
    company
  ],

  data: () => {
    return {
      departments: [],
      selectedDepartments: []
    };
  },

  mounted() {
    let _vm = this;
    //load view for today if not set url for another day
    let loadCallback = () => {
      if (_vm.$route.params.year && _vm.$route.params.month
          && _vm.$route.params.day) {
        try {
          let year = parseInt(_vm.$route.params.year);
          let month = parseInt(_vm.$route.params.month);
          let day = parseInt(_vm.$route.params.day);
          _vm.toDate(moment.utc([year, month-1, day]));
          return;
        } catch (err) {
          //eslint-disable-next-line
          console.log(err);
        }
      }
      _vm.today();
    };

    _vm.getCompanyInfo(_vm.identity.worker.managerId).then(company => {
      _vm.companyInfo = company;
      loadCallback();
    });
    _vm.$root.$on('mobile-refresh-calendar', () => {
      loadCallback();
    });
    _vm.$root.$on('mobile-reload', () => {
      _vm.load();
    });
    _vm.$root.$on('mobile-today-calendar', () => {
      _vm.goToday();
    });
  },

  watch: {
    dates: {
      handler: function() {
        this.load();
      }
    },
  },

  methods: {
    departmentSelected(departments) {
      this.selectedDepartments = departments;
      if (departments) {
        this.load();
      }
    },
    load() {
      let _vm = this;
      let dates = _vm.dates;
      let statTimestamp = moment(dates[0]*1000)
                            .startOf('day')
                            .utcOffset(0)
                            .unix();
      let endTimestamp = moment(dates[dates.length-1]*1000)
                            .utcOffset(0)
                            .add(1, 'day')
                            .endOf('day')
                            .unix();
      // eslint-disable-next-line
      _vm.$root.$emit('mobile-load', {
        statTimestamp,
        endTimestamp,
        dates
      });
    }
  },
};
</script>

<style scoped>
  .date-range-text {
    color: white;
    font-weight: bold;
    font-size: 13px;
  }

  #calendar-event-toolbar {
    background: #333;
    margin-top: 55px !important;
  }

  .small-text {
    font-size: 12px !important;
  }
</style>
<style>
  @media only screen and (max-width: 600px) {
    .dayspan-mobile .v-alert__icon {
      display: inline;
    }
  }
</style>
