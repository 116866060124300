<template>
    <div>
        <HomeHeader></HomeHeader>
        <div class="box">
            <div class="box-title">
                OOPS!
            </div>
            <div class="box-sub-title">
                The page that you are looking for can't be found :(
            </div>
            <div class="box-actions">
                <v-btn
                  color="blue lighten-1"
                  href="/"
                >
                  <v-icon right dark>home</v-icon>
                  <span class="nav-text">
                    GO TO HOMEPAGE
                  </span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import HomeHeader from '@/components/layouts/HomeHeader';

export default {
    name: 'error404',
    components: {
        HomeHeader
    },
};
</script>

<style scoped>
.box {
    margin-top: 10%;
    text-align: center;
    font-weight: bold;
}
.box-title {
    line-height: 0.7;
    font-size: 236px;
    font-weight: 200;
    font-family: Helvetic, serif;
}
.box-sub-title {
    font-size: 45px;
}
.box-actions a {
    color: white;
}
</style>
