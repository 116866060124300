<template>
    <div>
      <v-dialog
        v-model="dialog"
        max-width="660px"
        :persistent="true"
        scrollable
      >
        <v-btn
          slot="activator"
          color="blue"
          style="background: #07bd5f !important"
        >
        <v-icon>add</v-icon>
          ADD RECURRING
        </v-btn>
        <custom-card :card-title="formTitle"
                     :card-help-text="dialogHelpText"
                     @save="save" @close="close">
            <v-container
              grid-list-md
              class="noVertcalPadding"
            >
                <v-layout wrap>
                <v-flex xs12 sm6 md12>
                  <div v-if="overlapRepeatScheds.length"
                       class="repeat-schedules-overlap-block">
                      <v-subheader class="red--text">
                        Overlaped Recurring Schedules
                      </v-subheader>
                      <v-chip v-for="(sched, index) in overlapRepeatScheds"
                              :key="index"
                              @click="selectRepeatScheduleOverlap(sched)"
                              color="red" text-color="white">
                        {{formatDate(sched.dateStart)}} -
                        {{sched.dateEnd ? formatDate(sched.dateEnd) : 'Never'}}
                     </v-chip>

                  </div>
                  <v-alert
                      v-model="openOverlapError"
                      dismissible
                      type="error" >
                      <span v-html="overlapError"
                            class="overlap-error-block"></span>
                  </v-alert>
                    <v-autocomplete
                        v-validate="'required'"
                        v-model="form.worker"
                        :color="inputColor"
                        :items="workers"
                        :filter="searchFilter"
                        :disabled="Boolean(workerId)"
                        data-vv-name="defaultWorker"
                        :error-messages="errors.collect('defaultWorker')"
                        label="Choose a Worker"
                        prepend-icon="person"
                        return-object
                    >
                    <template slot="selection" slot-scope="data">
                        <v-avatar>
                        <img
                            :src="getAvatar(data.item.avatar)"
                            alt="Avatar"
                            class="w_picture"
                            >
                        </v-avatar>
                        <span
                        :class="[
                            'subheading',
                            'font-weight-medium',
                            'text-capitalize',
                            'px-2'
                        ]">
                        {{ data.item.firstName + ' ' + data.item.lastName }}
                        </span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-avatar>
                        <img
                            :src="getAvatar(data.item.avatar)"
                            alt="Avatar"
                            class="w_picture">
                        </v-avatar>
                        <span
                        :class="[
                            'subheading',
                            'font-weight-medium',
                            'text-capitalize',
                            'px-2'
                        ]">
                        {{ data.item.firstName + ' ' + data.item.lastName }}
                        </span>
                    </template>
                    </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md12>
                    <v-autocomplete
                        v-validate="'required'"
                        v-model="form.role"
                        v-if="form.worker.length != 0"
                        :color="inputColor"
                        :items="workerRoles"
                        :filter="searchFilter"
                        data-vv-name="defaultRole"
                        no-data-text="No roles assigned to this worker."
                        :error-messages="errors.collect('defaultRole')"
                        item-text="name"
                        item-value="id"
                        label="Choose a Role"
                        prepend-icon="group"
                    ></v-autocomplete>
                </v-flex>
                <v-flex
                xs12
                v-for="(schedule, weekDayNumber) in schedules"
                :key="schedule.name"
                v-if="form.role">
                    <v-layout row xs12 wrap>
                        <v-flex xs3>
                            <v-checkbox
                              style="margin-top: 8px"
                              :label="schedule.name"
                              v-model="schedules[weekDayNumber].allow"
                              @change="selectWeekDay(schedule, weekDayNumber)"
                            ></v-checkbox>
                        </v-flex>
                        <v-flex xs7>
                          <v-layout
                            row
                            xs12
                            wrap
                            v-for="(time, _index) in schedule.times"
                            :key="_index"
                          >
                          <v-flex xs6>
        <vue-ctk-date-time-picker
          placeholder="Start time"
          label="Start time"
          :format="formatForTime"
          :formatted="formatForTime"
          output-format="HH:mm"
          :minute-interval="5"
          @change="daySetChanged = true"
          v-model="schedules[weekDayNumber].times[_index].startTime"
          input-size="sm"
          :only-time="true"
          v-validate="'required'"
          :data-vv-name="'defaultStartTime'+weekDayNumber+_index"
          class="timepicker"
          color="#009688"
          style="width: 100%;"
          v-if="schedules[weekDayNumber].allow"
          noClearButton
          > </vue-ctk-date-time-picker>
        <!--<el-time-picker
          placeholder="Start time"
          @change="daySetChanged = true"
          v-model="schedules[weekDayNumber].times[_index].startTime"
          v-validate="'required'"
          :data-vv-name="'defaultStartTime'+weekDayNumber+_index"
          class="timepicker"
          size="medium"
          style="width: 100%;"
          v-if="schedules[weekDayNumber].allow"
          :format="formatForTime"
          value-format="HH:mm"
          :editable="false"
          :clearable="false"
          :picker-options="pickerStartOption(schedule.times, _index-1)"
          > </el-time-picker> -->
                          <div
                            class="v-messages theme--light error--text"
                            style="margin-top: 5px;margin-left: 10px">
                            <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                            {{
                              mapError('defaultStartTime'+weekDayNumber+_index)
                            }}
                            </div>
                            </div>
                          </div>
                        </v-flex>
                        <v-flex xs6>
        <vue-ctk-date-time-picker
          placeholder="End time"
          label="End time"
          :format="formatForTime"
          :formatted="formatForTime"
          output-format="HH:mm"
          :minute-interval="5"
          @change="daySetChanged = true"
          v-model="schedules[weekDayNumber].times[_index].endTime"
          input-size="sm"
          :only-time="true"
          v-validate="'required'"
          :data-vv-name="'defaultEndTime'+weekDayNumber+_index"
          class="timepicker"
          color="#009688"
          style="width: 100%;"
          v-if="schedules[weekDayNumber].allow"
          noClearButton
          > </vue-ctk-date-time-picker>

          <!--<el-time-picker-->
              <!--@change="daySetChanged = true"-->
              <!--placeholder="End time"-->
              <!--v-model="schedules[weekDayNumber].times[_index].endTime"-->
              <!--v-validate="'required'"-->
              <!--:data-vv-name="'defaultEndTime'+weekDayNumber+_index"-->
              <!--class="timepicker"-->
              <!--size="medium"-->
              <!--v-if="schedules[weekDayNumber].allow"-->
              <!--:format="formatForTime"-->
              <!--value-format="HH:mm"-->
              <!--:clearable="false"-->
              <!--:editable="false"-->
              <!--:picker-options="pickerEndOption(schedule.times, _index)"-->
              <!--style="width: 100%;">-->
          <!--</el-time-picker>-->
                    <div
                        class="v-messages theme--light error--text"
                        style="margin-top: 5px;margin-left: 10px">
                        <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                        {{
                          mapError('defaultEndTime'+weekDayNumber+_index)
                        }}
                        </div>
                        </div>
                      </div>
                        </v-flex>
                    </v-layout>
                        </v-flex>
                        <v-flex xs2>
                            <v-layout
                            row
                            xs12
                            v-for="(time, _index) in schedule.times"
                            :key="'actions_'+_index"
                            >
                    <v-flex xs12 sm6 class="no_margin">
                        <v-btn
                        icon
                        v-if="(schedule.allow
                            && schedule.times[_index].hasAddPermission)"
                        :color="inputColor"
                        @click="addSchedule(weekDayNumber, _index)">
                        <v-icon>add</v-icon>
                        </v-btn>
                        <div
                        v-show="!(schedule.allow
                            && schedule.times[_index].hasAddPermission)"
                        style="padding: 28px;"
                        >
                        </div>
                    </v-flex>
                    <v-flex
                    xs12
                    sm6
                    v-show="schedules[weekDayNumber].allow"
                    class="no_margin"
                    >
                        <v-btn
                        icon
                        v-if="(schedule.times[_index].hasRemovePermission)"
                        @click="removeSchedule(schedule, _index)"
                        :color="inputColor">
                        <v-icon>clear</v-icon>
                        </v-btn>
                        <div
                        v-if="!(schedule.times[_index].hasRemovePermission)"
                        style="margin-top: 28px;"
                        >
                        </div>
                    </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs-6 v-if="form.role">
                    <v-menu
                      ref="menuDateStart"
                      :close-on-content-click="false"
                      v-model="dateStartModal"
                      :nudge-right="40"
                      :return-value.sync="form.dateStart"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <v-text-field
                        slot="activator"
                        :value="computedStartDateFormat"
                        label="Date Start"
                        prepend-icon="event"
                        readonly
                        v-validate="'required'"
                        data-vv-name="dateStart"
                      ></v-text-field>
                      <v-date-picker @change="dateStartChanged=true"
                                     :first-day-of-week="startWeekDay"
                                     v-model="form.dateStart"
                                     :min="minDateStart"
                                     no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary"
                               @click="dateStartModal = false">Cancel</v-btn>
                        <v-btn flat color="primary"
                             @click="$refs.menuDateStart.save(form.dateStart)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <div
                      class="v-messages theme--light error--text"
                      style="margin-left: 10px">
                      <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                      {{
                        mapError('dateStart')
                      }}
                      </div>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs-6 v-if="form.role">
                    <v-menu
                      ref="menuDateEnd"
                      :close-on-content-click="false"
                      v-model="dateEndModal"
                      :nudge-right="40"
                      :return-value.sync="form.dateEnd"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <v-text-field
                        v-if="form.dateEnd"
                        slot="activator"
                        :value="computedEndDateFormat"
                        label="Date End"
                        prepend-icon="event"
                        data-vv-name="dateEnd"
                        readonly
                      ></v-text-field>
                      <v-text-field
                        v-if="!form.dateEnd"
                        slot="activator"
                        v-model="neverLabel"
                        label="Date End"
                        prepend-icon="event"
                        readonly
                      ></v-text-field>
                      <v-date-picker v-model="form.dateEnd"
                                     @change="dateEndChanged=true"
                                     :first-day-of-week="startWeekDay"
                                     :min="form.dateStart"
                                     no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="amber"
                             @click="$refs.menuDateEnd.save(null)">
                          Never
                        </v-btn>
                        <v-btn flat color="primary"
                               @click="dateEndModal = false">Cancel</v-btn>
                        <v-btn flat color="primary"
                               @click="$refs.menuDateEnd.save(form.dateEnd)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                  </v-flex>
                </v-layout>
              </v-container>
          </custom-card>
      </v-dialog>
    </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';

//import all mixins
import {user} from '@/mixins/user';
import {crud} from '@/mixins/crud';
import {picture} from '@/mixins/picture';
import {timezone} from '@/mixins/timezone';

import {_} from 'vue-underscore';
import * as moment from 'moment-timezone';
import * as types from '@/store/types';

//import all components
import {
  VForm,
  VInput,
  VTextField,
  VCheckbox,
  VDivider,
  VSelect,
  VAutocomplete,
  VAlert,
  VChip,
  VSubheader,
} from 'vuetify';
import * as VDatePicker from 'vuetify/es5/components/VDatePicker';
import * as VItemGroup from 'vuetify/es5/components/VItemGroup';
import CustomCard from '@/components/common/custom-card';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'RecurringSchedule',
  props: ['workers', 'roles', 'companyInfo', 'workerId'],
  data() {
    return {
      overlapRepeatScheds: [],
      overlapRepeatSchedsErrMessage: '',
      selectedRule: null,
      overlapError: '',
      openOverlapError: false,
      overlapErrorTime: 8, // in seconds
      daySetChanged: false,
      dateStartChanged: false,
      dateEndChanged: false,
      dateStart: '',
      dateStartModal: false,
      minDateStart: null,
      dateEndModal: false,
      neverLabel: 'Never',
      dialog: false,
      formTitle: 'Recurring Schedule',
      dialogHelpText: 'Save base schedule for every workers',
      inputColor: 'teal',
      defaultStartTime: '08:00',
      defaultEndTime: '20:00',
      allowMonday: false,
      maxClonedTimeRow: 3,
      form: {
        role: '',
        worker: '',
        dateStart: moment(),
        dateEnd: null
      },
      schedules: [],
      dictionary: {
        attributes: {
          defaultWorker: 'Worker',
          defaultRole: 'Role'
        },
        custom: {
          defaultWorker: {
            required: () => 'Worker field is required.'
          },
          defaultRole: {
            required: () => 'Role field is required.'
          }
        }
      }
    };
  },
  components: {
    VForm,
    VInput,
    VTextField,
    VCheckbox,
    VDivider,
    VSelect,
    VAutocomplete,
    ...VDatePicker,
    VAlert,
    VChip,
    ...VItemGroup,
    VSubheader,
    CustomCard
  },
  mixins: [
    user,
    crud,
    picture,
    timezone
  ],
  mounted () {
    let _vm = this;
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
  },
  computed: {
    formatForTime () {
      if (this.companyTimeFormat === '24-hour') {
        return 'HH:mm';
      }
      return 'hh:mm A';
    },
    companyTimeFormat () {
      return this.getUser('company', 'timeFormat');
    },
    NextDate () {
      return moment().add(1,'days')
                     .startOf('day')
                     .format(this.getReadableDateFormat())
                     .toString();
    },
    computedStartDateFormat() {
      return this.formatDate(this.form.dateStart);
    },
    computedEndDateFormat() {
      return this.formatDate(this.form.dateEnd);
    },
    workerRoles () {
      if (this.form.worker && this.roles.length) {
        return this.roles.filter(role => {
          return this.form.worker.roles.find(r => r.roleId === role.id);
        });
      }
      return [];
    },
    startWeekDay() {
      if (this.companyInfo) {
        return this.companyInfo.startWeekDay;
      }
      return 0;
    }
  },
  watch: {
    workers: function(value) {
      if (value && value.length && this.workerId) {
        let selectedWorker = value.find(w => w.id === this.workerId);
        if (selectedWorker) {
          this.$set(this.form, 'worker', selectedWorker);
        }
      }
    },
    'form.worker': function(worker) {
      let _vm = this;
      _vm.$set(_vm.form, 'role', '');
      let defaultRole = _.find(worker.roles, function(role) {
        return (role.default === 1);
      });
      //make sure that default role found before set
      if (typeof defaultRole !== 'undefined') {
        _vm.$set(_vm.form, 'role', defaultRole.roleId);
      }
    },
    'form.role': function(value) {
      //only init/preload schedules when worker role is selected
      if (value && !this.schedules.length) {
        this.initSchedules();
      }
    }
  },
  methods: {
    formatDate(date) {
      let format = 'MM/DD/YYYY';
      if (this.companyInfo && this.companyInfo.dateFormat) {
        format = this.companyInfo.dateFormat.toUpperCase();
      }
      return date ? moment(date).format(format) : '';
    },
    selectRepeatScheduleOverlap(schedule) {
      this.selectRule = {byId: schedule.id};
      this.selectRepeatSchedule();
    },
    pickerStartDefault(weekday, index) {
      let prevStartTime = null;
      let i = index - 1;
      while (i >= 0) {
        if (this.schedules[i].times.length > 0) {
          prevStartTime = this.schedules[i].times[0].startTime;
          break;
        }
        i--;
      }
      if (prevStartTime && this.schedules[index].times.length === 0) {
        return prevStartTime;
      }
      if (this.companyInfo) {
        let compWorkingHrs = this.companyInfo.workingHours.find(
          wh=>wh.weekday===weekday);
        if (compWorkingHrs) {
          return compWorkingHrs.timeStart;
        }
      }
      return '';
    },
    pickerEndDefault(weekday, index) {
      let prevEndTime = null;
      let i = index - 1;
      while (i >= 0) {
        if (this.schedules[i].times.length > 0) {
          prevEndTime = this.schedules[i].times[0].endTime;
          break;
        }
        i--;
      }
      if (prevEndTime && this.schedules[index].times.length === 0) {
        return prevEndTime;
      }
      if (this.companyInfo) {
        let compWorkingHrs = this.companyInfo.workingHours.find(
          wh=>wh.weekday===weekday);
        if (compWorkingHrs) {
          return compWorkingHrs.timeEnd;
        }
      }
      return '';
    },
    initSchedules () {
      let _vm = this;
      //reset schedules
      _vm.resetSchedule();

      //append local schedules only if missing some rows for remote schedules
      _vm.loadLocalSchedules();

      //allow to load remote schedules only if valid worker/role
      _vm.loadRemoteSchedules();
    },
    loadLocalSchedules () {
      let _vm = this;
      let weekdays = moment.weekdays(true);//from sunday
      _.each(_vm.getWeekDays(), (day, number) => {
        let _number = weekdays.findIndex(e => day===e);
        //console.log(_number);
        //init with starter schedule weekday template
          _vm.$set(_vm.schedules, number, {
            name: day,
            day: _number,
            allow: false,
            times: []
          });
      });
    },
    selectRepeatSchedule() {
      let _vm = this;
      let s = null;
      if (_vm.selectRule) {
        if (_vm.selectRule.byId) {
          s = _vm.repeatSchedules.find(s => s.id === _vm.selectRule.byId);
        } else if (_vm.selectRule.byDayId) {
          s = _vm.repeatSchedules.find(s => {
            return s.RepeatSchedulesDays.find(
              sd => sd.id === _vm.selectRule.byDayId);
          });
        }
        _vm.activeRepeatSchedule = s;
        if (s) {
          _vm.changedRepeatSchedules(s);
        } else {
          _vm.resetSchedule();
        }
        _vm.selectRule = null;
      }
    },
    loadRemoteSchedules () {
      let _vm = this;
      let workerId = _vm.form.worker.id;
      _vm.fetchApiRemoteSchedules(workerId).then(schedules => {
        _vm.repeatSchedules = schedules;
        _vm.form.dateStart = moment().toISOString().substr(0, 10);
        _vm.form.dateEnd = '';
        if (schedules.length) {
          _vm.selectRepeatSchedule();
        }
      });
    },
    changedRepeatSchedules (repeatSchedule) {
      let _vm = this;
      _vm.loadLocalSchedules();
      let schedules = repeatSchedule.RepeatSchedulesDays;
      let dateEnd = repeatSchedule.dateEnd;
      _vm.form.role = repeatSchedule.roleId;

      let dateStart = moment.utc(repeatSchedule.dateStart);
      if (dateStart.isBefore(moment.utc(), 'day')) {
        dateStart = moment.utc();
      }
      _vm.$set(_vm.form, 'dateStart', dateStart.toISOString().substr(0, 10));
      _vm.minDateStart = moment().toISOString().substr(0, 10);
      _vm.$set(_vm.form, 'dateEnd', dateEnd ? dateEnd.substr(0, 10) : '');
      let weekdays = _vm.getWeekDays();
      let _weekdays = moment.weekdays(true);//from sunday
        //eslint-disable-next-line
      _.each(schedules, (schedule) => {
        //count number of rows to add
        let numberDayRowsToAdd = schedules.filter((_schedule) => {
          return (_schedule.day === schedule.day);
        }).length;
        let day = _weekdays[schedule.day];
        let _number = weekdays.findIndex(e => day===e);
        let _length = _vm.schedules[_number].times.length + 1;
        let btnsPermissions = {
          hasAddPermission: false,
          hasRemovePermission: false
        };
        let _row = {
          startTime: schedule.timeStart,
          endTime: schedule.timeEnd,
          id: schedule.id
        };
            //
        if (typeof _vm.schedules[schedule.day] === 'undefined') {
          _vm.$set(_vm.schedules, _number, {
            allow: true,
            day: _number,
            name: weekdays[schedule.day],
            times: []
          });
        } else {
          _vm.$set(_vm.schedules[_number], 'allow', true);
        }
        //
        if (_length === numberDayRowsToAdd
            && numberDayRowsToAdd < _vm.maxClonedTimeRow) {
          btnsPermissions.hasAddPermission = true;
        }
        if (_length > 1) {
          btnsPermissions.hasRemovePermission = true;
        }
        //console.log('====DAY====', schedule.day);
        //console.log('==== ROW DETAILS====', _length, numberDayRowsToAdd);
        //always append times on both cases
        _row = Object.assign(_row, btnsPermissions);
        _vm.schedules[_number].times.push(_row);
      });
    },
    fetchApiRemoteSchedules (workerId) {
      let url = process.env.VUE_APP_API_URL +
                  `schedules/repeat-schedules/${workerId}`;
      if (!workerId) {
        return Promise.resolve([]);
      }
      return new Promise(async function(resolve, reject) {
        try {
          let schedulePromise = axios(url);
          const response = await schedulePromise;
          if (response.data.status === types.REQUEST_SUCCESS) {
            resolve(response.data.data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getWeekDays () {
      return Array.apply(null, Array(7)).map((_, i) => {
        return moment(i, 'e').startOf('week')
                            .isoWeekday(i + 1)
                            .format('dddd');
      });
    },
    selectWeekDay (schedule, index) {
      let _vm = this;
      let allowWeekDay = schedule.allow;
      _vm.daySetChanged = true;
      //if a day is choosen, set first entry (when creating new times)
      if (allowWeekDay && schedule.times.length === 0) {
        schedule.times.push({
            startTime: _vm.pickerStartDefault(schedule.day, index)
              || _vm.defaultStartTime,
            endTime: _vm.pickerEndDefault(schedule.day, index)
              || _vm.defaultEndTime,
            hasAddPermission: true,
            hasRemovePermission: false
        });
      } else {//reset times array
        schedule.times = [];
      }
    },
    mapError (index) {
      let error = this.errors
                      .first(index);
      return (error) ? error.replace(/([A-Z])/g, ' $1')
                            .replace(/[0-9]/g, '')
                            .trim()
                     : '';
    },
    pickerStartOption: function (rows, index) {
      let options = {};
      if (index >= 0) {
        let minTime = rows[index].endTime;
        options = this.timePickerOptions(minTime);
      } else {
        options = this.timePickerOptions();
      }
      return options;
    },
    pickerEndOption: function (rows, index) {
      let minTime = rows[index].startTime;
      return this.timePickerOptions(minTime);
    },
    timePickerOptions: function(minTime) {
      let options = {
        start: this.defaultStartTime,
        step: '00:15',
        end: this.defaultEndTime
      };
      if (minTime) {
        options.minTime = minTime;
      }
      let op = {
        selectableRange: `${minTime || options.start}:00 - ${options.end}:00`
      };
      return op;
    },
    removeSchedule (schedule, index) {
      schedule.times.splice(index, 1);
      schedule.times[index-1].hasAddPermission = true;
    },
    addSchedule (weekDayNumber, index) {
      let _vm = this;
      let schedule = _vm.schedules[weekDayNumber];
      let length = schedule.times.length;
      let _length = length+1;
      let startTime = _vm.defaultStartTime;
      let endTime = _vm.defaultEndTime;
      //copy previous entry times
      if (_length > 1) {
        startTime = schedule.times[index].startTime;
        endTime = schedule.times[index].endTime;
      }
      schedule.times[index].hasAddPermission = false;
      schedule.times.push({
        id: 'row_' + index,
        startTime: startTime,
        endTime: endTime,
        hasAddPermission: (length+1 < _vm.maxClonedTimeRow),
        hasRemovePermission: true
      });
    },
    save () {
      //filter schedules which can be saved
      let _vm = this;
      let schedules = _.filter(_vm.schedules, function(schedule) {
        return (schedule.times.length > 0);
      });
      //vee validation first
      _vm.$validator.validate().then(result => {
        if (result) { // well validated
          if (!schedules.length) {
            // eslint-disable-next-line max-len
            let msg = 'You need select minimum one week day before process to save schedules.';
            _vm.$notify(msg, 'error');
          } else {
            let url = process.env.VUE_APP_API_URL;
            if (_vm.activeRepeatSchedule) {
              url += 'schedules/repeat-schedules/' +
                `${_vm.activeRepeatSchedule.id}`;
            } else {
              url += 'schedules/repeat-schedules'; //means preset
            }
            //
            axios({
              method: 'post',
              url: url,
              data: {
                roleId: _vm.form.role,
                workerId: _vm.form.worker.id,
                managerId: this.identity.manager.id,
                timezone: _vm.identity.manager.timezone,
                dateStart: _vm.form.dateStart,
                dateEnd: _vm.form.dateEnd,
                daySetChanged: _vm.daySetChanged,
                dateStartChanged: _vm.dateStartChanged,
                dateEndChanged: _vm.dateEndChanged,
                schedules
              }
            }).then((response) => {
              var r = response.data;
              if (r.status === 'success'){
                _vm.$notify(
                  'Schedules have been successfully saved.',
                  'success'
                );
                _vm.$root.$emit('reload');
                _vm.close();
              } else if (r.status === 'warning'){
                _vm.overlapError = r.message;
                _vm.openOverlapError = true;
                setTimeout(() => _vm.openOverlapError = false,
                  _vm.overlapErrorTime * 1000);
                _vm.$root.$emit('reload');
              } else if (r.overlapScheds) {
                _vm.overlapRepeatScheds = r.overlapScheds;
                _vm.overlapRepeatSchedsErrMessage = r.message;
              } else {
                _vm.$notify(`Something Wrong: ${r.message}`, 'error');
              }
            }).catch((error) => {
              _vm.$notify(`Something Wrong: ${error.message}`, 'error');
            });
          }
        }
      });
    },
    reset () {},
    resetSchedule() {
      this.activeRepeatSchedule = null;
      this.schedules = [];//reset
      this.overlapRepeatScheds = [];
      this.minDateStart = null;
      //append more if needed...
    },
    resetDialogForm () {
      let _vm = this;
      _vm.$validator.reset();
      _vm.form = {
        role: '',
        worker: '',
        dateStart: moment().toISOString().substr(0, 10),
        dateEnd: '',
        dateStartChanged: false,
        dateEndChanged: false,
        daySetChanged: false
      };
      //reset schedules
      _vm.resetSchedule();
    },
    close () {
      this.dialog = false;
      //reset
      this.resetDialogForm();
    },
    searchFilter (item, queryText) {
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1;
    }
  },
  filters: {
    dateFormat: function(value) {
      if (value) {
        return this.formatDate(value);
      } else {
        return 'Never';
      }
    }
  }
};
</script>

<style scoped>
.w_picture {
    width: 25px;
    height: 25px;
}
.timepicker {
    margin-top: 5px;
    border-color: #009688;
}
.no_margin {
    margin: 0;
    padding: 0;
}
.v-messages__message {
  text-transform: capitalize;
}

.repeat-schedules-overlap-block {
}

</style>
