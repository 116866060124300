<template>
  <div id="dayspan"
    v-cloak
    :class="[
      isAny() ? 'dayspan-mobile': ''
    ]"
    style="padding-top:0;"
    >
    <!-- Mobile View -->
    <MobileView
      v-if="hasWorkerAccount() && isAny()"
      :events="dailyEvents"
      :user="user"
      :totalMatchedEvents="totalMatchedEvents"
      :loading="showLoadingBox"
    ></MobileView>
    <!-- End Mobile View -->
    <WorkerLogin
      :company="company"
      :workers="workers"
      v-if="!isProperlyLogged()"></WorkerLogin>
    <HomeHeader
      :identity="this.identity"
      v-if="isManager() && canDisplayCalendarMobile"
      :class="[
        'top-bar'
      ]"></HomeHeader>
      <!-- TODO: move ds calendar to desktop view instead -->
      <ds-calendar-app ref="ds"
        :calendar="calendar"
        :read-only="readOnly"
        v-if="isProperlyLogged() && canDisplayCalendarMobile"
        :class="[
          hasManagerAccount() ? 'admin-calendar' : 'worker-calendar',
        ]"
        :types="types"
        @add="addEvent"
        @change="stateChanged"
        @changeView="changeView">
        <template
          slot="drawerTop"
          style="margin-bottom:50px;">
        </template>
        <template slot="title"></template>
        <template slot="menuRight">

        <!-- This block is for workers filter -->
        <item-selector
          button-title="workers"
          item-name="worker"
          :items="workers"
          :display-name="w => w.firstName + ' ' + w.lastName"
          v-if="isManager()"
          @selected="filterWorkers"
          :custom-style="dayspanDropdownStyle"
          ></item-selector>
        <!-- End of workers filter -->
        <!-- This block if for workers roles filter -->
        <item-selector
          button-title="roles"
          item-name="role"
          v-if="isManager()"
          :items="roles"
          @selected="roleSelected"
          :custom-style="dayspanDropdownStyle">
        </item-selector>
        <!-- End of workers roles filter. -->
        <!-- Department Selector -->
        <department-selector
          v-if="isManager()"
          :departments="this.departments"
          :customStyle="dayspanDropdownStyle"
          @selected="this.departmentSelected">
        </department-selector>
        <!-- End Department Selector -->
        <!-- Recurring Schedule -->
        <v-spacer></v-spacer>
        <RecurringSchedule
          ref="recurringSchedule"
          v-if="isManager()"
          :roles="this.roles"
          :company-info="companyInfo"
          :workerId="workerId"
          :workers="this.workers">
        </RecurringSchedule>
        <!-- End Recurring Schedule -->
        <!-- Logout -->
          <v-menu
            offset-y
            v-if="isWorker()">
            <v-btn
              slot="activator"
              dark
              flat
            >
              <v-avatar class="mr-2" size="32px">
                <img
                  :src="userAvatarSrc"
                  alt="Avatar" >
              </v-avatar>
              <b>{{this.getfullName()}}</b>
              <v-icon right dark>arrow_drop_down</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile
                @click="logout()"
              >
                <v-list-tile-title>
                  <v-icon>fas fa-sign-out-alt</v-icon> &nbsp;&nbsp;Logout
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        <!-- End Logout -->
      </template>
      <template slot="calendarAppCalendar" slot-scope="scopeData">
        <ds-calendar ref="calendar"
          v-bind="{$scopedSlots: scopeData.$scopedSlots}"
          :calendar="scopeData.calendar"
          :read-only="false"
          :handle-add="false"
          @addEvent="addEvent"
          @showWarnings="showDayWarnings"
          @moved="handleMove"
          ></ds-calendar>
      </template>
      <template
      slot="eventPopover"
      slot-scope="slotData"
      style="margin-top:52px">
        <ds-calendar-event-popover
          ref="popover"
          v-bind="slotData"
          :read-only="readOnly"
          :allow-edit-on-read-only="false"
          @finish="saveState">
          <template slot="eventPopoverOccurs" slot-scope="slogData">
            <v-list-tile-title>
              Daily at {{ timeFormat(slotData.details.meta.startTime) }} to
              {{ timeFormat(slotData.details.meta.endTime) }}
              ( {{ slotData.details.meta.sched.duration }}h )
            </v-list-tile-title>
          </template>
          <template
            slot="eventPopoverToolbarLeft"
            slot-scope="slotData">
              <v-btn
                v-if="!slotData.details.meta.sched.canceled"
                class="popover-action"
                color="secondary"
                absolute bottom left small fab icon
                @click="selectScheduleEdit(slotData)"
                >
                <v-icon>edit</v-icon>
              </v-btn>

              <!-- Actions for Requested Schedules -->
              <v-btn
                v-if="isRequested(slotData.details.meta.sched) && isManager()"
                class="popover-action"
                style="margin-left: 40px"
                color="success"
                small fab icon absolute bottom left dark
                @click="aproveRequestSchedule(slotData);">
                <v-icon>fas fa-check-circle</v-icon>
              </v-btn>
              <v-btn
                v-if="isRequested(slotData.details.meta.sched) && isManager()"
                class="popover-action"
                style="margin-left: 85px"
                color="error"
                small fab icon absolute bottom left dark
                @click="declineRequestSchedule(slotData);">
                <v-icon>fas fa-ban</v-icon>
              </v-btn>
              <!-- ======================= -->

              <!-- Actions for Schedules -->
              <v-btn
                class="popover-action"
                :style="{marginLeft: slotData.details.meta.sched.canceled ?
                         '-5px' : '40px'}"
                v-if="isManager() && !isRequested(slotData.details.meta.sched)"
                color="red"
                small fab icon absolute bottom left dark
                @click="selectScheduleDelete(slotData);">
                <v-icon>delete</v-icon>
              </v-btn>
              <!-- ======================= -->

          </template>
        </ds-calendar-event-popover>
      </template>
      <template
      slot="summary"
      slot-scope="{summary, calendar}">
        <h1
          class="title text--white"
          style="font-size:16px !important;">
          {{ summary }}
        </h1>
      </template>
      <template
      slot="eventCreatePopover"
      slot-scope="{placeholder, calendar, close}">
      </template>
      <template
      slot="eventTimeTitle"
      slot-scope="{calendarEvent, details}">
        <div>
          <v-icon class="ds-ev-icon"
            v-if="details.icon"
            size="14"
            :style="{color: details.forecolor}">
            {{ details.icon }}
          </v-icon>
          <strong class="ds-ev-title">
            {{ details.title }}
          </strong>
        </div>
        <div class="ds-ev-description">
          {{ getCalendarTime( calendarEvent ) }}
        </div>
      </template>
    </ds-calendar-app>
    <v-dialog
      v-model="openSchedDialog"
      max-width="660px"
      :persistent="true"
      v-if="!isAny()"
      >
      <schedule-form
        ref="scheduleForm"
        @cancel="closeDialog"
        @save="saveSchedule"
        :workers="workers"
        :roles="roles"
        :is-mobile="isAny()"
        :schedule="selectedSchedule"
        :company-info="companyInfo"
        :identity="identity"
        :formatForTime="formatForTime"
        :is24Format="is24Format"
        :select-worker="isManager()"
        :workerId="workerId">
      </schedule-form>
    </v-dialog>
    <v-dialog
      v-model="openSchedDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-else>
      <schedule-form
        ref="scheduleForm"
        @cancel="closeDialog"
        @save="saveSchedule"
        :workers="workers"
        :roles="roles"
        :is-mobile="isAny()"
        :schedule="selectedSchedule"
        :company-info="companyInfo"
        :identity="identity"
        :workerId="workerId">
      </schedule-form>
    </v-dialog>
    <v-dialog v-model="showLoadingBox" persistent fullscrean width="300" >
      <v-card color="primary" dark >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openSelectEditDialog"
              max-width="660px" :persistent="true">
        <v-card>
          <v-card-title
            class="headline teal white--text"
            primary-title
            >Edit Schedule</v-card-title>
          <v-card-text>
            Do you want update just this event, or the entire Series?
          </v-card-text>
          <v-card-actions class="teal">
            <v-spacer></v-spacer>
            <v-btn
              color="blue lighten-4"
              small
              @click="openSelectEditDialog = false; editSchedule()"
              >Event Only</v-btn>
            <v-btn
              color="blue lighten-4"
              small
              @click="openSelectEditDialog = false; editRecurringSchedule()"
              >Entire Series</v-btn>
            <v-btn
              color="red"
              class="white--text"
              small
              @click="openSelectEditDialog = false; selectedSchedule = {}"
              >Cancel</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="openSelectDeleteDialog"
              max-width="660px" :persistent="true">
        <v-card>
          <v-card-title
            class="headline teal white--text"
            primary-title
            >Delete Schedule</v-card-title>
          <v-card-text>
            Do you want delete just this event,
            the day series or the entire Series?
          </v-card-text>
          <v-card-actions class="teal">
            <v-spacer></v-spacer>
            <v-btn
              color="blue lighten-4"
              small
              @click="openSelectDeleteDialog = false; deleteSchedule('event')"
              >Event Only</v-btn>
            <v-btn
              color="blue lighten-4"
              small
              @click="openSelectDeleteDialog = false; deleteSchedule('day')"
              >{{selectedDeleteWeekDay}} Series Only</v-btn>
            <v-btn
              color="blue lighten-4"
              small
              @click="openSelectDeleteDialog = false; deleteSchedule('series')"
              >Entire Series</v-btn>
            <v-btn
              color="red"
              class="white--text"
              small
              @click="openSelectDeleteDialog = false; deleteSlotData = null"
              >Cancel</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
      max-width="650px"
      :persistent="true"
      v-model="confirmCancelRequest">
      <v-card>
        <v-card-title
          class="title dialog-title amber--text text--darken-4">
          <v-icon class="amber--text text--darken-4">warning</v-icon> Warning
        </v-card-title>
        <v-card-text
          class="text-xs-center orange--text--darken-1"
          style="padding: 18px;">
          <p
            class="mt-4 title font-weight-regular teal--text font-italic">
            {{ confirmationCancelMsg }}
          </p>
         <v-textarea
            v-if="useReasonForm"
            v-validate="'required'"
            name="cancel-reason"
            data-vv-as="Cancel reason"
            label="Cancel Reason"
            hint="The reason for canceling."
            v-model="cancelReason"
            :error-messages="errors.collect('cancel-reason')"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red lighten-1"
            class="white--text"
            @click="confirmCancelRequest=false; $validator.reset()" >
            {{ labels.no }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue lighten-1"
            class="white--text"
            @click="approveCancelRequest">{{ labels.yes }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openWarnings" max-width="650px">
      <v-card class="red--text">
        <v-card-actions>
          <v-layout>
            <v-flex xs6>
              <span class="headline">Warning!</span>
            </v-flex>
            <v-flex offset-xs7 xs1>
              <v-btn flat icon color="red"
                @click="openWarnings = false; dayWarnings=[];">
                <v-icon >close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
        <v-card-text style="padding-top: 0">
          <v-list class="red--text">
            <v-list-tile v-for="w in dayWarnings" :key="w.title">
              <v-list-tile-action>
                <v-icon color="red">{{w.icon || 'warning'}}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-text="w.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {dsMerge} from '@/functions';
import {
  Calendar,
  Event,
  Schedule,
  Day,
  Units,
  Time
} from 'dayspan';
import axios from 'axios';
import HomeHeader from '@/components/layouts/HomeHeader';
import MobileView from '@/components/schedules/MobileView';
// eslint-disable-next-line max-len
import RecurringSchedule from '@/components/schedules/RecurringSchedule';
import ScheduleForm from '@/components/schedules/ScheduleForm';
import WorkerLogin from '@/components/users/WorkerLogin';
import ItemSelector from '@/components/common/item-selector';
import DepartmentSelector from '@/components/common/department-selector';

//import all mixins
import {user} from '@/mixins/user';
import {crud} from '@/mixins/crud';
import {timezone} from '@/mixins/timezone';
import {picture} from '@/mixins/picture';
import {company} from '@/mixins/company';
import {theme} from '@/mixins/theme';
import {intro} from '@/mixins/intro';
import {_} from 'vue-underscore';
import * as momentTz from 'moment-timezone';
import moment from 'dayspan/node_modules/moment';
import globalUtils from '@/utils/global';
import calendarUtils from '@/utils/calendar';

var SmartPhone = require('detect-mobile-browser')(false);

//calendar methods
let methods = {
  showDayWarnings (warnings) {
    this.dayWarnings = warnings;
    this.openWarnings = true;
  },
  getDayWarnings(day) {
    // warnings for minimun staff required for roles
    return this.getDayWarningsForStaff(day);
  },
  getDayWarningsForStaff(day) {
    if (!this.companyInfo.workingHours.find(d => d.weekday === day.dayOfWeek)) {
      return [];
    }
    return this.roles.map(r => {
      if (r.minStaff > 0) {
        let events = day.events || [];
        let roleCount = events.reduce(
          (c, e) => e.data.meta.sched.roleId === r.id ? c + 1 : c, 0);
        if (roleCount < r.minStaff) {
          return {
            title: `Not enough '${r.name}' for this day`
          };
        }
      }
    }).filter(i => i);
  },
  filterWorkers (selectedWorkers) {
    this.selectedWorkers = selectedWorkers;
    let route = {name: this.$route.name};
    route.params = {
      worker: selectedWorkers.map(w => w.slug).join('+') || 'none',
      company: this.companyInfo.slug
    };
    this.$router.push(route);
  },
  handleMove(e) {
    if (e && e.mouseEvent.day) {
      let sched = e.calendarEvent.data.meta.sched;
      let toDate = e.mouseEvent.day.date;
      let tz = parseFloat(this.user.timezone);
      let timeStart = moment.utc(sched.start*1000);
      sched.timeStart = moment.utc(
        moment.utc(sched.end*1000).utcOffset(-tz).toObject())
        .format('HH:mm');
      sched.timeStart = moment.utc(
        moment.utc(sched.start*1000).utcOffset(-tz).toObject())
        .format('HH:mm');
      let dateString = toDate.format('gggg-MM-DD')
        + 'T' + timeStart.format('HH:mm');
      toDate = moment.utc(dateString).utcOffset(-tz);
      toDate.hours(0);
      toDate.minute(0);
      sched.date = moment.utc(toDate.toObject()).unix();
      this.saveSchedule(sched);
    }
  },
  getWorkerFullName(item) {
    return item.firstName + ' ' + item.lastName;
  },
  aproveRequestSchedule(slotData) {
    let _vm = this;
    let sched = slotData.details.meta.sched;
    let confirmBoxTitle = 'Approve Request Confirmation';
    let confirmBoxMessage = 'Are you sure you want approve this request?';
    let url = `${this.$root.apiUrl}requests/approve/${sched.id}`;
    _vm.$confirm(confirmBoxTitle, confirmBoxMessage, function(){
      axios.patch(url, {managerId: _vm.identity.manager.id})
        .then(res => {
          res = res.data;
          if (res.status === 'success') {
            _vm.$notify(res.message, 'success');
            _vm.loadState();
          } else {
            _vm.$notify(res.message, 'error');
          }
        })
        .catch(err => {
          _vm.$notify(err.message, 'error');
        });
    }, function(){});
  },
  declineMobileRequestSchedule(slotData) {
    let _vm = this;
    _vm.scheduleData = slotData;
    if (!_vm.isRequested(slotData.details.meta.sched)) {
      _vm.confirmationCancelMsg = _vm.dialogScheduleMsg;
      _vm.useReasonForm = true;
    } else {
      _vm.confirmationCancelMsg = _vm.dialogRequestMsg;
      _vm.useReasonForm = false;
    }
    _vm.confirmCancelRequest = true;
  },
  approveCancelRequest() {
    this.$validator.validate().then(valid => {
      if (valid) {
        this.declineRequestScheduleApi(this.scheduleData);
        this.confirmCancelRequest = false;
        this.cancelReason = '';
      }
    });
  },
  declineRequestSchedule(slotData) {
    let _vm = this;
    let confirmBoxTitle = 'Decline Request Confirmation';
    let confirmBoxMessage = 'Are you sure you want decline this request?';
    _vm.$confirm(confirmBoxTitle, confirmBoxMessage, function(){
      _vm.declineRequestScheduleApi(slotData);
    }, function(){});
  },
  declineRequestScheduleApi(slotData) {
    let _vm = this;
    let schedule = slotData.details.meta.sched;
    let method = 'DELETE';
    //console.log(slotData);
    let url = `${_vm.$root.apiUrl}requests/decline/`;
    //if requested means cancel schedule
    if (_vm.isRequested(slotData) && !slotData.requested) {
      url = `${_vm.$root.apiUrl}schedules/submit-cancel-schedule/`;
      method = 'POST';
    }
    axios({
      url: url + schedule.id,
      method,
      data: {
        managerId: _vm.managerId,
        reason: _vm.cancelReason,
      }
    }).then(res => {
      res = res.data;
      if (res.status === 'success') {
        if (_vm.isAny() && _vm.isWorker()) {
          _vm.$notify('Request have been successfully canceled', 'success');
          _vm.$root.$emit('mobile-reload');
        } else {
          _vm.$notify(res.message, 'success');
          _vm.loadState();
        }
      } else {
        _vm.$notify(res.message, 'error');
      }
    })
    .catch(err => {
      _vm.$notify(err.message, 'error');
    });
  },
  editRequestSchedule(slotData) {
    if (slotData) {
      return null;
    }
  },
  logout () {
    this.forceLogout();
    this.$router.go();
  },
  addEvent(day) {
    this.selectedSchedule = {
      date: day.date,
    };
    this.openSchedDialog = true;
    if (typeof this.$refs['scheduleForm'] !== 'undefined') {
      this.$refs.scheduleForm.reset();
    }
  },
  createDaysLabel(startWeekDay) {
    if (startWeekDay >= 0 || startWeekDay < 7) {
      let oldWeekDays = this.$dayspan.defaults.dsWeeksView.weekdays;
      let newWeekDays = oldWeekDays.slice();
      while (oldWeekDays[startWeekDay] !== newWeekDays[0]) {
        let day = newWeekDays.shift();
        newWeekDays.push(day);
      }
      this.$root.weekdays = newWeekDays;
    }
  },
  createCalendar(startWeekDay) {
    moment.updateLocale('en', {week: {dow: startWeekDay, doy: 4}});
    let calendar = Calendar.months();
    if (this.$route.params.year && this.$route.params.month) {
      try {
        let year = parseInt(this.$route.params.year);
        let month = parseInt(this.$route.params.month);
        let day = new Day(moment([year, month-1]));
        calendar = Calendar.months(1, day);
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
      }
    }
    this.calendar = calendar;
  },
  closeDialog() {
    this.openSchedDialog = false;
    setTimeout(() => {
      this.selectedSchedule = {};
    }, 500);
  },
  getCalendarDay() {
    return (this.companyInfo)
              ? this.companyInfo.startWeekDay
              : 1;
  },
  //overlap array
  generateOverlapMessage (overlap) {
    let _vm = this;
    let timezone = _vm.user.timezone;
    let message = '<ul class="overlap-errors">';
    overlap.forEach(o => {
      let start = _vm.timeFormat(
        moment(o.start*1000).utcOffset(parseFloat(timezone)));
      let end = _vm.timeFormat(
        moment(o.end*1000).utcOffset(parseFloat(timezone)));
      message += `<li>${start} - ${end}</li>`;
    });
    message += '</ul>';
    return message;
  },
  saveSchedule(schedule) {
    let _vm = this;
    let url = `${process.env.VUE_APP_API_URL}schedules/`;
    let axiosPromise = null;
    if (schedule.id) {
      url += _vm.isRequested(schedule) ? 'update-request/' : 'update/';
      url += schedule.id;
      axiosPromise = axios.put(url, schedule);
    } else {
      url += (_vm.isManager()) ? 'create' : 'create-request';
      axiosPromise = axios.post(url, {schedule});
    }
    axiosPromise.then(response => {
      if (response.data.status === 'success') {
        _vm.$notify(response.data.message, 'success');
        _vm.openSchedDialog = false;
        if (_vm.isAny()) {
          //_vm.loadMobileStateTimestamp(schedule.date);
          _vm.$root.$emit('mobile-reload');
        } else {
          _vm.loadState();
        }
      } else {
        let err = response.data;
        let message = `<p>${err.message}</p>`;
        if (err.overlap && err.overlap.length) {
          message += _vm.generateOverlapMessage(err.overlap);
        }
        _vm.$notify(message, 'error');
      }
    }).catch(err => {
      _vm.$notify(err.message, 'error');
    });
  },
  // TODO: Remove this method than have debug purpose.
  check(data) {
    //eslint-disable-next-line
    console.log(JSON.stringify(data.details));
  },
  selectScheduleEdit(slotData) {
    this.$refs.scheduleForm.reset();
    this.selectedSchedule = slotData.details.meta;
    if (this.selectedSchedule.sched.repeatDayId && this.isManager()) {
      this.openSelectEditDialog = true;
    } else {
      this.editSchedule();
    }
  },
  editSchedule() {
    this.openSchedDialog = true;
  },
  editMobileSchedule(slotData) {
    this.openSchedDialog = true;
    this.selectedSchedule = slotData.details.meta;
  },
  editRecurringSchedule() {
    // Extract the worker
    let schedule = this.selectedSchedule.sched;
    let worker = this.workers.find(w => w.id === schedule.workerId);
    this.$refs.recurringSchedule.selectRule = {
      byDayId: schedule.repeatDayId
    };
    this.$refs.recurringSchedule.dialog = true;
    this.$refs.recurringSchedule.form.role = schedule.roleId;
    this.$set(this.$refs.recurringSchedule.form, 'worker', worker);
  },
  selectScheduleDelete(slotData) {
    this.deleteSlotData = slotData;
    if (slotData.details.meta.sched.repeatDayId) {
      this.openSelectDeleteDialog = true;
    } else {
      this.deleteSchedule('event');
    }
  },
  getInfoForDelete(deleteType) {
    let _vm = this;
    let boxTitle, boxMessage;
    let url = `${process.env.VUE_APP_API_URL}`;
    if (deleteType === 'event') {
      boxTitle = 'Remove Schedule Confirmation';
      boxMessage = 'Are you sure that you want to remove this schedule ?';
      url += 'schedules/remove/';
    } else if (deleteType === 'day'){
      boxTitle = `Remove ${_vm.selectedDeleteWeekDay} Series Confirmation`;
      boxMessage =
        'Are you sure that you want to remove this day serie schedules?';
      url += 'schedules/day-series/remove/';
    } else {
      boxTitle = 'Remove Recurring Schedule Confirmation';
      boxMessage =
        'Are you sure that you want to remove this recurring schedule ?';
      url += 'schedules/series/remove/';
    }
    return {boxTitle, boxMessage, url};
  },
  deleteSchedule(deleteType) {
    let _vm = this;
    if (!_vm.deleteSlotData) {
      return;
    }
    let {boxTitle, boxMessage, url} = _vm.getInfoForDelete(deleteType);
    let sched = this.deleteSlotData.details.meta.sched;
    let id = deleteType === 'event' ? sched.id : sched.repeatDayId;
        _vm.$confirm(boxTitle, boxMessage, () => {
      axios.delete(url + id).then(response => {
        if (response.data.status === 'success') {
          _vm.$notify(response.data.message, 'success');
        } else {
          _vm.$notify(response.data.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err.data, 'error');
      }).finally(() => {
        _vm.deleteSlotData.close();
        _vm.deleteSlotData = null;
        _vm.loadState();
      });
    }, () => {});
  },
  roleSelected(roles) {
    this.selectedRoles = roles;
    if (roles) {
      this.loadState();
    }
  },
  departmentSelected(departments) {
    this.selectedDepartments = departments;
    if (departments) {
      this.loadState();
    }
  },
  getCalendarTime(calendarEvent) {
    let sa = calendarEvent.start.format('a');
    let ea = calendarEvent.end.format('a');
    let sh = calendarEvent.start.format('h');
    let eh = calendarEvent.end.format('h');
    if (calendarEvent.start.minute !== 0) {
      sh += calendarEvent.start.format(':mm');
    }
    if (calendarEvent.end.minute !== 0) {
      eh += calendarEvent.end.format(':mm');
    }
    return (sa === ea) ? (sh + ' - ' + eh + ea) : (sh + sa + ' - ' + eh + ea);
  },
  saveState() {
    let state = this.calendar.toInput(true);
    let json = JSON.stringify(state);
    localStorage.setItem(this.storeKey, json);
  },
  stateChanged(event) {
    if (event.calendar.type === 2 && this.$route.params.worker) {
      let route = {name: 'calendar'};
      route.params = this.$route.params;
      route.params.year = event.calendar.start.year.toString();
      route.params.month = (event.calendar.start.month + 1).toString();
      if (route.params.month.length === 1) {
        route.params.month = '0' + route.params.month;
      }
      this.$router.push(route);
    } else {
      //eslint-disable-next-line
      console.log("stateChanged");
      this.loadState();
    }
  },
  changeView(type) {
    if (type.type === 1) { // week view
      this.monthCalendar = this.calendar;
      if (!this.weekCalendar) {
        this.weekCalendar = Calendar.weeks();
        this.weekCalendar.addEvents(this.events);
      }
      this.calendar = this.weekCalendar;
    } else { // month view
      this.weekCalendar = this.calendar;
      this.calendar = this.monthCalendar;
    }
  },
  createDaysArray(daysSet) {
    let arr = Array.from(daysSet);
    let todayForm = moment().format('DDMMYYYY');
    let todayIndex = arr.findIndex(i => i === todayForm);
    if (todayIndex !== -1) {
      arr = _.union(arr.slice(todayIndex), arr.slice(0, todayIndex));
    }
    return arr;
  },
  populateCalendar(context) {
    //eslint-disable-next-line
    let _vm = this;
    let daysSet = new Set();
    let daysMap = {};
    context.events.forEach(e => {
      let dayName = e.schedule.start.format('DDMMYYYY');
      daysSet.add(dayName);
      if (!daysMap[dayName]) {
        daysMap[dayName] = [];
      }
      let schedule = new Schedule(e.schedule);
      let event = new Event(schedule, e.data, e.data.meta.sched.id);
      daysMap[dayName].push(event);
    });
    let daysArray = _vm.createDaysArray(daysSet);
    _vm.calendar.removeEvents();
    let addEvents = (i) => {
      let addedEvents = 0;
      while (addedEvents < 30 && i < daysArray.length) {
        daysMap[daysArray[i]].forEach((d) => {
          _vm.calendar.rawEventsByDays[daysArray[i]].push(d);
          addedEvents++;
        });
        i++;
      }
      return i;
    };
    let process = (i) => {
      setTimeout(() => {
        if (_vm.populateContext.repopulate) {
          _vm.populateContext = _vm.populateCalendar(
            {events: _vm.populateContext.repopulate});
        } else if (i < daysArray.length) {
          process(addEvents(i));
        } else {
          _vm.populateContext = null;
        }
      }, 300);
    };
    process(addEvents(0));
    return context;
  },

  loadState() {
    this.showLoadingBox = true;
    let {start, end} = this.calendar.filled;
    this.getSchedules(start, end).then(schedules => {
      let defaults = this.$dayspan.getDefaultEventDetails();
      defaults.busy = null;
      let daysForSet = {};
      schedules.forEach(ev => {
        // check warnings for each event
        ev.data.meta.warnings = this.getDayWarnings(ev);
        ev.data = dsMerge(ev.data, defaults);
        ev.schedule.start = moment(ev.schedule.start);
        ev.schedule.end = moment(ev.schedule.end);
        let dayFormat = ev.schedule.start.format('DDMMYYYY');
        if (!daysForSet[dayFormat]) {
          daysForSet[dayFormat] = [];
        }
      });
      this.events = schedules;
      this.$set(this.calendar, 'rawEventsByDays', daysForSet);
      if (this.populateContext) {
        this.populateContext.repopulate = schedules;
      } else {
        this.populateContext = this.populateCalendar({events: schedules});
      }
      //this.calendar.withEventsOutside(true);
      //this.calendar.setEvents(this.events);
      //this.calendar = Calendar.fromInput(input);
    }).catch(err => {
      console.log(err);
      this.$notify(err.data.message, 'error');
    }).finally(() => {
      this.showLoadingBox = false;
    });
  },
  /*loadMobileStateTimestamp(timestamp) {
    let _vm = this;
    let start = _vm.weekStart(_vm.getCalendarDay(), timestamp*1000)
                   .startOf('day')
                   .subtract(1, 'hour');
    console.log(start);
    let end = momentTz((start.unix() + (3600*24*6))*1000)
                .utcOffset(0)
                .endOf('day');
    console.log(end);
    _vm.loadMobileState(start, end);
  },*/
  loadMobileState(start, end) {
    let _vm = this;
    _vm.showLoadingBox = true;
    _vm.totalMatchedEvents = 0;
    _vm.events = [];
    _vm.getSchedules(start, end).then(events => {
      _vm.events = events;
    }).catch(err => {
      console.log(err);
      _vm.$notify(err.data.message, 'error');
    }).finally(() => {
      _vm.showLoadingBox = false;
    });
  },
  getSchedules(start, end) {
    let vm = this;
    let url = process.env.VUE_APP_API_URL + 'schedules/load';
    //send only ids to avoid user to understand backend db structure
    let roles = vm.selectedRoles.map(r => r.id);
    let departments = vm.selectedDepartments
                        .map(d => d.id);
    let _workers = [vm.identity.worker];
    if (vm.hasManagerAccount()) {
      _workers = vm.selectedWorkers ? vm.selectedWorkers : vm.workers;
    }
    let workers = _workers.map(w => w.id);
    let unassignedDepartment = departments.includes(null);
    //
    departments = departments.filter(id => id != null);
    //
    let reqData = {
      dayStart: start.toISOString(),
      dayEnd: end.toISOString(),
      roles,
      workers,
      departments,
      unassignedDepartment,
      timezone: vm.user.timezone
    };
    return new Promise((resolve, reject) => {
      axios.post(url, reqData).then(response => {
        if (response.data.status === 'success') {
          let events = [];
          for (let s of response.data.data) {
            let worker = _workers.find(w => w.id === s['Worker.id']);
            if (worker && worker !== -1) {
              let startTime = momentTz.utc(s.start*1000);
              let endTime = momentTz.utc(s.end*1000);
              let data = {
                color: vm.getEventPaperColor(s),
                title: vm.getEventTitle(s, worker, startTime, endTime),
                description: s.canceled ?
                  'CANCEL REASON: ' + s.cancelReason : s.description,
                icon: s.description ? 'fas fa-thumbtack': '',
                meta: {
                  sched: s,
                  startTime,
                  endTime,
                  date: momentTz.utc(s.date*1000)
                }
              };
              let schedule = {
                id: s.id,
                end: momentTz(startTime.toObject()).endOf('day').toDate(),
                start: momentTz(startTime.toObject()).startOf('day').toDate(),
                times: [
                  new Time(startTime.hours(), startTime.minutes())
                ],
                duration: s.duration,
                durationUnit: 'hours',
              };
              events.push({data, schedule});
            }
          }
          resolve(events);
        } else {
          reject(response);
        }
      }).catch(err => {
        reject(err);
      });
    });
  },
  querySelections (v) {
    this.loading = true;
    // Simulated ajax query
    setTimeout(() => {
      this.items = this.workers.filter(e => {
        return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1;
      });
      this.loading = false;
    }, 500);
  },
  genRequestedTitle(startTime, endTime) {
    let title = `${this.timeFormat(startTime)} - ${this.timeFormat(endTime)}`;
    title += ' (waiting for approval)';
    return title;
  },
  genTitle(worker, department, startTime, endTime) {
    let title = `${this.timeFormat(startTime)} - ${this.timeFormat(endTime)}`;
    title += ` | ${worker.firstName} ${worker.lastName[0]}. `;
    // Remove the department from event title.
    /* let departmentName = 'Unassigned';
    if (!_.isEmpty(department)) {
      departmentName = department.name;
    }
    title +=  `(${departmentName})`; */
    return title;
  },
  getEventTitle(s, worker, startTime, endTime) {
    let department = s['Worker.Department'];
    return (this.isRequested(s))
              ? this.genRequestedTitle(startTime, endTime)
              : this.genTitle(worker, department, startTime, endTime);
  },
  isRequested(event) {
    return event.hasOwnProperty('requested');
  },
  getEventPaperColor(s) {
    let paperColor = s['Role.paperColor'];
    if (this.isAny() && this.isWorker()) {
      paperColor = '#33dc30';
    }
    if (s.canceled) {
      paperColor = '#909399';
    }
    return (s.hasOwnProperty('requested'))
              ? '#f71c1c'
              : paperColor;
  },
  loadWorkerFromSlug(slug) {
    let _vm = this;
    let url = `${_vm.$root.apiUrl}workers/show/${_vm.companyId}/${slug}`;
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        if (response.data.status === 'success') {
          _vm.workers = response.data.data;
          resolve(_vm.workers);
        }
      }).catch(err => {
        reject(err);
        _vm.$notify(err.data, err);
      });
    });
  },
  loadWorkers() {
    let _vm = this;
    let url = `${_vm.$root.apiUrl}workers/list/company-${_vm.companyId}/1000/1`;
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        if (response.data.status === 'success') {
          _vm.workers = response.data.data;
          resolve(_vm.workers);
        }
      }).catch(err => {
        reject(err);
        _vm.$notify(err.data, err);
      });
    });
  },
  loadRoles() {
    let _vm = this;
    let url = `${_vm.$root.apiUrl}companies/${_vm.companyId}/roles`;
    axios.get(url).then(response => {
      let r = response.data;
      if (r.status === 'success' && r.data) {
        _vm.roles = r.data;
        _vm.selectedRoles = _vm.roles;
      }
    }).catch(err => {
      _vm.$notify(`Can't load roles: ${err.message}`, 'error');
    });
  },
  loadDepartments() {
    let _vm = this;
    let url = `${_vm.$root.apiUrl}companies/${_vm.companyId}/departments`;
    axios.get(url).then(response => {
      let r = response.data;
      if (r.status === 'success' && r.data) {
        _vm.departments = r.data;
        _vm.selectedDepartments = _vm.departments;
      }
    }).catch(err => {
      _vm.$notify(`Can't load roles: ${err.message}`, 'error');
    });
  }
};



//merge methods
methods = Object.assign(
  {},
  methods,
  globalUtils,
  calendarUtils,
  SmartPhone,
  // This will disable the desktop view for worker.
  // Remove the 'isAny' method when need the desktop view for worker.
  {isAny() {
    return this.hasWorkerAccount();
  }}
);
//remove user agent property
delete methods['userAgent'];

// import others ressources
import {
  VCheckbox,
  VTextField,
  VAutocomplete,
  VProgressLinear,
  VSelect,
  VChip,
} from 'vuetify';

export default {
  name: 'dayspan',
  metaInfo: {
    title: 'SimpleTym - Calendar',
    titleTemplate: null
  },
  components: {
    VCheckbox,
    VTextField,
    VAutocomplete,
    HomeHeader,
    RecurringSchedule,
    ItemSelector,
    ScheduleForm,
    VProgressLinear,
    DepartmentSelector,
    WorkerLogin,
    MobileView,
    VSelect,
    VChip,
  },
  mixins: [
    user,
    picture,
    timezone,
    company,
    theme,
    crud,
    intro,
  ],
  data: () => {
    let calendar = Calendar.months();
    return {
      calendar,
      weekCalendar: null,
      monthCalendar: null,
      populateContext: null,
      dayWarnings: [],
      openWarnings: false,
      selectedWorkers: null,
      company: {},
      dailyEvents: {},
      confirmCancelRequest: false,
      confirmationCancelMsg: '',
      useReasonForm: false,
      cancelReason: '',
      dialogRequestMsg: 'Are you sure you want to cancel the request ?',
      dialogScheduleMsg: 'Are you sure you want cancel this schedule ?',
      openSchedDialog: false,
      openSelectEditDialog: false,
      openSelectDeleteDialog: false,
      selectedSchedule: {},
      deleteSlotData: null,
      displayTimeFormat: 'H:mma',
      tFormat: 'HH:mm',
      selectedRoles: [],
      readOnly: true,
      loading: false,
      showLoadingBox: false,
      items: [],
      search: null,
      select: null,
      workers: [],
      roles: [],
      departments: [],
      selectedDepartments: [],
      storeKey: 'dayspanState',
      events: [],
      dates: [],
      totalMatchedEvents: 0,
      emptyBoxColor: '#828c94',
      scheduleData: {},
      //items: [],
      types: [
        {
          id: 'W',
          label: 'Weeks',
          shortcut: 'W',
          type: Units.WEEK,
          size: 1,
          focus: 0.4999,
          repeat: true,
          listTimes: true,
          updateRows: true,
          schedule: false
        },
        {
          id: 'M',
          label: 'Months',
          shortcut: 'M',
          type: Units.MONTH,
          size: 1,
          focus: 0.4999,
          repeat: true,
          listTimes: false,
          updateRows: true,
          schedule: false
        }
      ]
    };
  },

  created() {
    let _vm = this;
    _vm.$root.$on('mobile-load', tm => {
      //set dates range
      _vm.dates = tm.dates;
      //load events from date range specified
      let start = moment(tm.statTimestamp*1000).utcOffset(0);
      let end = moment(tm.endTimestamp*1000).utcOffset(0);
      _vm.loadMobileState(start, end);
    });
    //mobile add event
    _vm.$root.$on('mobile-open-add-event', day => {
      _vm.addEvent(day);
    });
    //mobile cancel request
    _vm.$root.$on('mobile-cancel-request', slotData => {
      _vm.declineMobileRequestSchedule(slotData);
    });
    //mobile review event
    _vm.$root.$on('mobile-open-edit-event', slotData => {
      _vm.editMobileSchedule(slotData);
    });
    //emit reload
    _vm.$root.$on('reload', () => {
      _vm.loadState();
    });
  },

  mounted() {
    // this value is used on redirecting logic
    sessionStorage.setItem('calendarPageWasInit', true);
    let _vm = this;
    _vm.exitIntro();
    if (_vm.isProperlyLogged()) {
      //auto-load roles
      _vm.loadRoles();
      //auto-load departments
      _vm.loadDepartments();
    } else {//no such account
      let companySlug = _vm.$route.params.company;
      let workerSlug = _vm.$route.params.worker;
      if (!workerSlug) {//when worker provided
        //set company id
        _vm.getCompanyFromSlug(companySlug).then(company => {
          if (!_.isEmpty(company)) {
            _vm.company = company;
          }
        });
      }
    }
    //do this for both view
    window.ds = _vm.$refs.ds;
  },

  computed: {
    selectedDeleteWeekDay() {
      if (this.deleteSlotData) {
        let weekDay = this.deleteSlotData.calendarEvent.day.dayOfWeek;
        return moment.weekdays(weekDay);
      }
      return 'Day';
    },
    canDisplayCalendarMobile() {
      let _vm = this;
      return _vm.isProperlyLogged() && (_vm.hasManagerAccount()
              || _vm.hasWorkerAccount() && !_vm.isAny());
    },
    workerId() {
      let _vm = this;
      if (_vm.isWorker()) {
        return _vm.getUser('worker', 'id');
      } else if (_vm.workerFromUri) {
        return _vm.workerFromUri.id;
      } else {
        return false;
      }
    },
    workerFromUri() {
      let _vm = this;
      let workerSlug = _vm.$route.params.worker;
      if (workerSlug) {
        return _vm.workers.find(w => w.slug === workerSlug);
      }
      return null;
    },
    companyId() {
      return this.getUser('company', 'id');
    },
    formatForTime() {
      if (this.companyTimeFormat === '24-hour') {
        return 'HH:mm';
      }
      return 'hh:mm A';
    },
    companyTimeFormat() {
      return this.getUser('company', 'timeFormat');
    },
    is24Format() {
      return this.companyTimeFormat === '24-hour';
    },
    companyDateFormat() {
      return this.getUser('company', 'dateFormat');
    },
    user() {
      let _vm = this;
      if (!_vm.identity) {
        return {};
      }
      return _vm.identity.hasOwnProperty('manager')
                ? _vm.identity.manager
                : _vm.identity.worker;
    },
    managerId() {
      return this.isManager()
              ? this.user.id
              : this.user.managerId;
    }
  },

  watch: {
    calendar: {
      handler: function() {
        this.calendar.days.forEach(d => {
          d.warnings = this.getDayWarnings(d);
        });
      },
      deep: true
    },
    selectedWorkers: {
      handler: function(value) {
        if (value && value.length) {
          this.workers = this.workers.map(w => {
            w.selected = Boolean(value.find(i => i.slug === w.slug));
            return w;
          });
        }
      }
    },
    identity: {
      handler: function(val) {
        let _vm = this;
        if (val) {
          let managerId = _vm.isManager()
                            ? val.manager.id
                            : val.worker.managerId;
          _vm.getCompanyInfo(managerId).then(() => {
            if (!_vm.isAny()) {//desktop view only
              _vm.createDaysLabel(_vm.companyInfo.startWeekDay);
              _vm.createCalendar(_vm.companyInfo.startWeekDay);
              //this.$forceUpdate();
            }
          });
          //load workers
          let workersSlug = _vm.$route.params.worker
            ? _vm.$route.params.worker.split('+') : [];
          let workerPromise = (workersSlug.length && !_vm.isManager())
                                ? _vm.loadWorkerFromSlug(workersSlug[0])
                                : _vm.loadWorkers();
          /**
           * load dayspan events in :
           * 1- desktop view only
           * 2- mobile view in manager account only
           */
          if (!_vm.isAny()
              || _vm.isAny() && _vm.isManager()) {
            workerPromise.then(workers => {
              if (workers.length) {
                _vm.selectedWorkers = workers.filter(w => {
                  if (workersSlug.length) {
                    return Boolean(workersSlug.find(s => s === w.slug));
                  }
                  return true;
                });
              }
              _vm.loadState();
            });
          }
        }
      }
    },
    search (val) {
      val && val !== this.select && this.querySelections(val);
    },
    events: {
      handler: function(events) {
        let _vm = this;
        let count = 0;
        let _events = {};
        if (events.length) {
          _.each(_vm.dates, date => {
            let dateEvents = _.filter(events, e => {
              let start = e.data.meta.startTime;
              return start.startOf('day').utcOffset(0).unix() === date;
            });
            let _dateEvents = [];
            //when events found for a specific date
            if (dateEvents.length) {
              _.each(dateEvents, event => {
                if (!event.data.meta.sched.canceled) {
                  count += 1;
                  let _sched = event.data.meta.sched;
                  let reviewed = _sched.hasOwnProperty('reviewed')
                  // eslint-disable-next-line
                                  ? !Boolean(_sched.reviewed)
                                  : false;
                  let edit = Boolean(_sched.reviewed);
                  _dateEvents.push({
                    data: event,
                    bgColor: _vm.getEventPaperColor(_sched),
                    add: false,//hide add button
                    reviewed,
                    canceled: (_sched.type===2),
                    edit,
                    requested: _vm.isRequested(_sched),
                    locked: Boolean(_sched.locked) || false
                  });
                }
              });
            } else {
              _dateEvents.push({
                data: [],
                bgColor: _vm.emptyBoxColor,
                reviewed: false,//hide review button
                add: true//show add button
              });
            }
            _events[date] = _dateEvents;
          });
        }
        _vm.dailyEvents = _events;
        _vm.totalMatchedEvents = count;
      }
    }
  },

  methods
};
</script>

<style scoped>
body, html, #dayspan {
  width: 100%;
  height: 100%;
}
#dayspan {
  height: 90%
}
#dayspan .ds-calendar-app .v-toolbar__content h1.ds-light-forecolor {
  color: white;
}
#dayspan .top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>

<style>
#dayspan .admin-calendar {
  margin-top: 55px;
}
#dayspan .ds-calendar-app .v-toolbar__content {
  background-color: #383e3e !important;
  color: white;
}
#dayspan .ds-calendar-app .v-toolbar__content button{
  background: rgba(0,0,0, 0.3);
  color: white;
}
#dayspan .ds-calendar-app .v-toolbar__content .v-btn__content,
.ds-light-forecolor {
  color: white;
}
#dayspan .v-toolbar__title {
  color: white;
}
.ds-app-calendar-toolbar {
  top: 50px
}
#dayspan .v-navigation-drawer .pa-3 {
  margin-top: 40px;
}
#dayspan main {
  padding: 64px 0px 0px 5px !important;
}
#dayspan .v-toolbar__side-icon {
  display: none;
}
#dayspan .v-navigation-drawer {
  display: none;
}
#dayspan .ds-month .ds-calendar-event {
  position: static !important;
  width: 100%;
}
#dayspan .ds-calendar-event>span>span:first-child {
  display: none;
}
.popover-action {
  left: 10px !important;
  top: 100px;
}
.ds-week-header-today {
  color: #757575 !important;
  font-weight: 400 !important;
}
.ds-day.ds-weekends{
  background-color: #ECEFF1;
}

.ds-day {
  position: relative;
}

.v-btn.add-button {
  position: absolute;
  top: 0;
  right: 0;
}

.v-btn.warning-button {
  position: absolute;
  top: 0;
  right: 25px;
}

#dayspan .ds-calendar-event:hover {
  filter: brightness(90%);
}

.ds-week-header {
  flex: none !important;
}

.ds-week {
  flex: none !important;
  min-height: 150px !important;
}

.overlap-errors {
  text-align: left;
}

.dayspan-mobile {
  background: white;
}

.v-dialog--fullscreen {
  background: white;
}

</style>

