<template>
  <v-layout wrap align-center justify-center>
    <v-card class="elevation-6 box2">
      <v-card-text>
        <v-container>
          <v-layout row wrap class="pb-3">
            <v-flex xs12>
              <h2 class="text-xs-center
                        title font-weight-regular font-italic pb-2">
                Congratulations, Your Business is Registered!
              </h2>
              <h4 class="text-xs-center
                        subheading font-weight-regular font-italic">
                To Get Started, Complete the following:
              </h4>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-form>
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-text-field
                  v-model="form.firstName"
                  v-validate="'required'"
                  label="First Name"
                  prepend-icon="person_pin"
                  data-vv-name="firstName"
                  color="teal"
                  :error-messages="errors.collect('firstName')"
                  ></v-text-field>
                  </v-flex>
                  <v-text-field
                  v-model="form.lastName"
                  v-validate="'required'"
                  label="Last Name"
                  prepend-icon="person_pin"
                  data-vv-name="lastName"
                  color="teal"
                  :error-messages="errors.collect('lastName')"
                  ></v-text-field>
                    <v-flex xs6>
                    </v-flex>
                </v-layout>
                <v-layout row xs12 wrap>
                  <v-select
                  v-model="form.timezone"
                  v-validate="'required'"
                  data-vv-name="timezone"
                  color="teal"
                  :error-messages="errors.collect('timezone')"
                  :items="timezoneList"
                  :clearable="true"
                  label="Choose your timezone"
                  prepend-icon="language"
                  solo
                  @change="timezoneChanged"
                  ></v-select>
                </v-layout>
                <v-layout row wrap v-show="!showSlugField">
                  <v-flex offset-xs1>
                    <div class="slug subheading">
                      {{ previewUrl }}
                      <v-btn @click="editSlug"
                             class="teal" fab dark small>
                        <v-icon dark>{{ icons.edit }}</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout row justify-center align-center
                          wrap v-show="showSlugField">
                  <v-flex xs4>
                    <div class="text-xs-right slug subheading">
                      {{ mainUrl }}
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                         v-validate="'slug-unique'"
                         v-model="slug"
                         label="Custom URL"
                         data-vv-name="companyslug"
                         :error-messages="errors.collect('companyslug')"
                         ></v-text-field>
                  </v-flex>
                  <v-flex xs-2>
                    <v-btn @click="confirmSlug"
                           color="teal" fab dark small>
                      <v-icon dark>{{ icons.confirm }}</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="complete" color="teal"
               dark title="Complete your profile now." >Complete</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      max-width="650px"
      :persistent="true"
      v-model="openConfirm">
      <v-card>
        <v-card-text class="text-xs-center orange--text--darken-1">
          <p
              class="mt-4 title font-weight-regular teal--text font-italic">
            Please confirm your timezone before complete registration.
          </p>
          <p class="body-1 font-italic teal--text">
          {{ form.timezone }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red lighten-4" @click="openConfirm=false" >
            {{ labels.cancel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue lighten-4" @click="confirmAction">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showLoadingBox"
      persistent
      fullscrean
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import * as moment from 'moment-timezone';

import {company} from '@/mixins/company';
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {crud} from '@/mixins/crud';
import {VProgressLinear} from 'vuetify';

export default {
  name: 'registerComplete',
  metaInfo: {
    title: 'SimpleTym - Complete Profile'
  },
  mixins: [
    company,
    user,
    timezone,
    crud
  ],
  components: {
    VProgressLinear
  },
  mounted() {
    let _vm = this;
    //set completed to false when no manager account
    if (!_vm.hasManagerAccount()) {
      _vm.form.complete = 1;
    }
    //when manager account & completed process
    //then redirect to dashboard otherwise stand for completing process
    if (_vm.hasManagerAccount()
        && _vm.userRegisterIsCompleted()) {
      _vm.$router.push('/dashboard');
    } else {
      _vm.loadReadableTz().then(() => {
        _vm.form = {
          id: _vm.identity.user.id,
          firstName: _vm.identity.user.firstName,
          lastName: _vm.identity.user.lastName,
          timezone: _vm.defaultTimezone()
        };
      });
    }
  },
  data() {
    return {
      form: {completed: 1},
      timezoneList: [],
      showSlugField: false,
      slug: '',
      tzIsChanged: false,
      openConfirm: false,
      showLoadingBox: false
    };
  },
  methods: {
    defaultTimezone() {
      let _vm = this;
      //eslint-disable-next-line
      let localeTz = moment.tz(Intl.DateTimeFormat()
        .resolvedOptions().timeZone);
      let offset = localeTz.utcOffset() / 60;
      let defaultTimezone = _vm.timezoneList.find(tz => {
        let tzValues = _vm.getTimezoneValues(tz);
        return parseFloat(tzValues[0]) === offset;
      });
      return defaultTimezone;
    },
    timezoneChanged () {
      this.tzIsChanged = true;
    },
    confirmAction () {
      this.tzIsChanged = true;
      this.complete();
    },
    complete () {
      this.$validator.validate().then(isValid => {
        if (isValid) {
          // If timezone is not changed then open confirmation dialog
          if (!this.tzIsChanged) {
            this.openConfirm = true;
          } else {
            this.showLoadingBox = true;
            this.submitProfile();
          }
        }
      });
    },
    submitProfile () {
      [this.form.tz, this.form.tz_text] =
        this.getTimezoneValues(this.form.timezone);
      this.form['Company.timezone'] = this.form.tz;
      this.form['Company.timezone_text'] = this.form.tz_text;
      this.form.companySlug = this.slug;
      this.signup(this.form).then(() => {
        return this.setLocalUser();
      }).then(() => {
        this.$router.push('/dashboard');
        this.showLoadingBox = false;
      }).catch(err => {
        this.$notify(err, 'error');
        this.showLoadingBox = false;
      });
    },
    editSlug () {
      this.showSlugField = true;
    },
    confirmSlug () {
      this.showSlugField = false;
    }
  },
  computed: {
    mainUrl: function() {
      return process.env.VUE_APP_PREVIEW_URL;
    },
    previewUrl: function() {
      return this.mainUrl + this.slug;
    }
  },
  watch: {
    identity: {
      handler (val) {
        if (val) {
          if (this.hasManagerAccount()) {
            this.getCompanyInfo(this.identity.manager.id);
          }
          this.$_.extend(this.form, this.identity.manager);
        }
      }
    },
    companyInfo (value) {
      if (value) {
        this.slug = value.slug;
      }
    },
    'form.timezone': function(value) {
      if (!value) {
        this.$set(this.form, 'timezone', this.defaultTimezone());
      }
    }
  }
};

</script>

<style scoped>
  .slug {
    color:red
  }
  .box2 {
    border: 5px solid #009688;
    border-radius: 10px;
  }
</style>
