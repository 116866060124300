<template>
    <v-select
      :label="label"
      v-model="colorModel"
      :items="themes"
      class="theme-select"
      item-value="code"
      >
      <template slot="selection" slot-scope="data">
        <span :class="['theme-color', data.item.color]" ></span>
          {{ data.item.name }}
      </template>
      <template slot="item" slot-scope="data">
        <span :class="['theme-color', data.item.color]" ></span>
          {{ data.item.name }}
      </template>
    </v-select>
</template>

<script>

import {VSelect} from 'vuetify';

export default {
  name: 'colorPicker',
  components: {VSelect},
  props: ['value', 'label', 'selectedColors'],
  mounted () {
    this.colorModel = this.selectRandomColor();
  },
  watch: {
    value: function(v) {
      if (v && this.colorModel !== v) {
        this.colorModel = v;
      }
    },
    colorModel: function(value) {
      if (this.value !== value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    selectRandomColor () {
      let colorsForSelect = this.themes;
      if (this.selectedColors && this.selectedColors.length &&
          this.selectedColors.length < this.themes.length) {
        colorsForSelect = this.themes.filter(c => {
          return !this.selectedColors.find(sc => sc === c.code);
        });
      }
      let selectedColor = colorsForSelect[
        this.$_.random(0, colorsForSelect.length-1)];
      return selectedColor.code;
    },
    reset () {
      this.colorModel = this.selectRandomColor();
    }
  },
  data() {
    return {
      colorModel: null,
      themes: [
        {
          color: 'teal',
          code: '#009688',
          name: 'default'
        },
        {
          color: 'purple darken-1',
          name: 'purple',
          code: '#8E24AA'
        },
        {
          color: 'blue darken-1',
          name: 'blue',
          code: '#1E88E5'
        },
        {
          color: 'cyan lighten-1',
          name: 'cyan',
          code: '#26C6DA'
        },
        {
          color: 'green darken-1',
          name: 'green',
          code: '#43A047'
        },
        {
          color: 'lime darken-1',
          name: 'lime',
          code: '#C0CA33'
        },
        {
          color: 'orange darken-1',
          name: 'orange',
          code: '#FB8C00'
        },
        {
          color: 'yellow darken-1',
          name: 'yellow',
          code: '#FDD835'
        },
        {
          color: 'brown darken-1',
          name: 'brown',
          code: '#6D4C41'
        }
      ]
    };
  }
};

</script>

<style scoped>
.theme-color {
  padding: 6px;
  margin-right: 5px;
}
.theme-random-color {
  padding: 6px 14px;
  margin: 5px;
}

.random-label {
}
</style>
