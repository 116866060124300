<template>
    <v-layout wrap align-center justify-center>
        <v-card :class="
          [
            'elevation-6',
            'box2',
            isAny() ? 'hide-mobile' : ''
          ]">
        <v-card-text>
            <v-container>
            <v-layout row wrap class="pb-3">
                <v-flex xs12>
                <h2 class="text-xs-center
                    title font-weight-regular font-italic pb-2">
                    {{ company.name }}
                </h2>
                <h4 class="text-xs-center
                    subheading font-weight-regular font-italic">
                    Enter your email to get access to your Calendar:
                </h4>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                <v-form>
                    <!--v-autocomplete
                        v-validate="'required'"
                        v-model="selectedWorker"
                        :color="inputColor"
                        :items="workers"
                        :filter="searchFilter"
                        data-vv-name="defaultWorker"
                        :error-messages="errors.collect('defaultWorker')"
                        label="Choose a Worker"
                        prepend-icon="person"
                        return-object
                    >
                    <template slot="selection" slot-scope="data">
                        <v-avatar>
                        <img
                            :src="getAvatar(data.item.avatar)"
                            alt="Avatar"
                            class="w_picture"
                            >
                        </v-avatar>
                        <span
                        :class="[
                            'subheading',
                            'font-weight-medium',
                            'text-capitalize',
                            'px-2'
                        ]">
                        {{ data.item.firstName + ' ' + data.item.lastName }}
                        </span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-avatar>
                        <img
                            :src="getAvatar(data.item.avatar)"
                            alt="Avatar"
                            class="w_picture">
                        </v-avatar>
                        <span
                        :class="[
                            'subheading',
                            'font-weight-medium',
                            'text-capitalize',
                            'px-2'
                        ]">
                        {{ data.item.firstName + ' ' + data.item.lastName }}
                        </span>
                    </template>
                    </v-autocomplete-->
                    <v-text-field
                        v-validate="'required|email'"
                        v-model="form.email"
                        :error-messages="errors.collect('email')"
                        :color="inputColor"
                        label="E-mail Address"
                        data-vv-name="email"
                        prepend-icon="email"
                        required
                    ></v-text-field>
                    <!--v-text-field
                        v-validate="'required'"
                        v-model="form.lastName"
                        :error-messages="errors.collect('lastname')"
                        label="Lastname"
                        :color="inputColor"
                        data-vv-name="lastname"
                        prepend-icon="person_pin"
                        required
                        ></v-text-field-->
                </v-form>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer v-if="!isAny()"></v-spacer>
        <v-btn
            @click="submit"
            color="info"
            dark
        >Continue</v-btn>
        </v-card-actions>
        </v-card>
    </v-layout>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {crud} from '@/mixins/crud';
import {picture} from '@/mixins/picture';
import {user} from '@/mixins/user';

import {
  //VAutocomplete,
  VTextField
} from 'vuetify';

let methods = {
  searchFilter (item, queryText) {
    const name = item.name.toLowerCase();
    const searchText = queryText.toLowerCase();
    return name.indexOf(searchText) > -1;
  },
  submit () {
    let _vm = this;
    let msg = `This worker does not belong to <b>${_vm.company.slug}</b>.`;
    _vm.$validator.validate().then(result => {
      if (result) {
        // login user to cas
        _vm.login({
          username: _vm.form.email,
          //skip backend password validation
          password: '{{$$no_password$$}}',
          //allowed for exceptional validation on backend side
          /*extra: {
            field: 'lastName',//field to validation instead password!!!
            value: _vm.form.lastName, //value to validate matching
            errorMsg: msg //provide custom error message from third party app
          },*/
          params: {//set params like slug
            company: _vm.company
          },
          //specify login type which allow what information to load
          type: _vm.loginType.worker
        }).then(completed => {
          if (completed) {
              _vm.showLoading = false;
              let workerSlug = _vm.getUser('worker', 'slug');
              _vm.$router.push({
                path: `/cal/${_vm.company.slug}/${workerSlug}`
              });
          } else {
            _vm.$notify(msg, 'error');
          }
        }).catch(() => {
          _vm.showLoading = false;
          _vm.$notify(msg, 'error');
        });
      }
    });
  }
};

var SmartPhone = require('detect-mobile-browser')(false);
//merge methods
methods = Object.assign({}, methods, SmartPhone);
delete methods['userAgent'];//remove user agent property

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },

  name: 'WorkerLogin',

  components: {
    //VAutocomplete,
    VTextField
  },

  mixins: [
    crud,
    picture,
    user
  ],

  props: [
    'company',
    'workers'
  ],

  data: () => {
    return {
      selectedWorker: null,
      inputColor: 'blue',
      form: {
        email: '',
        lastName: ''
      },
      dictionary: {
        attributes: {
          email: 'E-mail Address',
          lastname: 'Password'
        },
        custom: {
          email: {
            required: 'Email field can not be empty.'
          },
          lastname: {
            required: 'Lastname field is required.'
          }
        }
      }
    };
  },

  mounted () {
    let _vm = this;
    // init validator
    _vm.$validator.localize('en', this.dictionary);
  },

  methods
};
</script>

<style scoped>
  .w_picture {
    width: 25px;
    height: 25px;
  }
  .hide-mobile {
    box-shadow: none !important;
  }
  .hide-mobile .v-card__actions{
    display: flex;
    justify-content: center;
  }
</style>
