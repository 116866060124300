// Getters
// Mutations
export const LOGIN = 'LOGIN'; // can be used
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP'; // can be used
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const REQUEST_SUCCESS = 'success';
export const REQUEST_FAIL = 'fail';
