<template>

  <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="localTime"
      persistent
      lazy
      full-width
      width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formatedTime"
          :label="label"
          prepend-icon="access_time"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="modal"
        :format="is24Format ? '24hr' : 'ampm'"
        :min="min"
        v-model="localTime"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn flat color="primary"
          @click="modal = false">
          Cancel
        </v-btn>
        <v-btn flat color="primary"
          @click="save()">
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>

</template>

<script>

import moment from 'moment';

import {
  VTimePicker
} from 'vuetify/es5/components/VTimePicker';

export default {
  name: 'CustomTimePicker',
  components: {
    VTimePicker
  },
  props: ['time', 'label', 'is24Format', 'min'],
  data() {
    return {
      localTime: null,
      modal: false,
    };
  },
  methods: {
    save() {
      this.$refs.dialog.save(this.localTime);
      this.$emit('changed', this.localTime);
      this.modal = false;
    },
    toAMPM(time) {
      let m = moment(time, 'HH:mm');
      return m.isValid() ? m.format('hh:mm A') : time;
    },
    to24Format(time) {
      let m = moment(time, 'hh:mm A');
      return m.isValid() ? m.format('HH:mm') : time;
    }
  },
  watch: {
    time(value) {
      this.localTime = value;
    }
  },
  computed: {
    formatedTime() {
      return this.is24Format ? this.localTime : this.toAMPM(this.localTime);
    }
  }
};

</script>

<style>
</style>
