<template>
    <v-container class="pt-0">
      <v-layout row wrap>
        <v-flex>
          <h1>Account</h1>
          <!--<user-profile-avatar-->
            <!--@changed="setAvatar"-->
            <!--:user-token="getUserToken()"-->
            <!--:user-avatar="userAvatarSrc"-->
            <!--size="100">-->
            <!--</user-profile-avatar>-->
        </v-flex>
      </v-layout>
      <v-layout row>
      <v-flex class="mx-auto" xs9>
          <v-alert
            :value="editedItem.password"
            dismissible
            transition="scale-transition"
            type="warning">
            You will be logout on save!
          </v-alert>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <manager-form
            ref="mform"
            class="mx-auto"
            :form="editedItem"
            :processDialog="false">
         </manager-form>
          <v-switch
            v-show="showTzSwitch"
            class="mt-0"
            style="margin-left: 25%"
            label="Use company timezone"
            v-model="useCompTz"
            color="teal"
            width="20px"
            :hide-details="true"
          ></v-switch>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex class="mx-auto" xs2>
          <v-btn color="teal" dark @click="update">Save</v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
      </v-layout>
    </v-container>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import ManagerForm from '@/components/common/FormManager';
import UserProfileAvatar from '@/components/layouts/UserProfileAvatar';
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {company} from '@/mixins/company';
import {VAlert, VSwitch} from 'vuetify';

export default {
  name: 'user-account',
  mixins: [
    user,
    timezone,
    company
  ],
  components: {
    ManagerForm,
    UserProfileAvatar,
    VAlert,
    VSwitch
  },
  mounted: function() {

    if (this.identity) {
      this.createEditedItem(this.identity);
    }

    this.$root.$on('company-update', managerId => {
      if (managerId) {
        this.getCompanyInfo(managerId);
      }
    });
  },
  data: function() {
    return {
      editedItem: {},
      useCompTz: false,
    };
  },
  computed: {
    showTzSwitch: function() {
      return !(this.companyTz === this.userTz
        && this.companyTz === this.editedItem.timezone);
    },
    companyTz: function() {
      if (this.companyInfo) {
        let tz = this.companyInfo.timezone;
        let tzText = this.companyInfo.timezone_text;
        return this.readableOffset(tz) + ' ' + tzText;
      }
      return '';
    },
    userTz: function() {
      if (this.identity) {
        let tz = this.identity.user.timezone || this.identity.manager.timezone;
        let tzText = this.identity.user.timezone_text
          || this.identity.manager.timezone_text;
        return this.readableOffset(tz) + ' ' + tzText;
      }
      return '';
    },
    currentUser: function() {
      return this.identity ? this.identity.user : {};
    },
    userToken: function() {
      if (this.identity) {
        return this.getUserToken();
      }
      return '';
    },
    userAvatarSrc: function() {
      if (this.currentUser.avatar) {
        let url = new URL(this.currentUser.avatar,
          process.env.VUE_APP_CAS_API_URL);
        return url.href;
      } else {
          return '/images/blank-avatar.png';
      }
    }
  },
  methods: {
    createEditedItem(value) {
      this.editedItem = this.$_.omit(value.user, 'password', 'confirmation');
      this.editedItem.casUserId = value.manager.casUserId;
      this.editedItem.id = value.manager.id;
      // eslint-disable-next-line
      this.$set(this.editedItem, 'timezone', this.userTz);
    },
    update() {
      let _vm = this;
      _vm.logout = Boolean(_vm.editedItem.password);
      _vm.$refs.mform.submit().then(newUser => {
        newUser.timezone = newUser.tz;
        _vm.identity.manager.timezone = newUser.tz;
        //eslint-disable-next-line
        newUser.timezone_text = newUser.tz_text;
        //eslint-disable-next-line
        _vm.identity.manager.timezone_text = newUser.tz_text;
        for (let attr in newUser) {
          _vm.identity.user[attr] = newUser[attr];
        }
        let encrypted = _vm.encryptUser(_vm.identity);
        localStorage.setItem('x-simple-tym-user', encrypted);
        if (_vm.logout) {
          setTimeout(() => {
            _vm.clearUserData();
            _vm.$router.go('/login');
            _vm.logout = false;
          }, 1000);
        } else {
          _vm.$root.$emit('user-changed', _vm.identity.user);
        }
      }).catch(() => {});
    }
  },
  watch: {
    identity: {
      handler: function(value) {
        if (value) {
          this.createEditedItem(value);
          this.getCompanyInfo(value.manager.id);
        }
      },
      deep: true
    },
    editedItem: {
      handler: function(value) {
        this.useCompTz = value.timezone === this.companyTz;
      },
      deep: true
    },
    useCompTz: function(value) {
      if (value && this.editedItem.timezone !== this.companyTz) {
        this.$set(this.editedItem, 'timezone', this.companyTz);
      }
      if (!value && this.companyTz !== this.userTz) {
        this.$set(this.editedItem, 'timezone', this.userTz);
      }
    },
    companyTz: function(value) {
      if (value === this.editedItem.timezone) {
        this.useCompTz = true;
      } else {
        this.useCompTz = false;
      }
    }
  }
};

</script>

<style>

</style>
