//import all ressources needed
import * as types from '@/store/types';

//import mixins
import {user} from '@/mixins/user';

//variables declaration
import {_} from 'vue-underscore';
/*eslint no-console: ["error", { allow: ["log"] }] */
let managerMethods = {
    submit() {
        let vm = this;
        return new Promise((resolve, reject) => {
          vm.$validator.validate().then(result => {
              if (result) { // well validated
                  this.showLoading = true;
                  let form = vm.form;
                  if (form.timezone) {
                    let timezoneValues =
                      this.getTimezoneValues(this.form.timezone);
                    if (vm.createManagerOnly) {
                        let identity = vm.getLocalUser();
                        //
                        vm.form.companyId = identity.manager.companyId;
                        vm.form.casUserId = identity.manager.casUserId;
                    }
                    _.extend(form, {
                        tz: timezoneValues[0],
                        // eslint-disable-next-line camelcase
                        tz_text: timezoneValues[1],
                    });
                  }
                  if (vm.processedThroughDialog
                        && !_.isEmpty(vm.oldForm)
                        && form.hasOwnProperty('id')) {
                    _.each(form, (value, key, obj) => {
                        let hasProperty = vm.oldForm.hasOwnProperty(key);
                        if (hasProperty) {
                            if (obj[key] === vm.oldForm[key]) {
                                form = _.omit(form, [key]);
                            }
                        }
                    });
                  }

                   vm.$root.$emit('onFrontLoading');
                  //signup user
                  vm.signup(form).then((result) => {
                      // check if user have login access
                      if (result.status === types.SIGNUP_SUCCESS) {
                          if (!vm.signupOnly) {
                              vm.$store.dispatch('login', { // process login
                                  username: vm.form.email,
                                  password: vm.form.password
                                    || result.user.password
                              }).then(() => {
                                  if (vm.$store.getters.isLoggedIn) {
                                      //store encrypted user data
                                      vm.setLocalUser ();
                                      setTimeout(() => {
                                          vm.clear();
                                          vm.showLoading = false;
                                          // redirect to dashboard
                                          vm.$router.push('/dashboard');
                                          resolve(form);
                                      }, 3000);
                                  } else {
                                      vm.showLoading = false;
                                      let error =
                                        vm.$store.getters.loginMessage;
                                      vm.$notify(error, 'error');
                                      reject(true);
                                  }
                              }).catch(function (error) {
                                  vm.$notify(error, 'error');
                                  reject(true);
                              });
                          } else {
                              vm.$notify(result.message, 'success');
                              vm.$root.$emit('reload');
                              /*setTimeout(() => {
                                  vm.$router.go();
                              }, 1000);*/
                              if (vm.processedThroughDialog) {
                                  vm.$root.$emit('close');
                              }
                              resolve(form);
                          }
                      } else {
                          vm.$notify(result.errors.join('<br/>'), 'error');
                          reject(true);
                      }
                      vm.$root.$emit('offFrontLoading');
                  }).catch(function (error) {
                      vm.$notify(error.message, 'error');
                      reject(true);
                  });
              }
          });
      });
    },
    setPreviewUrl(event) {
     let slug = event.target.value;
     let _vm = this;
      if (slug.length !== 0) {// slug set
        _vm.previewUrl = process.env.VUE_APP_PREVIEW_URL
                             + event.target.value;
      } else {
        _vm.previewUrl = '';
      }
    },
    clear() {
        let _vm = this;
        // reset properties
        _vm.form = {
            timezone: '',
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            password: '',
            confirmation: '',
            companyName: '',
            companySlug: '',
            companyId: 0
        };
        //reset validations
        _vm.$validator.reset();
    }
};

//attempt merge user methods with manager
//so basically, we can reuse user methods only
let methods = _.extend(managerMethods, user.methods);

// reusable properties / methods of manager
export const manager = {
    data() {
        return {
            timezoneList: [],
            timezone: '',
            previewUrl: '',
            signupOnly: false,
            processedThroughDialog: false,
            //specify if required to create company also or not
            createManagerOnly: false,
            dictionary: {
            attributes: {
                firstname: 'Firstname',
                lastname: 'Lastname',
                username: 'Username',
                email: 'Email',
                password: 'Password',
                confirmation: 'Confirmation',
                companyname: 'Company Name',
                companyslug: 'Company Slug'
            },
            custom: {
                firstname: {
                required: () => 'Firstname field is required.'
                },
                lastname: {
                required: () => 'Lastname field is required.'
                },
                email: {
                required: () => 'Email field is required.',
                email: 'Email field must be a valid email.'
                },
                password: {
                required: () => 'Password field is required.',
                min: 'Password should be greater than 6 characters '
                },
                confirmation: {
                required: () => 'Confirmation field is required.',
                confirmed: 'Password field not match with confirmation.'
                },
                timezone: {
                required: () => 'Timezone should be selected.'
                },
                companyname: {
                required: () => 'Company Name field is required.'
                },
                companyslug: {
                required: () => 'Company Slug field is required.'
                }
            }
            }
        };
    },
    methods: methods
};
