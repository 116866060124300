<template>
  <v-container style="min-width: 800px">
    <v-layout row>
      <v-flex xs10 offset-xs1>
        <h1>{{page.name}}</h1>
      </v-flex>
    </v-layout>
        <v-layout row>
        <v-flex xs2 offset-xs9>
            <v-btn
                :style="greenButtonStyle"
                dark
                @click="addDepartment()">
            <v-icon small>add</v-icon>&nbsp;
            {{ labels.create }}
            </v-btn>
        </v-flex>
        <v-dialog
          v-model="dialog"
          :persistent="true"
          width="40%"
          scrollable>
            <custom-card :card-title="formTitle"
                         :card-help-text="dialogHelpText"
                         @save="save" @close="close">
                <v-container>
                    <FormDepartment
                        ref="dform"
                        class="dform"
                        :form="editedItem"
                        :oldName="oldName"
                        :companyId="companyId"
                        :selected-colors="selectedColors"
                    />
                </v-container>
           </custom-card>
        </v-dialog>
        </v-layout>
    <v-layout row>
      <v-flex d-block xs12>
        <v-data-table
            :headers="headers"
            :items="departments"
            :total-items="totalDepartments"
            :loading="loading"
            select-all
            :no-data-text='noRecordsText'
            :rows-per-page-items="selectPagination"
            v-model="selected"
            hide-actions
            class="elevation-1"
            style="border: 5px solid #04796e;" >
                <v-progress-linear
                slot="progress"
                background-color="teal lighten-4"
                color="teal lighten-3"
                indeterminate ></v-progress-linear>
                <template slot="headers" slot-scope="props">
                <tr :key="props.index"
                    style="border-bottom: 5px solid #04796e;">
                    <th class="teal" style="width: 15px">
                    <v-checkbox
                        :input-value="props.all"
                        :indeterminate="props.indeterminate"
                        primary
                        hide-details
                        @click.native="toggleAll"
                        :key="props.index"
                        ></v-checkbox>
                    </th>
                    <th :class="getHeadersConfig(header)"
                        v-for="header in props.headers"
                        :key="header.text"
                        :style="header.style || ''"
                        @click="changeSort(header.sortable, header.value)" >
                    <v-icon small v-if="header.sortable">
                    arrow_upward
                    </v-icon>
                    {{ header.text }}
                </th>
                </tr>
                </template>
                <template slot="items" slot-scope="props">
                <td>
                    <v-checkbox
                        v-model="props.selected" primary
                        hide-details
                        color="teal">
                    </v-checkbox>
                </td>
                <!--td class="text-xs-left">
                    {{ props.index+pagination.number }}
                </td-->
                <td >
                    {{ props.item.name }}
                </td>
                <td >
                  <router-link v-if="getWorkerCount(props.item.id) !== '-'"
                    :to="{name: 'people', query: {department: props.item.name}}"
                    >
                    {{ getWorkerCount(props.item.id) }}
                  </router-link>
                  <span v-else>{{ getWorkerCount(props.item.id) }}</span>
                </td>
                <td class="text-xs-center">
                    <span :class="['theme-color']"
                    :style="'background-color: '+props.item.paperColor" >
                    </span>
                </td>
                <td class="text-xs-right">
                    <div>
                        <v-btn
                            color="warning"
                            @click="editItem(props.item)"
                            fab small dark>
                        <v-icon>{{ icons.edit }}</v-icon>
                        </v-btn>
                        <v-btn
                            color="error"
                            @click="deleteDepartment(props.item)"
                            v-if="canDeleteDepartment(props.item.id)"
                            fab small dark>
                        <v-icon>{{ icons.delete }}</v-icon>
                        </v-btn>
                        <v-btn
                            color="error"
                            v-else
                            :title="canNotDeleteMsg"
                            fab small dark>
                        <v-icon>lock</v-icon>
                        </v-btn>
                    </div>
                </td>
                </template>
                <template slot="footer">
                <tr class="teal"
                    style="border-top: 4px solid rgb(4, 121, 110)" >
                    <td colspan="100%">
                    <v-layout row>
                        <v-flex xs2 class="perPage white--text">
                        Per page :
                        </v-flex>
                        <v-flex xs2>
                        <v-select :items="selectPagination"
                            v-model="pagination.rowsPerPage"
                            :single-line="true"
                            solo
                            @change="resetPagination" ></v-select>
                        </v-flex>
                        <v-flex xs3 offset-xs5>
                        <v-btn
                            color="error"
                            :disabled="disableBulkDelete"
                            v-show="!disableBulkDelete"
                            @click="bulkDelete" >
                            {{ deleteDepartmentText }}
                        </v-btn>
                        </v-flex>
                    </v-layout>
                    </td>
                </tr>
            </template>
        </v-data-table>
      </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          color="teal"
          total-visible="5"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';
import {crud} from '@/mixins/crud';
import {theme} from '@/mixins/theme';
import FormDepartment from '@/components/departments/FormDepartment';
import CustomCard from '@/components/common/custom-card';
import {_} from 'vue-underscore';
import {
  VPagination,
  VCheckbox,
  VProgressLinear,
  VSelect
} from 'vuetify';

export default {
  name: 'Departments',
  mounted() {
    let _vm = this;
    _vm.reset();
    _vm.pagination.rowsPerPage = _vm.$root.settings.defPageSize;
    if (_vm.pagination.rowsPerPage && _vm.companyId) {
      _vm.load();
    }
    _vm.$root.$watch('settings.defPageSize', function(value) {
      if (value) {
        _vm.pagination.rowsPerPage = value;
        if (_vm.companyId) {
          _vm.load();
        }
      }
    });
    //
    _vm.loadDepartmentsCount();
  },
  components: {
    VPagination,
    VCheckbox,
    VProgressLinear,
    VSelect,
    FormDepartment,
    CustomCard
  },
  mixins: [
    crud,
    theme
  ],
  props: ['companyId'],
  data: function() {
    return {
      labels: {
        create: 'Add a Department',
        deleteMany: 'Delete Departments',
        deleteAll: 'Delete All Departments',
      },
      page: {
        name: 'Manage Departments'
      },
      headers: [
        /*{
            text: '#',
            sortable: false,
            value: 'index',
            class: ['text-xs-left']
        },*/
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          class: ['text-xs-left']
        },
        {
          text: 'Number Of Workers',
          value: 'count',
          sortable: false
        },
        {
          text: 'Color',
          value: 'paperColor',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          class: ['text-xs-center']
        }
      ],
      depWorkersCount: [],
      departments: [],
      totalDepartments: 0,
      loading: false,
      noRecordsText: 'No departments was found.',
      selectPagination: [20, 30, 40, 50],
      selected: [],
      //eslint-disable-next-line
      canNotDeleteMsg: 'You can not delete this department because it has workers assigned. Please remove workers before try deletion!!!',
      pagination: {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: null
      },
      deleteDepartmentText: '',
      disableBulkDelete: true,
      dialog: false,
      editedItem: {},
      oldName: ''
    };
  },
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    formTitle() {
      if (this.editedItem.id) {
        return 'Edit Department';
      } else {
        return 'Create new Department';
      }
    },
    dialogHelpText() {
      if (this.editedItem.id) {
        return 'Edit the Department to your company easily';
      } else {
        return 'Add new Department to your company easily';
      }
    },
    pages() {
      if (this.totalDepartments === 0 || this.pagination.rowsPerPage === null) {
        return 0;
      }
      return Math.ceil(this.totalDepartments / this.pagination.rowsPerPage);
    },
    selectedColors() {
      return this.departments.map(d => d.paperColor).filter(c => c);
    }
  },
  methods: {
    getWorkerCount(departmentId) {
      let _vm = this;
      let _department = _.find(_vm.depWorkersCount, dep => {
        return (dep.departmentId === departmentId);
      });
      return (typeof _department !== 'undefined'
               && _department.hasOwnProperty('count'))
              ? _department.count
              : '-';
    },
    canDeleteDepartment(id) {
      let _vm = this;
      let _department = _.find(_vm.depWorkersCount, dep => {
        return (dep.departmentId === id);
      });
      return (typeof _department === 'undefined');
    },
    loadDepartmentsCount() {
      let _vm = this;
      let url = process.env.VUE_APP_API_URL
        + `workers/departments/count/${_vm.companyId}`;
      axios.get(url).then(response => {
        if (response.data.status === 'success') {
          this.depWorkersCount = response.data.data;
        }
      }).catch(err => {
        this.$notify(err.data, err);
      });
    },
    addDepartment () {
      let _vm = this;
      _vm.reset();
      _vm.dialog = true;
    },
    nextPage(page) {
      let _vm = this;
      let rowsPerPage = _vm.pagination.rowsPerPage;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.pagination.number = ((page-1)*rowsPerPage)+1;
        _vm.departments = data;
      });
    },
    bulkDelete() {
      let _vm = this;
      let boxTitle = 'Delete Departments Confirmation';
      let boxMessage = 'Are you sure you want to '
          + _vm.deleteDepartmentText.toLowerCase().trim();
      _vm.disableDeleteNotification = true;
      _vm.$confirm(boxTitle, boxMessage, () => {
        let url = `${_vm.baseApiUrl}companies/department/bulk-delete`;
        axios.post(url, {ids: _vm.selected.map(i => i.id)})
          .then(res => {
            if (res.data.status === 'success') {
              _vm.$notify(res.data.message, 'success');
              _vm.selected = [];
              _vm.load();
            } else {
              _vm.$notify(res.data.message, 'error');
            }
          }).catch(err => {
              _vm.$notify(err.data.message, 'error');
          });
      }, () => {});
    },
    load() {
      let _vm = this;
      _vm.loading = true;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.departments = data;
        _vm.loading = false;
        if (_vm.$refs.dform) {
          _vm.$refs.dform.reset();
        }
      });
    },
    reload () {
      let _vm = this;
      _vm.pagination = {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: _vm.$root.settings.defPageSize
      };
      _vm.load();
    },
    fetchDataFromApi() {
      let _vm = this;
      _vm.loading = true;
      const {page, rowsPerPage} = _vm.pagination;
      return new Promise(resolve => {
        _vm.loadDepartments(_vm.companyId, page, rowsPerPage)
          .then(departments => {
            if (_vm.pagination.sortBy) {
                departments = _vm.sorting(departments);
            }
            if (rowsPerPage > 0) {
                departments = _vm.paginate(departments);
            }
            _vm.loading = false;
            _vm.clear();
            resolve(departments);
          });
      });
    },
    loadDepartments(companyId, page, rowsPerPage) {
      let url = `${this.baseApiUrl}companies/${companyId}/department/`;
      url += `${rowsPerPage}/${page}`;
      return new Promise(resolve => {
        axios.get(url).then(res => {
          if (res.data.status === 'success') {
            resolve(res.data.data);
            this.totalDepartments = res.data.totalCount;
          } else {
            resolve([]);
            this.$notify(res.data.message, 'error');
          }
        }).catch(err => {
          resolve([]);
          this.$notify(err.message, 'error');
        });
      });
    },
    getHeadersConfig(header) {
      let _vm = this;
      let baseConfig = ['column teal white--text',
        header.sortable ? 'sortable' : '',
        (header.value === _vm.pagination.sortBy
          && header.sortable) ? 'active' : '',
      ];
      if (header.class) {
        baseConfig = [...baseConfig, ...header.class];
      }
      let sortableArrow = (_vm.pagination.descending) ? 'desc' : 'asc';
      if (header.sortable) {
        baseConfig.push(sortableArrow);
      }
      return baseConfig;
    },
    clear() {},
    sorting (items) {
      const {sortBy, descending} = this.pagination;
      return items.sort((a, b) => {
        const sortA = a[sortBy];
        const sortB = b[sortBy];
        if (descending) {
          if (sortA < sortB) {
            return 1;
          }
          if (sortA > sortB) {
            return -1;
          }
          return 0;
        } else {
          if (sortA < sortB) {
            return -1;
          }
          if (sortA > sortB) {
            return 1;
          }
          return 0;
        }
      });
    },
    paginate(items) {
      const {rowsPerPage} = this.pagination;
      return items.slice(0, rowsPerPage);
    },
    resetPagination (value) {
      let _vm = this;
      _vm.pagination.page = 1;
      _vm.pagination.rowsPerPage = value;
      _vm.load();
    },
    editItem(item) {
      let _vm = this;
      _vm.editedItem = item;
      _vm.dialog = true;
      _vm.oldName = item.name;
    },
    deleteDepartment(department) {
      let _vm = this;
      let url = `${_vm.baseApiUrl}companies/department/${department.id}`;
      let boxTitle = 'Delete Department Confirmation';
      let boxMessage = `Are you sure you want to delete ${department.name}?`;
      _vm.$confirm(boxTitle, boxMessage, () => {
        axios.delete(url).then(res => {
          if (res.data.status === 'success') {
            _vm.$notify(res.data.message, 'success');
            _vm.load();
          } else {
            _vm.$notify(res.data.message, 'error');
          }
        }).catch(err => {
          _vm.$notify(err.data.message, 'error');
        });
        _vm.selected = [];
      }, () => {});
    },
    reset() {
      let _vm = this;
      _vm.$refs.dform.$validator.reset();
      _vm.oldName = '';
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.departments.slice();
      }
    },
    createDepartment(department) {
      let _vm = this;
      let url = `${_vm.baseApiUrl}companies/${_vm.companyId}/department`;
      axios.post(url, department).then(res => {
        if (res.data.status === 'success') {
          _vm.$notify(res.data.message, 'success');
          _vm.load();
          _vm.close();
        } else {
          _vm.$notify(res.data.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err.data.message, 'error');
      });
    },
    editDepartment(department) {
      let _vm = this;
      let url = `${_vm.baseApiUrl}companies/department/${department.id}`;
      axios.put(url, department).then(res => {
        if (res.data.status === 'success') {
          _vm.$notify(res.data.message, 'success');
          _vm.close();
          _vm.load();
        } else {
          _vm.$notify(res.data.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err.data.message, 'error');
      });
    },
    save() {
      let _vm = this;
      _vm.$refs.dform.$validator.validate().then(result => {
        if (result) {
          if (_vm.editedItem.id) {
            // edit department
            _vm.editDepartment(_vm.editedItem);
          } else {
            // add new department
            _vm.createDepartment(_vm.editedItem);
          }
        }
      });
    },
    close() {
      this.dialog = false;
      this.editedItem = {};
      //this.reset();
    }
  },
  watch: {
    dialog (value) {
      if (value) {
        this.$refs.dform.reset();
      }
    },
    companyId (value) {
      if (value) {
        this.load();
      }
    },
    selected: {
        handler () {
          let _vm = this;
          let length = _vm.selected.length;
          _vm.disableBulkDelete = !length;
          //small adjust for plural && all
          if (length === 1) {
            _vm.deleteDepartmentText = _vm.labels.delete;
          } else if (length === _vm.pagination.rowsPerPage
                      || length === _vm.totalDepartments
                        && length < _vm.pagination.rowsPerPage) {
            _vm.deleteDepartmentText = _vm.labels.deleteAll;
          } else {
            _vm.deleteDepartmentText = _vm.labels.deleteMany;
          }
      },
      deep: true
    }
  }
};

</script>

<style>
.dialogTitle {
  font-weight: bold;
  color: white;
  cursor: default;
}
.dialogHelpText {
  font-style: italic;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.63);
  position: absolute;
  margin-top: 20px;
  cursor: default;
}
.theme-color {
  padding: 15px;
}
</style>
<style scoped>
td a {
  text-decoration: none;
  font-weight: bold;
}
</style>
