<template>
    <div>
        <HomeHeader
            :identity="this.identity"
            :class="[
                'top-bar'
            ]">
        </HomeHeader>
        <v-toolbar style="background: #333">
            <v-menu offset-y min-width="150">
                <v-btn dark
                slot="activator" class="ds-skinny-button">
                View
                </v-btn>
                <v-card>
                <v-list>
                    <v-list-tile v-for="type in types"
                        :key="type.id"
                        @click="currentType = type.id"
                        :disabled="type.disabled">
                        <v-list-tile-content>
                        <v-list-tile-title>
                            {{ type.title }}
                        </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
                </v-card>
            </v-menu>
            <v-btn
                dark
                @click="today()">TODAY</v-btn>
            <v-btn @click="prev()"
                fab dark small depressed>
                <v-icon>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn @click="next()"
                fab dark small depressed>
                <v-icon>keyboard_arrow_right</v-icon>
            </v-btn>
            <span class="date-range-text">{{ currentViewDates }}</span>
            <v-spacer></v-spacer>
            <department-selector
                v-if="hasManagerAccount()"
                :departments="this.departments"
                :customStyle="dayspanDropdownStyle"
                @selected="this.departmentSelected">
            </department-selector>
        </v-toolbar>
        <payroll-week-view
            v-if="currentType===1"
            :dates="dates"
            :items="payrollData"
            :loading="showLoadingBox">
        </payroll-week-view>
        <v-dialog v-model="showLoadingBox" persistent fullscrean width="300" >
          <v-card color="primary" dark >
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';

//components
import HomeHeader from '@/components/layouts/HomeHeader';
import DepartmentSelector from '@/components/common/department-selector';
import PayrollWeekView from '@/components/payroll/weeksView';

import {
  VProgressLinear
} from 'vuetify';

//mixins
import {user} from '@/mixins/user';
import {theme} from '@/mixins/theme';
import {company} from '@/mixins/company';
import {calendar} from '@/mixins/calendar';

import * as moment from 'moment-timezone';
import {_} from 'vue-underscore';

export default {
  name: 'Payroll',

  metaInfo: {
    title: 'SimpleTym - Payroll',
    titleTemplate: null
  },

  mixins: [
    user,
    theme,
    company,
    calendar
  ],

  components: {
    HomeHeader,
    DepartmentSelector,
    PayrollWeekView,
    VProgressLinear
  },

  data () {
    return {
      departments: [],
      workers: [],
      selectedDepartments: [],
      currentType: 1,
      //loading: true,
      showLoadingBox: true,
      types: [
        {
          id: 1,
          title: 'Week',
          disabled: false
        },
        {
          id: 2,
          title: 'Month',
          disabled: true
        }
      ],
      dates: [],
      payrollData: [],
      pagination: {
        sortBy: 'lastName'
      }
    };
  },

  mounted() {
    let _vm = this;
    _vm.companyInfo = _vm.getUser('company');
    _vm.loadWorkers().then(() => {
      //auto-load departments
      _vm.loadDepartments();
      //load view for today
      _vm.today();
    });
    _vm.$root.$on('reload', (pagination) => {
      _vm.pagination = pagination;
        _vm.load();
    });
  },

  watch: {
    dates: {
      handler: function() {
        let _vm = this;
        _vm.load();
      }
    }
  },

  computed: {},

  methods: {
    loadWorkers() {
      let _vm = this;
      let companyId = _vm.companyInfo.id;
      let url = `${_vm.$root.apiUrl}workers/list/company-${companyId}/1000/1`;
      return new Promise((resolve, reject) => {
        axios.get(url).then(response => {
          if (response.data.status === 'success') {
            _vm.workers = response.data.data;
            resolve(_vm.workers);
          }
        }).catch(err => {
          reject(err);
          _vm.$notify(err.data, err);
        });
      });
    },
    loadDepartments() {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      axios({
        url: `${process.env.VUE_APP_API_URL}companies/${companyId}/departments`
      }).then(response => {
        let r = response.data;
        if (r.status === 'success' && r.data) {
          _vm.departments = r.data;
          _vm.selectedDepartments = _vm.departments;
        }
      }).catch(err => {
        _vm.$notify(`Can't load roles: ${err.message}`, 'error');
      });
    },
    departmentSelected(departments) {
      this.selectedDepartments = departments;
      if (departments) {
        this.load();
      }
    },
    isDayView () {
      return (this.currentType === 0);
    },
    isWeekView () {
      return (this.currentType === 1);
    },
    isMonthView () {
      return (this.currentType === 2);
    },
    getPayrollDay() {
      return (this.companyInfo)
                ? this.companyInfo.payrollDay
                : 1;
    },
    //set today dates range
    today () {
      let _vm = this;
      let daysCount = 7;
      let begin;
      if (_vm.isWeekView()) {
        begin = moment().utcOffset(0)
                        .startOf('week')
                        .weekday(_vm.getPayrollDay())
                        .unix();
      }
      _vm.updateView(begin, daysCount);
    },
    prev () {
      let _vm = this;
      let begin;
      let daysCount = 7;
      //next week/month date
      let timestamp = _vm.dates[0] - daysCount*86400;
      if (_vm.isWeekView()) {
        begin = moment(timestamp*1000).utcOffset(0)
                                      .startOf('week')
                                      .weekday(_vm.getPayrollDay())
                                      .unix();
      }
      _vm.updateView(begin, daysCount);
    },
    next () {
      let _vm = this;
      let begin;
      //next week/month date
      let timestamp = _vm.dates[_vm.dates.length-1] + 86400;
      let daysCount = 7;
      if (_vm.isWeekView()) {
        begin = moment(timestamp*1000).utcOffset(0)
                                      .startOf('week')
                                      .weekday(_vm.getPayrollDay())
                                      .unix();
      }
      _vm.updateView(begin, daysCount);
    },
    updateView (timestamp, count) {
      let dates = [];
      let _vm = this;
      dates.push(timestamp);
      for (let i=1; i < count; i++) {
        timestamp += 86400;
        dates.push(timestamp);
      }
      _vm.dates = dates;
    },
    fetchDataFromApi (start, end) {
      let _vm = this;
      let url = process.env.VUE_APP_API_URL + 'workers/payroll';
      //departments
      let departments = _vm.selectedDepartments.map(d => d.id);
      let unassignedDepartment = departments.includes(null);
      departments = departments.filter(id => id != null);
      let workers = _vm.workers.map(w => w.id);
      //timezone
      let timezone = _vm.identity.manager.timezone;
      let reqData = {
        dayStart: start.toISOString(),
        dayEnd: end.toISOString(),
        //roles,
        workers,
        departments,
        unassignedDepartment,
        timezone
      };
      //
      return new Promise((resolve, reject) => {
        axios.post(url, reqData).then(response => {
            if (response.data.status === 'success') {
              resolve(response.data.data);
            } else {
              reject(response);
            }
        }).catch(err => {
          reject(err);
        });
      });
    },
    load () {
      let _vm = this;
      let start = moment(_vm.dates[0]*1000).utcOffset(0);
      let end = moment(_vm.dates[_vm.dates.length-1]*1000).utcOffset(0);
      let company = _vm.companyInfo;
      _vm.showLoadingBox = true;
      _vm.fetchDataFromApi(start, end).then(workers => {
        if (_vm.pagination.sortBy) {
          workers = _vm.sorting(workers);
        }
        end.add(1, 'day');
        _.each(workers, worker => {
          //init start each time
          start = moment(_vm.dates[0]*1000).utcOffset(0);
          let data = _vm.buildWeeklyData(start, end, worker, company);
          worker.durations = data.durations;
          worker.total = {
            hours: data.totalHours,
            hourlyPay: data.hourlyPay,
            overTimeHours: data.overTimeHours,
            totalAmount: data.totalAmount
          };
        });
        _vm.payrollData = workers;
        _vm.showLoadingBox = false;
      });
    },
    sorting (items) {
      const {sortBy, descending} = this.pagination;
      return items.sort((a, b) => {
        const sortA = a[sortBy];
        const sortB = b[sortBy];
        if (descending) {
          if (sortA < sortB) {
            return 1;
          }
          if (sortA > sortB) {
            return -1;
          }
          return 0;
        } else {
          if (sortA < sortB) {
            return -1;
          }
          if (sortA > sortB) {
            return 1;
          }
          return 0;
        }
      });
    },
    // eslint-disable-next-line max-statements
    buildWeeklyData (start, end, worker, company) {
      let durations = [];
      let totalHours = 0;
      let totalAmount = 0;
      let workerRole = _.find(worker.roles, role => role.default === 1);
      let overTimeHours = 0;
      while (start.isBefore(end, 'day')) {
        let currentDate = start.format('YYYY-MM-DD');
        let dateHours = (typeof worker.durations[currentDate] !== 'undefined')
                      ? worker.durations[currentDate]
                      : 0;
        durations.push(dateHours);
        totalHours += dateHours;
        start.add(1, 'day');
      }
      //check overtime hours
      if (totalHours > company.minHoursPerWeek) {
        overTimeHours = totalHours - company.minHoursPerWeek;
        totalHours = company.minHoursPerWeek;
        // eslint-disable-next-line max-len
        totalAmount = (company.minHoursPerWeek + overTimeHours*company.overtimeRate)*workerRole.hourlyPay;
      } else {
        totalAmount = totalHours*workerRole.hourlyPay;
      }

      return {
        totalHours : totalHours.toFixed(2),
        durations,
        hourlyPay: workerRole.hourlyPay,
        overTimeHours : overTimeHours.toFixed(2),
        totalAmount : totalAmount.toFixed(2)
      };
    }
  }
};
</script>

<style scoped>
  .date-range-text {
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
  nav {
    box-shadow: 0 0 0
  }
</style>
