<template>
  <v-container
    id="events-list"
    grid-list-md
    text-xs-center>
      <v-flex
        xs12
        :key="'events-date'+date"
        v-for="(_events, date) in events"
        >
        <v-layout row wrap
          xs12
          v-for="(dayEvent, i) in _events"
          :key="'event-'+i"
          :class="[
            'events-date-row',
            isToday(date) ? 'event-current-day' : ''
          ]"
          :style="{
            'background-color': bgColor(dayEvent.bgColor, date)
          }">
            <v-flex
              :xs8="dayEvent.add || dayEvent.reviewed"
              :xs10="dayEvent.locked"
              @click="debug(dayEvent);"
              :class="[
                'event-row-text',
                //(dayEvent.reviewed) ? 'vertical-text-60' : 'vertical-text-40',
                'vertical-text-40',
                (dayEvent.requested)
                  ? 'requested-row-event'
                  : '',
                eventHasDescription(dayEvent) ? 'has-note' : ''
              ]">
              <a
                href="javascript:;"
                @click="cancelRequest(dayEvent)"
                v-if="allowCancelRequest(dayEvent)">
                <v-icon
                  small
                  :class="[
                    (dayEvent.requested) ? 'white--text' : 'red--text'
                  ]"
                  style="font-size: 17px;">cancel</v-icon>
              </a>
              <v-tooltip
              bottom
              v-if="eventHasDescription(dayEvent)">
                <v-icon
                  slot="activator"
                  class="pin-icon"
                  small
                  dark
                >fas fa-thumbtack</v-icon>
                <span>{{ dayEvent.data.data.description }}</span>
              </v-tooltip>
              <span>
              {{ getMobileCalendarDateFormat(date) }}
                - {{ mapTitle(dayEvent.data) }}
              </span>
              <div
              v-if="dayEvent.requested"
              class="bottom-text">
                <span
                  v-html="mapApprovalText(dayEvent)"></span>
              </div>
              <!--div
              v-if="dayEvent.locked && dayEvent.reviewed"
              class="bottom-text">
                <span>Waiting for the review of the request</span>
              </div-->
            </v-flex>
            <v-flex
              v-if="!dayEvent.locked"
              :xs4="dayEvent.reviewed"
              :class="[
                (dayEvent.add || dayEvent.edit || dayEvent.requested)
                  ? 'vertical-text-40' : ''
              ]">
              <a
                href="javascript:;"
                @click="openAddTime(date)"
                v-if="dayEvent.add && canShowAddButton(date)">
              + Add Time
              </a>
              <a
                href="javascript:;"
                @click="openEditTime(dayEvent)"
                v-if="allowEditButton(dayEvent) && allowReview(dayEvent)">
              <v-icon small>edit</v-icon>Edit
              </a>
              <v-btn
                dark
                depressed
                small
                color="success"
                style="margin-right: 4px;"
                @click="openEditTime(dayEvent)"
                v-if="allowReview(dayEvent) && dayEvent.reviewed">
              <v-icon small>fas fa-eye</v-icon>&nbsp;Review
              </v-btn>
            </v-flex>
            <v-flex
              v-else
              style="margin-top: 8px;">
              <v-icon small>fas fa-lock</v-icon>
            </v-flex>
        </v-layout>
      </v-flex>
  </v-container>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {timezone} from '@/mixins/timezone';
import userUtils from '@/utils/user';
import moment from 'dayspan/node_modules/moment';

var Color = require('color');

let methods = {
  allowEditButton(event) {
    return event.edit || event.requested && !event.canceled;
  },
  allowCancelRequest(event) {
    return event.requested || event.edit || event.reviewed;
  },
  isToday(date) {
    //console.log(this.today, parseInt(date));
    return this.today === parseInt(date);
  },
  hasSchedule(data) {
    return typeof data.data !== 'undefined';
  },
  mapTitle(data) {
    let title = 'OFF';
    let schedule = (this.hasSchedule(data)) ? data.data.meta.sched : {};
    let start = moment(schedule.start*1000).utcOffset(0);
    let end = moment(schedule.end*1000).utcOffset(0);
    if (schedule.hasOwnProperty('Schedule.scheduleStart')
        && schedule['Schedule.scheduleStart']) {
      start = moment(schedule['Schedule.scheduleStart']*1000).utcOffset(0);
      end = moment(schedule['Schedule.scheduleEnd']*1000).utcOffset(0);
    }
    //
    if (this.hasSchedule(data)) {
      title =  `${this.timeFormat(start)} - ${this.timeFormat(end)}`;
    }

    return title;
  },
  mapApprovalText(event) {
    let text = 'Waiting for approval';
    let data = event.data;
    let schedule = (this.hasSchedule(data)) ? data.data.meta.sched : {};
    if (event.canceled) {
      text = 'Cancellation Pending';
    }
    if (schedule.hasOwnProperty('Schedule.scheduleStart')
        && schedule['Schedule.scheduleStart']
        && !event.canceled) {
      let start = moment(schedule.start*1000).utcOffset(0);
      let end = moment(schedule.end*1000).utcOffset(0);
      text += ` - <b>${this.timeFormat(start)} - ${this.timeFormat(end)}</b>`;
    }
    return text;
  },
  openAddTime(timestamp) {
    let day = {
      date: moment(timestamp*1000).utcOffset(0)
    };
    this.$root.$emit('mobile-open-add-event', day);
  },
  openEditTime(event) {
    let slotData = {
      details: {
        meta: event.data.data.meta
      }
    };
    this.$root.$emit('mobile-open-edit-event', slotData);
  },
  allowReview(event) {
    return event.data.schedule &&
      this.isReviewPeriod(event.data.schedule.start);
  },
  cancelRequest(event) {
    let slotData = {
      details: {
        meta: event.data.data.meta
      },
      requested: event.requested
    };
    this.$root.$emit('mobile-cancel-request', slotData);
  },
  // eslint-disable-next-line
  debug(data) {
    // eslint-disable-next-line
    var color = Color(data.bgColor).lighten(0.4);
    //console.log(color.hex());
    console.log(data);
  },
  eventHasDescription(e) {
    let _return = false;
    if (e.data.hasOwnProperty('data')
              && e.data.data.hasOwnProperty('description')
              && e.data.data.description) {
      _return = !!e.data.data.description.length;
    }
    return _return;
  },
  bgColor(color, date) {
    let bgColor = color;
    if (parseInt(date) < this.today) {
      // eslint-disable-next-line
      bgColor = Color(color).darken(0.15).lighten(0.8);
    }
    return bgColor;
  },
  isReviewPeriod(date) {
    let eod = moment().endOf('day');
    let startWeek = this.companyInfo ? this.companyInfo.payrollDay : 1;
    let startAllowWeek = moment().endOf('day')
      .startOf('week')
      .weekday(startWeek);
    if (startAllowWeek > eod) {
      startAllowWeek.subtract(7, 'days');
    }
    return date < eod && date >= startAllowWeek;

  },
  canShowAddButton(date) {
    //manager view or worker view and current to future week only
    return (this.hasManagerAccount() || this.hasWorkerAccount() &&
      this.isReviewPeriod(moment(date * 1000)));
  }
};

//merge methods
methods = Object.assign({}, userUtils, methods);

export default {
  name: 'CalendarDayEventList',

  props: {
    dates: {
      type: Array
    },
    events: {
      type: Object,
      default() {
        return {};
      }
    },
    companyInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data: () => {
    return {
      emptyBoxColor: '#828c94'
    };
  },

  mixins: [
    timezone
  ],

  computed: {
    today() {
      //let timezone = parseInt(this.user.timezone);
      return moment().utcOffset(0).startOf('day').unix();
    },
    calendarStartWeekDay() {
      return (this.company)
                ? this.company.startWeekDay
                : 1;//default to monday if nothing set
    },
    calendarStartPayrollDay() {
      return (this.company)
                ? this.company.payrollDay
                : this.calendarStartWeekDay;
    },
    company() {
      return this.getUser('company');
    },
  },

  methods
};
</script>

<style scoped>
  .event-row {
    box-shadow: none !important;
    text-align: left;
  }
  .events-date-row {
    margin-bottom: 10px !important;
    border-radius: 8px;
    font-size: 14px;
  }
  .event-current-day {
    border: 2px solid #666;
    box-shadow: 0 0px 10px #d809d2;
  }
  a {
    text-decoration: none;
    color: #0d5eb5;
  }
  .noTopPadding {
    padding-top: 0;
  }
  .withTopPadding {
    padding-top: 4px;
  }
  .container.grid-list-md .layout .flex {
    padding: 0px;
  }
  #events-list {
    padding: 15px;
  }
  #events-list .event-row-text {
    text-align: left;
    padding-left: 10px;
  }
  #events-list .has-note {
    padding-left: 8px;
  }
  .vertical-text-40 {
    line-height: 40px;
  }
  .vertical-text-60 {
    line-height: 60px;
  }
  .requested-row-event {
    line-height: 20px;
    margin-top: 5px;
  }
  .requested-row-event .bottom-text {
    font-style: italic;
    text-align: center;
    color: white;
    font-size: 12px;
  }
  .pin-icon {
    margin-left: 5px;
    font-size: 12px;
    transform: rotate(-30deg);
  }
</style>
