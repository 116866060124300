<template>
    <v-form id="role-form">
        <v-layout row>
            <v-flex xs12>
            <v-text-field label="Role Name"
                data-vv-name="name"
                :error-messages="errors.collect('name')"
                v-model="form.name"
                v-validate="fieldValidateRules"
                required >
            </v-text-field>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
            <color-picker ref="colorPicker"
                          v-model="form.paperColor"
                          selected-colors="selectedColors"
                          label="Choose Color Paper"></color-picker>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
            <v-select
                label="Minimum Staff Required"
                v-model="form.minStaff"
                :items="minStaffOpts" >
                <template slot="selection" slot-scope="data">
                {{ data.item ? data.item : 'n/a' }}
                </template>
                <template slot="item" slot-scope="data">
                {{ data.item ? data.item : 'n/a' }}
                </template>
            </v-select>
            </v-flex>
        </v-layout>
    </v-form>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {crud} from '@/mixins/crud';
import colorPicker from '@/components/common/colorpicker';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'formRole',
  components: {
    colorPicker
  },
  mixins: [
    crud
  ],
  props: [
    'form',
    'companyId',
    'oldName',
    'minStaffOpts',
    'selectedColors'
  ],
  data() {
    return {
      dictionary: {
        attributes: {
          name: 'Role Name'
        },
        custom: {
          name: {
            required: () => 'The Role Name field is required',
          }
        }
      }
    };
  },
  mounted() {
    let _vm = this;
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
  },
  computed: {
    fieldValidateRules() {
      let rules = 'required|min:2|role-unique:';
      rules += this.companyId + ',';
      if (this.oldName) {
        rules += this.oldName;
      }
      return rules;
    }
  },
  methods: {
    reset() {
      this.$refs.colorPicker.reset();
    }
  }
};
</script>
