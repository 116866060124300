import axios from 'axios';
import ip from 'ip';
import data2blob from 'vue-image-crop-upload/utils/data2blob.js';

export const worker = {
  data() {
    return {
      timezoneList: [],
      timezone: '',
      previewUrl: '',
      processedThroughDialog: false
    };
  },
  methods: {
    submit(managerId) {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.$validator.validate().then(result => {
          if (result) {
            vm.showLoading=true;
            let timezoneValues = vm.getTimezoneValues(vm.form.timezone);
            vm.form.managerId = managerId;
            vm.form.tz = timezoneValues[0];

            // eslint-disable-next-line
            vm.form.tz_text = timezoneValues[1];
            vm.$root.$emit('onFrontLoading');

            let preCall = form => {
                if (vm.form.casUserId) {
                  return vm.apiUploadAvatar(form);
                } else {
                  return Promise.resolve(form);
                }
            };

            let postCall = form => {
              if (!vm.form.casUserId) {
                return vm.apiUploadAvatar(form);
              } else {
                return Promise.resolve(form);
              }
            };

            let postUpdate = form => {
              if (!vm.form.casUserId && vm.img) {
                return vm.apiCreateOrUpdateWorker(form);
              } else {
                return Promise.resolve(form);
              }
            };

            preCall(vm.form).then(form => {
              let _form = Object.assign({}, form, {
                // eslint-disable-next-line max-len
                defaultStartTime: vm.getTimestampFromTime(vm.form.defaultStartTime),
                defaultEndTime: vm.getTimestampFromTime(vm.form.defaultEndTime)
              });
              return vm.apiCreateOrUpdateWorker(_form);
            }).then(result => {
              if (result.data.status === 'success') {
                vm.$notify(result.data.message, 'success');
                return postCall(result.data.worker);
              } else {
                let errMsg = result.data.message || result.data.errors;
                if (errMsg.join) {
                  errMsg = errMsg.join('<br/>');
                }
                vm.$notify(errMsg, 'error');
                return Promise.reject({response: result});
              }
            }).then(form => {
              vm.$root.$emit('worker-avatar-changed', form);
              return postUpdate(form);
            }).then(() => {
                vm.$root.$emit('offFrontLoading');
                vm.$root.$emit('close');
                vm.$root.$emit('worker-reload');
                resolve(true);
            }).catch(err => {
              err = err.response.data;
              let errMsg = err.message || err.errors;
              vm.$notify(errMsg, 'error');
              vm.$root.$emit('offFrontLoading');
              reject(err);
            });
          }
        });
      });

    },

    apiUploadAvatar(form) {
      let vm = this;
      return new Promise(resolve => {
        if (vm.img) {
          let formData = new FormData();
          // append the avatar file
          formData.append('avatar', data2blob(vm.img.dataUrl, vm.img.mime),
            vm.img.name);
          axios({
            method: 'POST',
            url: process.env.VUE_APP_CAS_API_URL
              + `users/${form.casUserId}/avatar/upload/`,
            data: formData,
            headers: {
              'Authorization': 'Bearer ' + vm.getUserToken()
            }
          }).then(result => {
            if (result.data.status === 'success') {
              form.avatar = result.data.avatar;
            }
            resolve(form);
          }).catch(() => {
            resolve(form);
          });
        } else {
          resolve(form);
        }
      });
    },

    apiCreateOrUpdateWorker(form) {
      let vm = this;
      let isUpdate = (form.hasOwnProperty('id') && form.id > 0);
      let callUrl = `${process.env.VUE_APP_API_URL}workers/` +
        (isUpdate ? `update/${form.id}` : 'create');
     return axios({
        method: (isUpdate ? 'patch' : 'post'),
        url: callUrl,
        data: form,
        headers: {
          'X-Ip-Address': ip.address(),
          'Authorization': 'Bearer ' + vm.getUserToken()
        }
      });
    }
  }
};
