/*eslint no-console: ["error", { allow: ["log"] }] */

import axios from 'axios';
import user from './user';


export default {
  checkSlugUnique: {
    getMessage() {
      return 'Company Slug is already used.';
    },
    validate(value) {
      if (value) {
        return new Promise(resolve => {
          var localUser = user.getLocalUser();
          var callUrl = process.env.VUE_APP_API_URL
            + 'companies/unique/'
            + value;
          //
          if (localUser.hasOwnProperty('user')
            && localUser.manager) {
            callUrl += '/' + localUser.manager.companyId;
          }
          axios({
            method: 'get',
            url: callUrl,
          })
            .then(function (response) {
              resolve({
                valid: response.data.unique
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      } else {
        return false;
      }
    }
  },

  userUnique: {
    getMessage(field, args) {
      if (args[0] === 'email') {
        field = 'Email';
      }
      return `The ${field} is already used`;
    },
    validate(value, args) {
      let [fieldName, oldValue] = args;
      if (value && value.trim() !== oldValue) {
        return new Promise(resolve => {
          axios.get(process.env.VUE_APP_CAS_API_URL
            + `users/check/unique/${fieldName}/${value}`)
            .then(result => {
              if (result.data.status === 'success') {
                resolve(result.data.data.unique);
              } else {
                resolve(true);
              }
            }).catch(err => {
              console.log(err);
            });
        });
      } else {
        return Promise.resolve(true);
      }
    }
  },

  roleUnique: {
    getMessage() {
      return 'The Role already exist.';
    },
    validate(value, args) {
      let [companyId, oldValue] = args;
      if (value && value.trim() !== oldValue) {
        return new Promise(resolve => {
          let url = process.env.VUE_APP_API_URL
            + `companies/${companyId}/unique/role/${value}`;
          axios.get(url).then(res => {
            if (res.data.status === 'success') {
              resolve(res.data.unique);
            } else {
              resolve(true);
            }
          }).catch(() => {
          });
        });
      } else {
        return Promise.resolve(true);
      }
    }
  },

  departmentUnique: {
    getMessage() {
      return 'The Department already exist.';
    },
    validate(value, args) {
      let [companyId, oldValue] = args;
      if (value && value.trim() !== oldValue) {
        return new Promise(resolve => {
          let url = process.env.VUE_APP_API_URL
            + `companies/${companyId}/unique/department/${value}`;
          axios.get(url).then(res => {
            if (res.data.status === 'success') {
              resolve(res.data.unique);
            } else {
              resolve(true);
            }
          }).catch(() => {
          });
        });
      } else {
        return Promise.resolve(true);
      }
    }
  },

  workerSlugUnique: {
    getMessage() {
      return 'The slug is already exist.';
    },
    validate(value, args) {
      let [companyId, oldValue] = args;
      if (value && value.trim() !== oldValue) {
        return new Promise(resolve => {
          let url = process.env.VUE_APP_API_URL
            + `workers/slug/unique/${companyId}/${value}`;
          axios.get(url).then(res => {
            if (res.data.status === 'success') {
              resolve(res.data.unique);
            } else {
              resolve(true);
            }
          }).catch(() => {
            resolve(true);
          });
        });
      } else {
        return Promise.resolve(true);
      }
    }
  }
};

