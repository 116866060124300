<template>
  <v-form style="width: 100%">
    <v-card
      v-if="isMobile"
      style="box-shadow: none !important">
      <v-toolbar dark color="primary">
        <v-btn round dark color="orange lighten-1" @click.native="close">
          <v-icon>navigate_before</v-icon>
          <span class="pr-2">
            Back
          </span>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="toolbar-items-date">
          <div class="mt-1 title"> {{ formMobileTitle }} </div>
          <span> {{ date }}</span>
        </div>
      </v-toolbar>
        <v-container class="noVerticalPadding" grid-list-md>
          <v-layout
            row wrap
            v-if="!isEdit"
            v-show="!hideRoleField">
            <v-flex xs12>
              <v-autocomplete
                  v-validate="'required'"
                  v-model="selectedRole"
                  :color="inputColor"
                  :items="workerRoles"
                  :filter="searchFilter"
                  data-vv-name="defaultRole"
                  :error-messages="errors.collect('defaultRole')"
                  item-text="name"
                  item-value="id"
                  label="Choose a Role"
                  no-data-text="No roles assigned to this worker."
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-show="hideRoleField && workerId">
            <v-flex xs12 style="text-align: center;">
              <v-chip
              v-for="role in workerRoles"
              small
              :key="role.roleId"
              :style="{
                'background-color': role['paperColor']
              }"
              text-color="white">
                <v-avatar>
                  <v-icon>label</v-icon>
                </v-avatar>
                <b>{{ role['name'] }}</b>
              </v-chip>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex x12>
              <custom-time-picker @changed="saveStartTime"
                                  :time="startTime"
                                  :is24Format="is24Format"
                                  label="Start Time">
              </custom-time-picker>
              <div class="v-messages theme--light error-text">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ mapError('startTime') }}
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>

            <v-flex xs12>

              <custom-time-picker @changed="saveEndTime"
                                  :time="endTime"
                                  :is24Format="is24Format"
                                  :min="startTime"
                                  label="End Time">
              </custom-time-picker>

              <div class="v-messages theme--light error-text">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ mapError('endTime') }}
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-textarea
                name="note"
                label="Reason for Changes"
                rows="2"
                v-model="eventDescription"
                :error-messages="errors.collect('description')"
                v-validate="isTimeChanged && isEdit ? 'required' : ''"
                data-vv-name="description"
                hint="Please provide more details to explain the reason."
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout
            style="text-align: center;">
            <v-flex xs12>
              <v-btn
                dark
                color="success"
                @click="save">
              {{ buttonSaveDisplayText }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
    </v-card>
    <custom-card v-else :cardTitle="formTitle" :cardHelpText="dialogHelpText"
      @save="save" @close="close">
        <v-container
          class="noVerticalPadding" grid-list-md>
          <v-layout row wrap v-if="!isEdit" v-show="selectWorker">
            <v-flex xs12>
              <v-autocomplete
                  v-validate="'required'"
                  v-model="selectedWorker"
                  :color="inputColor"
                  :items="workers"
                  :filter="searchFilter"
                  data-vv-name="defaultWorker"
                  :error-messages="errors.collect('defaultWorker')"
                  label="Choose a Worker"
                  :disabled="Boolean(workerId)"
                  prepend-icon="person"
                  return-object
              >
              <template slot="selection" slot-scope="data">
                  <v-avatar>
                  <img
                      :src="getAvatar(data.item.avatar)"
                      alt="Avatar"
                      class="w_picture"
                      >
                  </v-avatar>
                  <span
                  :class="[
                      'subheading',
                      'font-weight-medium',
                      'text-capitalize',
                      'px-2'
                  ]">
                  {{ data.item.firstName + ' ' + data.item.lastName }}
                  </span>
              </template>
              <template slot="item" slot-scope="data">
                  <v-avatar>
                  <img
                      :src="getAvatar(data.item.avatar)"
                      alt="Avatar"
                      class="w_picture">
                  </v-avatar>
                  <span
                  :class="[
                      'subheading',
                      'font-weight-medium',
                      'text-capitalize',
                      'px-2'
                  ]">
                  {{ data.item.firstName + ' ' + data.item.lastName }}
                  </span>
              </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout
            row wrap
            v-show="!hideRoleField">
            <v-flex xs12>
              <v-autocomplete
                  v-validate="'required'"
                  v-model="selectedRole"
                  v-show="selectedWorker"
                  :color="inputColor"
                  :items="workerRoles"
                  :filter="searchFilter"
                  data-vv-name="defaultRole"
                  :error-messages="errors.collect('defaultRole')"
                  item-text="name"
                  item-value="id"
                  label="Choose a Role"
                  no-data-text="No roles assigned to this worker."
                  prepend-icon="group"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-show="hideRoleField && workerId">
            <v-flex xs12 style="text-align: center;">
              <v-chip
              v-for="role in workerRoles"
              small
              :key="role.roleId"
              :style="{
                'background-color': role['paperColor']
              }"
              text-color="white">
                <v-avatar>
                  <v-icon>label</v-icon>
                </v-avatar>
                <b>{{ role['name'] }}</b>
              </v-chip>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="!isEdit">
            <v-flex xs12>
              <v-text-field
                slot="activator"
                v-model="date"
                label="Picker in menu"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs6>
              <vue-ctk-date-time-picker
                position="top"
                placeholder="Start time"
                label="Start time"
                v-model="startTime"
                :format="formatForTime"
                :formatted="formatForTime"
                output-format="HH:mm"
                :minute-interval="5"
                input-size="sm"
                :only-time="true"
                v-validate="'required'"
                data-vv-name="startTime"
                class="timepicker"
                color="#009688"
                style="width: 100%;"
                noClearButton
                > </vue-ctk-date-time-picker>
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ mapError('startTime') }}
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex xs6>
              <vue-ctk-date-time-picker
                position="top"
                placeholder="End time"
                label="Start time"
                v-model="endTime"
                :format="formatForTime"
                :formatted="formatForTime"
                output-format="HH:mm"
                :minute-interval="5"
                input-size="sm"
                :only-time="true"
                v-validate="'required'"
                data-vv-name="endTime"
                color="#009688"
                class="timepicker"
                style="width: 100%;"
                noClearButton
                ></vue-ctk-date-time-picker>
              <div class="v-messages theme--light error-text">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ mapError('endTime') }}
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-textarea
                name="note"
                label="Event Notes"
                rows="2"
                v-model="eventDescription"
                hint="Write some notes for this event."
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </custom-card>
    </v-form>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {crud} from '@/mixins/crud';
import {timezone} from '@/mixins/timezone';
import {picture} from '@/mixins/picture';
import * as moment from 'moment-timezone';

let methods = {
  saveStartTime(time) {
    this.$set(this, 'startTime', time);
  },
  saveEndTime(time) {
    this.$set(this, 'endTime', time);
  },
  reset() {
    /*this.startTime = this.endTime = this.date = this.selectedWorker
      = this.selectedRole = null;
    this.date = this.selectedWorker = this.selectedRole = null;
    this.workerRoles = [];
    this.eventDescription = '';*/
    this.$validator.reset();
  },
  mapError (index) {
    let error = this.errors
                    .first(index);
    return (error) ? error.replace(/([A-Z])/g, ' $1')
                          .replace(/[0-9]/g, '')
                          .trim()
                    : '';
  },
  close() {
    this.reset();
    this.$emit('cancel');
  },
  checkCompanyHours() {
    let _vm = this;
    return new Promise((resolve, reject) => {
      let compStart, compEnd, start, end;
      if (_vm.companyDayHours) {
        compStart = moment(_vm.companyDayHours.timeStart,
          this.getDefaultTimeFormat());
        compEnd = moment(_vm.companyDayHours.timeEnd,
          this.getDefaultTimeFormat());
        start = moment(_vm.startTime, this.getDefaultTimeFormat());
        end = moment(_vm.endTime, this.getDefaultTimeFormat());
      }
      if (!_vm.companyDayHours || compStart > start || compEnd < end) {
        // If selected timerange are not in company working hours
        // we should to show the confirmation box.
        _vm.$confirm(_vm.boxTitle, _vm.boxMessage,
          () => resolve(),
          () => reject());
      } else {
        resolve();
      }
    });
  },
  save () {
    let _vm = this;
    //eslint-disable-next-line
    let isValidTimes = () => {
      return _vm.$validator;
    };
    _vm.$validator.validateAll().then(isValid => {
      //if (isValid || (_vm.isEdit && isValidTimes())) {
      if (isValid) {
        let tz = _vm.user.timezone;
        let managerId = _vm.identity.hasOwnProperty('manager')
                          ? _vm.identity.manager.id
                          : _vm.identity.worker.managerId;
        let timeToSec = time => {
          time = moment(time, _vm.getDefaultTimeFormat());
          return time.hours() * 60 * 60 + time.minutes() * 60;
        };
        let formatTime = time => {
          return _vm.setUtcTime(time, tz)
            .format(_vm.getDefaultTimeFormat());
        };
        //eslint-disable-next-line max-statements
        this.checkCompanyHours().then(() => {
          let sched = {
            timeStart: formatTime(_vm.startTime),
            description: _vm.eventDescription,
            timeEnd: formatTime(_vm.endTime),
            duration: (timeToSec(_vm.endTime)-timeToSec(_vm.startTime))/60/60,
            managerId
          };
          if (_vm.isEdit) {
            sched.id = _vm.schedule.sched.id;
          }
          sched.date = _vm.updateDate();
          if (_vm.selectedWorker) {
            sched.workerId = _vm.selectedWorker.id;
          }
          if (_vm.selectedRole) {
            sched.roleId = _vm.selectedRole;
          }
          if (_vm.identity.hasOwnProperty('worker')) {
            sched.companyId = _vm.companyInfo.id;
          }
          if (_vm.isMobile && _vm.isEdit) {
            sched.reviewed = 1;
          }
          if (_vm.schedule.hasOwnProperty('sched')
                && _vm.schedule.sched.repeatDayId) {
            sched.oldRepeatDayId = _vm.schedule.sched.repeatDayId;
          }
          if (_vm.schedule.hasOwnProperty('sched')
              && _vm.schedule.sched.requested) {
            sched.requested = _vm.schedule.sched.requested;
          }
          _vm.$emit('save', sched);
        }).catch(err => {
          console.log(err);
        });
      }
    });
  },
  updateDate () {
    let _vm = this;
    let tz = parseFloat(_vm.user.timezone);
    let dateString = _vm.schedule.date.format('gggg-MM-DD')
      + 'T' + _vm.startTime;
    let date = moment.utc(dateString).utcOffset(-tz);
    date.hours(0);
    date.minute(0);
    return moment.utc(date.toObject()).unix();
  },
  searchFilter (item, queryText) {
    const name = item.name.toLowerCase();
    const searchText = queryText.toLowerCase();
    return name.indexOf(searchText) > -1;
  }
};

var SmartPhone = require('detect-mobile-browser')(false);

//merge methods
methods = Object.assign({}, methods, SmartPhone);
delete methods['userAgent'];//remove user agent property

import {
  VAutocomplete,
  VChip,
  VDivider,
  VSubheader,
} from 'vuetify';

import CustomCard from '@/components/common/custom-card';
import CustomTimePicker from '@/components/common/time-picker';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidator: {
    validator: 'new'
  },
  name: 'ScheduleForm',
  components: {
    VAutocomplete,
    VChip,
    VDivider,
    VSubheader,
    CustomTimePicker,
    CustomCard
  },
  mixins: [
    crud,
    timezone,
    picture
  ],
  props: {
    isMobile: {
      type: Boolean,
      default() {
        return false;
      }
    },
    schedule: {
      type: Object,
      default() {
        return {};
      }
    },
    identity: {
      type: Object
    },
    workers: {
      type: Array
    },
    roles: {
      type: Array
    },
    companyInfo: {
      type: Object
    },
    workerId: {
      type: [Number, Boolean]
    },
    selectWorker: {
      type: Boolean,
      default() {
        return true;
      }
    },
    formatForTime: {
      type: [String],
      default() {
        return 'HH:mm';
      }
    },
    is24Format: {
      type: Boolean
    }
  },

  data() {
    return {
      startTimeMobilePickerModal: false,
      endTimeMobilePickerModal: false,
      startTime: null,
      oldStartTime: null,
      oldEndTime: null,
      endTime: null,
      eventDescription: '',
      date: null,
      selectedWorker: null,
      selectedRole: null,
      workerRoles: [],
      boxTitle: 'Add Schedule Confirmation',
      boxMessage: 'You are scheduling this on a day your company is not open. '
        + 'Are you sure you want to do this?',
      dictionary: {
        attributes: {
          defaultRole: 'Role',
          description: 'Note'
        },
        custom: {
          defaultRole: {
            required: () => 'Role field is required.'
          },
          description: {
            required: () => 'Note field is required.'
          }
        },
      }
    };
  },

  mounted() {
    // update vee-validate message
    this.$validator.localize('en', this.dictionary);
  },

  computed: {
    inputColor () {
      return this.isMobile ? 'blue lighten-1' : 'teal';
    },
    isEdit () {
      return this.schedule && this.schedule.sched;
    },
    formTitle () {
      return this.isEdit ? 'Edit Schedule' : 'Add Time';
    },
    formMobileTitle () {
      let _vm = this;
      let title = 'Review';
      //requested title
      if (!_vm.isEdit) {
        title = this.formTitle;
      } else if (_vm.isEdit && _vm.schedule.sched.requested) {
        title = 'Edit Time';
      }
      return title;
    },
    buttonSaveDisplayText () {
      let _vm = this;
      let sched = _vm.schedule.sched;
      let text = _vm.labels.edit;
      if (!_vm.isEdit) {
        text = _vm.labels.save;
      } else if (_vm.isEdit && sched.hasOwnProperty('reviewed')) {
        //console.log(sched.reviewed);
        let reviewed = Boolean(sched.reviewed);
        if (!reviewed) {
          text = 'Accept';
        }
      }
      return text;
    },
    dialogHelpText () {
      return this.isEdit ? 'Edit the specific schedule.' :
        'Add new schedule.';
    },
    pickerOptions () {
      return {
        start: '00:00',
        end: '23:59',
        step: '00:15',
      };
    },
    endPickerOptions () {
      return {
        selectableRange: `${this.startTime}:00 - 23:59:00`
      };
    },
    companyDayHours () {
      if (this.companyInfo && this.schedule.date) {
        let compHrs = this.companyInfo.workingHours.find(wh => {
          return wh.weekday === this.schedule.date.day();
        });
        if (!compHrs) {
          return null;
        }
        return compHrs;
      }
      return null;
    },
    hideRoleField() {
      return this.workerRoles.length === 1 && this.workerId;
    },
    user() {
      let _vm = this;
      return _vm.identity.hasOwnProperty('manager')
                ? _vm.identity.manager
                : _vm.identity.worker;
    },
    isTimeChanged() {
      return (this.startTime !== this.oldStartTime)
        || (this.endTime !== this.oldEndTime);
    },
    isOutside() {
      return (this.startTime < this.oldStartTime)
        || (this.endTime > this.oldEndTime);
    }
  },

  methods,

  watch: {
    // eslint-disable-next-line max-statements
    schedule(value) {
      if (value) {
        let _vm = this;
        if (_vm.companyDayHours) {
          _vm.startTime = _vm.companyDayHours.timeStart;
          _vm.endTime = _vm.companyDayHours.timeEnd;
        }

        if (value.sched && value.sched.start) {
          _vm.startTime = moment(value.sched.start*1000)
              .utcOffset(0)
              .format(_vm.getDefaultTimeFormat());
        }

        if (value.sched && value.sched.end) {
          _vm.endTime = moment(value.sched.end*1000)
              .utcOffset(0)
              .format(_vm.getDefaultTimeFormat());
        }

        //set old start/end time
        _vm.oldStartTime = _vm.startTime;
        _vm.oldEndTime = _vm.endTime;

        if (value.date) {
          _vm.date = moment.utc(value.date.toObject())
            .format(_vm.getReadableDateFormat());
        }

        /**
         * calendar worker view
         * when workerid set, auto-select worker field
         */
        if (_vm.workerId) {
          _vm.selectedWorker = _vm.workers.find(w => w.id === _vm.workerId);
        } else if (_vm.isEdit) {
          _vm.selectedWorker = _vm.workers
            .find(w => w.id === _vm.schedule.sched.workerId);
        } else {
          _vm.selectedWorker = null;
        }

        if (value.sched) {
          _vm.eventDescription = value.sched.description;
        } else {
          _vm.eventDescription = '';
        }
      }
    },

    selectedWorker(value) {
      let _vm = this;
      if (value) {
        _vm.workerRoles = _vm.roles.filter(
          r => value.roles.find(rf => rf.roleId === r.id));
        //
        if (_vm.workerRoles.length === 1) {
          _vm.selectedRole = _vm.workerRoles[0].id;
        } else if (_vm.isEdit) {
          let role = _vm.workerRoles
            .find(r => r.id === _vm.schedule.sched.roleId);
          if (role) {
            _vm.selectedRole = role.id;
          }
        }
      }
    },

    companyDayHours(value) {
      let _vm = this;
      if (value) {
        if (!_vm.startTime) {
          _vm.startTime = value.timeStart;
        }
        if (!_vm.endTime) {
          _vm.endTime = value.timeEnd;
        }
      }
    }
  }
};

</script>

<style scoped>
  .w_picture {
    width: 25px;
    height: 25px;
  }
  .v-messages__message::first-letter{
    text-transform: uppercase;
  }
  .toolbar-items-date {
    text-align: right;
  }

  .toolbar-items-date span {
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    color: #ece8e8;
  }
</style>
