<template>
    <div>
        <HomeHeader></HomeHeader>
        <HomeLeftContent></HomeLeftContent>
        <div class="right-content">
            <v-card
              class="elevation-6 right"
              style="width: 540px; top:80px; right: 25px;"
            >
              <v-toolbar dark color="warning">
                <v-toolbar-title>
                  {{ page.name }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items
                  class="hidden-sm-and-down"
                  style="margin-top: 30px;"
                >
                  <v-progress-circular
                    :value="loadingValue"
                    size="25"
                    indeterminate
                    v-show="showLoading"
                  ></v-progress-circular>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-form>
                    <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-validate="'required'"
                            v-model="form.companyName"
                            label="Business Name"
                            prepend-icon="business"
                            data-vv-name="companyname"
                            :error-messages="errors.collect('companyname')"
                          ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                            v-validate="'required|email|user-unique:email'"
                            v-model="form.email"
                            :error-messages="errors.collect('email')"
                            label="E-mail Address"
                            data-vv-name="email"
                            prepend-icon="email"
                            required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="submit"
                    color="warning"
                    dark
                >Start</v-btn>
              </v-card-actions>
            </v-card>
        </div>
    </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
// import all components
import HomeHeader from '@/components/layouts/HomeHeader';
import HomeLeftContent from '@/components/layouts/HomeLeftContent';

// import others ressources
import {
  VForm,
  VInput,
  VTextField,
  VCheckbox,
  VDivider,
  VProgressCircular,
  VSelect
} from 'vuetify';

// import all mixins
import {user} from '@/mixins/user';
import {manager} from '@/mixins/manager';
import {company} from '@/mixins/company';
import {timezone} from '@/mixins/timezone';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'login',
  metaInfo: {
    title: 'SimpleTym - Get started now',
    titleTemplate: null
  },
  mixins: [
    user,
    company,
    manager,
    timezone
  ],
  components: {
    HomeHeader,
    HomeLeftContent,
    VForm,
    VInput,
    VTextField,
    VCheckbox,
    VDivider,
    VProgressCircular,
    VSelect
  },
  data: () => ({
    form: {
      email: '',
      confirmation: '',
      companyName: '',
      completed: 0
    },
    previewUrl: '',
    loadingValue: 0,
    showLoading: false,
    page: {
      name: 'Get started now'
    },
    signupOnly: false
  }),
  created () {
    this.clearUserData();
    this.$root.$on('reload', () => {
      this.showLoading = false;
    });
  },
  watch: {
  },
  computed: {
    companySlug() {
      return this.form.companySlug;
    }
  },
  mounted () {
    let vm = this;
    // init validator
    vm.$validator.localize('en', this.dictionary);
    //
    /*vm.$root.$watch('settings.defGuestTz', function(value) {
      if (value) {
        vm.setDefaultTimezone();
      }
    });*/
    // Add default company timezone
    let timezoneText = 'Pacific Time (US & Canada)';
    let timezone = -8;
    if (vm.checkDst('America/Dawson')) {
      timezone = vm.getDstOffset(timezone);
    }
    vm.form['Company.timezone'] = timezone;
    vm.form['Company.timezone_text'] = timezoneText;
    //vm.setDefaultTimezone();
  },
  methods: {
  }
};
</script>
<style scoped>
</style>
