<template>
  <span>
    <v-tooltip
      bottom
      slot="activator"
    >
      <v-btn
        slot="activator"
        @click="openTuduModal"
      >
        <v-icon dark left>add</v-icon>
        Add New
      </v-btn>
      <span>Tudu Module</span>
    </v-tooltip>
    <v-dialog v-model="openModal" max-width="650px">
      <v-card>
        <v-card-title class="teal">
          <span class="headline white--text">Create Tudu Board</span>
        </v-card-title>
        <v-card-text class="text-xs-center">
          <v-text-field v-model="boardSlug" label="Board Slug"></v-text-field>
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
            height="2"
            color="success"
          >
          </v-progress-linear>
        </v-card-text>
        <v-card-actions v-if="!loading" class="teal">
          <v-btn color="blue lighten-4" @click="create" flat>create</v-btn>
          <v-btn color="blue lighten-4" @click="close" flat>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import axios from 'axios';
import {
  VDialog,
  VProgressLinear
} from 'vuetify';

export default {
  name: 'TuduCreateModal',
  props: ['identity'],
  components: {
    VDialog,
    VProgressLinear,
  },
  data: () => ({
    openModal: false,
    boardSlug: '',
    loading: false,
  }),
  methods: {
    openTuduModal() {
      this.openModal = true;
    },
    create() {
      this.loading = true;
      let url = `${process.env.VUE_APP_TUDU_API_URL}/board`;
      let params = {
        company: this.identity.company.slug,
        slug: this.boardSlug,
        creator: this.identity.user.email,
      };
      axios.post(url, params).then(response => {
        if (response.data.success) {
          this.$notify(
            `The board was created successfull!
            Check your email for board link.`, 'success');
          this.$emit('create');
        } else {
          throw response.data;
        }
      }).catch(error => {
        //eslint-disable-next-line
        console.error(error);
        this.$notify('Error on creating the board. Please try again later.',
          'error');
      }).finally(() => {
        this.close();
        this.loading = false;
      });
    },
    close() {
      this.reset();
      this.openModal = false;
    },
    reset() {
      this.boardSlug = '';
    }
  }
};

</script>

<style>

</style>
