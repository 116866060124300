<template>
    <v-form id="worker-form"
      >
        <div
          class="dividerWithText"
          style="margin-top: 10px"
        >
          <span class="dividerText">
              Personal Details
          </span>
          <v-divider></v-divider>
        </div>
        <v-flex xs12>
            <v-layout row xs12 wrap>
                <v-flex xs12 style="max-width: 48%;">
                    <v-text-field
                        v-validate="'required'"
                        v-model="form.firstName"
                        :error-messages="errors.collect('firstname')"
                        label="Firstname"
                        :color="inputColor"
                        data-vv-name="firstname"
                        prepend-icon="person_pin"
                        required
                        ></v-text-field>
                </v-flex>
                <v-flex xs12 style="max-width: 4%;"></v-flex>
                <v-flex xs12 style="max-width: 48%;">
                    <v-text-field
                        v-validate="'required'"
                        v-model="form.lastName"
                        :error-messages="errors.collect('lastname')"
                        label="Lastname"
                        :color="inputColor"
                        data-vv-name="lastname"
                        prepend-icon="person_pin"
                        required
                        ></v-text-field>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-layout class="slug-block">
          <v-flex v-show="!editSlug">
            <p class="user-url teal--text pt-3 pl-3">
              {{generatedUrl}}
              <v-btn
                color="amber"
                dark fab small @click="editSlug=true">
                  <v-icon>{{ icons.edit }}</v-icon>
              </v-btn>
            </p>
          </v-flex>
          <v-flex xs6 v-show="editSlug">
            <p class="user-url text-xs-right teal--text pt-4 pl-3" >
              {{mainUrl}}/
            </p>
          </v-flex>
          <v-flex
            xs6
            class="pl-0"
            v-show="editSlug"
            style="margin-left: 2px;">
            <v-layout>
              <v-flex xs10>
                <v-text-field
                  v-model="form.slug"
                  class="slug-field"
                  label="Slug"
                  :color="inputColor"
                  data-vv-name="slug"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-btn
                  v-show="editSlug"
                  @click="confirmSlug"
                  class="slug-confirm-btn"
                  color="teal"
                  fab dark small>
                  <v-icon dark>{{ icons.confirm }}</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <!--<v-flex xs6> -->
            <!--<worker-avatar-form @avatar-uploaded="avatarUpload"-->
              <!--:worker-avatar="workerAvatar"-->
              <!--ref="avatarForm">-->
            <!--</worker-avatar-form>-->
          <!--</v-flex>-->
        </v-layout>
        <v-text-field
            v-validate="'required|email|user-unique:email,'+oldEmail"
            v-model="form.email"
            :error-messages="errors.collect('email')"
            label="E-mail Address"
            :color="inputColor"
            data-vv-name="email"
            prepend-icon="email"
            required
            ></v-text-field>
    <v-flex xs12>
        <v-layout row xs12 wrap>
            <v-flex xs12 style="max-width: 100%;">
                <v-select
                    :color="inputColor"
                    v-model="form.timezone"
                    v-validate="'required'"
                    data-vv-name="timezone"
                    :error-messages="errors.collect('timezone')"
                    :items="timezoneList"
                    :clearable="true"
                    label="Choose your timezone"
                    prepend-icon="language"
                    solo>
                </v-select>
                  <!--v-radio-group
                    v-model="useTimezone"
                    column
                    style="margin-top: 0">
                    <v-radio
                      label="Use company timezone for this worker."
                      value="company"
                    ></v-radio>
                  </v-radio-group-->
            </v-flex>
        </v-layout>
    </v-flex>
    <v-flex
      xs12
      v-if="departmentCount>1">
        <v-layout style="margin-top: -20px;">
            <v-flex xs12 style="max-width: 100%;">
                <v-select
                  :color="inputColor"
                  v-model="form.departmentId"
                  v-validate=""
                  data-vv-name="department"
                  :error-messages="errors.collect('department')"
                  :items="departmentList"
                  :clearable="true"
                  item-text="name"
                  item-value="id"
                  label="Choose department"
                  prepend-icon="domain"
                  solo>
                </v-select>
            </v-flex>
        </v-layout>
    </v-flex>
    <v-flex xs12 v-for="(role, index) in form.roles" v-bind:key="index">
      <v-layout style="margin-top: -25px;">
        <v-flex xs5 v-if="roleCount>0">
          <v-autocomplete
            v-validate="''"
            v-model="role.roleId"
            :color="inputColor"
            :items="roleLists[index]"
            :filter="roleFilter"
            :data-vv-name="'defaultRole'+index"
            :error-messages="errors.collect('defaultRole'+index)"
            item-text="name"
            item-value="id"
            :label="role.default ? 'Default Role' : 'Another Role'"
            prepend-icon="group"
          ></v-autocomplete>
        </v-flex>
        <v-flex
          :xs4="roleCount>1 ? true : false"
          :x12="roleCount===0 ? true : false">
          <v-text-field
              v-validate="'required'"
              v-model="role.hourlyPay"
              :error-messages="errors.collect('hourlyPay'+index)"
              label="Hourly Pay"
              :color="inputColor"
              @blur="currencyFocusOut(index)"
              :data-vv-name="'hourlyPay'+index"
              :placeholder="'0.00'"
              prepend-icon="attach_money"
              required
              ></v-text-field>
        </v-flex>
        <v-flex xs3 v-if="roleCount>1">
          <v-btn
            v-show="!role.default"
            class="mt-3"
            color="red"
            dark fab small @click="removeRole(index)">
            <v-icon>{{ icons.cancel }}</v-icon>
          </v-btn>
          <v-btn
            v-show="index+1 === form.roles.length
                    && companyHasRoles"
            class="mt-3"
            :style="greenButtonStyle"
            dark fab small
            @click="addRole">
              <v-icon>person_add</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    </v-form>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
//import all components
import axios from 'axios';
import {
  VForm,
  VInput,
  VTextField,
  VCheckbox,
  VDivider,
  VSelect,
  VAutocomplete,
  VTooltip
} from 'vuetify';

import {VRadioGroup, VRadio} from 'vuetify/es5/components/VRadioGroup';

//import all mixins
import {timezone} from '@/mixins/timezone';
import {theme} from '@/mixins/theme';
import {worker} from '@/mixins/worker';
import {user} from '@/mixins/user';
import {crud} from '@/mixins/crud';
import {intro} from '@/mixins/intro';
import WorkerAvatarForm from '@/components/people/WorkerAvatarForm';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'formPeople',
  components: {
    VForm,
    VInput,
    VTextField,
    VCheckbox,
    VDivider,
    VSelect,
    VRadio,
    VRadioGroup,
    WorkerAvatarForm,
    VAutocomplete,
    VTooltip
  },
  props: [
    'inputColor',
    'form',
    'companyInfo'
  ],
  mixins: [
    user,
    worker,
    timezone,
    crud,
    theme,
    intro,
  ],
  created() {
  },
  mounted() {
    let _vm = this;
    // auto-load timezone data
    _vm.loadReadableTz();
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
    //auto-load roles
    _vm.loadRoles();
    //auto-load departments
    _vm.loadDepartments();
  },
  data () {
    return {
        editSlug: false,
        slugSuggestions: [],
        showSlugSuggestion: false,
        roleList: [],
        roleCount: 0,
        departmentList: [],
        departmentCount: 0,
        roleLists: [[]],
        timezoneList: [],
        timezone: '',
        //useTimezone: null,
        signupOnly: true,
        processedThroughDialog: true,
        createManagerOnly: true,
        oldEmail: null,
        companyHasRoles: true,
        dictionary: {
            messages: {
              required: 'This field is required.'
            },
            attributes: {
              firstname: 'Firstname',
              lastname: 'Lastname',
              email: 'Email',
              slug: 'Worker Slug',
            },
            custom: {
              firstname: {
                required: () => 'Firstname field is required.'
              },
              lastname: {
                required: () => 'Lastname field is required.'
              },
              email: {
                required: () => 'Email field is required.',
                email: 'Email field must be a valid email.'
              },
              slug: {},
              timezone: {
                required: () => 'Timezone should be selected.'
              }
            },
        }
    };
  },
  computed: {
    managerTimezone: function() {
      if (this.identity) {
        let textOffset = this.readableOffset(this.identity.manager.timezone);
        return `${textOffset} ${this.identity.manager.timezone_text}`;
      }
      return '';
    },
    companyTimezone: function() {
      if (this.companyInfo && this.companyInfo.timezone_text) {
        let textOffset = this.readableOffset(this.companyInfo.timezone);
        return `${textOffset} ${this.companyInfo.timezone_text}`;
      }
    },
    workerAvatar: function() {
      if (this.form.avatar) {
        let url = new URL(this.form.avatar,
          process.env.VUE_APP_CAS_API_URL);
        return url.href;
      }
      return null;
    },
    mainUrl: function() {
      let url = process.env.VUE_APP_PREVIEW_URL;
      if (this.companyInfo) {
        url += this.companyInfo.slug;
      }
      return url;
    },
    generatedUrl: function() {
      let url = this.mainUrl;
      if (this.form.slug) {
        url += '/' + this.form.slug;
      }
      return url;
    }
  },
  methods: {
    getSlugInLowerCase (slug) {
      if (typeof slug !== 'undefined') {
        slug = slug.toLowerCase();
      }
      return slug;
    },
    currencyFocusOut (index) {
      let value = 0;
      let _vm = this;
      let hourlyPay = _vm.form.roles[index].hourlyPay;
      if (typeof hourlyPay !== 'undefined') {
        //eslint-disable-next-line
        value = parseFloat(hourlyPay.replace(/[^\d\.]/g, ''));
      }
      if (isNaN(value)) {
        value = 0;
      }
      //
      hourlyPay = value.toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
      _vm.$set(_vm.form.roles[index], 'hourlyPay', hourlyPay);
    },
    confirmSlug () {
      this.editSlug = false;
    },
    generateRoleLists() {
      this.$set(this, 'roleLists', []);
      for (let i = 0; i < this.form.roles.length; i++) {
        this.roleLists.push(this.roleList.filter(
          r => {
            return this.form.roles[i].roleId === r.id
              || this.form.roles.findIndex(sr => sr.roleId === r.id) === -1;
          }
        ));
      }
    },
    addRole () {
      let _vm = this;
      _vm.form.roles.push({});
    },
    isNewWorker () {
      return !this.form.hasOwnProperty('id');
    },
    removeRole (index) {
      let _vm = this;
      let role = _vm.roleList.find(r => {
        return r.id === _vm.form.roles[index].roleId;
      });
      if (_vm.form.id && role) {
        let boxTitle = 'Delete Role Confirmation';
        let boxMessage = `Are you sure you want to remove "${role.name}"
          role for this user?`;
        _vm.$confirm(boxTitle, boxMessage, () => {
          _vm.form.roles.splice(index, 1);
          _vm.companyHasRoles = true;
        }, () => {});
      } else {
        _vm.form.roles.splice(index, 1);
        _vm.companyHasRoles = true;
      }
    },
    timePickerOptions: function(minTime) {
      let _vm = this;
      let options = {
        start: '09:00',
        step: '00:15',
        end: '18:00'
      };
      if (minTime) {
        options.minTime = _vm.form.defaultStartTime;
      }
      return options;
    },
    roleFilter (item, queryText) {
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1;
    },
    loadRoles() {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      axios({
        url: `${process.env.VUE_APP_API_URL}companies/${companyId}/roles`
      }).then(response => {
        let r = response.data;
        if (r.status === 'success' && r.data) {
          _vm.roleList = r.data;
          _vm.roleCount = r.totalCount;
        }
      }).catch(err => {
        _vm.$notify(`Can't load roles: ${err.message}`, 'error');
      });
    },
    loadDepartments() {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      axios({
        url: `${process.env.VUE_APP_API_URL}companies/${companyId}/departments`
      }).then(response => {
        let r = response.data;
        if (r.status === 'success' && r.data) {
          _vm.departmentList = r.data;
          _vm.departmentCount = r.totalCount;
        }
      }).catch(err => {
        _vm.$notify(`Can't load departments: ${err.message}`, 'error');
      });
    },
    reset () {
      //this.useTimezone = null;
      this.oldEmail = null;
      this.editSlug = false;
      //this.$refs.avatarForm.reset();
    },
    resetRole () {
      this.companyHasRoles = true;
    },
    avatarUpload: function(imgDataUrl) {
      this.img = {
        dataUrl: imgDataUrl,
        mime: 'image/png',
        name: 'avatar.png'
      };
    },
  },
  watch: {
    roleList: {
      handler: function() {
      },
      deep: true
    },
    /*useTimezone: function(value) {
      if (value === 'manager') {
        this.form.timezone = this.managerTimezone;
      } else if (value === 'company') {
        this.form.timezone = this.companyTimezone;
      }
    },*/
    form: {
      //eslint-disable-next-line
      handler: function(value) {
        let _vm = this;
        let companyId = _vm.identity.manager.companyId;

        if (!_vm.oldEmail && value.id) {
          _vm.oldEmail = value.email;
        }
        if (!value.timezone) {
          // Set default timezone
          //let textOffset =
          //this.readableOffset(this.$root.settings.defWorkerTz);
          //value.timezone =
          //  `${textOffset} ${this.$root.settings.defWorkerTzText}`;
          //set default timezone to company timezone
          value.timezone = _vm.companyTimezone;
        }

        if (value.slug) {
          value.slug = value.slug.split('/', 1)[0];
        }

        if (!value.roles || !value.roles.length) {
          _vm.$set(value, 'roles', [{default: true}]);
        }

        //on update worker side, append unassigned
        if (value.hasOwnProperty('id')) {
          _vm.roleList.unshift({
            id: null,
            companyId,
            name: 'Unassigned',
            paperColor: ''
          });
        } else {
          _vm.roleList = _vm.roleList.filter(r => r.id !== null);
        }

        if (typeof value.departmentId === 'undefined') {
          value.departmentId = null;
        }
      },
      deep: true
    },
    'form.roles': function(value) {
      let _vm = this;
      if (value) {
        _vm.generateRoleLists();
      }
      let roleCount = (_vm.isNewWorker()) ? _vm.roleCount : _vm.roleCount + 1;
      if (roleCount === value.length) {
        _vm.companyHasRoles = false;
      }
    },
    'form.firstName': function(value, oldValue) {
      let _vm = this;
      let slug = _vm.getSlugInLowerCase(_vm.form.slug);
      oldValue = _vm.getSlugInLowerCase(oldValue);
      value = _vm.getSlugInLowerCase(value);
      if (!_vm.form.id && !slug || slug === oldValue) {
        _vm.$set(_vm.form, 'slug', value);
      }
    },
    errors: {
      handler: function(value) {
        if (value.items.filter(i => i.rule === 'worker-slug-unique').length) {
          this.showSlugSuggestion = true;
        } else {
          this.showSlugSuggestion = false;
          this.slugSuggestions = [];
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
form {
  width: 560px;
}
.dividerText {
    position: absolute;
    margin-top: -10px;
    background: white;
    left: 40%;
    padding-left: 10px;
    padding-right: 10px;
    color: #777;
    /* font-weight: bold; */
    font-style: italic;
}

.suggestion-list {
  list-style: none;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}

.timepicker {
  width: 270px;
  margin-left: 5px;
  margin-top: 5px;
  border-color: #009688;
}

.user-url {
  font-size: 16px;
}

#worker-form .pt-3 {
  padding-top: 0 !important;
  margin-bottom: 0;
}

#worker-form .slug-block {
  margin-top: -10px;
  margin-bottom: -15px;
}

#worker-form .slug-block p {
  padding-left: 5px !important;
}

#worker-form .slug-field {
  margin-top: 8px;
  margin-left: 22px;
}

#worker-form .slug-confirm-btn {
  margin-top: 12px
}
</style>
