require('dotenv').config();
import '@babel/polyfill';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import {Validator} from 'vee-validate';
import './plugins/vuetify';
import './plugins/element';
import DaySpanVuetify from 'dayspan-vuetify';
import 'vuetify/dist/vuetify.css';
import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css';
import App from './App.vue';
import router from './router';
import Meta from 'vue-meta';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {store} from './store/store';
// alertify
import 'alertifyjs/build/alertify.min.js';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import Alertifyjs from 'vue2-alertifyjs';
import underscore from 'vue-underscore';
import rules from './utils/validators';
import {system} from '@/mixins/system';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueIntro from 'vue-introjs';
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';

// alertify settings
const alertifyPresets = {
  padding: true,
  notifier: {
    delay: 2,
    position: 'top-right',
    closeButton: false
  }
};

Vue.config.productionTip = false;

// load axios for api requests
Vue.use(VueAxios, axios);
// load alertify
Vue.use(Alertifyjs, alertifyPresets);
// load vue meta
Vue.use(Meta);
// load vee validate
Vue.use(VeeValidate);
// load underscore
Vue.use(underscore);
// load vue-introjs
Vue.use(VueIntro);
// load vue-ctk-date-time-pikcer
Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);

Validator.extend('slug-unique', rules.checkSlugUnique);
Validator.extend('user-unique', rules.userUnique);
Validator.extend('role-unique', rules.roleUnique);
Validator.extend('department-unique', rules.departmentUnique);
Validator.extend('worker-slug-unique', rules.workerSlugUnique);

const dictionary = {
  en: {
    messages:{
      required: (field) => field +' is required.'
    }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);

Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor: () => '#1976d2'
  }
});


let vm = new Vue({
  created: function() {
    // load and set global settings
    let url = `${process.env.VUE_APP_API_URL}settings/load`;
    axios.get(url).then(res => {
      if (res.data.status === 'success') {
        let settings = res.data.data.globalSetting;
        //this.settings.defWorkerTz = settings.defaultWorkerTimezone;
        //this.settings.defWorkerTzText = settings.defaultWorkerTimezoneText;
        //this.settings.defGuestTz = settings.defaultGuestManagerTimezone;
        //this.settings.defGuestTzText =
        //settings.defaultGuestManagerTimezoneText;
        this.settings.defPageSize = settings.defaultPageSize;
        this.settings.defMinSearch = settings.minSearchCharacterCount;
      }
    });
  },
  mixins: [
    system
  ],
  mounted: function() {
  },
  data: function() {
    return {
      settings: {
        defPageSize: null,
        defMinSearch: 3,
        //defGuestTzText: null,
        //defGuestTz: null,
        //defWorkerTzText: null,
        //defWorkerTz: null
      },
      weekdays: null,
    };
  },
  store,
  router,
  render: h => h(App)
});

// We should to register this components globaly to override this
// components from dayspan-vuetify for make some change on them.
import WeeksView from '@/components/calendar/weeks-view';
import Day from '@/components/calendar/day';
import DayTimes from '@/components/calendar/day-times';
import CalendarApp from '@/components/calendar/calendar-app';
import CalendarEvent from '@/components/calendar/calendar-event';
Vue.component('dsWeeksView', WeeksView);
Vue.component('dsDay', Day);
Vue.component('dsDayTimes', DayTimes);
Vue.component('dsCalendarApp', CalendarApp);
Vue.component('dsCalendarEvent', CalendarEvent);

vm.$mount('#app');
