// import * as types from './types'

export default {
  isLoggedIn: state => {
    return state.isLoggedIn;
  },
  loginMessage: state => {
    return state.loginMessage;
  }
};
