<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            v-if="items.length"
            :pagination.sync="pagination"
            :total-items="totalRows"
            select-all
            :no-data-text='noRecordsText'
            :rows-per-page-items="selectPagination"
            hide-actions
            style="border: 3px solid #04796e;"
        >
            <template slot="headers" slot-scope="props">
            <tr
                :key="props.index"
                style="border-bottom: 5px solid #04796e;">
                <th
                v-for="header in props.headers"
                :key="header.text"
                :class="getHeadersConfig(header)"
                @click="changeSort(header.sortable, header.value)"
                >
                  <v-icon small v-if="header.sortable">arrow_upward</v-icon>
                  <div v-if="header.weekDay">
                      {{ header.textTop }}
                  </div>
                  <div :class="[
                      (header.weekDay) ? 'week-day-text' : ''
                  ]">{{ header.text }}</div>
                  <div v-if="header.value === 'total'"
                   class="red--text text-darken-4 font-weight-black subheading">
                    ${{ calculateTotalPerWeek() }}
                  </div>
                </th>
            </tr>
            </template>
            <template slot="items" slot-scope="props">
            <td :class="[
                'text-xs-center'
            ]">
              {{ props.item.lastName }}
            </td>
            <td :class="[
                'text-xs-center'
            ]">
              {{ props.item.firstName }}
            </td>
            <td v-for="(duration, index) in props.item.durations"
            :key="index"
            :class="[
                'text-xs-center'
            ]">
              {{ duration }}
            </td>
            <td :class="[
                'text-xs-center'
            ]">
                {{ props.item.total.hours }}
            </td>
            <td :class="[
                'text-xs-center'
            ]">
                {{ getColumnValue(props.item.total.overTimeHours) }}
            </td>
            <td :class="[
                'text-xs-center'
            ]">
              ${{ props.item.total.hourlyPay }}
            </td>
            <td :class="[
                'text-xs-center'
            ]">
              ${{ props.item.total.totalAmount }}
            </td>
            </template>
            <!--template slot="footer">
            <tr
                class="teal"
                style="border-top: 4px solid rgb(4, 121, 110)"
            >
                <td colspan="100%">
                    <v-layout row wrap>
                        <v-flex xs1 class="perPage white--text">
                        Per page :
                        </v-flex>
                        <v-flex xs1>
                            <v-select
                                :items="selectPagination"
                                v-model="pagination.rowsPerPage"
                                :single-line="true"
                                solo
                                @change="resetPagination"
                            ></v-select>
                        </v-flex>
                        <v-flex xs8></v-flex>
                        <v-flex xs1></v-flex>
                    </v-layout>
                </td>
            </tr>
            </template-->
        </v-data-table>
        <!--div class="text-xs-center pt-2">
            <v-pagination
            v-model="pagination.page"
            :length="pages"
            color="teal"
            circle
            @input="nextPage"
            ></v-pagination>
        </div-->
    </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {_} from 'vue-underscore';
import * as moment from 'moment-timezone';

import {
  VPagination,
  VProgressLinear
} from 'vuetify';

export default {
  name: 'PayrollWeekView',

  components: {
    VPagination,
    VProgressLinear
  },

  props: [
    'dates',
    'items',
    'loading'
  ],

  data () {
    return {
      headers: [],
      totalRows: 0,
      disableBulkDelete: true,
      pagination: {
        number: 1,
        sortBy: 'lastName',
        page: 1,
        rowsPerPage: 10
      },
      noRecordsText: '',
      selectPagination: [20, 30, 40, 50]
    };
  },

  watch: {
    dates: {
      handler: function(values) {
        let _vm = this;
        _vm.totalRows = _vm.items.length;
        //
        _vm.initHeaders();
        //build headers
        _vm.headers.push({
          text: 'Lastname',
          sortable: true,
          value: 'lastName'
        },
        {
          text: 'Firstname',
          sortable: true,
          value: 'firstName'
        });
        //
        _.each(values, value => {
          let date = moment(value*1000).utcOffset(0);
          _vm.headers.push({
            textTop: date.format('ddd'),
            text: date.format('D'),
            sortable: false,
            weekDay: true,
            value: date.format('dddd')
          });
        });
        //
        _vm.headers.push({
          text: 'Hrs',
          sortable: false,
          value: 'hours'
        },
        {
          text: 'O/T',
          sortable: false,
          value: 'overtime'
        },
        {
          text: 'Hourly',
          sortable: false,
          value: 'hourly'
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total'
        });
        //
        if (_vm.loading && _vm.totalRows === 0) {
          _vm.noRecordsText = 'No matching records found.';
        }
      }
    }
  },

  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null) {
        return 0;
      }
      return Math.ceil(this.totalRows / this.pagination.rowsPerPage);
    }
  },

  methods: {
    calculateTotalPerWeek() {
      return this.items.reduce(
        (total, i) => total + parseFloat(i.total.totalAmount), 0).toFixed(2);
    },
    getColumnValue (value) {
      return (parseInt(value) !== 0) ? value : '-';
    },
    getHeadersConfig(header) {
      let _vm = this;
      let baseConfig = [
        'column teal white--text text-xs-center',
        header.sortable ? 'sortable' : '',
        (header.value === _vm.pagination.sortBy
          && header.sortable) ? 'active' : '',
        header.hide && !_vm.adminView ? 'hide' : ''
      ];
      let sortableArrow = (_vm.pagination.descending) ? 'desc' : 'asc';
      if (header.sortable) {
        baseConfig.push(sortableArrow);
      }
      return baseConfig;
    },
    resetPagination (value) {
      let _vm = this;
      _vm.pagination.page = 1;
      _vm.pagination.rowsPerPage = value;
      _vm.load();
    },
    nextPage (page) {
      let _vm = this;
      let rowsPerPage = _vm.pagination.rowsPerPage;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.pagination.number = ((page-1)*rowsPerPage)+1;
        _vm.managers = data;
      });
    },
    initHeaders() {
      this.headers = [];
    },
    changeSort (sortable, column) {
      if (sortable) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending;
        } else {
          this.pagination.sortBy = column;
          this.pagination.descending = false;
        }
        this.$root.$emit('reload', this.pagination);
      }
    }
  }
};
</script>

<style scoped>
  .week-day-text {
    font-weight: bold;
    color: #48494a;
    font-size: 40px;
    line-height: 36px;
  }
</style>
