<template>

  <div class="ds-day"
    :class="classesDay"
    @mouseenter="mouseEnterDay"
    @mouseleave="mouseLeaveDay"
    @mousemove.stop="mouseMoveDay"
    @mousedown="mouseDownDay"
    @mouseup="mouseUpDay"
    @click.stop="add"
    @dragstart.prevent>

    <div v-if="day.inCalendar" :class="classesHeader">

      <div>
        <a class="ds-dom" href
          :class="classesDayOfMonth"
          @click.stop.prevent="viewDay"
          @mousedown.stop>
          {{ dayOfMonth }}
        </a>
        <span class="ds-first-day" v-if="showMonth">
          {{ month }}
        </span>
        <v-btn :style="warningButtonStyle"
                v-if="day.warnings && day.warnings.length"
                class="warning-button"
                dark small fab icon
                @click="showWarnings">
          <span class="font-weight-black">!</span>
        </v-btn>
        <v-btn :style="addButtonStyle"
          class="add-button"
          v-if="canShowAddButton"
          dark small fab icon
          @click="addEvent">
          <v-icon dark>add</v-icon>
        </v-btn>
      </div>

    </div>

    <div v-if="day.inCalendar">
      <template v-for="(event, i) in visibleEvents" >

        <ds-calendar-event
          v-if="i < maxEventsShow || showAllEvents"
          v-bind="{$scopedSlots}"
          v-on="$listeners"
          :key="event.id"
          :calendar-event="event"
          :calendar="calendar"
          :index="i"
        ></ds-calendar-event>

      </template>
      <div class="load-more-link"
           v-if="visibleEvents.length > maxEventsShow">
        <a @click="showAllEvents = !showAllEvents">
          Show {{ showAllEvents ? 'less' : 'more'}}
        </a>
      </div>

      <div v-if="hasPlaceholder">

        <ds-calendar-event-placeholder
          v-bind="{$scopedSlots}"
          v-on="$listeners"
          :day="day"
          :placeholder="placeholder"
          :placeholder-for-create="placeholderForCreate"
          :calendar="calendar"
          :index="visibleEvents.length"
        ></ds-calendar-event-placeholder>

      </div>
    </div>

  </div>

</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {DaySpan, Day, Calendar, CalendarEvent, Functions as fn} from 'dayspan';
import {theme} from '@/mixins/theme';
import userUtils from '@/utils/user';
import moment from 'dayspan/node_modules/moment';

let methods = {
  isVisible(calendarEvent) {
    if (this.$dayspan.features.hideOnMove &&
        this.placeholder &&
        this.placeholder.event === calendarEvent.event) {
      return false;
    }

    return true;
  },

  showWarnings() {
    this.$emit('showWarnings', this.day.warnings || []);
  },

  add() {
    this.$emit('add', this.day);
  },

  addEvent() {
    this.$emit('addEvent', this.day);
  },

  viewDay() {
    this.$emit('view-day', this.day);
  },

  mouseEnterDay() {
    this.$emit('mouse-enter-day', this.day);
  },

  mouseLeaveDay() {
    this.$emit('mouse-leave-day', this.day);
  },

  mouseMoveDay($event) {
    this.$emit('mouse-move-day', this.getEvent('mouse-move-day', $event));
  },

  mouseDownDay($event) {
    this.$emit('mouse-down-day', this.getEvent('mouse-down-day', $event));
  },

  mouseUpDay($event) {
    this.$emit('mouse-up-day', this.getEvent('mouse-up-day', $event));
  },

  getEvent(type, $event, extra = {}) {
    return fn.extend({

      type: type,
      day: this.day,
      calendar: this.calendar,
      left: $event.button === 0 && $event.buttons > 0,
      right: $event.button === 1 && $event.buttons > 0,
      handled: false,
      $event: $event,
      $vm: this,
      $element: this.$el

    }, extra);
  }
};

//merge methods
methods = Object.assign({}, userUtils, methods);

export default {

  name: 'dsDay',

  props: {
    day: {
      required: true,
      type: Day
    },

    calendar: {
      required: true,
      type: Calendar
    },

    placeholder: {
      type: CalendarEvent
    },

    placeholderForCreate: {
      type: Boolean,
      default: false
    },

    formats: {
      validate(x) {
        return this.$dsValidate( x, 'formats' );
      },
      default() {
        return this.$dsDefaults().formats;
      }
    }
  },

  mixins: [
    theme
  ],

  data () {
    return {
      maxEventsShow: 5,
      showAllEvents: false
    };
  },

  computed: {
    isNextWeek() {
      let _vm = this;
      let currentDay = _vm.day.date;
      let diffDay = _vm.calendarStartPayrollDay
        - moment.localeData().firstDayOfWeek();
      diffDay = diffDay < 0 ? diffDay + 7 : diffDay;
      let beginWeek = moment()
                        .subtract(1, 'weeks')
                        .startOf('week')
                        .weekday(diffDay);
      return beginWeek.isSameOrBefore(currentDay, 'day');
    },
    canShowAddButton() {
      //manager view or worker view and current to future week only
      return (this.hasManagerAccount()
                || this.hasWorkerAccount() && this.isNextWeek);
    },
    calendarStartWeekDay() {
      return (this.company)
                ? this.company.startWeekDay
                : 1;//default to monday if nothing set
    },
    calendarStartPayrollDay() {
      return (this.company)
                ? this.company.payrollDay
                : this.calendarStartWeekDay;
    },
    classesDay() {
      return {
        'ds-today': this.day.currentDay,
        'ds-first-day-day': this.day.dayOfMonth === 1,
        'ds-out-calendar': !this.day.inCalendar,
        'ds-weekends': this.day.dayOfWeek === 6 || this.day.dayOfWeek === 0,
      };
    },

    classesHeader() {
      return {
        'ds-out-calendar': !this.day.inCalendar
      };
    },

    classesDayOfMonth() {
      return {
        'ds-today-dom': this.day.currentDay
      };
    },

    showMonth() {
      return this.day.dayOfMonth === 1;
    },

    dayOfMonth() {
      return this.day.dayOfMonth;
    },

    month() {
      return this.day.format( this.formats.month );
    },

    hasPlaceholder() {
      return this.placeholder &&
        this.placeholder.time.matchesDay( this.day );
    },

    dayFormat() {
      return this.day.date.format('DDMMYYYY');
    },

    visibleEvents() {
      //return this.day.events.filter( this.isVisible );
      if (this.calendar.rawEventsByDays) {
        let evs  = this.calendar.rawEventsByDays[this.dayFormat];
        if (evs) {
          return evs.map((e) => {
            let daySpan = new DaySpan(e.schedule.start, e.schedule.end);
            let calEvent = new CalendarEvent(
              e.id, e, daySpan, this.day);
            return calEvent;
          });
        }
      }
      return [];
    },

    warningButtonStyle() {
      let _vm = this;
      let style = _vm.warnButtonStyle;
      return Object.assign(style, {
        'height': '20px',
        'width': '20px'
      });
    },

    addButtonStyle() {
      //let margin = this.showMonth ? 60 : 70;
      //return `height: 20px; width: 20px; margin-left: ${margin}%`;
      let _vm = this;
      let style = _vm.grayButtonStyle;
      return Object.assign(style, {
        'height': '20px',
        'width': '20px'
      });
    },

    company() {
      return this.getUser('company');
    },
  },

  methods
};
</script>

<style scoped lang="scss">

.ds-day {
  flex: 1;
  width: 0;
  border-right: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
  padding: 4px;
  user-select: none;

  .ds-dom {
    text-decoration: none;
    color: #212121;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    user-select: none;


    &:hover {
      text-decoration: underline;
    }

    &.ds-today-dom {
      border-radius: 12px;
      background-color: #4285f4;
      color: white;
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }
}

.ds-out-calendar {
  background-color: #d0d0d0;
}

.load-more-link {
  margin-top: 5px;
}
</style>
