import Vue from 'vue';
import {
  Vuetify,
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VForm,
  VMenu,
  VAvatar,
  VInput,
  VIcon,
  VGrid,
  VToolbar,
  VDialog,
  VCard,
  VImg,
  VTabs,
  VDataTable,
  VTooltip,
  transitions,
  VTextField,
  VSelect,
  VTextarea
} from 'vuetify';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VForm,
    VMenu,
    VAvatar,
    VInput,
    VIcon,
    VGrid,
    VToolbar,
    VDialog,
    VCard,
    VImg,
    VTabs,
    VDataTable,
    VTooltip,
    transitions,
    VTextField,
    VSelect,
    VTextarea
  },
  theme: {
    primary: '#007bff',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
});
