<template>
    <div style="background: #FFF;">
        <HomeHeader :identity="this.identity"></HomeHeader>
        <div class="body">
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ page.title }}</v-toolbar-title>
              <v-divider
                class="mx-2"
                inset
                vertical
              ></v-divider>
              <v-text-field
                :label="labels.search"
                v-model="search"
                append-icon="search"
                style="width: 250px; margin-right: 25px;"
                color="teal"
              ></v-text-field>
              <div class="searchHelpText">
                Search starts from {{ minSearchCharacterCount }} characters
              </div>
              <v-spacer></v-spacer>
               <v-switch
                :label="showPer"
                v-model="perManager"
                @change="changeSelectorPer()"
                v-show="isAdmin()"
                color="teal"
                width="20px"
                :hide-details="true"
              ></v-switch>
              <v-select v-show="perManager"
                :items="managers"
                label="Manager"
                v-model="selectedManager"
                @change="changeView()"
                item-value="id"
                item-text="fullName">
              </v-select>
              <v-select v-show="!perManager"
                :items="companies"
                label="Company"
                v-model="selectedCompany"
                @change="changeView"
                item-value="id"
                item-text="name">
              </v-select>
              <!-- Filter worker per department block -->
              <department-selector
                v-show="departments.length > 1"
                :departments="departments"
                :customStyle="blackDropdownStyle"
                @selected="departmentSelected">
              </department-selector>
              <!-- Filter worker per department block end-->
              <!-- This block if for workers roles filter -->
              <item-selector
                button-title="roles"
                item-name="roles"
                v-show="roles.length > 1"
                :items="this.roles"
                :customStyle="blackDropdownStyle"
                @selected="this.roleSelected">
              </item-selector>
              <!-- End of workers roles filter. -->
              <v-dialog
                v-model="dialog"
                max-width="650px"
                :persistent="true"
                scrollable
              >
                <v-btn
                    slot="activator"
                    :style="greenButtonStyle"
                    dark
                    class="mb-2"
                    @click="reset"
                    v-intro="introInfo.addPeople.message"
                    v-intro-step="introInfo.addPeople.step"
                >
                  <v-icon small>add</v-icon>&nbsp;
                  {{ labels.create }}
                </v-btn>
                <v-card
                  >
                    <v-card-title
                    style="background-color: #3e3d3d;">
                      <div class="headline dialogTitle" >{{ formTitle }}</div>
                      <div class="dialogHelpText"> {{ dialogHelpText }} </div>
                    </v-card-title>
                    <v-card-text
                      class="pa-0"

                    >
                    <v-container
                      grid-list-md
                      class="noVertcalPadding"
                    >
                        <v-layout wrap>
                        <v-flex xs12 sm6 md4>
                          <FormWorker
                            ref="mform"
                            class="mform"
                            inputColor="teal"
                            :form="editedItem"
                            :companyInfo="companyInfo"
                          />
                        </v-flex>
                        </v-layout>
                    </v-container>
                    </v-card-text>
                    <v-card-actions
                    style="background-color: #3e3d3d;">
                      <v-btn
                        color="red lighten-1"
                        class="white--text"
                        @click.native="close"
                      >{{ labels.cancel }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue lighten-1"
                        class="white--text"
                        @click="save"
                      >{{ labels.save }}</v-btn>
                    </v-card-actions>
                </v-card>
              </v-dialog>
              <v-badge v-model="showDeleteBadget" color="red" >
                  <template v-slot:badge>
                    <span>{{ deletedWorkersCount }}</span>
                  </template>
                  <v-icon
                    @click="trashDialog = true"
                    large
                    color="red"
                  >
                  delete
                  </v-icon>
              </v-badge>
              <v-dialog v-model="trashDialog" scrollable max-width="650px">
                <v-card>
                  <v-card-title>Persons Trash</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 300px;">
                    <v-list>
                      <v-list-tile v-for="w in deletedWorkers" :key="w.id">
                        <v-list-tile-content>
                          {{ w.firstName }} {{ w.lastName }}
                        </v-list-tile-content>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="success"
                                @click="restoreWorker(w.id)"
                                fab small dark v-on="on" >
                                <v-icon>restore_from_trash</v-icon>
                              </v-btn>
                            </template>
                            <span>Restore Person</span>
                          </v-tooltip>

                         <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="error"
                                @click="deleteItem(w, hard=true)"
                                fab small dark v-on="on" >
                              <v-icon>{{ icons.delete }}</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Person</span>
                          </v-tooltip>

                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="blue darken-1" flat
                      @click="trashDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="workers"
              :pagination.sync="pagination"
              :total-items="totalWorkers"
              :loading="loading"
              :search="search"
              select-all
              :no-data-text='noRecordsText'
              :rows-per-page-items="selectPagination"
              v-model="selected"
              hide-actions
              class="elevation-1"
              style="margin: 0 30px 0 30px; border: 5px solid #04796e;"
            >
            <v-progress-linear
              slot="progress"
              background-color="teal lighten-4"
              color="teal lighten-3"
              height="4"
              indeterminate
            ></v-progress-linear>
              <template slot="headers" slot-scope="props">
                <tr
                  :key="props.index"
                  style="border-bottom: 5px solid #04796e;">
                  <th class="teal">
                    <v-checkbox
                      :input-value="props.all"
                      :indeterminate="props.indeterminate"
                      primary
                      hide-details
                      @click.native="toggleAll"
                      :key="props.index"
                    ></v-checkbox>
                  </th>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="getHeadersConfig(header)"
                    @click="changeSort(header.sortable, header.value)"
                  >
                    <v-icon small v-if="header.sortable">arrow_upward</v-icon>
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template slot="items" slot-scope="props">
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details
                    color="teal"
                  ></v-checkbox>
                </td>
                <!--td :class="[
                  'text-xs-center'
                ]">
                {{ props.index+pagination.number }}
                </td-->
                <td
                  :class="[
                    'text-xs-center'
                  ]"
                  v-html="mapText(props.item.firstName)"></td>
                <td
                  :class="[
                    'text-xs-center'
                  ]"
                  v-html="mapText(props.item.lastName)"></td>
                <td
                  :class="[
                    'justify-center px-0'
                  ]"
                  :style="!perManager
                          ? 'width: 180px;'
                          : ''">
                  <div v-if="isOwner(props.item)">
                    <a
                      :href="workerUrl(props.item)"
                      target="_blank"
                      title="View Worker Schedule">
                      <v-btn
                        color="info"
                        fab
                        small
                        dark
                      >
                      <v-icon>date_range</v-icon>
                      </v-btn>
                    </a>
                   <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="warning"
                          @click="editItem(props.item)"
                          fab small dark v-on="on" >
                          <v-icon>{{ icons.edit }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Person</span>
                    </v-tooltip>

                   <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="error"
                          @click="deleteItem(props.item)"
                          fab small dark v-on="on" >
                        <v-icon>{{ icons.delete }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Person</span>
                    </v-tooltip>
                  </div>
                </td>

                <td :class="[
                  'text-xs-center'
                ]">
                <a
                  :href="'mailto:' +  props.item.email"
                  v-html="mapText(props.item.email)"></a>
                </td>
                <!--td
                  :class="[
                    'text-xs-center',
                    perManager ? 'hide' : ''
                  ]">{{ props.item["Manager.firstName"] +
                       ' ' + props.item["Manager.lastName"] }}</td-->
                <td
                  v-show="hasDepartments()"
                  :class="[
                    'text-xs-center'
                  ]">
                  {{ showColumnValue(props.item['Department.name']) }}
                </td>
                <td
                  v-show="hasRoles()"
                  :class="[
                    'text-xs-center'
                  ]">
                <v-chip
                v-for="role in props.item.roles"
                small
                :key="role.roleId"
                :style="{
                  'background-color': role['Role.paperColor']
                }"
                text-color="white">
                  <v-avatar>
                    <v-icon>label</v-icon>
                  </v-avatar>
                  {{ showColumnValue(role['Role.name'], 'Unassigned') }}
                </v-chip>
                </td>
                <!--td :class="[
                  'text-xs-center'
                ]">
                  <a
                    :href="workerUrl(props.item)"
                    target="_blank"
                    title="View Worker Schedule">
                    <v-btn
                      color="info"
                      fab
                      small
                      dark
                    >
                    <v-icon>date_range</v-icon>
                    </v-btn>
                  </a>
                </td>
                <td
                  :class="[
                    'text-xs-center'
                  ]">
                  {{ readableOffset(props.item.timezone) }}
                  {{ props.item.timezone_text }}
                </td-->
              </template>
              <template slot="footer">
                <tr
                  class="teal"
                  style="border-top: 4px solid rgb(4, 121, 110"
                >
                  <td colspan="100%">
                      <v-layout row wrap>
                          <v-flex xs1 class="perPage white--text">
                            Per page :
                          </v-flex>
                          <v-flex xs1>
                              <v-select
                                  :items="selectPagination"
                                  v-model="pagination.rowsPerPage"
                                  :single-line="true"
                                  solo
                                  @change="resetPagination"
                              ></v-select>
                          </v-flex>
                          <v-flex xs8></v-flex>
                          <v-flex xs1>
                            <v-btn
                              color="error"
                              :disabled="disableBulkDelete"
                              v-show="!disableBulkDelete"
                              @click="bulkDelete"
                            >
                            {{ deleteWorkerText }}
                            </v-btn>
                          </v-flex>
                      </v-layout>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                color="teal"
                circle
                @input="nextPage"
              ></v-pagination>
            </div>
            <v-dialog
              v-model="floading"
              hide-overlay
              persistent
              width="300"
            >
              <v-card
                color="teal"
                dark
              >
              <v-card-text>
                Please wait few moments...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
    </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */

//import all components
import HomeHeader from '@/components/layouts/HomeHeader';
import FormWorker from '@/components/people/FormWorker';
import DepartmentSelector from '@/components/common/department-selector';
import ItemSelector from '@/components/common/item-selector';
import axios from 'axios';
import {
  VDialog,
  VChip,
  VDivider,
  VPagination,
  VCheckbox,
  VProgressLinear,
  VSelect,
  VTextField,
  VSwitch,
  VBadge,
} from 'vuetify';

//import all mixins
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {company} from '@/mixins/company';
import {crud} from '@/mixins/crud';
import {theme} from '@/mixins/theme';
import {intro} from '@/mixins/intro';

//require node modules
var _ = require('underscore');

var querystring = require('querystring');

export default {
  name: 'People',
  metaInfo: {
    title: 'SimpleTym - People List',
    titleTemplate: null
  },
  mixins: [
    user,
    timezone,
    company,
    crud,
    theme,
    intro,
  ],
  components: {
    HomeHeader,
    VDialog,
    VChip,
    VDivider,
    VPagination,
    VCheckbox,
    VSelect,
    VProgressLinear,
    VTextField,
    FormWorker,
    VSwitch,
    DepartmentSelector,
    ItemSelector,
    VBadge,
  },
  data () {
    return {
      perManager: false,
      floading: false,
      dialog: false,
      dialogSettings: {
        manager: {
        }
      },
      managers: [],
      departments: [],
      roles: [],
      selectedManager: null,
      selectedDepartments: [],
      selectedRoles: [],
      editedIndex: -1,
      editedItem: {},
      totalWorkers: 0,
      loading: true,
      disableBulkDelete: true,
      disableDeleteNotification: false,
      selected: [],
      deleted: [],
      minStarTime: '09:00',
      maxEndTime: '18:00',
      deleteWorkerText: '',
      trashDialog: false,
      selectPagination: [20, 30, 40, 50],
      noRecordsText: '',
      search: '',
      page: {
        title: 'People'
      },
      pagination: {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: null
      },
      headers: [
        {
          text: 'Firstname',
          value: 'firstName',
          sortable: true
        },
        {
          text: 'Lastname',
          value: 'lastName',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email'
        },
        /*{
          text: 'Manager',
          sortable: true,
          value: 'manager',
          hide: true
        }*/
      ],
      workers: [],
      deletedWorkers: [],
      labels: {
        create: 'Add People',
        search: 'Search a People',
        createHelpText: '(Add new People to your company easily.)',
        delete: 'Delete People',
        deleteMany: 'Delete People',
        deleteAll: 'Delete All People',
        edit: 'Edit Worker',
        editHelpText: 'Edit people informations.'
      },
      selectedCompany: null
    };
  },
  created () {
    let _vm = this;
    //emit start front loading
    _vm.$root.$on('onFrontLoading', () => {
      _vm.floading = true;
    });
    //emit stop front loading
    _vm.$root.$on('offFrontLoading', () => {
      _vm.floading = false;
    });
    //emit close
    _vm.$root.$on('close', () => {
      _vm.close();
    });
    //emit reload
    _vm.$root.$on('worker-reload', () => {
      _vm.reload();
    });
  },
  mounted () {
    this.initIntro(2);
    let _vm = this;
    _vm.pagination.rowsPerPage = _vm.$root.settings.defPageSize;
    //auto-load departments
    _vm.loadDepartments().then((departments) => {
      _vm.departments = departments;
      if (_vm.hasDepartments()) {
        _vm.headers.push({
          text: 'Department',
          value: 'Department.name',
          sortable: true
        });
      }
      //auto-load roles
      return _vm.loadRoles();
    }).then((roles => {
      let companyId = _vm.identity.manager.companyId;
      roles.unshift({
        id: null,
        companyId,
        name: 'Unassigned',
        paperColor: ''
      });
      _vm.roles = roles;
      if (_vm.hasRoles()) {
        _vm.headers.push({
          text: 'Roles',
          value: 'roles',
          sortable: true
        });
      }
      //load workers
      if (_vm.pagination.rowsPerPage) {
        _vm.load();
      }
    }));
    _vm.$root.$watch('settings.defPageSize', function(value) {
      if (value) {
        _vm.pagination.rowsPerPage = value;
      }
    });
    //cancel sorting
    if (_vm.pagination.hasOwnProperty('sortBy')) {
      _vm.pagination = _.omit(_vm.pagination, ['sortBy']);
    }
    //if (_vm.isAdmin()) {
      // get companies that has workers
      this.getCompanies(true);
    //}
  },
  watch: {
    pagination: {
      handler (value) {
        let _vm = this;
        _vm.disableBulkDelete = true;
        _vm.selected = [];
        if (!value.rowsPerPage) {
          value.rowsPerPage = _vm.$root.settings.defPageSize;
        }
      },
      deep: true
    },
    selected: {
      handler () {
        let _vm = this;
        let length = _vm.selected.length;
        _vm.disableBulkDelete = !length;
        //small adjust for plural && all
        if (length === 1) {
          _vm.deleteWorkerText = _vm.labels.delete;
        } else if (length === _vm.pagination.rowsPerPage
                    || length === _vm.totalWorkers
                        && length < _vm.pagination.rowsPerPage) {
          _vm.deleteWorkerText = _vm.labels.deleteAll;
        } else {
          _vm.deleteWorkerText = _vm.labels.deleteMany;
        }
      },
      deep: true
    },
    search: {
      handler () {
        let _vm = this;
        if (_vm.search.length >= _vm.minSearchCharacterCount
            || _vm.search.length === 0) {
          _vm.fetchDataFromApi()
          .then(data => {
            _vm.workers = data;
          });
        }
      }
    },
    identity: {
      handler (val) {
        if (val) {
          // load the company info
          this.getCompanyInfo(this.identity.manager.id);
          let companyId = null;
          if (!this.isAdmin()) {
            companyId = this.identity.manager.companyId;
          }
          this.getManagersList(companyId, this.identity.manager.id);
          this.selectedCompany = this.identity.manager.companyId;
        }
      }
    },
  },
  computed: {
    showDeleteBadget() {
      return this.deletedWorkersCount > 0;
    },
    deletedWorkersCount() {
      if (this.deletedWorkers.length > 0) {
        return this.deletedWorkers.length;
      } else {
        '';
      }
    },
    minSearchCharacterCount () {
      return this.$root.settings.defMinSearch;
    },
    listingUrl () {
      let _vm = this;
      if (_vm.identity) {
        let companyId = _vm.selectedCompany || _vm.identity.manager.companyId;
        let baseUrl = process.env.VUE_APP_API_URL;
        if (_vm.perManager) {
          return `${baseUrl}workers/list/manager-${_vm.selectedManager}/`;
        } else {
          return `${baseUrl}workers/list/company-${companyId}/`;
        }
      }
      return '';
    },
    searchUrl () {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      let url = process.env.VUE_APP_API_URL + 'workers/search';
      if (_vm.perManager) {
        url += `/manager-${_vm.selectedManager}/`;
      } else {
        url += `/company-${companyId}/`;
      }
      return url;
    },
    showPer () {
      let _vm = this;
      let status = (_vm.perManager) ? 'Manager' : 'Company';
      return 'Show per ' + status;
    },
    pages () {
      if (this.pagination.rowsPerPage == null) {
        return 0;
      }
      return Math.ceil(this.totalWorkers / this.pagination.rowsPerPage);
    },
    formTitle () {
      return this.editedIndex === -1
              ? this.labels.create
              : this.labels.edit;
    },
    dialogHelpText () {
      return this.editedIndex === -1
              ? this.labels.createHelpText
              : this.labels.editHelpText;
    },
    baseApiUrl () {
      return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    openTrash() {
      //eslint-disable-next-line
      console.log('trash');
    },
    mapText(text) {
      let _vm = this;
      if (!_vm.loading) {
        let transformSearchText = '<b><u>$&</u></b>';
        let regEx = new RegExp(_vm.search, 'ig');
        let output = (_vm.search)
                        ? text.replace(regEx, transformSearchText)
                        : text;
        return output;
      } else {
        return text;
      }
    },
    hasDepartments () {//means without assigned department
      return this.departments.length > 1;
    },
    hasRoles () {//means without assigned role
      return this.roles.length > 1;
    },
    showColumnValue (value, defaultValue = '') {
      if (!value
        || typeof value !== 'undefined'
          && value.length === 0) {
        value = (defaultValue.length) ? defaultValue : '-';
      }
      return value;
    },
    departmentSelected(departments) {
      let _vm = this;
      _vm.selectedDepartments = departments;
      if (departments) {
        _vm.load();
      }
    },
    roleSelected(roles) {
      let _vm = this;
      _vm.selectedRoles = roles;
      if (roles) {
        _vm.load();
      }
    },
    loadDepartments() {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      return new Promise((resolve) => {
        axios({
          url: `${_vm.baseApiUrl}companies/${companyId}/departments`
        }).then(response => {
          let r = response.data;
          if (r.status === 'success' && r.data) {
            resolve(r.data);
          }
        }).catch(err => {
          _vm.$notify(`Can't load departments: ${err.message}`, 'error');
        });
      });
    },
    loadRoles() {
      let _vm = this;
      let companyId = _vm.identity.manager.companyId;
      return new Promise((resolve) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}companies/${companyId}/roles`
        }).then(response => {
          let r = response.data;
          if (r.status === 'success' && r.data) {
            resolve(r.data);
          }
        }).catch(err => {
          _vm.$notify(`Can't load roles: ${err.message}`, 'error');
        });
      });
    },
    getHeadersConfig(header) {
      let _vm = this;
      let baseConfig = [
        'column teal white--text text-xs-center',
        header.sortable ? 'sortable' : '',
        (header.value === _vm.pagination.sortBy
          && header.sortable) ? 'active' : '',
        header.hide && _vm.perManager ? 'hide' : ''
      ];
      let sortableArrow = (_vm.pagination.descending) ? 'desc' : 'asc';
      if (header.sortable) {
        baseConfig.push(sortableArrow);
      }
      return baseConfig;
    },
    isOwner(worker) {
      return (worker.companyId === this.identity.manager.companyId);
    },
    changeView() {
      let _vm = this;
      _vm.reload();
    },
    nextPage (page) {
      let _vm = this;
      let rowsPerPage = _vm.pagination.rowsPerPage;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.pagination.number = ((page-1)*rowsPerPage)+1;
        _vm.workers = data;
      });
    },
    async fetchDataFromApi () {
      let _vm = this;
      _vm.loading = true;
      const {page, rowsPerPage} = _vm.pagination;
      let items = await _vm.loadWorkers(page, rowsPerPage);
      return new Promise((resolve) => {
        if (_vm.pagination.sortBy) {
          items = _vm.sorting(items);
        }
        if (rowsPerPage > 0) {
          items = _vm.paginate(items);
        }
        setTimeout(() => {
          _vm.loading = false;
          _vm.clear();
          if (page === 1) {
            _vm.pagination.number = 1;
          }
          resolve(items);
        }, 1000);
      });
    },
    // eslint-disable-next-line
    async loadWorkers (page, pageSize) {
        let _vm =  this;
        let url;
        let unassignedDepartment = false;
        let unassignedRole = false;
        //let haveMoreQueryParams = false;
        let departments = _vm.selectedDepartments.map(d => d.id);
        if (!departments.length && _vm.$route.query.department) {
          let queryDep = _vm.$route.query.department;
          departments = _vm.departments.filter(d => d.name === queryDep)
            .map(d => d.id);
        }
        let roles = _vm.selectedRoles.map(r => r.id);
        if (!roles.length && _vm.$route.query.role) {
          let queryRole = _vm.$route.query.role;
          roles = _vm.roles.filter(r => r.name === queryRole).map(r => r.id);
        }
        let query = {};
        //
        if (departments.length) {
          unassignedDepartment = departments.includes(null);
          departments = departments.filter(id => id != null);
        }
        if (roles.length) {
          unassignedRole = roles.includes(null);
          roles = roles.filter(id => id != null);
        }
        //
        _vm.noRecordsText = '';
        if (_vm.perManager && !_vm.managers.length) {
            _vm.noRecordsText = 'No matching records found.';
            _vm.totalWorkers = 0;
          return [];
        }
        _vm.loading = true;
        if (_vm.search.length > 0) {
          url = `${_vm.searchUrl}${pageSize}/${page}?q=${_vm.search}`;
        } else {
          url = `${_vm.listingUrl}${pageSize}/${page}?filter=true`;
        }

        //append departments
        if (departments.length) {
          query.departments = departments.join(',');
        }
        //append roles
        if (roles.length) {
          query.roles = roles.join(',');
        }
        //set unassigned department
        if (unassignedDepartment) {
          query.unassignedDepartment = true;
        }

        if (unassignedRole) {
          query.unassignedRole = true;
        }
        //append query if not empty
        if (!_.isEmpty(query)) {
          url += '&' + querystring.stringify(query);
        }
        //console.log(url);

        let worker = await axios.get(url, {
          headers: {
            'X-Auth-Token': _vm.getUserToken()
          }
        });
        let _data = _vm.workers;
        if (worker.data.status === 'success') {
          _data = worker.data.data;
          _vm.totalWorkers = worker.data.totalCount;
          if (_vm.totalWorkers === 0) {
            _vm.noRecordsText = 'No matching records found.';
          }
        } else {
          _vm.$notify(worker.data.message, 'error');
          _vm.noRecordsText = 'No matching records found.';
        }
      return _data;
    },
    sorting (items) {
      const {sortBy, descending} = this.pagination;
      return items.sort((a, b) => {
        const sortA = a[sortBy];
        const sortB = b[sortBy];
        if (descending) {
          if (sortA < sortB) {
            return 1;
          }
          if (sortA > sortB) {
            return -1;
          }
          return 0;
        } else {
          if (sortA < sortB) {
            return -1;
          }
          if (sortA > sortB) {
            return 1;
          }
          return 0;
        }
      });
    },
    paginate (items) {
      const {rowsPerPage} = this.pagination;
      return items.slice(0, rowsPerPage);
    },
    viewItem (item) {
      console.log(item);
    },
    editItem (item) {
      let _vm = this;
      _vm.editedIndex = _vm.workers.indexOf(item);
      item = _.omit(item, [
        'password'
      ]);
      item.timezone = _vm.readableOffset(item.timezone)
                        + ' '
                        + item.timezone_text;
      /*item.defaultStartTime = (item.defaultStartTime)
                              ? _vm.getTimeFromTimestamp(item.defaultStartTime)
                              : _vm.minStarTime;
      item.defaultEndTime = (item.defaultEndTime)
                              ? _vm.getTimeFromTimestamp(item.defaultEndTime)
                              : _vm.maxEndTime;*/
      _vm.editedItem = Object.assign({}, item);
      _vm.dialog = true;
      _vm.$refs.mform.$validator.reset();
    },
    //eslint-disable-next-line
    restoreWorker(id) {
      let _vm = this;
      let baseUrl = process.env.VUE_APP_API_URL;
      axios({
        url: baseUrl + 'workers/restore/' + id,
        headers: {
          'Authorization': 'Bearer ' + _vm.getUserToken()
        }
      }).then(response => {
        let r = response.data;
        if (r.status === 'success') {
          _vm.$notify(r.message, 'success');
          _vm.deletedWorkers = _vm.deletedWorkers
            .filter(w => w.id !== id);
          _vm.load();
        } else {
          _vm.$notify(r.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err, 'error');
      });

    },
    deleteHardWorker(casUserId){
      let _vm = this;
      let baseUrl = process.env.VUE_APP_API_URL;
      axios({
        url: baseUrl + 'workers/hard-delete/' + casUserId,
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + _vm.getUserToken()
        }
      }).then(response => {
        let r = response.data;
        if (r.status === 'success') {
          _vm.$notify(r.message, 'success');
          _vm.deletedWorkers = _vm.deletedWorkers
            .filter(w => w.casUserId !== casUserId);
        } else {
          _vm.$notify(r.message, 'error');
        }
      }).catch(err => {
        _vm.$notify(err, 'error');
      });
    },
    deleteWorkerFromApi(id) {
      let _vm = this;
      let baseUrl = process.env.VUE_APP_API_URL;
        axios({
          url: baseUrl + 'workers/delete/' + id,
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + _vm.getUserToken()
          }
        })
        .then(function(response) {
          let r = response.data;
          if (r.status === 'success') {
            if (!_vm.disableDeleteNotification) {
              _vm.$notify(r.message, 'success');
              //track all workers deleted successfully
              _vm.deleted.push(id);
            }
            //append more if needed...
          } else {
            if (!_vm.disableDeleteNotification) {
              _vm.$notify(r.message, 'error');
            }
            //append more if needed...
          }
          return _vm.loadDeletedWorkers();
        }).then(data => _vm.deletedWorkers = data);
    },
    deleteItem (item, hard=false) {
      let _vm = this;
      const index = this.workers.indexOf(item);
      let ConfirmBoxTitle = 'Delete Manager Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want to delete '
                              + item.firstName
                              + ' '
                              + item.lastName
                              + '?';
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        if (hard) {
          _vm.deleteHardWorker(item.casUserId);
        } else {
          _vm.deleteWorkerFromApi(item.id);
        }
        _vm.workers.splice(index, 1);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    close () {
      let _vm = this;
      _vm.goIntroStep(2);
      _vm.dialog = false;
      //reset validations
      if (_vm.$refs.mform) {
        _vm.$refs.mform.$validator.reset();
        _vm.$refs.mform.reset();
        setTimeout(function(){
          _vm.$refs.mform.resetRole();
        }, 200);
      }
    },
    reload () {
      let _vm = this;
      _vm.pagination = {
        number: 1,
        sortBy: 'id',
        page: 1,
        rowsPerPage: _vm.$root.settings.defPageSize
      };
      _vm.load();
    },
    clear () {
      this.workers = [];
    },
    load () {
      let _vm = this;
      _vm.fetchDataFromApi()
      .then(data => {
        _vm.workers = data;
        return _vm.loadDeletedWorkers();
      }).then(data => {
        _vm.deletedWorkers = data;
      });
    },
    loadDeletedWorkers() {
      let _vm = this;
      return new Promise((resolve, reject) => {
        let url = _vm.listingUrl + '?filter=true&deleted=true';
        axios({url}).then(response => {
          if (response.data.status === 'success') {
            resolve(response.data.data);
          } else {
            reject(response.data);
          }
        }).catch(err => reject(err));
      });
    },
    resetPagination (value) {
      let _vm = this;
      _vm.pagination.page = 1;
      _vm.pagination.rowsPerPage = value;
      _vm.load();
    },
    reset () {
      let _vm = this;
      _vm.closeIntro();
      _vm.editedItem = {};
      _vm.editedIndex = -1;
      _vm.$refs.mform.$validator.reset();
    },
    save () {
      let _vm = this;
      _vm.$refs.mform.submit(_vm.identity.manager.id).then(() => {
        _vm.goIntroStep(3);
        _vm.getManagersList(_vm.identity.manager.companyId,
            _vm.identity.manager.id);
      }, ()=>{});
    },
    toggleAll () {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.workers.slice();
      }
    },
    changeSort (sortable, column) {
      if (sortable) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending;
        } else {
          this.pagination.sortBy = column;
          this.pagination.descending = false;
        }
        this.load();
      }
    },
    bulkDelete () {
      let _vm = this;
      let items = _vm.selected;
      let ConfirmBoxTitle = 'Delete Worker Confirmation';
      let ConfirmBoxMessage = 'Are you sure you want to '
                              + _vm.deleteWorkerText.toLowerCase().trim();
      _vm.disableDeleteNotification = true;
      _vm.$confirm(ConfirmBoxTitle, ConfirmBoxMessage, function(){
        _.each(items, (item) => {
          let index = _vm.workers.indexOf(item);
          _vm.workers.splice(index, 1);
          _vm.deleteWorkerFromApi(item.casUserId);
        });
        setTimeout(() => {
          if (_vm.deleted.length === _vm.selected.length) {
            _vm.$notify('Workers have been deleted successfully!', 'success');
          } else {
            let _failedEntries = _vm.selected.length - _vm.deleted.length;
            _vm.$notify( _failedEntries + ' Workers not deleted!', 'error');
          }
        }, 500);
        _vm.selected = [];// clear selection
      }, function(){});
    },
    workerUrl (worker) {
      let url = process.env.BASE_URL + 'cal/';
      if (this.companyInfo && this.companyInfo.slug) {
        url += this.companyInfo.slug + '/';
      }
      url += worker.slug;
      return url;
    },
    changeSelectorPer () {
      if (!this.perManager) {
        this.changeView();
      } else {
        this.changeView();
      }
    },
    getManagersList (companyId, managerId) {
      let _vm = this;
      let url = `${process.env.VUE_APP_API_URL}managers/list/all`;
      if (companyId) {
        url += '/' + companyId;
      }
      url += '?hasWorkers=1';
      axios({url}).then(response => {
        if (response.data.status === 'success') {
          this.managers = response.data.data;
          for (let manager of this.managers) {
            manager.fullName = manager.firstName + ' ' + manager.lastName;
          }
          if (this.managers.length) {
            let findManager = this.managers.find(m => m.id === managerId);
            this.selectedManager = findManager ? findManager.id
              : this.managers[0].id;
          }
        } else {
          _vm.$notify(`Can't load the list of managers: ${response.message}`,
            'error');
        }
      }).catch(err => {
        _vm.$notify(`Can't load the list of managers: ${err}`, 'error');
      });
    }
  }
};
</script>

<style scoped>
.noVertcalPadding {
  padding-bottom: 0;
  padding-top: 0;
}
.dialogTitle {
  font-weight: bold;
  color: white;
  cursor: default;
}
.dialogHelpText {
  font-style: italic;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.63);
  position: absolute;
  margin-top: 20px;
  cursor: default;
}
.perPage {
  float: right;
  vertical-align: bottom;
  text-align: right;
  margin-right: 15px;
  position: relative;
  padding-top: 13px;
}
/* tables styles */
thead tr th {
  text-align: left;
}
.hide {
  display: none;
}
.perManagerMargin {
  margin-bottom: 20px;
}
table.v-table tbody td:not(:nth-child(1)) {
  padding-left:0;
}
.searchHelpText {
  position: absolute;
  left: 130px;
  margin-top: 20px;
  color:red;
  font-size: 12px;
}
.v-chip .v-avatar {
  height: 32px !important;
  margin-left: -12px;
  margin-right: -4px;
  min-width: 32px;
  width: 18px !important;
}
.v-chip {
  font-weight: bold;
  height: 0px;
  padding: 9px;
}
.v-chip .v-chip__content {
  padding: 0px;
}
</style>
