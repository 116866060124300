<template>
    <v-form>
        <div
            class="dividerWithText"
            style="margin-top: 10px"
            v-if="enableCompanyFields"
        >
            <span class="dividerText">
                Company Details
            </span>
            <v-divider></v-divider>
        </div>
        <v-flex xs12 v-if="enableCompanyFields">
            <v-layout row xs12 wrap>
                <v-flex xs12 style="max-width: 48%;">
                    <v-text-field
                        v-model="form.companyName"
                        label="Company Name"
                        prepend-icon="business"
                        :color="inputColor"
                        ></v-text-field>
                </v-flex>
                <v-flex xs12 style="max-width: 4%;"></v-flex>
                <v-flex xs12 style="max-width: 48%;">
                    <v-text-field
                        v-validate="'slug-unique'"
                        v-model="form.companySlug"
                        :color="inputColor"
                        label="Slug Url"
                        prepend-icon="link"
                        data-vv-name="companyslug"
                        :error-messages="errors.collect('companyslug')"
                        @keyup="setPreviewUrl($event.target.value)"
                        ></v-text-field>
                </v-flex>
            </v-layout>
        </v-flex>
        <span class="slug">{{previewUrl}}</span>
        <div class="dividerWithText" v-if="showSubtitle" >
            <span class="dividerText">
                Personal Details
            </span>
            <v-divider v-if="enableCompanyFields"></v-divider>
        </div>
        <v-flex xs12>
            <v-layout row xs12 wrap>
                <v-flex xs12 style="max-width: 48%;">
                    <v-text-field
                        v-validate="'required|min:3'"
                        v-model="form.firstName"
                        :error-messages="errors.collect('firstname')"
                        label="Firstname"
                        :color="inputColor"
                        data-vv-name="firstname"
                        prepend-icon="person_pin"
                        required
                        ></v-text-field>
                </v-flex>
                <v-flex xs12 style="max-width: 4%;"></v-flex>
                <v-flex xs12 style="max-width: 48%;">
                    <v-text-field
                        v-validate="'required|min:3'"
                        v-model="form.lastName"
                        :error-messages="errors.collect('lastname')"
                        label="Lastname"
                        :color="inputColor"
                        data-vv-name="lastname"
                        prepend-icon="person_pin"
                        required
                        ></v-text-field>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-text-field
            v-validate="'required|email|user-unique:email,'+oldEmail"
            v-model="form.email"
            :error-messages="errors.collect('email')"
            label="E-mail Address"
            :color="inputColor"
            data-vv-name="email"
            prepend-icon="email"
            required
            ></v-text-field>
    <v-flex xs12>
        <v-layout row xs12 wrap>
            <v-flex xs12 style="max-width: 48%;">
                <v-text-field
                    :color="inputColor"
                    v-validate="'min:6'"
                    v-model="form.password"
                    ref="password"
                    :error-messages="errors.collect('password')"
                    label="Password"
                    :type="'password'"
                    data-vv-name="password"
                    autocomplete="off"
                    prepend-icon="lock"
                    required
                ></v-text-field>
            </v-flex>
            <v-flex xs12 style="max-width: 4%;"></v-flex>
            <v-flex xs12 style="max-width: 48%;">
                <v-text-field
                    :color="inputColor"
                    v-validate="'confirmed:password|min:6'"
                    v-model="form.confirmation"
                    :error-messages="errors.collect('confirmation')"
                    label="Confirmation"
                    :type="'password'"
                    data-vv-name="confirmation"
                    autocomplete="off"
                    prepend-icon="lock"
                    required
                ></v-text-field>
            </v-flex>
        </v-layout>
    </v-flex>
    <v-flex xs12>
        <v-layout row xs12 wrap>
            <v-flex xs12 style="max-width: 100%;">
                <v-select
                    :color="inputColor"
                    v-model="form.timezone"
                    v-validate="'required'"
                    data-vv-name="timezone"
                    :error-messages="errors.collect('timezone')"
                    :items="timezoneList"
                    :clearable="true"
                    label="Choose your timezone"
                    prepend-icon="language"
                    solo
                    ></v-select>
            </v-flex>
        </v-layout>
    </v-flex>
    </v-form>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
//import all components
import {
  VForm,
  VInput,
  VTextField,
  VCheckbox,
  VDivider,
  VSelect
} from 'vuetify';

//import all mixins
import {manager} from '@/mixins/manager';
import {timezone} from '../../mixins/timezone';
import {_} from 'vue-underscore';

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'formManager',
  components: {
    VForm,
    VInput,
    VTextField,
    VCheckbox,
    VDivider,
    VSelect
  },
  props: [
    'inputColor',
    'enableCompanyFields',
    'form',
    'showSubtitle',
    'processDialog'
  ],
  mixins: [
    manager,
    timezone
  ],
  mounted() {
    let _vm = this;
    // auto-load timezone data
    _vm.loadReadableTz();
    // init validator
    _vm.$validator.localize('en', _vm.dictionary);
  },
  data () {
    return {
        oldEmail: null,
        oldForm: {},
        signupOnly: true,
        processedThroughDialog: true,
        createManagerOnly: true,
        dictionary: {
            attributes: {
                firstname: 'Firstname',
                lastname: 'Lastname',
                email: 'Email',
                password: 'Password',
                confirmation: 'Confirmation',
                companyname: 'Company Name',
                companyslug: 'Company Slug'
            },
            custom: {
                firstname: {
                    required: () => 'Firstname field is required.'
                },
                lastname: {
                    required: () => 'Lastname field is required.'
                },
                email: {
                    required: () => 'Email field is required.',
                    email: 'Email field must be a valid email.'
                },
                password: {
                    required: () => 'Password field is required.',
                    min: 'Password should be greater than 6 characters '
                },
                confirmation: {
                    required: () => 'Confirmation field is required.',
                    confirmed: 'Password field not match with confirmation.'
                },
                timezone: {
                    required: () => 'Timezone should be selected.'
                }
            }
        }
    };
  },
  watch: {
    form: function(value) {
      let _vm = this;
      if (typeof _vm.processDialog !== 'undefined') {
        _vm.processedThroughDialog = _vm.processDialog;
      }
      if (!_vm.oldEmail && value.id) {
        _vm.oldEmail = value.email;
      }
      if (!value.timezone) {
        // Set default timezone
        let textOffset = _vm.readableOffset(_vm.$root.settings.defWorkerTz);
        value.timezone = `${textOffset} ${_vm.$root.settings.defWorkerTzText}`;
      }
      let NotAllowedProperties = ['id', 'casUserId'];
      _.each(_vm.form, (value, key, obj) => {
        if (!_.contains(NotAllowedProperties, key)) {
          let hasProperty = _vm.oldForm.hasOwnProperty(key);
          if (!hasProperty) {
            _vm.oldForm[key] = obj[key];
          }
        }
      });
    }
  },
  methods: {
    reset: function() {
      this.oldEmail = null;
      this.oldForm = {};
    }
  }
};
</script>
<style scoped>
form {
  width: 560px;
}
.dividerText {
    position: absolute;
    margin-top: -10px;
    background: white;
    left: 40%;
    padding-left: 10px;
    padding-right: 10px;
    color: #777;
    /* font-weight: bold; */
    font-style: italic;
}
</style>
