import axios from 'axios';
/*eslint no-console: ["error", { allow: ["log"] }] */

export const setting = {
  data () {
    return {
      defaultSystemColor: 'blue lighten-1',
      systemTextColor: 'white'
    };
  },
  computed: {
    systemColor() {
      let _vm = this;
      let setting = _vm.getManagerSetting();
      return (setting) ? setting.color: _vm.defaultSystemColor;
    }
  },
  methods: {
    loadManagerSetting() {
      return new Promise((resolve) => {
        let _vm = this;
        let localUser = _vm.getLocalUser();
        // load and set manager settings
        // it is only possible for authenticated users
        let baseUrl = process.env.VUE_APP_API_URL;
        let manUrl = baseUrl + 'settings/load/manager/'
                            + localUser.manager.id;
        let setting = {
          color: _vm.defaultSystemColor
        };
        axios.get(manUrl).then(res => {
          if (res.data.status === 'success') {
            let manSettings = res.data.data.managerSetting;
            //only custom and needed data
            setting = {
              color: manSettings.themeColor
            };
          }
          localStorage.setItem('managerSetting', JSON.stringify(setting));
          resolve(setting);
        });
      });
    },
    getManagerSetting() {
      let setting = localStorage.getItem('managerSetting');
      return JSON.parse(setting);
    }
  }
};
