var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items.length)?_c('v-data-table',{staticStyle:{"border":"3px solid #04796e"},attrs:{"headers":_vm.headers,"items":_vm.items,"pagination":_vm.pagination,"total-items":_vm.totalRows,"select-all":"","no-data-text":_vm.noRecordsText,"rows-per-page-items":_vm.selectPagination,"hide-actions":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',{key:props.index,staticStyle:{"border-bottom":"5px solid #04796e"}},_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:_vm.getHeadersConfig(header),on:{"click":function($event){return _vm.changeSort(header.sortable, header.value)}}},[(header.sortable)?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e(),(header.weekDay)?_c('div',[_vm._v("\n                  "+_vm._s(header.textTop)+"\n              ")]):_vm._e(),_c('div',{class:[
                  (header.weekDay) ? 'week-day-text' : ''
              ]},[_vm._v(_vm._s(header.text))]),(header.value === 'total')?_c('div',{staticClass:"red--text text-darken-4 font-weight-black subheading"},[_vm._v("\n                $"+_vm._s(_vm.calculateTotalPerWeek())+"\n              ")]):_vm._e()],1)}),0)]}},{key:"items",fn:function(props){return [_c('td',{class:[
            'text-xs-center'
        ]},[_vm._v("\n          "+_vm._s(props.item.lastName)+"\n        ")]),_c('td',{class:[
            'text-xs-center'
        ]},[_vm._v("\n          "+_vm._s(props.item.firstName)+"\n        ")]),_vm._l((props.item.durations),function(duration,index){return _c('td',{key:index,class:[
            'text-xs-center'
        ]},[_vm._v("\n          "+_vm._s(duration)+"\n        ")])}),_c('td',{class:[
            'text-xs-center'
        ]},[_vm._v("\n            "+_vm._s(props.item.total.hours)+"\n        ")]),_c('td',{class:[
            'text-xs-center'
        ]},[_vm._v("\n            "+_vm._s(_vm.getColumnValue(props.item.total.overTimeHours))+"\n        ")]),_c('td',{class:[
            'text-xs-center'
        ]},[_vm._v("\n          $"+_vm._s(props.item.total.hourlyPay)+"\n        ")]),_c('td',{class:[
            'text-xs-center'
        ]},[_vm._v("\n          $"+_vm._s(props.item.total.totalAmount)+"\n        ")])]}}],null,false,1056169539)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }