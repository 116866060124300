<template>
  <span>
    <v-tooltip
      bottom
      slot="activator"
    >
      <v-btn
        color="info"
        slot="activator"
        @click="openTuduModal"
      >
        <v-icon dark left>list</v-icon>
        Tudu
      </v-btn>
      <span>Tudu Module</span>
    </v-tooltip>
    <v-dialog v-model="openModal" scrollable max-width="650px">
      <v-card>
        <v-card-title class="teal">
          <span class="headline white--text">Tudu Boards</span>
          <v-spacer></v-spacer>
          <TuduCreateBoard :identity="identity" @create="loadBoards">
          </TuduCreateBoard>
        </v-card-title>
        <v-card-text style="max-height: 550px" class="text-xs-center">
          <v-container>
            <v-progress-linear
              v-show="loading"
              indeterminate
              color="cyan"
            ></v-progress-linear>
            <v-list v-show="!loading" three-line>
              <template v-for="(item, index) in boards">
                <v-list-tile :key="index">
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.slug">
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      Company: {{ item.company }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      Creator: {{ item.creator }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <a :href="createBoardLink(item)" target="_blank">
                    <v-icon>launch</v-icon>
                  </a>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        @click="cloneBoard(item)"
                        v-on="on"
                      >
                        <v-icon color="success">add_to_photos</v-icon>
                      </v-btn>
                    </template>
                    <span>Clone Board</span>
                  </v-tooltip>
                </v-list-tile>
              </template>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="!loading" class="teal">
          <v-btn color="blue lighten-4" @click="close" flat>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>

import axios from 'axios';
import {
  VDialog,
  VProgressLinear,
} from 'vuetify';

import TuduCreateBoard from '@/components/modules/tudu/CreateModal';

export default {
  name: 'TuduBoardsList',
  props: ['identity'],
  components: {
    VDialog,
    VProgressLinear,
    TuduCreateBoard,
  },
  data: () => ({
    boards: [],
    openModal: false,
    loading: false,
  }),
  methods: {
    openTuduModal() {
      this.openModal = true;
      this.loadBoards();
    },
    loadBoards() {
      this.loading = true;
      this.getAllBoards().then(() => {
        this.loading = false;
      }).catch(() => {
        this.$notify('Error to get boards', 'error');
        this.loading = false;
      });
    },
    close() {
      this.openModal = false;
    },
    createBoardLink(board) {
      let host = process.env.VUE_APP_TUDU_HOST;
      let q = `?email=${this.identity.user.email}`;
      return `${host}/${board.company}/${board.boardIndex}${q}`;
    },
    async cloneBoard(board) {
      try {
        const url = `${process.env.VUE_APP_TUDU_API_URL}/board/clone`;
        const response = await axios.post(url, {srcBoard: board._id});
        if (!response.data.success) {
          throw response.data;
        }
        const newBoard = response.data.data.board;
        window.open(this.createBoardLink(newBoard), '_blank');
      } catch (e) {
        //eslint-disable-next-line
        console.error(e);
        this.$notify('Error on clone board!', 'error');
      }
    },
    getAllBoards() {
      let url = `${process.env.VUE_APP_TUDU_API_URL}/board/all`;
      let params = {
        user: this.identity.user.email,
      };
      return new Promise((resolve, reject) => {
        axios.post(url, params).then(response => {
          if (response.data.success) {
            this.boards = response.data.data.boards;
            return resolve(response.data.data);
          }
          reject(response.data);
          }).catch(error => reject(error));
      });
    },
    clickOnBoard() {
    }
  },
  watch: {
  }
};

</script>

<style>

</style>
