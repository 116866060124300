<template>
  <div>
    <v-container style="background: #FFF;">
      <v-layout row wrap>
        <v-flex xs10 offset-xs1>
            <h1>{{page.name}}</h1>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs6>
                      <v-text-field
                          v-validate="'required'"
                          v-model="companyName"
                          data-vv-name="companyName"
                          :error-messages="errors.collect('companyName')"
                          label="Company Name"
                          prepend-icon="business"
                        ></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                      <v-text-field
                          v-validate="'required|slug-unique'"
                          v-model="companySlug"
                          label="Slug Url"
                          prepend-icon="link"
                          data-vv-name="companySlug"
                          :error-messages="errors.collect('companySlug')"
                          @keyup="setPreviewUrl($event.target.value)"
                        ></v-text-field>
                  </v-flex>
                  <span class="slug">{{previewUrl}}</span>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                      <v-select
                         v-model="timezone"
                         v-validate="'required'"
                         data-vv-name="timezone"
                         :error-messages="errors.collect('timezone')"
                         :items="timezoneList"
                         label="Choose your timezone"
                         prepend-icon="language"
                         solo
                       ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      v-model="timeFormatChoise"
                      v-validate="'required'"
                      data-vv-name="timeFormat"
                      :error-message="errors.collect('timeFormat')"
                      :items="timeFormats"
                      label="Choose the system time format"
                      prepend-icon="access_time"
                      ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="dateFormat"
                      v-validate="'required'"
                      data-vv-name="dateFormat"
                      :error-message="errors.collect('dateFormat')"
                      :items="dateFormats"
                      label="Choose the system date format"
                      prepend-icon="calendar_today"
                      ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                      <v-text-field
                          v-validate="'required|numeric'"
                          v-model="minHoursPerWeek"
                          label="Mininum Hours Per Week"
                          prepend-icon="alarm"
                          data-vv-name="minHoursPerWeek"
                          :error-messages=
                            "errors.collect('minHoursPerWeek')"
                          type="number"
                        ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex x12>
                    <v-text-field
                        v-validate="'required|decimal'"
                        v-model="overtimeRate"
                        label="Overtime Rate"
                        prepend-icon="fa-percent"
                        data-vv-name="overtimeRate"
                        @blur="decimalForceFormat('overtimeRate')"
                        :error-messages=
                          "errors.collect('overtimeRate')"
                      ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                 <v-flex xs12>
                    <v-select
                      v-model="startWeekDay"
                      v-validate="'required'"
                      data-vv-name="startWeekDay"
                      :errors-messages="errors.collect('startWeekDay')"
                      label="Week Start On"
                      :items="weekDays"
                      prepend-icon="today"
                      item-text="name"
                      item-value="index">
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex x12>
                    <v-select
                      v-model="payrollDayOn"
                      v-validate="'required'"
                      data-vv-name="payrollDayOn"
                      :errors-messages="errors.collect('payrollDayOn')"
                      label="Payroll Day On"
                      :items="weekDays"
                      prepend-icon="today"
                      item-text="name"
                      item-value="index">
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs1></v-flex>
                  <v-flex xs10>
                    <table class="working-hours-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Week Days</th>
                          <th colspan="2">Working Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="day in weekDays" :key="day.index"
                              :class="{selected: !day.selected}">
                          <td>
                            <v-checkbox color="tail" v-model="day.selected">
                            </v-checkbox>
                          </td>
                          <td>{{day.name}}</td>
                          <td class="time-picker">
                          <vue-ctk-date-time-picker
                            v-if="day.selected"
                            v-model="day.startTime"
                            placeholder="Start time"
                            label="Start time"
                            :format="formatForTime"
                            :formatted="formatForTime"
                            output-format="HH:mm"
                            :minute-interval="5"
                            min-date="08:00"
                            :disabled-hours="disabledHours"
                            max-date="20:00"
                            input-size="sm"
                            :only-time="true"
                            v-validate="'required'"
                            :data-vv-name="'StartTime'+day.index"
                            class="timepicker"
                            style="width: 100%;"
                            color="#009688"
                            noClearButton
                            ></vue-ctk-date-time-picker>

                           <div
                              class="v-messages theme--light error--text"
                              style="margin-top: 5px;margin-left: 10px">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                  {{ mapError('StartTime'+day.index) }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="time-picker">
                          <vue-ctk-date-time-picker
                            v-if="day.selected"
                            v-model="day.endTime"
                            placeholder="End time"
                            label="End time"
                            :format="formatForTime"
                            :formatted="formatForTime"
                            output-format="HH:mm"
                            :minute-interval="5"
                            :disabled-hours="disabledHours"
                            input-size="sm"
                            :only-time="true"
                            v-validate="'required'"
                            :data-vv-name="'EndTime'+day.index"
                            class="timepicker"
                            style="width: 100%;"
                            color="#009688"
                            noClearButton
                            ></vue-ctk-date-time-picker>
                          <div
                              class="v-messages theme--light error--text"
                              style="margin-top: 5px;margin-left: 10px">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                  {{ mapError('EndTime'+day.index) }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-flex>
                  <v-flex xs1></v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs1></v-flex>
                  <v-flex xs2>
                    <v-btn
                        @click="submit"
                        color="teal"
                        dark
                    >Update</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-flex>
      </v-layout>
   </v-container>
   <v-dialog
      v-model="showLoadingBox"
      persistent
      fullscrean
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
   </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {user} from '@/mixins/user';
import {timezone} from '@/mixins/timezone';
import {company} from '@/mixins/company';
import companyUtils from '@/utils/company';
import Uformatter from '@/utils/formatter';
import axios from 'axios';
import {
  VForm,
  VInput,
  VTextField,
  VSelect,
  VProgressLinear,
  VCheckbox
} from 'vuetify';

var _ = require('underscore');

//methods
let methods = {
  submit(event) {
    event.preventDefault();
    let _vm = this;
    _vm.$validator.validate().then(result => {
      var form = {
        CompanyName: _vm.companyName,
        CompanySlug: _vm.companySlug,
        minHoursPerWeek: _vm.minHoursPerWeek,
        startWeekDay: _vm.startWeekDay,
        payrollDay: _vm.payrollDayOn,
        overtimeRate: _vm.overtimeRate,
        timeFormat: _vm.timeFormatChoise,
        dateFormat: _vm.dateFormat,
        workingHours: _vm.weekDays.filter(wd => wd.selected).map(wd => {
          return {
            weekday: wd.index,
            timeStart: wd.startTime,
            timeEnd: wd.endTime,
            companyId: _vm.companyId
          };
        })
      };
      if (result) { // well validated
        let timezoneValues = _vm.getTimezoneValues(_vm.timezone);
        _.extend(form, {
          tz: timezoneValues[0],
          // eslint-disable-next-line camelcase
          tz_text: timezoneValues[1],
        });
        axios({
          method: 'patch',
          url: process.env.VUE_APP_API_URL
                + 'companies/update/'
                + _vm.companyId,
          data: form
        })
        .then(function (response) {
          var r = response.data;
          if (r.status === 'success') {
            _vm.getCompanyInfo(_vm.identity.manager.id)
            .then(data => {
              _vm.$set(_vm.identity, 'company', data);
              let encrypted = _vm.encryptUser(_vm.identity);
              localStorage.setItem('x-simple-tym-user', encrypted);
            });
            _vm.$notify(r.message, 'success');
            _vm.$root.$emit('company-update', _vm.identity.manager.id);
          } else {
            _vm.$notify(r.errors.join('</br>'), 'error');
          }
        })
        .catch(function (error) {
            _vm.$notify(error, 'error');
        });
      }
    });
  },
  loadCompany () {
    let _vm = this;
    let company = _vm.getUser('company');
    _vm.timezone = _vm.readableOffset(company.timezone)
                      + ' '
                      + company.timezone_text;
    _vm.companyId = company.id;
    _vm.companyName = company.name;
    _vm.companySlug = company.slug;
    _vm.minHoursPerWeek = company.minHoursPerWeek;
    _vm.overtimeRate = company.overtimeRate;
    _vm.startWeekDay = company.startWeekDay;
    _vm.payrollDayOn = company.payrollDay;
    _vm.setPreviewUrl(_vm.companySlug);
    _vm.workingHours = company.workingHours;
    _vm.timeFormatChoise = company.timeFormat;
    _vm.dateFormat = company.dateFormat;
  },
  clear() {
    let _vm = this;
    _vm.companyId = 0;
    _vm.timezone = '';
    _vm.companyName = '';
    _vm.companySlug = '';
  },
  mapError (index) {
    let error = this.errors
                    .first(index);
    return (error) ? error.replace(/([A-Z])/g, ' $1')
                          .replace(/[0-9]/g, '')
                          .trim()
                    : '';
  },
};

//
Object.assign(methods, Uformatter);
Object.assign(methods, companyUtils);

export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'Dashboard',
  /*metaInfo: {
    title: 'Company Details',
    titleTemplate: null
  },*/
  mixins: [user, timezone, company],
  components: {
    VForm,
    VInput,
    VTextField,
    VSelect,
    VProgressLinear,
    VCheckbox
  },
  data () {
    return {
      timeFormatChoise: 'AM/PM',
      timeFormats: ['AM/PM', '24-hour'],
      dateFormat: 'dd/mm/yyyy',
      dateFormats: ['dd/mm/yyyy', 'mm/dd/yyyy'],
      disabledHours: [
        '00', '01', '02', '03', '04', '05', '06', '07', '21', '22', '23'
      ],
      weekDays: [
        {
          index: 1,
          name: 'Monday'
        },
        {
          index: 2,
          name: 'Tuesday'
        },
        {
          index: 3,
          name: 'Wednesday'
        },
        {
          index: 4,
          name: 'Thursday'
        },
        {
          index: 5,
          name: 'Friday'
        },
        {
          index: 6,
          name: 'Saturday'
        },
        {
          index: 0,
          name: 'Sunday'
        }
      ],
      timezoneList: [],
      dictionary: {
        attributes: {
          companyName: 'Company Name',
          companySlug: 'Company Slug',
          timezone: 'Timezone',
          minHoursPerWeek: 'Mininum Hours Per Week',
          overtimeRate: 'Overtime Rate'
        },
        custom: {
          companyName: {
            required: () => 'Company Name field is required'
          },
          companySlug: {
            required: () => 'Company Slug field is required'
          },
          timezone: {
            required: () => 'Timezone field is required'
          },
          minHoursPerWeek: {
            required: () => 'Mininum Hours Per Week is required',
            numeric: () => 'Should be a number'
          }
        }
      },
      companyId: 0,
      minHoursPerWeek: 40,
      overtimeRate: 0.00,
      startWeekDay: 0,
      payrollDayOn: 0,
      companyName: '',
      companySlug: '',
      previewUrl: '',
      timezone: '',
      workingHours: [],
      page: {
        name: 'Company Details'
      },
      showLoadingBox: false
    };
  },
  mounted () {
    let _vm = this;
    // init validator
    _vm.$validator.localize('en', this.dictionary);
    _vm.loadReadableTz();
    if (_vm.identity) {
      _vm.loadCompany();
    }
  },
  methods,
  computed: {
    formatForTime () {
      if (this.timeFormatChoise === '24-hour') {
        return 'HH:mm';
      }
      return 'hh:mm A';
    },
  },
  watch: {
    identity: function(value) {
      if (value && !this.companyId) {
        this.loadCompany();
      }
    },
    workingHours: function(value) {
      if (value && value.length) {
        value.forEach(wh => {
          let wd = this.weekDays.find(d => d.index === wh.weekday);
          if (wd !== -1) {
            this.$set(wd, 'selected', true);
            this.$set(wd, 'startTime', wh.timeStart);
            this.$set(wd, 'endTime', wh.timeEnd);
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.slug {
    color:red
}
.working-hours-table {
  margin-top: 15px;
  border: 4px solid rgb(4, 121, 110);
  border-radius: 5px;
  border-collapse: collapse;
}
.working-hours-table td {
  padding: 0px 15px;
}
.working-hours-table td.time-picker {
  min-width: 250px;
}

.working-hours-table tr.selected {
  background-color: #ECEFF1;
}
.working-hours-table tr.selected td {}

/** temp design [can be updated later]**/
.working-hours-table thead th {
  padding: 8px;
}
.working-hours-table thead {
  background: #009688 !important;
  color: white;
  border-bottom: 4px solid rgb(4, 121, 110);
}
.working-hours-table tbody tr {
  border-bottom: 1px solid #009688;
}
.working-hours-table .el-date-editor.el-input{
  margin-top: 15px;
}
</style>
