<template>
  <div class="text-xs-center">
    <v-menu ref="menu" :close-on-content-click="false" offset-y>
      <v-btn
        slot="activator"
        :style="customStyle.button">{{(buttonTitle || itemName) | capitalize}}
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>
      <v-card>
        <v-card-text class="filters-list">
          <v-list >
            <v-list-tile @click="selectAll">
              <v-list-tile-title>
                <v-icon v-show="editedSelectedItems.length !== items.length">
                  check_circle_outline
                </v-icon>
                <v-icon :color="customStyle.item"
                        v-show="editedSelectedItems.length === items.length">
                  check_circle
                </v-icon>
                All / None
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="select(item)"
                         v-for="(item, index) in editedItems"
                         :key="index">

              <v-list-tile-title>
                <v-icon v-show="!item.selected">
                  check_circle_outline
                </v-icon>
                <v-icon
                  :color="customStyle.item"
                  v-show="item.selected">
                  check_circle
                </v-icon>
                {{ displayName(item) }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mx-auto apply-button"
                 style="display: block"
                 dark
                 :color="customStyle.item"
                 small @click="apply">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {VMenu} from 'vuetify';

export default {
  name: 'itemSelector',
  components: {VMenu},
  props: {
    items: Array,
    customStyle: Object,
    itemName: String,
    buttonTitle: {
      type: String,
      required: false
    },
    displayName: {
      type: Function,
      default: i => i.name
    }
  },
  data () {
    return {
      savedItems: [],
      editedItems: []
    };
  },
  mounted() {
    this.$refs.menu.$on('input', open => {
      if (open) {
        this.editedItems = this.savedItems.map(v => this.$_.clone(v));
      }
    });
  },
  methods: {
    apply() {
      this.savedItems = this.editedItems.map(v => this.$_.clone(v));
      this.changed();
      this.$refs.menu.isActive = false;
    },
    changed() {
      this.$emit('selected', this.selectedItems);
    },
    select(item) {
      this.$set(item, 'selected', !item.selected);
    },
    selectAll() {
      let shouldSelec = this.editedSelectedItems.length !== this.items.length;
      for (let item of this.editedItems) {
        this.$set(item, 'selected', shouldSelec);
      }
    },
    open() {
      //eslint-disable-next-line
      console.log("open");
    }
  },
  computed: {
    selectedItems() {
      if (this.savedItems) {
        return this.savedItems.filter(r => r.selected);
      }
    },
    editedSelectedItems() {
      if (this.editedItems) {
        return this.editedItems.filter(r => r.selected);
      }
    }
  },
  watch: {
    items(value) {
      if (this.savedItems.length === 0) {
        this.savedItems = value;
      }
    },
    savedItems(value) {
      this.editedItems = value.map(v => this.$_.clone(v));
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};

</script>

<style scoped>
.v-menu__content {
  background: white;
}
.filters-list {
  max-height: 500px;
  overflow-y: overlay;
  padding-top: 0;
}
.apply-button {
}
</style>
