export const intro = {
  data() {
    return {
      intro: null,
      introOptions: {
        'showButtons': true,
        'showBullets': false,
        'showStepNumbers': false
      },
      introInfo: {
        People: {
          message: 'Click the People button and go to the People page.',
          step: 1
        },
        Calendar: {
          message: 'Go to calendar page.',
          step: 3
        },
        addPeople: {
          message: 'Click this button to add new Person',
          step: 2
        }
      }
    };
  },
  mounted() {
  },
  methods: {
    initIntro(step = 1) {
      if (this.getIntroState() !== -1) {
        //eslint-disable-next-line
        console.log(`initIntro ${step}` );
        setTimeout(() => {
          this.intro = this.$intro().setOptions(this.introOptions)
            .goToStepNumber(step).start();
          window.intro = this.intro;
          this.intro.onskip(this.exitIntro);
        }, 1000);
      }
    },
    exitIntro() {
      localStorage.setItem('introState', -1);
    },
    goIntroStep(state) {
      if (this.intro) {
        this.intro.exit();
      }
      this.initIntro(state);
    },
    closeIntro() {
      this.intro.exit();
      this.intro = null;
    },
    setIntroState(state) {
      if (this.getIntroState() !== -1) {
        localStorage.setItem('introState', state);
      }
    },
    getIntroState() {
      let state = localStorage.getItem('introState');
      if (state) {
        return parseInt(state);
      }
      return 1;
    },
    resetIntroState() {
      localStorage.setItem('introState', 1);
    }
  }

};
