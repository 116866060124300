<template>
  <div>
    <v-navigation-drawer
      v-model="mobileDrawer"
      absolute
      temporary
      style="display:block;"
    >
      <v-list class="pa-1">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <img
              :src="userAvatarSrc"
              alt="Avatar" >
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              {{this.getfullName()}}
            </v-list-tile-title>
            <v-list-tile-sub-title
            style="color:rgb(247, 11, 11);">
              {{ userEmail }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list class="pt-0" dense>
        <v-divider></v-divider>
        <v-list-tile
          v-for="nav in navs"
          :key="nav.name"
          :class="[
            isActiveTab(nav.uri) ? 'active' : ''
          ]"
          @click="$router.push(`/${nav.uri}`)"
        >
          <v-list-tile-action>
            <v-icon>{{ nav.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ nav.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="logout()">
          <v-list-tile-action>
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      class="blue lighten-1"
      fixed
      app
      :clipped-left="clipped">
      <v-toolbar-side-icon
        style="display: block;"
        @click.stop="mobileDrawer = !mobileDrawer">
      </v-toolbar-side-icon>
      <v-toolbar-title>Calendar</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        offset-y>
        <v-btn
          slot="activator"
          icon>
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-list
          class="menu-option">
          <v-list-tile
            @click="today()"
          >
            <v-list-tile-title>
              <v-icon>today</v-icon> Today
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <v-list
          class="menu-option">
          <v-list-tile>
            <v-list-tile-title>
              <v-icon>business</v-icon> Departments
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <v-list>
          <v-list-tile>
            <v-list-tile-title>
              <v-icon>supervised_user_circle</v-icon> Roles
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
  </div>
</template>

<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {_} from 'vue-underscore';
import {user} from '@/mixins/user';
//methods
let methods = {
  logout () {
    let companySlug = this.$route.params.company;
    this.$store.dispatch('logout');
    this.$router.push(`/calendar/${companySlug}`);
  },
  isActiveTab (tab) {
    return this.$router.currentRoute.name === tab;
  },
  today () {
    this.$root.$emit('mobile-today-calendar');
  }
};//append more methods
let computed = {
  userEmail() {
    return (!_.isEmpty(this.identity))
              ? this.identity.user.email
              : '';
  }
};//append more computed
_.extend(methods, user.methods);
_.extend(computed, user.computed);

import {
  VDivider,
  VMenu
} from 'vuetify';

export default {
  name: 'MHeader',

  props: ['identity'],

  components: {
    VDivider,
    VMenu
  },

  data: () => {
    return {
      mobileDrawer: false,
      clipped: false,
      navs: []
      /*navs: [
        {
          name: 'People',
          icon: 'person_outline',
          info: 'Manage all People.',
          uri: 'people',
          show: true
        },
        {
          name: 'Calendar',
          icon: 'event',
          info: 'Manage people schedules (presets, manual).',
          uri: 'calendar',
          show: true
        },
        {
          name: 'Payroll',
          icon: 'attach_money',
          info: 'Weekly Payments.',
          uri: 'payroll',
          show: true
        },
        {
          name: 'Settings',
          icon: 'settings',
          info: 'Admin Settings.',
          uri: 'settings',
          show: true
        }
      ]*/
    };
  },

  computed,

  methods
};
</script>

<style scoped>
.v-menu__content {
  border: 5px solid rgb(33, 120, 189) !important;
}
.menu-option {
  border-bottom: 2px solid #42a5f5 !important;
}
.v-list {
  padding: 0;
}
.v-menu__content {
  left: 0px !important;
  right: 10px !important;
  min-width: 100px !important;
}
</style>
