<template>
  <v-card class="custom-card">
    <v-card-title class="custom-card-title teal">
      <div class="headline custom-card-title-text">{{cardTitle}}</div>
      <div class="card-help-text">{{cardHelpText}}</div>
      <span class="card-close-button" @click="$emit('close')">
      </span>
    </v-card-title>
    <v-card-text
      :style="{maxHeight: contentHeight}"
      class="custom-card-content">
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <span class="card-cancel-button">
        <v-btn color="error" small @click="$emit('close')">
          {{ labels.cancel }}
        </v-btn>
      </span>
      <v-spacer></v-spacer>
      <span class="card-save-button">
        <v-btn
          color="info"
          small
          @click="$emit('save')"
        >{{ labels.save }}</v-btn>
      </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import {crud} from '@/mixins/crud';

export default {
  name: 'CustomCard',
  props: [
    'cardTitle', 'cardHelpText'
  ],
  mixins: [crud],
  data() {
    return {
      windowHeight: 0
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight;
    }
  },
  computed: {
    contentHeight() {
      if (this.windowHeight) {
        return (75 * this.windowHeight / 100) + 'px';
      }
      return '100%';
    }
  }
};
</script>

<style>

.custom-card {
  height: 100%;
}

.custom-card-content {
  position: relative;
  padding: 0;
  overflow-y: auto;
}

.card-save-button {
}

.card-cancel-button {
}

.card-close-button {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 2px;
  right: 5px;
  display: block;
  background-size: contain;
  cursor: pointer;
  background-image: url('/images/error.png');
}

.custom-card-title {
  height: 35px;
  padding: 0 5px;
}

.custom-card-title-text {
  font-weight: bold;
  color: white;
  cursor: default;
  font-size: 16px !important;
  margin-left: 10px;
}

.card-help-text {
  font-style: italic;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.63);
  margin-left: 10px;
}

.v-dialog {
  max-height: none !important;
}
</style>
