import * as moment from 'moment-timezone';
import axios from 'axios';
import {_} from 'vue-underscore';
import encryption from '@/utils/encryption';
 /*eslint no-console: ["error", { allow: ["log"] }] */
export const timezone = {
    methods: {
        timeFormat(m) {
          if (this.companyTimeFormat === '24-hour') {
            return m.format(this.getDefaultTimeFormat());
          }
          return m.format(this.getDefaultTimeFormat12(m));
        },
        setTimezone(time, timezone) {
          if (typeof time === 'number') {
            time = moment.utc(time);
          } else {
            let tFormat = this.getDefaultTimeFormat();
            time = moment.utc(time, tFormat);
          }
          time.utcOffset(parseFloat(timezone));
          return time;
        },
        setUtcTime(time, timezone) {
          time = moment.utc(time, this.getDefaultTimeFormat());
          time.utcOffset(-parseFloat(timezone));
          return time;
        },
        readableOffset(offset) {
            var output = '';
            var timeOffset = moment.unix(Math.abs(offset)*3600)
                                .utcOffset(0)
                                .format(this.getDefaultTimeFormat());
            if (offset === 0) {
                output = 'UTC';
            } else if (offset > 0) {
                output = 'UTC+' + timeOffset;
            } else {
                output = 'UTC-' + (timeOffset);
            }
            return '(' + output + ')';
        },
        getDstOffset(offset) {
            return offset + 1;
        },
        getOffset(time) {
            return moment().utcOffset(time)._offset/60;
        },
        getTimezoneValues(timezoneText) {
            var tv = timezoneText.split(') ', 2);
            var offset = tv[0].replace('(UTC', '').trim();
            return [
                this.getOffset(offset).toString(),
                tv[1].trim()
            ];
        },
        checkDst(timezone) {
            return (typeof timezone !== 'undefined')
                    ? moment().tz(timezone).isDST()
                    : false;
        },
        getDefaultTimeFormat() {
          return 'HH:mm';
        },
        getDefaultTimeFormat12(time) {
          //eslint-disable-next-line
          //debugger;
          if (time && time.minute() === 0) {
            return 'ha';
          }
          return 'hmma';
        },
        getReadableDateFormat() {
          return 'dddd Do of MMMM YYYY';
        },
        timeToDecimalHour(time) {
          return (moment.duration(time).asHours()) * 3600;
        },
        getMobileCalendarDateFormat(timestamp) {
          let currentDate = moment(timestamp*1000).utcOffset(0);
          let year = currentDate.year();
          let format = 'ddd, MMM D';
          if (moment().utcOffset(0).year() !== year) {
            format += ' YYYY';
          }
          return moment.unix(timestamp)
                      .utc()
                      .format(format);
        },
        getTimestampFromTime(time) {
            return moment.utc(time, this.getDefaultTimeFormat())
                        .year(1970)
                        .month(0)
                        .date(1)
                        .unix();
        },
        getTimeFromTimestamp(timestamp) {
            return moment.unix(timestamp)
                        .utc()
                        .format(this.getDefaultTimeFormat());
        },
        getLocaleTimezoneOffet() {
            return new Date().getTimezoneOffset() / 60;
        },
        loadReadableTz() {
          let vm = this;
          // Try to get the timezones from localStorage
          return new Promise((resolve, reject) => {
            let savedTimezones = localStorage.getItem('x-simple-tym-timezones');
            if (savedTimezones) {
              try {
                vm.timezoneList = encryption.decrypt(savedTimezones);
                resolve();
                return;
              } catch (err) {
                //eslint-disable-next-line
                console.log(err);
              }
            }
            axios({
              method: 'get',
              url: process.env.VUE_APP_API_URL + 'timezones'
            })
            .then(function (response) {
              var r = response.data;
              if (r.status === 'success') {
                _.sortBy(r.data, function(timezone) {
                  return timezone.offset;
                });
                _.each(r.data, function(timezone) {
                  var offset = (timezone.isdst
                                  && vm.checkDst(timezone.utc[0]))
                                  ? vm.getDstOffset(timezone.offset)
                                  : timezone.offset;
                  var timezoneText = vm.readableOffset(offset)
                                      + ' '
                                      + timezone.text;
                  vm.timezoneList.push(timezoneText);
                  // Save timezones on localStorage
                  localStorage.setItem('x-simple-tym-timezones',
                    encryption.encrypt(vm.timezoneList));

                  // auto-check if default timezone have been set from backend
                  if (timezone.hasOwnProperty('default') && timezone.default
                      && vm.form && !vm.form.timezone) {
                    vm.$set(vm.form, 'timezone', timezoneText);
                  }
                });
              }
              resolve();
            })
            .catch(function (error) {
              console.log(error);
              reject();
            });
          });
        }
    }
};
