import axios from 'axios';
import * as types from '../store/types';
import _ from 'underscore';
import ip from 'ip';
import userUtils from '@/utils/user';
import companyUtils from '@/utils/company';

_.extend(userUtils, companyUtils);

let methods = _.extend(userUtils, {
  isAdmin () {
    return (!!this.identity
      && this.identity.user.role === 'ROLE_GLOBAL_ADMIN');
  },
  getfullName() {
    return (this.identity)
      ? this.identity.user.firstName
      + ' '
      + this.identity.user.lastName
      : '';
  },
  forceLogout () {
    this.clearUserData();
    // reload page ---> redirect to /login
    this.$router.go();
  },
  //type can be manager or worker
  //params are extra parameters that can be used in this fn
  //eg: params.slug will be used in worker area for load related
  // company worker info if exists
  setLocalUser (type, params) {
    let vm = this;
    let _promise;
    let _dataType = 'manager';
    return new Promise((resolve) => {
      switch (type) {
        case vm.loginType.manager:
          _promise = vm.loadManager();
          break;
        case vm.loginType.worker:
          _promise = vm.loadWorker(params.company);
          _dataType = 'worker';
          break;
        default:
          _promise = vm.loadManager();
      }
      _promise.then(function(data) {
        let managerId = (!_.isEmpty(data.manager))
                          ? data.manager.id
                          : data.worker.managerId;
        return vm.getCompanyInfo(managerId, data);
      }).then(function(data) {
        let encryptedUserData = vm.encryptUser(data);
        let completed = (!data[_dataType]) ? 0 : 1;
        localStorage.setItem('x-simple-tym-user', encryptedUserData);
        resolve(completed);
      }).catch(error => {
        vm.$notify(error, 'error');
        vm.clearUserData();//clear local storage
      });
    });
  },
  processInvalidToken () { //make this action if invalid token
    let vm = this;
    setTimeout(() => {
      vm.forceLogout();
    }, 1000);
  },
  loadUser () {
    let _vm = this;
    return new Promise((resolve) => {
      axios({
        method: 'get',
        url: process.env.VUE_APP_CAS_API_URL + 'users/profile',
        headers: {
          'Authorization': 'Bearer ' + _vm.getUserToken()
        }
      })
        .then(function (response) {
          if (response.data.status === types.REQUEST_SUCCESS) {
            resolve(response.data);
          }
        })
        .catch(function (e) {
          /*eslint no-console: ["error", { allow: ["log"] }] */
          console.log(e);
        });
    });
  },
  loadManager () {
    let vm = this;
    return new Promise((resolve) => {
      vm.loadUser().then(function(result) {
        var casUserId = result.data.id;
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_URL + 'managers/show/' + casUserId
        })
          .then(function (response) {
            if (response.data.status === types.REQUEST_SUCCESS) {
              resolve({
                user: result.data,
                manager: response.data.data
              });
            }
          })
          .catch(function (e) {
            console.log(e);
          });
      });
    });
  },
  loadWorker (company) {
    let vm = this;
    return new Promise((resolve, reject) => {
      vm.loadUser().then(function(result) {
        let casUserId = result.data.id;
        let baseUrl = process.env.VUE_APP_API_URL;
        axios({
          method: 'get',
          url:  `${baseUrl}workers/${company.slug}/show/${casUserId}`
        }).then(function (response) {
          if (response.data.status === types.REQUEST_SUCCESS) {
            resolve({
              user: result.data,
              worker: response.data.data
            });
          } else {
            reject(`This worker does not belong to <b>${company.name}</b>`);
          }
        }).catch(function (e) {
          console.log(e);
        });
      });
    });
  },
  signup (form) {
    let _vm = this;
    // check if existing or new
    let isUpdated = (form.hasOwnProperty('id') && form.id > 0);
    //check if require completion instead save (create|update)
    let isNotCompleted = (form.hasOwnProperty('complete') && form.complete);
    // complete url
    let completeProfileUrl = process.env.VUE_APP_API_URL + 'managers/complete/';
    // normal request url (create|update)
    let requestUrl = isUpdated
      ? process.env.VUE_APP_API_URL
      + 'managers/update/'
      + form.id
      : process.env.VUE_APP_API_URL + 'managers/create';
    //consider complete as manager creation
    if (isNotCompleted) {
      isUpdated = false;
    }
    return new Promise((resolve, reject) => {
      axios({
        method: (isUpdated) ? 'patch' : 'post',
        url: (isNotCompleted) ? completeProfileUrl : requestUrl,
        data: form,
        headers: {
          'X-Ip-Address': ip.address(),
          'Authorization': 'Bearer ' + _vm.getUserToken()
        }
      })
        .then(function (response) {
          var r = response.data;
          if (r.status === 'success') {
            r.status = types.SIGNUP_SUCCESS;
          } else {
            r.status = types.SIGNUP_FAIL;
          }
          resolve(r);
        })
        .catch(function (error) {
          reject(error.message);
        });
    });
  },
  // eslint-disable-next-line max-statements
  async login (form) {
    let result = {};
    let _vm = this;
    let type = form.type;
    let params = form.params;
    //type & params are not needed anymore for call CAS login api
    _.omit(form, ['type','params']);
    try {
      let loginPromise = axios({
        method: 'post',
        url: process.env.VUE_APP_CAS_API_URL + 'users/login',
        data: form,
        headers: {
          'X-Application-Key': process.env.VUE_APP_KEY
        }
      });
      const login = await loginPromise;
      if (login.data.status === types.REQUEST_SUCCESS) {
        let token = login.headers['x-auth-token'];
        // set token
        localStorage.setItem('x-simple-tym-token', token);
        //set user data
        result = await _vm.setLocalUser(type, params);
      } else {
        result = {
          status: 'fail',
          message: login.data.message
        };
      }
    } catch (error) {
      result = {
        status: 'fail',
        message: error.name + ': ' + error.message
      };
    }
    return new Promise((resolve, reject) => {
      (result.status === types.REQUEST_FAIL)
        ? reject(result)
        : resolve(result);
    });
  },
  setAvatar (avatarImage) {
    let _vm = this;
    // Update the local user.
    _vm.$set(_vm.identity.user, 'avatar', avatarImage);
    _vm.$root.$emit('user-changed', _vm.identity.user);
    let encrypted = _vm.encryptUser(_vm.identity);
    localStorage.setItem('x-simple-tym-user', encrypted);
  },
  updateUser(newUser) {
    // Update the local user.
    let _vm = this;
    _vm.$set(_vm.identity, 'user', newUser);
  }
});

export const user = {
  mounted () {
    let vm = this;

    vm.$root.$on('user-changed', function(newUser) {
      vm.updateUser(newUser);
    });
    vm.$root.$on('refresh-identity', function() {
      try {
        vm.identity = vm.getLocalUser();
      } catch (err) {
        vm.forceLogout();
      }
    });

    if (vm.isAuthenticated()) { // check token validity if authenticated
      vm.validateToken().then(function(result) {
        if (result.status === types.REQUEST_FAIL) { //when invalid, force logout
          vm.processInvalidToken();
        }
      });
      vm.$root.$emit('refresh-identity');
      /*if (!vm.identity) {
        vm.forceLogout();
      }*/
    }
  },
  data () {
    return {
      identity: null,
      loginType: {
        manager: 1,
        worker: 2
      }
    };
  },
  computed: {
    currentUser: function() {
      return this.identity ? this.identity.user : {};
    },
    userAvatarSrc: function() {
      if (this.currentUser.avatar) {
        let url = new URL(this.currentUser.avatar,
          process.env.VUE_APP_CAS_API_URL);
        return url.href;
      } else {
          return '/images/blank-avatar.png';
      }
    }
  },
  methods
};
