<template>
    <div>
        <v-container style="background: #FFF;">
            <v-layout row wrap>
                <v-flex xs10 offset-xs1>
                    <h1>{{ page.name }}</h1>
                    <v-flex xs12>
                    <v-layout row xs12 wrap>
                        <v-flex xs5 class="fieldsLabels teal--text">
                        {{ settingLabels.defaultPaginationSize }}:
                        </v-flex>
                        <v-flex xs2>
                            <v-select
                                :items="paginationSizesList"
                                v-model="form.settingsPageSize"
                                :single-line="true"
                                solo
                                :small-chips="true"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    </v-flex>
                    <v-flex xs12>
                    <v-layout row xs12 wrap>
                        <v-flex xs5 class="fieldsLabels teal--text">
                        {{ settingLabels.minSearchingStart }}:
                        </v-flex>
                        <v-flex xs2>
                <v-text-field
                v-validate="'numeric'"
                v-model="form.minSearchingStart"
                :error-messages="errors.collect('minSearchingStart')"
                :color="systemColor"
                data-vv-name="minSearchingStart"
                type="number"
                style="direction:rtl;"
                min="2"
                ></v-text-field>
                        </v-flex>
                    </v-layout>
                    </v-flex>
                    <!--v-flex xs12>
                    <v-layout row xs12 wrap>
                        <v-flex xs5 class="fieldsLabels teal--text">
                        {{ settingLabels.defaultWorkerTimezone }}:
                        </v-flex>
                        <v-flex xs7>
                        <v-select
                        :color="systemColor"
                        v-model="form.timezone"
                        :items="timezoneList"
                        append-icon="language"
                        :clearable="true"
                        solo
                        ></v-select>
                        </v-flex>
                    </v-layout>
                    </v-flex-->
                    <!--v-flex xs12>
                    <v-layout row xs12 wrap>
                        <v-flex xs5 class="fieldsLabels teal--text">
                        {{ system.labels.defaultGuestManagerTimezone }}:
                        </v-flex>
                        <v-flex xs7>
                        <v-select
                        :color="systemColor"
                        v-model="form.guestManagerTimezone"
                        :items="timezoneList"
                        append-icon="language"
                        :clearable="true"
                        solo
                        ></v-select>
                        </v-flex>
                    </v-layout>
                    </v-flex-->
                    <v-flex xs12 style="text-align: center;">
                    <v-btn
                    :color="systemColor"
                    class="white--text"
                    @click="save()"
                    >
                    {{ labels.save }}
                    </v-btn>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {crud} from '@/mixins/crud';
import {timezone} from '@/mixins/timezone';
import {user} from '@/mixins/user';
import {
  VSelect,
  VTextField
} from 'vuetify';
import {_} from 'vue-underscore';
import axios from 'axios';

export default {
  name: 'general-settings',
  props: ['form', 'settingLabels', 'timezoneList'],
  components: {
    VSelect,
    VTextField
  },
  mixins: [
    crud,
    timezone,
    user
  ],
  data () {
    return {
      paginationSizesList: [20, 30, 40, 50],
      systemColor: 'teal',
      page: {
        name: 'General'
      }
    };
  },
  methods:{
    save () {
      let _vm = this;
      let baseUrl = process.env.VUE_APP_API_URL;
      let url = baseUrl + 'settings/save'
                        + '/';
      let workerTimezone = _vm.form.timezone;
      let workerTimezoneValues = _vm.getTimezoneValues(workerTimezone);
      let form = _.omit(_vm.form, ['timezone']);
      //build form
      _.extend(form, {
        defaultWorkerTimezone: workerTimezoneValues[0],
        defaultWorkerTimezoneText: workerTimezoneValues[1]
      });
      //save setting
      axios({
        url: url,
        method: 'PATCH',
        data: form,
        headers: {
          'Authorization': 'Bearer ' + _vm.getUserToken()
        }
      })
      .then(function(response) {
        let r = response.data;
        if (r.status === 'success') {
          _vm.$notify(r.message, 'success');
          //_vm.$root.settings.defWorkerTz = form.defaultWorkerTimezone;
          //_vm.$root.settings.defWorkerTzText = form.defaultWorkerTimezoneText;
          //_vm.$root.settings.defGuestTz = form.defaultGuestManagerTimezone;
          //_vm.$root.settings.defGuestTzText =
          //  form.defaultGuestManagerTimezoneText;
          _vm.$root.settings.defPageSize = form.settingsPageSize;
          _vm.$root.settings.defMinSearch = form.minSearchingStart;
        } else {
          _vm.$notify(r.message, 'error');
        }
      });
    }
  }
};
</script>

<style scoped>
    .fieldsLabels {
        margin-top: 12px;
        font-size: 15px;
        text-align: right;
        font-weight: bold;
    }
</style>
