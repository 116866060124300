<template>
    <div>
        <HomeHeader></HomeHeader>
        <HomeLeftContent></HomeLeftContent>
        <div class="right-content">
            <v-card
              class="elevation-6 right"
              style="width: 450px; top:80px; right: 25px;"
            >
              <v-toolbar dark color="info">
                <v-toolbar-title>{{ page.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items
                  class="hidden-sm-and-down"
                  style="margin-top: 30px;"
                >
                  <v-progress-circular
                    :value="loadingValue"
                    size="25"
                    indeterminate
                    v-show="showLoading">
                  </v-progress-circular>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-form>
                    <v-text-field
                        v-validate="'required'"
                        v-model="username"
                        :error-messages="errors.collect('username')"
                        label="E-mail"
                        data-vv-name="username"
                        prepend-icon="person"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-validate="'required'"
                        v-model="password"
                        :error-messages="errors.collect('password')"
                        label="Password"
                        :type="'password'"
                        data-vv-name="password"
                        autocomplete="off"
                        prepend-icon="lock"
                        required
                      ></v-text-field>
                      <v-checkbox
                        v-model="rememberme"
                        label="Remember Me"
                        type="checkbox"
                      ></v-checkbox>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="submit"
                    color="info"
                    dark
                >Login</v-btn>
              </v-card-actions>
            </v-card>
        </div>
    </div>
</template>
<script>
/*eslint no-console: ["error", { allow: ["log"] }] */
import {user} from '@/mixins/user';
import {setting} from '@/mixins/setting';
//import * as types from '@/store/types';
import HomeHeader from '@/components/layouts/HomeHeader';
import HomeLeftContent from '@/components/layouts/HomeLeftContent';
import {
  VForm,
  VInput,
  VTextField,
  VCheckbox,
  VProgressCircular
} from 'vuetify';
export default {
  // eslint-disable-next-line camelcase
  $_veeValidate: {
    validator: 'new'
  },
  name: 'login',
  metaInfo: {
    title: 'SimpleTym - Manager Login',
    titleTemplate: null
  },
  mixins: [user, setting],
  components: {
    HomeHeader,
    HomeLeftContent,
    VForm,
    VInput,
    VTextField,
    VCheckbox,
    VProgressCircular
  },
  data: () => ({
    username: '',
    password: '',
    rememberme: true,
    showLoading: false,
    loadingValue: 0,
    page: {
      name: 'Manager Login'
    },
    dictionary: {
      attributes: {
        username: 'E-mail Address',
        password: 'Password'
      },
      custom: {
        username: {
          required: 'The email can not be empty'
        },
        password: {
          required: 'Password field is required'
        }
      }
    }
  }),
  created () {
    this.clearUserData();
  },
  mounted () {},
  methods: {
    submit() {
      let vm = this;
      this.$validator.validate().then(result => {
        if (result) { // well validated
          vm.showLoading = true;
          var form = {
            username: vm.username,
            password: vm.password,
            type: vm.loginType.manager
          };
          // login user to cas
          vm.login(form).then(function(completed) {
            if (completed) {
              //load manager setting
              //vm.loadManagerSetting().then(() => {
                vm.showLoading = false;
                // check if we have redirect query
                let redirectUrl = vm.$route.query.r || '/dashboard';
                // redirect to dashboard
                vm.$router.push(redirectUrl);
              //});
            } else {
              //vm.moveTokenTemp();
              //vm.clearToken();
              vm.$router.push('/register-complete');
            }
          }).catch(error => {
            vm.showLoading = false;
            vm.$notify(error.message, 'error');
          });
        }
      });
    },
    clear() {
      // reset properties
      this.username = '';
      this.password = '';
      //reset validations
      this.$validator.reset();
    }
  }
};
</script>
<style scoped>
</style>
