 /*eslint no-console: ["error", { allow: ["log"] }] */
export const theme = {
  data () {
    return {
      blueDropdownStyle: {
        item: '#42a5f5',
        button: {
          'background-color': '#42a5f5 !important',
          border: '2px solid #3197ea !important',
          color: 'white'
        }
      },
      tealDropdownStyle: {
        item: '#009688',
        button: {
          'background-color': '#009688 !important',
          border: '2px solid #0d736a !important',
          color: 'white'
        }
      },
      dayspanDropdownStyle: {
        item: '#009688',
        button: {
          'background-color': 'rgba(0,0,0, 0.3)',
          border: '1px solid #333 !important',
          color: 'white'
        }
      },
      blackDropdownStyle: {
        item: '#3e3d3d',
        button: {
          'background-color': '#3e3d3d !important',
          border: '1px solid #333 !important',
          color: 'white'
        }
      },
      grayButtonStyle: {
        'background-color': '#929596 !important'
      },
      greenButtonStyle: {
        'background-color': '#07bd5f !important'
      },
      blackButtonStyle: {
        'background-color': '#3e3d3d !important'
      },
      warnButtonStyle: {
        'background-color': '#EF5350 !important'
      }
    };
  },
};
